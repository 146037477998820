import axios from "./../lib/axiosForApi";
import { AxiosResponse } from "axios";

interface IRequestOfferForm {
  product: string;
  additionalModules?: string[];
  licenseVolume: number;
  licenseDuration: number;
  message: string;
}

interface IProjectsForm {
  firstName: string;
  lastName: string;
  companyName: string;
  position: number;
  street: string;
  postCode: number;
  city: string;
  email: string;
  phone: string;
  product: string;
  licenseType: string;
  licenseVolume: number;
  additionalModules?: string[];
  currentProvider: number;
  message: string;
  licenseExpirationDate: Date;
  projectStartDate: Date;
  acceptTerms: number;
}

interface IAwarenessTrialsForm {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  countryCode: string;
  postCode: string;
  city: string;
  street: string;
  privacyPolicy: number;
}

class ContactFormsApi {
  /*
    Send request offer contact form request
    HTTP Response: 200 on success with {"success": true} payload, 200 on failure with {"success": false} payload
  */
  async requestOfferSubmit(values: IRequestOfferForm): Promise<AxiosResponse> {
    return axios.post("/contactforms/submit", {
      params: {
        cId: 43,
        f: "UniversalLead",
        data: values,
      },
    });
  }

  /*
    Send projects contact form request
    HTTP Response: 200 on success with {"success": true} payload, 200 on failure with {"success": false} payload
  */
  async projectSubmit(values: IProjectsForm): Promise<AxiosResponse> {
    return axios.post("/contactforms/submit", {
      params: {
        cId: 121,
        f: "ProjectRequest",
        data: values,
      },
    });
  }

  /*
    Send awareness trials form request
    HTTP Response: 200 on success with {"success": true} payload, 200 on failure with {"success": false} payload
  */
  async awarenessTrialsSubmit(values: IAwarenessTrialsForm): Promise<AxiosResponse> {
    return axios.post("/contactforms/submit", {
      params: {
        cId: 161,
        f: "UniversalLead",
        data: values,
      },
    });
  }
}
export default new ContactFormsApi();
