import { TFunction } from "i18next";
import * as Yup from "yup";

export type AwarenessTrainingsTrialsFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  countryCode: string;
  postCode: string;
  city: string;
  street: string;
  privacyPolicy: boolean;
};

export const getValidationSchema = (t: TFunction): Yup.ObjectSchema<AwarenessTrainingsTrialsFormValues> => {
  return Yup.object().shape({
    firstName: Yup.string()
      .max(
        50,
        t("Forms.TooLong", {
          maxLength: "50",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.FirstNameLabel"),
        }) ?? ""
      ),
    lastName: Yup.string()
      .max(
        50,
        t("Forms.TooLong", {
          maxLength: "50",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.LastNameLabel"),
        }) ?? ""
      ),
    companyName: Yup.string()
      .max(
        50,
        t("Forms.TooLong", {
          maxLength: "50",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.CompanyLabel"),
        }) ?? ""
      ),
    email: Yup.string()
      .email(t("Forms.ValidEmail") ?? "")
      .max(
        100,
        t("Forms.TooLong", {
          maxLength: "100",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.CustomerMailLabel"),
        }) ?? ""
      ),
    countryCode: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.CountryLabel"),
        }) ?? ""
      ),
    postCode: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Trials.Form.PostcodeLabel"),
      }) ?? ""
    ),
    city: Yup.string()
      .min(
        2,
        t("Forms.TooShort", {
          minLength: 2,
          field: t("Forms.City"),
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.City"),
        }) ?? ""
      ),
    street: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Address"),
      }) ?? ""
    ),
    privacyPolicy: Yup.boolean()
      .oneOf([true], t("Forms.Required") ?? "")
      .required(t("Forms.Required") ?? ""),
  });
};
