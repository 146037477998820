import { Basket } from "../../../types/basket";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BasketItem } from "./BasketItem";
import { Alert, Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { SummaryBox } from "../../../theme/styledComponents/Basket";
import FormatUtils from "../../../utils/FormatUtils";
import useAuth from "../../../contextProviders/Authentication";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface IBasketOverviewProps {
  readOnly: boolean;
  isFetched: boolean;
  basket?: Basket;
}

export const BasketOverview: FC<IBasketOverviewProps> = ({ readOnly, basket, isFetched }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  if (!isFetched) {
    return (
      <Box sx={{ mt: 2, ml: 4 }}>
        <Skeleton variant="text" sx={{ fontSize: theme.typography.h5 }} width={300} />
        <Skeleton variant="text" width={400} sx={{ fontSize: theme.typography.body1, ml: 2 }} />
        <Skeleton variant="text" width={200} sx={{ fontSize: theme.typography.body1, ml: 2 }} />
      </Box>
    );
  }

  if (!basket || basket.basketItems.length == 0) {
    return (
      <Alert severity="info" variant="outlined">
        {t("Basket.Messages.EmptyBasket")}
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h3" sx={{ mb: 1 }}>
        {t("Basket.Title")}{" "}
        {readOnly && (
          <Button size="small" sx={{ padding: 0 }} onClick={() => navigate("/app/shop/basket")}>
            {t("Basket.Checkout.BtnEditOrder")}
          </Button>
        )}
      </Typography>

      {basket.basketItems.map((item) => (
        <BasketItem key={`basketItem-${item.uid}`} basketItem={item} isCheckoutMode={readOnly} />
      ))}
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} md={8} lg={6}>
          <SummaryBox>
            <Grid container justifyContent="flex-end" sx={{ mb: 4 }}>
              <Grid item xs={8}>
                {t("Basket.SumNet")}
              </Grid>
              <Grid item xs={4} data-testid={"basket-overview_sum-price-net"}>
                <strong>{FormatUtils.getFormattedPrice(basket.sumPriceNet, i18n, user)}</strong>
              </Grid>
              {!basket.onlyNetPrices && (
                <>
                  <Grid item xs={8}>
                    {t("Basket.Tax", {
                      taxPercentage: user && FormatUtils.getFormattedPercent(user.taxPercentage, i18n),
                    })}
                  </Grid>
                  <Grid item xs={4}>
                    <strong>{FormatUtils.getFormattedPrice(basket.tax, i18n, user)}</strong>
                  </Grid>
                  <Grid item xs={8}>
                    {t("Basket.SumGross")}
                  </Grid>
                  <Grid item xs={4}>
                    <strong>{FormatUtils.getFormattedPrice(basket.sumPriceGross, i18n, user)}</strong>
                  </Grid>
                </>
              )}
            </Grid>
          </SummaryBox>
        </Grid>
      </Grid>
    </>
  );
};
