import React, { FC, useMemo, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  DataGridPro,
  GridColumns,
  GridColumnVisibilityModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { LinearProgress } from "@mui/material";
import { useGetBasket, useGetLicenseCenterResellerData } from "../../../lib/queryClient";
import { useTheme } from "@mui/material/styles";
import {
  getLicenseCenterColumnDefinition,
  getLicenseCenterLocales,
  LicenseCenterLoadingOverlay,
  LicenseCenterToolbar,
} from "./";
import { SupportedLocale } from "../../../locales";
import LicenseCenterLicenseEditDialog, { ILicenseCenterLicenseEditDialogApi } from "./LicenseCenterLicenseEditDialog";
import LicenseCenterLicenseRequestDialog, {
  ILicenseCenterLicenseRequestDialogApi,
} from "./LicenseCenterLicenseRequestDialog";
import { useMesExportService } from "../lib/useMesExportService";
import { LicenseCenterAddLicenseResult } from "../../../types/license";
import LicenseCenterAddLicenseDialog, { ILicenseCenterAddLicenseDialogApi } from "./LicenseCenterAddLicense";
import { useCancelSubscriptionService } from "../lib/useCancelSubscriptionService";
import { useUserAppSettings } from "../../../contextProviders/UserAppSettings";
import { useShop } from "../../../contextProviders/Shop";
import LicenseCenterLicenseRemoveDialog, {
  ILicenseCenterLicenseRemoveDialogApi,
} from "./LicenseCenterLicenseRemoveDialog";
import { GridRowId } from "@mui/x-data-grid";

const LicenseCenterResellerGrid: FC = () => {
  const { t, i18n } = useTranslation();
  const { settings: appSettings, setLicenseCenterSettings } = useUserAppSettings();
  const { state: shopState } = useShop();
  const {
    data: licenseData,
    isLoading,
    isInitialLoading,
  } = useGetLicenseCenterResellerData(shopState.emulatedUser ? shopState.emulatedUser.customerNo : undefined);
  const theme = useTheme();
  const licenseEditDialogRef = useRef<ILicenseCenterLicenseEditDialogApi>(null);
  const licenseRemoveDialogRef = useRef<ILicenseCenterLicenseRemoveDialogApi>(null);
  const licenseRequestDialogRef = useRef<ILicenseCenterLicenseRequestDialogApi>(null);
  const licenseAddDialogRef = useRef<ILicenseCenterAddLicenseDialogApi>(null);
  const { downloadExport } = useMesExportService();
  const { data: basket } = useGetBasket();
  const gridApiRef = useGridApiRef();
  const { cancelSubscription } = useCancelSubscriptionService();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const [sortModel, setSortModel] = React.useState<GridSortModel>();

  useEffect(() => {
    if (!columnVisibilityModel && appSettings.licenseCenter?.columns) {
      setColumnVisibilityModel(appSettings.licenseCenter.columns);
    }
    if (!sortModel && appSettings.licenseCenter?.sorting) {
      setSortModel(appSettings.licenseCenter.sorting);
    }
  }, [appSettings, columnVisibilityModel, sortModel]);

  // Array mit allen vorhandenen Produktnamen für den Filter
  const productNamesInList = useMemo(() => {
    const names = _.map(licenseData, _.property("productName"));
    return Array.from(new Set(names)).sort() as string[];
  }, [licenseData]);

  const [pageSize, setPageSize] = useState<number>(50);

  const columns: GridColumns = useMemo(
    () =>
      getLicenseCenterColumnDefinition(
        t,
        theme,
        i18n,
        productNamesInList,
        false,
        licenseEditDialogRef,
        licenseRequestDialogRef,
        licenseRemoveDialogRef,
        downloadExport,
        cancelSubscription,
        basket,
        [],
        shopState.emulatedUser != null
      ),
    [t, theme, i18n, productNamesInList, downloadExport, cancelSubscription, basket, shopState.emulatedUser]
  );
  const gridLocales = getLicenseCenterLocales(i18n.language as SupportedLocale);

  const onLicenseAddSuccess = (response: LicenseCenterAddLicenseResult) => {
    gridApiRef.current.setFilterModel({
      items: [
        {
          columnField: "registrationNumber",
          operatorValue: "equals",
          value: response.license?.registrationNumber,
          id: "regNo",
        },
      ],
    });
  };

  return (
    <>
      <DataGridPro
        sx={{ height: "100%", border: "none", ".MuiDataGrid-row:hover": { background: "rgba(86, 145, 209, 0.08)" } }}
        columns={columns}
        apiRef={gridApiRef}
        disableSelectionOnClick={true}
        rows={licenseData ?? []}
        getRowId={(row) => row.registrationNumber}
        loading={isLoading}
        localeText={gridLocales}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[50, 100, 250, 500]}
        pagination={true}
        components={{
          Toolbar: LicenseCenterToolbar,
          LoadingOverlay: !isInitialLoading ? LinearProgress : LicenseCenterLoadingOverlay,
        }}
        componentsProps={{
          panel: {},
          toolbar: {
            gridIsLoading: isLoading,
            isDistributor: false,
            licenseAddDialogRef: licenseAddDialogRef,
            isEmulated: shopState.emulatedUser != null,
          },
          filterPanel: {
            sx: {
              "& .MuiDataGrid-filterFormValueInput": {
                width: "350px",
              },
            },
            columnsSort: "asc",
          },
        }}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
          setLicenseCenterSettings({ columns: newModel, sorting: sortModel ?? null }).then();
        }}
        columnVisibilityModel={columnVisibilityModel ?? { unlimited: false }}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
          setLicenseCenterSettings({ columns: columnVisibilityModel ?? null, sorting: newSortModel }).then();
        }}
        sortModel={sortModel}
      />
      <LicenseCenterLicenseEditDialog ref={licenseEditDialogRef} />
      <LicenseCenterLicenseRemoveDialog
        ref={licenseRemoveDialogRef}
        onRemove={(registrationNumber: GridRowId) =>
          gridApiRef.current.updateRows([{ registrationNumber: registrationNumber, _action: "delete" }])
        }
      />
      <LicenseCenterLicenseRequestDialog ref={licenseRequestDialogRef} />
      <LicenseCenterAddLicenseDialog ref={licenseAddDialogRef} onSuccess={onLicenseAddSuccess} />
    </>
  );
};

export default LicenseCenterResellerGrid;
