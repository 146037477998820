import axios from "../lib/axiosForApi";
import { Contact } from "../types/contacts";

class ContactsApi {
  async getAll(): Promise<Contact[]> {
    const response = await axios.post("/sales-contacts/search", {
      pids: [97],
      categories: [13, 14, 15, 127, 128],
    });
    return response.status === 200 ? response.data.contacts : null;
  }
}

export default new ContactsApi();
