import axios from "./../lib/axiosForApi";
import { SupportedLocale } from "../locales";
import { CrossgradeConfiguration, CrossgradesInitResult } from "../types/crossgrades";
import { AxiosProgressEvent } from "axios";
import { Basket } from "../types/basket";
import { HierarchicalPriceData } from "../types/shop";

class CrossgradesApi {
  async init(): Promise<CrossgradesInitResult> {
    const response = await axios.get("/api/crossgrades/init");
    return response.status === 200 ? response.data : null;
  }

  async calculate(
    data: CrossgradeConfiguration,
    languageCode: SupportedLocale
  ): Promise<HierarchicalPriceData[] | null> {
    const response = await axios.post(
      "/api/crossgrades/calculate",
      {
        product: data.product,
        duration: data.duration,
        licenseCount: data.licenseCount,
      },
      { params: { "language-code": languageCode } }
    );
    return response.data;
  }

  async upload(file: string | Blob, onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined) {
    const formData = new FormData();

    formData.append("file", file);

    return axios.post("/api/crossgrades/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles = () => axios.get("/api/crossgrades/files");

  async buy(configuration: CrossgradeConfiguration): Promise<Basket> {
    const response = await axios.post("/api/crossgrades/buy", configuration);
    return response.data;
  }
}

export default new CrossgradesApi();
