import React, {
  forwardRef,
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import { Alert, Grid, Typography } from "@mui/material";
import { TextField } from "../../../components/formFields";
import { useFormik } from "formik";
import { AddLicenseFormValues, getValidationSchema } from "../formValidations/addLicense";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KEY_LICENSES, useGetLicenseCenterResellerData } from "../../../lib/queryClient";
import { LicenseApi } from "../../../api";
import { LicenseCenterAddLicenseResult } from "../../../types/license";
import useAuth from "../../../contextProviders/Authentication";
import UserUtils from "../../../utils/UserUtils";
import { LoadingButton } from "@mui/lab";

interface ILicenseCenterAddLicenseDialogProps {
  onSuccess: (addLicenseResponse: LicenseCenterAddLicenseResult) => void;
  ref: MutableRefObject<ILicenseCenterAddLicenseDialogApi>;
}

export interface ILicenseCenterAddLicenseDialogApi {
  openDialog: () => void;
}

const initialFormValues: AddLicenseFormValues = {
  registrationNumberOrLogin: "",
  email: "",
};

const addLicenseRequest = async ({
  registrationNumberOrLogin,
  email,
}: AddLicenseFormValues): Promise<LicenseCenterAddLicenseResult> => {
  return LicenseApi.addLicense(registrationNumberOrLogin, email);
};

const LicenseCenterAddLicenseDialog: ForwardRefExoticComponent<
  Omit<ILicenseCenterAddLicenseDialogProps, "ref"> & RefAttributes<ILicenseCenterAddLicenseDialogApi>
> = forwardRef((props, ref) => {
  const { onSuccess } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: licenseData } = useGetLicenseCenterResellerData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const isDistributor = UserUtils.hasPermission(["distributor"], user);

  const closeDialog = () => {
    setOpen(false);
  };

  const addLicenseToResellerPartner = useMutation(addLicenseRequest, {
    onSuccess: (response) => {
      if (licenseData !== undefined && response.license !== null) {
        queryClient.setQueryData([KEY_LICENSES], [...licenseData, response.license]);
        enqueueSnackbar(t("LicenseCenter.AddLicense.Alert.SUCCESS"), { variant: "success" });
        onSuccess(response);
        closeDialog();
      } else {
        enqueueSnackbar(t(`LicenseCenter.AddLicense.Alert.${response.message}`), { variant: "error" });
      }
      setIsLoading(false);
    },
    onError: () => {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      setIsLoading(false);
    },
  });

  const addLicenseToDistributorPartner = async (registrationNumberOrLogin: string, email: string) => {
    const addLicenseResponse = await LicenseApi.addLicense(registrationNumberOrLogin, email);
    if (addLicenseResponse !== undefined && addLicenseResponse.license !== null) {
      enqueueSnackbar(t("LicenseCenter.AddLicense.Alert.SUCCESS"), { variant: "success" });
      onSuccess(addLicenseResponse);
      closeDialog();
    } else {
      enqueueSnackbar(t(`LicenseCenter.AddLicense.Alert.${addLicenseResponse.message}`), { variant: "error" });
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (isDistributor) {
        await addLicenseToDistributorPartner(values.registrationNumberOrLogin, values.email);
      } else {
        addLicenseToResellerPartner.mutate({
          registrationNumberOrLogin: values.registrationNumberOrLogin,
          email: values.email,
        });
      }
    },
  });

  const openDialog = () => {
    formik.resetForm();
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openDialog: openDialog,
  }));
  // ToDo: Das Form wird zwar validiert, aber nicht "live". Weil die angefassten Felder nicht in "formik.touched" stehen.
  //  wer den Fehler findet, gerne beheben. Ich finde ihn nicht.
  return (
    <Dialog
      show={open}
      handleClose={closeDialog}
      maxWidth="md"
      title={t("LicenseCenter.AddLicense.Title")}
      content={
        <>
          <Typography sx={{ mb: 2 }}>{t("LicenseCenter.AddLicense.Description")}</Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container rowSpacing={0} columnSpacing={1} justifyContent="flex-end">
              <Grid item xs={12} md={6}>
                <TextField
                  formik={formik}
                  name="registrationNumberOrLogin"
                  label={t("LicenseCenter.AddLicense.Form.RegistrationNumberOrLogin")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField formik={formik} name="email" label={t("LicenseCenter.AddLicense.Form.Email")} required />
              </Grid>
              <Grid item xs={12}>
                <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
                  {t("LicenseCenter.AddLicense.BusinessInfo")}
                </Alert>
              </Grid>
              <Grid item xs={12} md={6}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isLoading}
                  disabled={!formik.dirty || !formik.isValid || isLoading}
                >
                  {t("LicenseCenter.AddLicense.Form.SubmitBtn")}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </>
      }
    ></Dialog>
  );
});

export default LicenseCenterAddLicenseDialog;
