import React from "react";
import type { FC } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { dateLocales, getLanguage } from "../../locales";
import { FormikProps, getIn } from "formik";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker/DatePicker.types";

type DatePickerFieldProps<FormValues> = DatePickerProps<Date> & {
  formik: FormikProps<FormValues>;
  label: string;
  name: string;
  value?: string;
  required?: boolean;
  size?: "small" | "medium";
};

const DatePickerField = <FormValues,>(props: DatePickerFieldProps<FormValues>): ReturnType<FC> => {
  const { formik, label, size, name, required, ...rest } = props;
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider adapterLocale={dateLocales[getLanguage(i18n)]} dateAdapter={AdapterDateFns}>
      <DatePicker
        label={required ? `${label}*` : label}
        value={getIn(formik.values, name)}
        onChange={(value) => {
          formik.setFieldValue(name, value);
        }}
        sx={{
          ".MuiIconButton-root": {
            marginRight: 0,
          },
        }}
        slotProps={{
          textField: {
            variant: "outlined",
            error: getIn(formik.touched, name) && !!getIn(formik.errors, name),
            onBlur: formik.handleBlur,
            fullWidth: true,
            size: size ?? "medium",
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
