import type { FC } from "react";
import { Box, Button, Container, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error403: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="lg"
      sx={{ display: "flex", justifyContent: "center", height: "100%", flexDirection: "column" }}
    >
      <Typography align="center" variant={mobileDevice ? "h4" : "h1"}>
        {t("Errors.403.Title")}
      </Typography>
      <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
        {t("Errors.403.Subtitle")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 6,
        }}
      >
        <Button component={Link} variant="outlined" to="/">
          {t("Errors.BackBtn")}
        </Button>
      </Box>
    </Container>
  );
};

export default Error403;
