import { Basket } from "../../../types/basket";
import React, { BaseSyntheticEvent, FC, useEffect, useMemo, useState } from "react";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { BasketApi } from "../../../api";
import { KEY_BASKET_GET, useGetPayments } from "../../../lib/queryClient";
import { useQueryClient } from "@tanstack/react-query";
import { Payment } from "../../../types/shop";
import { useSnackbar } from "notistack";
import { PaymentLabelBox } from "../../../theme/styledComponents/Basket";
import { useNavigate } from "react-router-dom";

interface IBasketPaymentsProps {
  readOnly: boolean;
  basket: Basket;
}

export const BasketPayments: FC<IBasketPaymentsProps> = ({ readOnly, basket }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: payments } = useGetPayments();
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const navigate = useNavigate();

  const handlePaymentMethodChange = useMemo(
    () => async (event: BaseSyntheticEvent) => {
      const changedPayment = _.find(payments, { name: event.target.value });
      if (changedPayment && changedPayment.paymentTypeUid !== basket.paymentType) {
        const updatedBasket = await BasketApi.updatePayment({
          paymentType: changedPayment.paymentTypeUid,
          payment: changedPayment.paymentUid,
        });
        if (updatedBasket) {
          queryClient.setQueryData([KEY_BASKET_GET], updatedBasket);
          setPaymentMethod(changedPayment.name);
        } else {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        }
      }
    },
    [queryClient, basket, payments, enqueueSnackbar, t]
  );

  useEffect(() => {
    const currentPayment = _.find(payments, (payment) => {
      return payment.paymentTypeUid === basket?.paymentType;
    });
    setPaymentMethod(currentPayment?.name ?? "");
  }, [basket, payments]);

  const getCheckedPaymentMethodName = () => {
    return paymentMethod ? (
      <Typography>{t(`Basket.Payments.${paymentMethod}`)}</Typography>
    ) : (
      <Skeleton width={120} height={30} sx={{ mb: 1 }} />
    );
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 1 }}>
        {t("Basket.PaymentType")}{" "}
        {readOnly && (
          <Button size="small" sx={{ padding: 0 }} onClick={() => navigate("/app/shop/basket")}>
            {t("Basket.Checkout.BtnEditOrder")}
          </Button>
        )}
      </Typography>
      {readOnly ? (
        getCheckedPaymentMethodName()
      ) : (
        <FormControl>
          {!payments ? (
            <>
              <Skeleton width={120} height={30} sx={{ mb: 1 }} />
              <Skeleton width={120} height={30} sx={{ mb: 1 }} />
              <Skeleton width={120} height={30} sx={{ mb: 1 }} />
            </>
          ) : (
            <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
              {payments.map((payment: Payment) => (
                <FormControlLabel
                  key={`form-control-label-${payment.name}`}
                  value={payment.name}
                  control={<Radio />}
                  label={
                    <>
                      <PaymentLabelBox key={`payment-box-label-${payment.name}`}>
                        {Array.isArray(payment.icons) &&
                          payment.icons.map((uri: string, iconIndex) => (
                            <img
                              key={`img-${iconIndex}`}
                              src={`${uri}`}
                              alt={t(`Basket.Payments.${payment.name}`)}
                              height="36"
                              style={{ height: "36px", marginRight: "8px" }}
                            />
                          ))}
                      </PaymentLabelBox>
                    </>
                  }
                />
              ))}
            </RadioGroup>
          )}
        </FormControl>
      )}
    </>
  );
};
