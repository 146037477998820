import { SupportedLocale } from "../locales";
import { DownloadFilter, DownloadFilterType, DownloadSearchResult } from "../types/downloads";
import axios from "../lib/axiosForApi";

class DownloadsApi {
  /*
    Return all available download filters of given type
    HTTP Response: always 200 with an array of filters or an empty array if no filters available
   */
  async getFilters(type: DownloadFilterType, languageCode: SupportedLocale): Promise<DownloadFilter[]> {
    const response = await axios.get(`/downloads/${type}`, { params: { "language-code": languageCode } });

    return response.status === 200 ? response.data[type] : [];
  }

  /*
    Return all available downloads
    HTTP Response: always 200 with an array of search results or an empty array if no search results available
   */
  async search(filters: number[], labels: number[], tags: string[]): Promise<DownloadSearchResult[]> {
    const response = await axios.post("/downloads/search", {
      f: filters,
      l: labels,
      t: tags,
    });

    return response.status === 200 ? response.data : [];
  }
}

export default new DownloadsApi();
