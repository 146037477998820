import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import ProjectsOverview from "./components/ProjectsOverview";
import ProjectsForm from "./components/ProjectsForm";
import { Box, Tabs } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import { useParams } from "react-router-dom";
import Tab from "../../components/Tab";

const Projects: FC = () => {
  const { t } = useTranslation();
  const { tabName } = useParams();
  const tabToDisplay = tabName ?? "overview";

  return (
    <Page title={t("Projects.Title")} subtitle={t("Sidebar.Shop.Title")} maxWidth="md">
      <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabToDisplay}>
          <Tab label={t("Projects.Overview.TabLabel")} value="overview" />
          <Tab label={t("Projects.Form.TabLabel")} value="form" />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel activeTab={tabToDisplay} tabName="overview">
          <ProjectsOverview />
        </TabPanel>
        <TabPanel activeTab={tabToDisplay} tabName="form">
          <ProjectsForm />
        </TabPanel>
      </Box>
    </Page>
  );
};

export default Projects;
