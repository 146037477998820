import React, { FC, useState, useCallback, createContext, useContext, useMemo, useReducer, ReactNode } from "react";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Divider,
} from "@mui/material";

interface HandlerFnParams {
  resetState: () => void;
  closeDialog: () => void;
}

type HandlerFn = ((params: HandlerFnParams) => void) | ((params: HandlerFnParams) => Promise<void>);

enum DialogActionTypes {
  OpenDialog = "open",
  CloseDialog = "close",
}

interface IConfirmationDialogProviderProps {
  children?: ReactNode;
}

interface DialogActionPayload {
  title: string;
  body: ReactNode;
  dialogProps?: DialogProps;
  onAccept?: HandlerFn;
  onDecline?: HandlerFn;
  acceptText?: string;
  declineText?: string;
  declineButtonProps?: ButtonProps;
  acceptButtonProps?: ButtonProps;
  dialogContentProps?: DialogContentProps;
  dialogTitleProps?: DialogTitleProps;
  disableButtonAfterAction?: boolean;
}

type DialogAction =
  | {
      type: DialogActionTypes.OpenDialog;
      payload: DialogActionPayload;
    }
  | { type: DialogActionTypes.CloseDialog };

interface DialogState extends DialogActionPayload {
  open: boolean;
}

const initialState = {
  open: false,
  title: "",
  body: "",
};

function reducer(state: DialogState, action: DialogAction): DialogState {
  switch (action.type) {
    case DialogActionTypes.OpenDialog:
      return { open: true, ...action.payload };
    case DialogActionTypes.CloseDialog:
      return { ...state, open: false };
    default:
      return state;
  }
}

const ConfirmationDialogContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getConfirmation: (payload: DialogActionPayload) => {},
});

export const ConfirmationDialogProvider: FC<IConfirmationDialogProviderProps> = ({ children }) => {
  const [dialogState, dispatch] = useReducer(reducer, initialState);

  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const resetState = useCallback(() => {
    setButtonsEnabled(true);
  }, []);
  const closeDialog = useCallback(() => {
    dispatch({ type: DialogActionTypes.CloseDialog });
    resetState();
  }, [resetState]);
  const openDialog = useCallback((payload: DialogActionPayload) => {
    dispatch({ type: DialogActionTypes.OpenDialog, payload });
  }, []);

  const acceptBtnProps = useMemo<ButtonProps>(
    () => ({
      ...(dialogState.acceptButtonProps || {}),
      ...(dialogState.disableButtonAfterAction && { disabled: !buttonsEnabled }),
    }),
    [dialogState.acceptButtonProps, dialogState.disableButtonAfterAction, buttonsEnabled]
  );
  const onAccept = useCallback(() => {
    setButtonsEnabled(false);

    setTimeout(async () => {
      if (dialogState.onAccept) {
        await dialogState.onAccept({ resetState, closeDialog });
      }
      closeDialog();
    });
  }, [closeDialog, dialogState, resetState]);

  const declineBtnProps = useMemo(
    () => ({
      ...(dialogState.declineButtonProps || {}),
      ...(dialogState.disableButtonAfterAction && { disabled: !buttonsEnabled }),
    }),
    [dialogState.declineButtonProps, dialogState.disableButtonAfterAction, buttonsEnabled]
  );
  const onDecline = useCallback(() => {
    setButtonsEnabled(false);

    setTimeout(async () => {
      if (dialogState.onDecline) {
        await dialogState.onDecline({ resetState, closeDialog });
      }
      closeDialog();
    });
  }, [closeDialog, dialogState, resetState]);

  return (
    <ConfirmationDialogContext.Provider value={{ getConfirmation: openDialog }}>
      {children}
      <Dialog
        maxWidth="md"
        {...dialogState.dialogProps}
        open={dialogState.open}
        onClose={(event: unknown, reason: string) => {
          if (reason && reason == "backdropClick") {
            return;
          }
          closeDialog();
        }}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title" {...dialogState.dialogTitleProps}>
          {dialogState.title}
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ whiteSpace: "pre-line" }}
          id="confirmation-dialog-description"
          dividers={false}
          {...dialogState.dialogContentProps}
        >
          {typeof dialogState.body === "string" ? (
            <DialogContentText>{dialogState.body}</DialogContentText>
          ) : (
            dialogState.body
          )}
        </DialogContent>
        <DialogActions>
          {dialogState.declineText && (
            <Button color="primary" variant="outlined" {...declineBtnProps} onClick={onDecline}>
              {dialogState.declineText}
            </Button>
          )}
          <Button color="primary" variant="contained" autoFocus {...acceptBtnProps} onClick={onAccept}>
            {dialogState.acceptText || "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationDialogContext.Provider>
  );
};

export type ConfirmationDialogAction = {
  getConfirmation: (props: DialogActionPayload) => void;
};

export function useConfirmationDialog(): ConfirmationDialogAction {
  return useContext(ConfirmationDialogContext);
}
