import React, { ReactNode, createContext, useContext, useReducer, Dispatch, useMemo } from "react";
import { AppNotification } from "../types/app";
import _ from "lodash";

type NotificationsState = {
  notifications: AppNotification[];
  unreadNotificationsCount: number;
  isSidebarOpen: boolean;
};

export enum NotificationAction {
  SetUnreadNotificationsCount = "setUnreadNotificationsCount",
  SetNotifications = "setNotifications",
  ToggleNotificationBar = "toggleNotificationBar",
}

const initialState: NotificationsState = { notifications: [], unreadNotificationsCount: 0, isSidebarOpen: false };

const AppNotificationsContext = createContext<{
  state: NotificationsState;
  dispatch: Dispatch<{ type: NotificationAction; payload: Partial<NotificationsState> }>;
}>({
  state: initialState,
  dispatch: () => null,
});

function notificationsReducer(
  state: NotificationsState,
  action: { type: NotificationAction; payload: Partial<NotificationsState> }
): NotificationsState {
  switch (action.type) {
    case NotificationAction.SetUnreadNotificationsCount: {
      return { ...state, unreadNotificationsCount: action.payload.unreadNotificationsCount ?? 0 };
    }
    case NotificationAction.SetNotifications: {
      const newNotifications = action.payload.notifications ?? [];
      return {
        ...state,
        notifications: newNotifications,
        unreadNotificationsCount: _.filter(newNotifications, (n) => n.is_read === 0).length,
      };
    }
    case NotificationAction.ToggleNotificationBar: {
      return { ...state, isSidebarOpen: action.payload.isSidebarOpen ?? !state.isSidebarOpen };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const useAppNotificationsState = () => {
  const context = useContext(AppNotificationsContext);
  if (context === null || context === undefined) throw new Error("Please add AppNotificationsProvider");
  return context;
};

export const AppNotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(notificationsReducer, initialState);
  const value = useMemo(() => {
    return { state, dispatch };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.notifications, state.unreadNotificationsCount, state.isSidebarOpen]);

  return <AppNotificationsContext.Provider value={value}>{children}</AppNotificationsContext.Provider>;
};
