import axios from "./../lib/axiosForApi";
import { InvoiceRow } from "../types/invoice";

class InvoiceApi {
  async getAll(): Promise<InvoiceRow[]> {
    const response = await axios.get("/invoices/get-all");
    return response.data.invoices;
  }

  async download(hash: string): Promise<Blob | null> {
    try {
      const response = await axios.post(`/api/invoices/download`, { hash: hash }, { responseType: "blob" });
      return new Blob([response.data], { type: "application/pdf;charset=utf-8" });
    } catch (e) {
      return null;
    }
  }
}

export default new InvoiceApi();
