import React, {
  ReactNode,
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useMemo,
  MutableRefObject,
  useRef,
} from "react";
import { User } from "../types/user";
import { BasketItemSidebar, IBasketItemSidebarApi } from "../pages/shop/components";

type ShopState = { emulatedUser: User | null };

export enum ShopAction {
  SetEmulatedUser = "setEmulatedUser",
}

const initialState: ShopState = {
  emulatedUser: null,
};

const ShopContext = createContext<{
  state: ShopState;
  dispatch: Dispatch<{ type: ShopAction; payload?: Partial<ShopState> }>;
  basketItemSidebar?: MutableRefObject<IBasketItemSidebarApi | null> | null;
}>({
  state: initialState,
  dispatch: () => null,
});

function shopReducer(state: ShopState, action: { type: ShopAction; payload?: Partial<ShopState> }): ShopState {
  switch (action.type) {
    case ShopAction.SetEmulatedUser:
      return { ...state, emulatedUser: action.payload?.emulatedUser ?? null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === null || context === undefined) throw new Error("Please add ShopProvider");
  return context;
};

export const ShopProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(shopReducer, initialState);
  const basketItemSidebar = useRef<IBasketItemSidebarApi>(null);

  const value = useMemo(() => {
    return { state, dispatch, basketItemSidebar };
  }, [state]);

  return (
    <ShopContext.Provider value={value}>
      {children}
      <BasketItemSidebar ref={basketItemSidebar} />
    </ShopContext.Provider>
  );
};
