import React, { FC, useEffect, useState } from "react";
import Page from "../../components/Page";
import ContentBox from "../../components/ContentBox";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Box, Button, Grid, Link, Typography } from "@mui/material";
import useAuth from "../../contextProviders/Authentication";
import { BasketArea } from "../../theme/styledComponents/Basket";
import { useGetBasket } from "../../lib/queryClient";
import { ArticleVariantType } from "../../types/basket";
import { getValidationSchema as addressValidationSchema } from "./formValidations/registrationAddress";
import { getValidationSchema as checkoutValidationSchema, CheckoutFormValues } from "./formValidations/checkout";
import { useShop } from "../../contextProviders/Shop";
import { useFormik } from "formik";
import { ShopApi } from "../../api";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ICheckoutCheckbox } from "../../api/shop";
import {
  BasketAddressData,
  BasketCheckboxes,
  BasketCustomOrderIdForm,
  BasketOverview,
  BasketPayments,
} from "./components";
import { useEffectOnce } from "../../hooks/useEffectOnce";

type CheckoutState = "basket" | "check";

const Basket: FC = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { data: basket, isFetched: basketIsFetched } = useGetBasket();
  const { basketItemSidebar } = useShop();
  const [checkboxes, setCheckboxes] = useState<ICheckoutCheckbox[]>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { checkoutStep } = useParams();
  const [queryParameters] = useSearchParams();
  const checkoutState: CheckoutState = (checkoutStep as CheckoutState) ?? "basket";

  const isReadOnly = checkoutState != "basket";
  const pageTitle = checkoutState != "basket" ? t("Basket.Checkout.Subtitle") : t("Basket.Subtitle");

  useEffect(() => {
    ShopApi.getCheckboxes().then((boxes) => {
      setCheckboxes(boxes ?? []);
    });
  }, []);

  useEffectOnce(() => {
    const errorCode = parseInt(queryParameters.get("error") ?? "0");
    if (Number.isInteger(errorCode) && errorCode > 0) {
      enqueueSnackbar(t("Basket.Messages.PaymentError", { code: errorCode }), {
        variant: "warning",
        autoHideDuration: null,
        preventDuplicate: true,
        persist: true,
        action: (snackbarId) => {
          return (
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              onClick={() => {
                closeSnackbar(snackbarId);
              }}
            >
              {t("Common.Close")}
            </Button>
          );
        },
      });
    }
  });

  const checkAndHandleInvalidBasket = () => {
    let isValid = true;
    basket?.basketItems.forEach((item) => {
      if (!isValid) {
        return;
      }
      const type = item.articleVariantType;
      const isBusiness =
        type == ArticleVariantType.B2B_NEW ||
        type == ArticleVariantType.B2B_UPGRADE ||
        type == ArticleVariantType.B2B_EXTEND ||
        type == ArticleVariantType.B2B_RENEWAL;
      const isNew =
        type === ArticleVariantType.B2B_NEW ||
        type === ArticleVariantType.B2C_NEW ||
        type === ArticleVariantType.ADDON_NEW;
      const is365Product = item.abbreviation == "BM";
      if (!isNew) {
        return;
      }
      try {
        const validationSchema = addressValidationSchema(t, user, isBusiness, is365Product, false);
        validationSchema.validateSync({
          comment: item.comment,
          ...item.registrationAddress,
        });
      } catch (e) {
        basketItemSidebar?.current?.open(item, false, t(`Basket.Messages.InvalidBasketItem`));
        isValid = false;
      }
    });
    return isValid;
  };

  const initialFormValues: CheckoutFormValues = {};

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: checkoutValidationSchema(t, checkboxes ?? []),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      ShopApi.finish(values)
        .then((response) => {
          // WIP: muss noch mal verifiziert werden, ob das alles auch bei codes > 300 funktioniert
          if (response.status === 200) {
            // Redirect zu Externen Zahlungsanbietern
            if (response.redirectUrl) {
              window.location.replace(response.redirectUrl);
            } else {
              navigate(`/app/shop/basket/completed/${response.basketId}`);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 406) {
            error.response.data.errors.forEach((singleError: string) => {
              enqueueSnackbar(t(`Basket.ValidationErrors.406.${singleError}`), { variant: "error" });
            });
          } else {
            enqueueSnackbar(t(`Basket.ValidationErrors.${error.response.status}`), { variant: "error" });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const navigateToCheck = () => {
    if (checkAndHandleInvalidBasket()) {
      navigate("/app/shop/basket/check");
    }
  };

  const finishOrder = () => {
    if (checkAndHandleInvalidBasket()) {
      formik.handleSubmit();
    }
  };

  if (user && user.isBlocked) {
    return (
      <Page title={t("Basket.Title")} subtitle={t("Sidebar.Shop.Title")} maxWidth={"md"}>
        <Alert severity="error" variant="outlined">
          {t("Shop.BlockedUserAlert")}
        </Alert>
      </Page>
    );
  }

  return (
    <Page title={t("Basket.Title")} subtitle={t("Sidebar.Shop.Title")} maxWidth={"lg"}>
      <ContentBox headline={pageTitle}>
        <BasketArea>
          <Box className={"data-box"}>
            <BasketAddressData />
            {basket && <BasketCustomOrderIdForm basket={basket} readOnly={isReadOnly} />}
          </Box>
          <Box className={"payment-box"}>{basket && <BasketPayments readOnly={isReadOnly} basket={basket} />}</Box>
          <Box className={"basket-box"}>
            <BasketOverview readOnly={isReadOnly} isFetched={basketIsFetched} basket={basket} />
            {checkoutState != "basket" && <BasketCheckboxes checkboxes={checkboxes} formik={formik} />}
            {basket && checkboxes && basket.basketItems.length > 0 && (
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={8} lg={6}>
                  {checkoutState == "basket" && (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 4 }}
                      onClick={navigateToCheck}
                      data-testid={"basket_button-order"}
                    >
                      {t("Basket.BtnOrder")}
                    </Button>
                  )}
                  {checkoutState == "check" && (
                    <>
                      <Typography variant="body2" sx={{ mt: 4, mb: 1, textAlign: "left" }}>
                        <Trans
                          i18nKey={`Basket.Checkout.LegalNotice`}
                          components={{
                            termsLink: <Link href={`https://secure.gd/gd-pp-terms-${i18n.language}`} target="_blank" />,
                            privacyLink: <Link href={t("Sidebar.Information.PrivacyPath")} target="_blank" />,
                          }}
                        />
                      </Typography>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={finishOrder}
                        data-testid={"basket_button-finish-order"}
                      >
                        {t("Basket.Checkout.BtnFinishOrder")}
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </BasketArea>
      </ContentBox>
    </Page>
  );
};

export default Basket;
