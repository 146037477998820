import React, { FC, useEffect, useState, useRef, MutableRefObject } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import ContentBox from "../../../components/ContentBox";
import { SelectField, TextField } from "../../../components/formFields";
import { useFormik } from "formik";
import { getValidationSchema } from "../formValidations/newLicense";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { KEY_BASKET_GET, useGetProducts, useInvalidateQueryClientData } from "../../../lib/queryClient";
import { ProductCode } from "../../../types/license";
import { AvailableProduct, SalesLicenseType } from "../../../types/shop";
import { BasketItemApi, ShopApi } from "../../../api";
import { ICalculatedPrice } from "../../../api/shop";
import useAuth from "../../../contextProviders/Authentication";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import { useShop } from "../../../contextProviders/Shop";
import { useQueryClient } from "@tanstack/react-query";
import { SupportedLocale } from "../../../locales";
import { PriceBox } from "./PriceBox";

const dummyProduct: AvailableProduct = {
  productCode: "" as ProductCode,
  licenseCountMin: 0,
  licenseCountMax: 0,
  salesLicenseTypes: [SalesLicenseType.DEFAULT],
  durations: [0],
  category: "BUSINESS",
};

type SelectableProduct = {
  productCode: ProductCode;
  description: string;
};

const NewLicenseCalculator: FC = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { state: shopState } = useShop();
  const userForCalculation = shopState.emulatedUser ?? user;

  const [availableProducts, setAvailableProducts] = useState<SelectableProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<AvailableProduct | null>(null);
  const [currentPriceData, setCurrentPriceData] = useState<ICalculatedPrice | null>(null);
  const [showSubscriptionPrice, setShowSubscriptionPrice] = useState<boolean>(false);
  const { basketItemSidebar } = useShop();
  const queryClient = useQueryClient();
  const { invalidatePayments } = useInvalidateQueryClientData();
  const licenseCountInputRef = useRef<HTMLInputElement>(null);
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const { data: productData, isLoading } = useGetProducts(shopState.emulatedUser?.customerNo ?? undefined);

  const initialFormValues = {
    productCode: "",
    quantity: 1,
    licenseCount: 1,
    duration: "",
    salesLicenseType: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: getValidationSchema(t, selectedProduct ?? dummyProduct),
    onSubmit: async (values) => {
      const priceData = await ShopApi.calculatePrice({
        ...values,
        duration: parseInt(values.duration),
        salesLicenseType: parseInt(values.salesLicenseType),
        customerNo: shopState.emulatedUser?.customerNo ?? undefined,
      });
      setCurrentPriceData(priceData);
      if (!priceData || priceData.subscription == null) {
        setShowSubscriptionPrice(false);
      }
      if (priceData === null) {
        enqueueSnackbar(t("LicenseCenter.EditLicense.Alert.NoPriceFound"), { variant: "info" });
      }
    },
  });

  const formDisabled = isLoading || formik.isSubmitting;

  useEffect(() => {
    if (productData) {
      const productCodes = Object.keys(productData.availableProducts) as ProductCode[];
      const productListSorted = _.sortBy(
        productCodes.map((product: ProductCode): SelectableProduct => {
          return { productCode: product, description: t(`Products.${product}`) };
        }),
        ["description"]
      );
      setAvailableProducts(productListSorted);
    }
  }, [productData, t]);

  const calculatePrice = (
    event: React.ChangeEvent<unknown> | SelectChangeEvent<unknown>,
    ref: MutableRefObject<HTMLInputElement | null> | null = null
  ) => {
    formik.handleChange(event);
    window.clearTimeout(window.GData.buyTimeout);
    window.GData.buyTimeout = window.setTimeout(
      () =>
        formik.submitForm().then(() => {
          if (ref !== null) {
            window.setTimeout(() => ref.current?.focus(), 10);
          }
        }),
      ref ? 500 : 0
    );
  };

  const handleProductChange = (event: SelectChangeEvent<unknown>) => {
    if (productData) {
      const newSelectedProduct = productData.availableProducts[event.target.value as ProductCode];
      setSelectedProduct(newSelectedProduct);
      if (formik.values.licenseCount > newSelectedProduct.licenseCountMax) {
        formik.setFieldValue("licenseCount", newSelectedProduct.licenseCountMax, false);
      }
      if (formik.values.licenseCount < newSelectedProduct.licenseCountMin) {
        formik.setFieldValue("licenseCount", newSelectedProduct.licenseCountMin, false);
      }
      if (!newSelectedProduct.durations.includes(parseInt(formik.values.duration))) {
        formik.setFieldValue("duration", newSelectedProduct.durations[0], false);
      }
      if (newSelectedProduct.category == "BUSINESS") {
        formik.setFieldValue("quantity", 1, false);
      }
      if (!newSelectedProduct.salesLicenseTypes.includes(parseInt(formik.values.salesLicenseType))) {
        formik.setFieldValue("salesLicenseType", SalesLicenseType.DEFAULT, false);
      }

      calculatePrice(event);
    }
  };

  const addToBasket = async () => {
    try {
      const updatedBasket = await BasketItemApi.addNewLicenseToBasket({
        ...formik.values,
        duration: parseInt(formik.values.duration),
        salesLicenseType: parseInt(formik.values.salesLicenseType),
        subscription: showSubscriptionPrice,
      });
      queryClient.setQueryData([KEY_BASKET_GET], updatedBasket);
      await invalidatePayments();
      basketItemSidebar?.current?.open(_.orderBy(updatedBasket.basketItems, ["uid"], ["desc"])[0], true);
    } catch (e) {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    }
  };

  const getQuote = async () => {
    const pdf = await ShopApi.getQuote(
      {
        productCode: formik.values.productCode,
        licenseCount: formik.values.licenseCount,
        quantity: formik.values.quantity,
        durations: selectedProduct?.durations ?? [],
        salesLicenseType: parseInt(formik.values.salesLicenseType),
      },
      i18n.language as SupportedLocale
    );
    if (pdf !== null) {
      saveAs(pdf, `GDATA_Price_Information.pdf`);
    } else {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    }
  };

  if (userForCalculation?.isBlocked) {
    return (
      <ContentBox headline={t("Shop.PriceCalculation")} loading={isLoading}>
        <Alert severity="error" variant="outlined">
          {t("Shop.BlockedUserAlert")}
        </Alert>
      </ContentBox>
    );
  }

  return (
    <ContentBox headline={t("Shop.PriceCalculation")} loading={isLoading}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12} lg={9}>
            <SelectField
              formik={formik}
              label={t(`Forms.Product`)}
              name="productCode"
              required
              disabled={formDisabled}
              onChange={handleProductChange}
              data-testid={"shop-buy-new_select-products"}
            >
              {availableProducts.map((product) => {
                return (
                  <MenuItem value={product.productCode} key={`productItem-${product.productCode}`}>
                    {product.description}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} lg={3}>
            <SelectField
              formik={formik}
              label={t(`Forms.LicenseType`)}
              name="salesLicenseType"
              required
              onChange={(e) => calculatePrice(e)}
              disabled={formDisabled || !selectedProduct}
              data-testid={"shop-buy-sales-license-type"}
            >
              {selectedProduct?.salesLicenseTypes.map((salesLicenseType) => {
                return (
                  <MenuItem value={salesLicenseType} key={`salesLicenseType-${salesLicenseType}`}>
                    {t(`Shop.SalesLicenseTypes.${salesLicenseType}`)}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              formik={formik}
              inputRef={quantityInputRef}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 1,
                max: 50,
                "data-testid": "new-license-calculator_quantity",
              }}
              onChange={(e) => calculatePrice(e, quantityInputRef)}
              label={t(`Common.Quantity`)}
              name="quantity"
              type="number"
              disabled={formDisabled || selectedProduct?.category == "BUSINESS" || !selectedProduct}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              formik={formik}
              inputRef={licenseCountInputRef}
              onChange={(e) => calculatePrice(e, licenseCountInputRef)}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: selectedProduct?.licenseCountMin,
                max: selectedProduct?.licenseCountMax,
                "data-testid": "new-license-calculator_license-count",
              }}
              label={t(`Common.Devices`)}
              name="licenseCount"
              type={"number"}
              disabled={formDisabled || !selectedProduct}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              formik={formik}
              onChange={(e) => calculatePrice(e)}
              label={t("Forms.LicenseDuration")}
              name="duration"
              disabled={formDisabled || !selectedProduct}
              required
              data-testid={"new-license-calculator_duration"}
            >
              {selectedProduct?.durations.map((duration) => {
                return (
                  <MenuItem value={duration} key={`duration-${duration}`} selected={false}>
                    {duration} {t("Common.Months")}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={0} md={12}>
            {productData?.specialEditions && productData.specialEditions.length > 0 && (
              <Alert sx={{ mb: 2 }} variant="outlined" severity="info" icon={<AnnouncementOutlinedIcon />}>
                <>
                  <AlertTitle>{t("Shop.SpecialEditions")}</AlertTitle>
                  {productData.specialEditions.map((specialEdition, index) => {
                    return (
                      <div key={`specialEdition-${index}`}>
                        {t(`Products.${specialEdition.productCode}`)}, {specialEdition.licenseCountMax}{" "}
                        {t("Common.Devices")}, {specialEdition.duration} {t("Common.Months")}
                      </div>
                    );
                  })}
                </>
              </Alert>
            )}
          </Grid>
        </Grid>
      </form>
      {currentPriceData && (
        <Box sx={{ mt: 3, pt: 3, borderTop: 1, borderColor: "divider", textAlign: "right" }}>
          {currentPriceData.subscription != null && (
            <Alert icon={<></>} variant="standard" severity="success" sx={{ mb: 2, p: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setShowSubscriptionPrice(event.target.checked)
                    }
                  />
                }
                label={t("LicenseCenter.EditLicense.SubscriptionPriceText")}
              />
            </Alert>
          )}
          {currentPriceData && userForCalculation && (
            <PriceBox
              isLoading={isLoading || formik.isSubmitting}
              priceData={currentPriceData}
              showSubscriptionPrice={showSubscriptionPrice}
              userForCalculation={userForCalculation}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            />
          )}
          {shopState.emulatedUser == null && (
            <Box sx={{ mt: 4 }}>
              <Button
                onClick={getQuote}
                variant="outlined"
                sx={{ mr: 1 }}
                size="large"
                disabled={isLoading || formik.isSubmitting}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                {t("Shop.QuoteBtn")}
              </Button>
              <Button
                size="large"
                variant="contained"
                disabled={isLoading || formik.isSubmitting}
                startIcon={<AddShoppingCartOutlinedIcon />}
                onClick={addToBasket}
                data-testid={"new-license-calculator_buy-button"}
              >
                {t("Shop.BuyBtn")}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </ContentBox>
  );
};

export default NewLicenseCalculator;
