import { TFunction } from "i18next";
import * as Yup from "yup";

export type VerifyFormValues = {
  verificationCode: string;
};

export const getValidationSchema = (
  t: TFunction,
  type: "one-time-password" | "recovery-code"
): Yup.ObjectSchema<VerifyFormValues> => {
  const isRecoveryType = type === "recovery-code";
  const codeLength = isRecoveryType ? 8 : 6;

  return Yup.object().shape({
    verificationCode: Yup.string()
      .min(
        codeLength,
        t("Forms.TooShort", {
          minLength: codeLength,
          field: isRecoveryType ? t("Mfa.VerifyForm.RecoveryCode") : t("Mfa.VerifyForm.VerificationCode"),
        }) ?? ""
      )
      .max(
        codeLength,
        t("Forms.TooLong", {
          maxLength: codeLength,
          field: isRecoveryType ? t("Mfa.VerifyForm.RecoveryCode") : t("Mfa.VerifyForm.VerificationCode"),
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: isRecoveryType ? t("Mfa.VerifyForm.RecoveryCode") : t("Mfa.VerifyForm.VerificationCode"),
        }) ?? ""
      ),
  });
};
