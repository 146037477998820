import type { FC, ReactNode } from "react";
import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../contextProviders/Authentication";

interface MfaGuardProps {
  children: ReactNode;
}

const MfaGuard: FC<MfaGuardProps> = (props) => {
  const { children } = props;
  const { isMfaEnabled, isMfaVerified } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;
  const { from } = (location.state as { from: string }) || {
    from: { pathname: "/app" },
  };

  if (isMfaEnabled && isMfaVerified) {
    return <Navigate to={from} />;
  }

  if (isMfaEnabled && !isMfaVerified && pathname.slice(0, 4) !== "/mfa") {
    return <Navigate to="/mfa/verify/one-time-password" state={{ from: pathname }} />;
  }

  return <>{children}</>;
};

export default MfaGuard;
