import axios from "./../lib/axiosForApi";
import { User } from "../types/user";

class PartnerViewerApi {
  async findUser(username: string): Promise<User | null> {
    const response = await axios.post("/partner-viewer/find-user", { username: username });
    if (response.status == 200) {
      return response.data.user.data;
    }
    return null;
  }
}

export default new PartnerViewerApi();
