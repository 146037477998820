import type { FC } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Footer from "./components/Footer";
import NavbarDefaultLayout from "./components/NavbarDefaultLayout";

interface DefaultLayoutProps {
  centeredContent?: boolean;
}

const DefaultLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  paddingTop: 64,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100vh",
  "&::before": {
    content: "' '",
    display: "block",
  },
}));

const DefaultLayoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flex: "1 1 auto",
  margin: "0 auto",
  width: "100%",
  padding: theme.spacing(2),
  maxWidth: theme.breakpoints.values.lg,
  "& >.MuiPaper-root": {
    padding: "32px",
  },
}));

const DefaultLayoutContainerCentered = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  padding: theme.spacing(2),
  maxWidth: theme.breakpoints.values.lg,
  "& >.MuiPaper-root": {
    padding: "32px",
  },
}));

const DefaultLayout: FC<DefaultLayoutProps> = ({ centeredContent }) => {
  return (
    <DefaultLayoutRoot>
      <NavbarDefaultLayout />
      {centeredContent === true ? (
        <DefaultLayoutContainerCentered>
          <Outlet />
        </DefaultLayoutContainerCentered>
      ) : (
        <DefaultLayoutContainer>
          <Outlet />
        </DefaultLayoutContainer>
      )}
      <Footer />
    </DefaultLayoutRoot>
  );
};

export default DefaultLayout;
