import { SupportedLocale } from "../../../locales";
import {
  deDE as gridLocaleDE,
  enUS as gridLocaleEN,
  frFR as gridLocaleFR,
  nlNL as gridLocaleNL,
  itIT as gridLocaleIT,
  esES as gridLocaleES,
  GridLocaleText,
} from "@mui/x-data-grid-pro";

export function getLicenseCenterLocales(languageCode: SupportedLocale): Partial<GridLocaleText> {
  const locales: Record<SupportedLocale, Partial<GridLocaleText>> = {
    de: gridLocaleDE.components.MuiDataGrid.defaultProps.localeText,
    en: gridLocaleEN.components.MuiDataGrid.defaultProps.localeText,
    fr: gridLocaleFR.components.MuiDataGrid.defaultProps.localeText,
    nl: gridLocaleNL.components.MuiDataGrid.defaultProps.localeText,
    it: gridLocaleIT.components.MuiDataGrid.defaultProps.localeText,
    es: gridLocaleES.components.MuiDataGrid.defaultProps.localeText,
  };

  return locales[languageCode];
}
