class FilterUtils {
  /**
   * Return filtered array elements based on the search text.
   *
   * @param {Array} mainArr
   * @param {String} searchText
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static filterArrayByString(mainArr: any[], searchText: string) {
    if (searchText === "") {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
  }

  /**
   * Determine whether the search text is included in the given object.
   *
   * @param {Object} itemObj
   * @param {String} searchText
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static searchInObj(itemObj: Record<string, any>, searchText: string) {
    if (!itemObj) {
      return false;
    }

    const propArray = Object.keys(itemObj);

    for (let i = 0; i < propArray.length; i += 1) {
      const prop = propArray[i];
      const value = itemObj[prop];

      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Determine whether the search text is included in the given array.
   *
   * @param {Array} arr
   * @param {String} searchText
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static searchInArray(arr: any[], searchText: string) {
    arr.forEach((value) => {
      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
      return false;
    });
    return false;
  }

  /**
   * Determine whether the search text is included in the given value.
   *
   * @param {String} value
   * @param {String} searchText
   */
  static searchInString(value: string, searchText: string) {
    return value.toLowerCase().includes(searchText);
  }
}

export default FilterUtils;
