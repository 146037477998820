import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Skeleton, TextField as MuiTextField, Typography } from "@mui/material";
import { MfaApi } from "../../../api";

const RecoveryCodesDialogContent: FC<{ handleCloseDialog: () => void }> = ({ handleCloseDialog }) => {
  const { t } = useTranslation();
  const [recoveryCodes, setRecoveryCodes] = useState<string | null>(null);

  useEffect(() => {
    MfaApi.recoveryCodesGenerate().then((codes) => {
      setRecoveryCodes(codes);
    });
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        {t("Mfa.RecoveryCodes.Title")}
      </Typography>
      <Typography>
        {t("Mfa.RecoveryCodes.Description")} <strong>{t("Mfa.RecoveryCodes.SecurityHint")}</strong>
      </Typography>
      {recoveryCodes === null ? (
        <Skeleton variant="rectangular" width="100%" height={217} sx={{ my: 2 }} />
      ) : (
        <>
          <MuiTextField fullWidth multiline rows={8} sx={{ my: 2 }} value={recoveryCodes} />
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleCloseDialog}>
              {t("Common.Close")}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default RecoveryCodesDialogContent;
