import { Box, Card, CardContent, CardMedia, Link, styled } from "@mui/material";
import { FC, MouseEventHandler } from "react";
import { Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import EmailIcon from "@mui/icons-material/Email";
import { Contact } from "../../../types/contacts";

export interface ITeamTileProps {
  action?: MouseEventHandler;
  classes?: string;
  teamMember: Contact;
  cta?: string;
  headline?: string;
  link?: string;
  subline?: string;
}

const TeamMemberCard = styled(Card)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minHeight: "210px",
}));

const TeamMemberTile: FC<ITeamTileProps> = (props: ITeamTileProps) => {
  const { headline, subline, teamMember } = props;
  const image = `${process.env.REACT_APP_TYPO3_DOMAIN}${teamMember.image}`;
  const name = `${teamMember.firstName} ${teamMember.lastName}`;

  return (
    <TeamMemberCard variant="outlined" sx={{}}>
      <CardMedia component="img" alt="Photo Team Member" image={image} title={name} />
      <CardContent sx={{ pl: 3, pr: 3, pt: 1, pb: 1, flexGrow: 2 }}>
        {teamMember.firstName && (
          <>
            <Typography variant="h5" component="div">
              {name}
            </Typography>
            <Typography variant="body2" component="div">
              {teamMember.position && <span>{teamMember.position}</span>}
            </Typography>
          </>
        )}
        {headline && (
          <Typography variant="h5" component="div" sx={{ textAlign: "center", pb: 2 }}>
            {headline}
          </Typography>
        )}
        <>
          {subline && (
            <Typography variant="body2" component="div">
              {subline}
            </Typography>
          )}
        </>
      </CardContent>
      <CardContent sx={{ pt: 2, pb: 1, pl: 3, pr: 3 }}>
        <>
          {teamMember.phone && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneIcon sx={{ pr: 1 }} />
              <Typography variant="body2" component="div">
                {teamMember.phone}
              </Typography>
            </Box>
          )}
          {teamMember.fax && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FaxIcon sx={{ pr: 1 }} />
              <Typography variant="body2" component="div">
                {teamMember.fax}
              </Typography>
            </Box>
          )}
          {teamMember.email && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EmailIcon sx={{ pr: 1 }} />
              <Link variant="body2" href={`mailto:${teamMember.email}`} color="inherit">
                {teamMember.email}
              </Link>
            </Box>
          )}
        </>
      </CardContent>
    </TeamMemberCard>
  );
};

export default TeamMemberTile;
