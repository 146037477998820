import React, { FC } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Badge, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useGetBasket } from "../../../lib/queryClient";

const MiniBasket: FC = () => {
  const { data: basket } = useGetBasket();

  return (
    <IconButton color="inherit" component={RouterLink} to="/app/shop/basket">
      <Badge badgeContent={basket ? basket.basketItems.length : 0} color="primary">
        <ShoppingCartOutlinedIcon />
      </Badge>
    </IconButton>
  );
};

export default MiniBasket;
