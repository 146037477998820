import React, { FC, MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { Box, Button, Divider, LinearProgress, Tooltip } from "@mui/material";
import ContentBox from "../../components/ContentBox";
import { KEY_RESELLERS, useGetResellerForDistributor } from "../../lib/queryClient";
import { getLicenseCenterLocales } from "./components";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowModel,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { SupportedLocale } from "../../locales";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ResellerEditDialog, { IResellerEditDialogApi } from "./components/ResellerEditDialog";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { ResellerApi } from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useConfirmationDialog } from "../../contextProviders/ConfirmationDialog";
import { Reseller } from "../../types/license";
import { Link } from "react-router-dom";

interface IResellerToolbarProps {
  resellerEditDialogRef: MutableRefObject<IResellerEditDialogApi>;
  gridIsLoading: boolean;
}

function ResellerToolbar(params: IResellerToolbarProps) {
  const { gridIsLoading, resellerEditDialogRef } = params;
  const { t } = useTranslation();

  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter debounceMs={750} variant="standard" sx={{ flexGrow: 1 }} />
      <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
      <Button
        size="small"
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={() => resellerEditDialogRef.current.openDialog(null)}
        disabled={gridIsLoading}
      >
        {t(`ResellerManagement.Actions.Add`)}
      </Button>
    </GridToolbarContainer>
  );
}

const ResellerManagement: FC = () => {
  const { t, i18n } = useTranslation();
  const { data: resellers, isLoading } = useGetResellerForDistributor();
  const [pageSize, setPageSize] = useState<number>(50);
  const gridLocales = getLicenseCenterLocales(i18n.language as SupportedLocale);
  const resellerEditDialogRef = useRef<IResellerEditDialogApi>(null);
  const queryClient = useQueryClient();
  const { getConfirmation } = useConfirmationDialog();

  const deleteReseller = useMutation(
    (resellerId: number) => {
      return ResellerApi.delete(resellerId);
    },
    {
      onSuccess: (resellerId: number) => {
        if (resellers && resellerId !== 0) {
          queryClient.setQueryData(
            [KEY_RESELLERS],
            resellers.filter((r) => r.uid != resellerId)
          );
        }
      },
      onError: () => {},
    }
  );

  const handleResellerDeleteClick = (resellerToDelete: GridRowModel<Reseller>) => {
    getConfirmation({
      title: t(`ResellerManagement.DeleteDialog.Title`),
      body: t("ResellerManagement.DeleteDialog.Text", { name: resellerToDelete.name }),
      acceptText: t(`ResellerManagement.DeleteDialog.Accept`),
      declineText: t(`ResellerManagement.DeleteDialog.Decline`),
      disableButtonAfterAction: true,
      onAccept: () => deleteReseller.mutate(resellerToDelete.uid),
    });
  };

  const columns: GridColumns = [
    {
      field: "number",
      headerName: t(`ResellerManagement.Columns.Number`),
      type: "number",
      width: 125,
    },
    {
      field: "name",
      headerName: t(`ResellerManagement.Columns.Name`),
      type: "string",
      flex: 0.333,
    },
    {
      field: "city",
      headerName: t(`ResellerManagement.Columns.City`),
      type: "string",
      flex: 0.333,
    },
    {
      field: "email",
      headerName: t(`ResellerManagement.Columns.EMail`),
      type: "string",
      flex: 0.333,
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      type: "actions",
      disableExport: true,
      renderHeader: () => "",
      getActions: (params) => {
        return [
          <Tooltip title={t(`ResellerManagement.Actions.Edit`)}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label={t(`ResellerManagement.Actions.Edit`)}
              className="textPrimary"
              onClick={() => resellerEditDialogRef?.current?.openDialog(params.row)}
              color="primary"
              showInMenu={false}
            />
          </Tooltip>,
          <Tooltip title={t(`ResellerManagement.Actions.Delete`)}>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label={t(`ResellerManagement.Actions.Delete`)}
              className="textPrimary"
              onClick={() => handleResellerDeleteClick(params.row)}
              color="primary"
              showInMenu={false}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <>
      <Page
        title={t("ResellerManagement.Title")}
        subtitle="Shop"
        maxWidth={"xl"}
        buttons={[
          <Button variant="outlined" component={Link} to={"/app/shop/license-center"}>
            {t("ResellerManagement.LicenseCenterButton")}
          </Button>,
        ]}
      >
        <ContentBox>
          <Box sx={{ width: "100%", height: "max(500px,80vh)" }}>
            <DataGridPro
              sx={{ height: "100%", border: "none" }}
              columns={columns}
              disableColumnFilter={true}
              disableColumnPinning={true}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              rows={resellers ?? []}
              getRowId={(row) => row.uid}
              loading={isLoading}
              localeText={gridLocales}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[50, 100, 250, 500]}
              pagination={true}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: ResellerToolbar,
              }}
              componentsProps={{
                toolbar: {
                  resellerEditDialogRef: resellerEditDialogRef,
                  gridIsLoading: isLoading,
                },
              }}
            />
          </Box>
        </ContentBox>
        <ResellerEditDialog ref={resellerEditDialogRef} />
      </Page>
    </>
  );
};

export default ResellerManagement;
