import { useTranslation } from "react-i18next";
import { LinearProgress, Typography } from "@mui/material";
import React from "react";

export function LicenseCenterLoadingOverlay() {
  const { t } = useTranslation();
  return (
    <>
      <LinearProgress />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          textAlign: "center",
        }}
      >
        <Typography>{t("LicenseCenter.InitialLoading")}</Typography>
      </div>
    </>
  );
}
