import {
  de as dateDe,
  enGB as dateEnGB,
  es as dateEs,
  fr as dateFr,
  it as dateIt,
  nl as dateNl,
} from "date-fns/locale";
type SupportedLocale = "de" | "en" | "es" | "fr" | "it" | "nl";
const supportedLocalesInternal: SupportedLocale[] = ["de", "en", "es", "fr", "it", "nl"];
const fallbackLocale = "en";

const supportedLocales = supportedLocalesInternal as string[];

const dateLocales: Record<SupportedLocale, Locale> = {
  en: dateEnGB,
  de: dateDe,
  es: dateEs,
  fr: dateFr,
  it: dateIt,
  nl: dateNl,
};

const dateMasks: Record<SupportedLocale, string> = {
  en: "__/__/____",
  de: "__.__.____",
  es: "__/__/____",
  fr: "__/__/____",
  it: "__/__/____",
  nl: "__-__-____",
};

/** Returns the first detected language that is supported
 * @param i18n An instance of i18next
 */
function getLanguage(i18n: { languages: readonly string[] }): SupportedLocale {
  const language = i18n.languages.find((l) => supportedLocales.includes(l as SupportedLocale));
  if (language) {
    return language as SupportedLocale;
  }
  // To make Typescript happy. i18n.languages should always include the fallback.
  return fallbackLocale;
}

export type { SupportedLocale };
export { supportedLocales, dateLocales, dateMasks, fallbackLocale, getLanguage };
