import { DownloadLanguageType } from "../../../types/downloads";
import { FC } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as React from "react";
import DownloadsFiltersList from "./DownloadsFiltersList";
import useDownloads from "../contextProviders/DownloadsContext";
import { supportedLocales } from "../../../locales";
import { useGetDownloadFilters, useGetDownloadLabels } from "../../../lib/queryClient";

const DownloadsFilter: FC = () => {
  const { t } = useTranslation();
  const languages = ["all", ...supportedLocales];
  const { language, setDownloadLanguage } = useDownloads();
  const { data: labels } = useGetDownloadLabels();
  const { data: filters } = useGetDownloadFilters();

  return (
    <Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>{t("Downloads.Form.LanguageLabel")}</InputLabel>
        <Select
          id="selectedLanguage"
          value={language}
          label={t("Downloads.Form.LanguageLabel")}
          onChange={(e) => setDownloadLanguage(e.target.value as DownloadLanguageType)}
        >
          {languages.map((languageString: string) => (
            <MenuItem key={languageString} value={languageString}>
              {t(`Downloads.Form.Languages.${languageString}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {labels && filters ? (
        <>
          <DownloadsFiltersList title={t("Downloads.Filter")} type="filters" items={filters} />
          <DownloadsFiltersList title={t("Downloads.Categories")} type="labels" items={labels} />
        </>
      ) : (
        <>
          <Typography component="div" width="70%" sx={{ mt: 2 }}>
            <Skeleton />
          </Typography>
          <Typography component="div" width="70%">
            <Skeleton />
          </Typography>
          <Typography component="div" width="50%">
            <Skeleton />
          </Typography>
        </>
      )}
    </Box>
  );
};

export default DownloadsFilter;
