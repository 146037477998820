import { TFunction } from "i18next";
import { ObjectSchema } from "yup";
import * as Yup from "yup";
import { ArticleConfig, AvailableProduct } from "../../../types/shop";

export const getValidationSchema = (
  t: TFunction,
  product: AvailableProduct
): ObjectSchema<Omit<ArticleConfig, "subscription">> => {
  return Yup.object().shape({
    productCode: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t(`Forms.Product`),
      }) ?? ""
    ),
    licenseCount: Yup.number()
      .min(
        product.licenseCountMin,
        t("Forms.Min", {
          field: t(`Common.Devices`),
          minValue: product.licenseCountMin,
        }) ?? ""
      )
      .max(
        product.licenseCountMax,
        t("Forms.Max", {
          field: t(`Common.Devices`),
          maxValue: product.licenseCountMax,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t(`Common.Devices`),
        }) ?? ""
      ),
    salesLicenseType: Yup.number().required(
      t("Forms.FieldIsRequired", {
        field: t(`Forms.LicenseType`),
      }) ?? ""
    ),
    quantity: Yup.number()
      .min(
        1,
        t("Forms.Min", {
          field: t(`Common.Quantity`),
          minValue: 1,
        }) ?? ""
      )
      .max(
        product.category == "BUSINESS" ? 1 : 50,
        t("Forms.Max", {
          field: t(`Common.Quantity`),
          maxValue: product.category == "BUSINESS" ? 1 : 50,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t(`Common.Quantity`),
        }) ?? ""
      ),
    duration: Yup.number().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.LicenseDuration"),
      }) ?? ""
    ),
  });
};
