export const provider: { id: number; name: string }[] = [
  { id: 1, name: "Bitdefender" },
  { id: 2, name: "Eset" },
  { id: 3, name: "F-Secure" },
  { id: 4, name: "GFI" },
  { id: 5, name: "Ikarus" },
  { id: 6, name: "Kaspersky" },
  { id: 7, name: "McAfee" },
  { id: 8, name: "Microsoft" },
  { id: 9, name: "Sophos" },
  { id: 10, name: "Symantec" },
  { id: 11, name: "Trend Micro" },
  { id: 12, name: "Awarentrain" },
  { id: 13, name: "Barracuda" },
  { id: 14, name: "Broadcom" },
  { id: 15, name: "Cofense" },
  { id: 16, name: "Fortinet" },
  { id: 17, name: "Hornetsecurity" },
  { id: 18, name: "Hoxhunt" },
  { id: 19, name: "increase your skillz" },
  { id: 20, name: "itWatch GmbH" },
  { id: 21, name: "KnowBe4" },
  { id: 22, name: "lawpilots" },
  { id: 23, name: "MetaCompliance" },
  { id: 24, name: "Mimecast" },
  { id: 25, name: "Mybreev" },
  { id: 26, name: "Proofpoint" },
  { id: 27, name: "Rapid7" },
  { id: 28, name: "SANS Institute" },
  { id: 29, name: "Secure Ninja" },
  { id: 30, name: "SoSafe" },
  { id: 31, name: "ThriveDX" },
  { id: 32, name: "Trustwave" },
  { id: 33, name: "VIPRE Security Group" },
  { id: 34, name: "WithSecure" },
];
