import { FC } from "react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../components/ContentBox";
import RequestOfferForm from "./components/RequestOfferForm";
import Page from "../../components/Page";

const RequestOffer: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("RequestOffer.Title")} subtitle={t("Sidebar.Shop.Title")} maxWidth="md">
      <ContentBox>
        <RequestOfferForm />
      </ContentBox>
    </Page>
  );
};

export default RequestOffer;
