import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Box, Checkbox, FormControlLabel, FormHelperText, Link, Skeleton } from "@mui/material";
import { BasketCheckboxWrapper } from "../../../theme/styledComponents/Basket";
import { FormikProps, FormikValues, getIn } from "formik";
import { ICheckoutCheckbox } from "../../../api/shop";
import { useTheme } from "@mui/material/styles";

interface IBasketCheckboxesProps {
  formik: FormikProps<FormikValues>;
  checkboxes?: ICheckoutCheckbox[];
}

export const BasketCheckboxes: FC<IBasketCheckboxesProps> = ({ formik, checkboxes }) => {
  const theme = useTheme();

  if (!checkboxes) {
    return (
      <BasketCheckboxWrapper>
        <Skeleton variant="text" width="70%" sx={{ fontSize: theme.typography.body1, mt: 1, mb: 1 }} />
        <Skeleton variant="text" width="50%" sx={{ fontSize: theme.typography.body1, mb: 1 }} />
      </BasketCheckboxWrapper>
    );
  }

  if (checkboxes.length == 0) {
    return <></>;
  }

  return (
    <BasketCheckboxWrapper>
      {checkboxes &&
        checkboxes.map((checkbox: ICheckoutCheckbox) => {
          return (
            <Box key={`checkbox-${checkbox.identifier}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    required={checkbox.mandatory}
                    onChange={formik.handleChange}
                    name={checkbox.identifier}
                    value="1"
                    data-testid={`basket-checkbox-${checkbox.identifier}`}
                  />
                }
                label={
                  <Trans
                    i18nKey={`Basket.Checkout.Checkboxes.${checkbox.identifier}`}
                    components={{
                      linkUri: <Link href={checkbox.link} target="_blank" />,
                    }}
                  />
                }
              />
              {getIn(formik.errors, checkbox.identifier) && (
                <FormHelperText error>{getIn(formik.errors, checkbox.identifier)}</FormHelperText>
              )}
            </Box>
          );
        })}
    </BasketCheckboxWrapper>
  );
};
