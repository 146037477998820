import React, {
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import { Reseller } from "../../../types/license";
import { Button, Grid, MenuItem, Stack } from "@mui/material";
import { SelectField, TextField } from "../../../components/formFields";
import { useFormik } from "formik";
import { getValidationSchema, ResellerFormValues } from "../formValidations/editReseller";
import { ResellerApi } from "../../../api";
import { ApiResponseStatus } from "../../../api/reseller";
import getCountryData from "../../../utils/CountryDataUtils";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useGetCountries, useInvalidateQueryClientData } from "../../../lib/queryClient";

export interface IResellerEditDialogApi {
  openDialog: (reseller: Reseller | null) => void;
}

interface IResellerEditDialogProps {
  ref: MutableRefObject<IResellerEditDialogApi>;
}

const ResellerEditDialog: ForwardRefExoticComponent<
  Omit<IResellerEditDialogProps, "ref"> & RefAttributes<IResellerEditDialogApi>
> = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [reseller, setReseller] = useState<Reseller | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { invalidateResellers } = useInvalidateQueryClientData();
  const { data: availableCountries } = useGetCountries();

  const initialFormValues: ResellerFormValues = {
    name: "",
    postCode: "",
    city: "",
    countryCode: "",
    email: "",
    number: "",
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setErrors }) => {
      if (reseller && reseller.uid) {
        // edit
        const updateResponse = await ResellerApi.update({ ...reseller, ...values });
        switch (updateResponse.status) {
          case ApiResponseStatus.OK:
            enqueueSnackbar(t(`ResellerManagement.EditDialog.SuccessMsg`), { variant: "success" });
            closeDialog();
            invalidateResellers().then();
            break;
          case ApiResponseStatus.NOT_FOUND:
            enqueueSnackbar(t(`ResellerManagement.EditDialog.NotFound`), { variant: "error" });
            break;
          case ApiResponseStatus.VALIDATION_FAILED:
            setErrors(updateResponse.validationErrors);
            break;
          default:
            enqueueSnackbar(t(`Common.Error`), { variant: "error" });
            break;
        }
      } else {
        // new
        const createResponse = await ResellerApi.create(values);
        switch (createResponse.status) {
          case ApiResponseStatus.OK:
            enqueueSnackbar(t(`ResellerManagement.EditDialog.Success`), { variant: "success" });
            closeDialog();
            invalidateResellers().then();
            break;
          case ApiResponseStatus.VALIDATION_FAILED:
            setErrors(createResponse.validationErrors);
            break;
          default:
            enqueueSnackbar(t(`Common.Error`), { variant: "error" });
            break;
        }
      }
    },
  });

  const openDialog = (resellerToEdit: Reseller | null) => {
    if (resellerToEdit) {
      formik.resetForm({
        values: {
          name: resellerToEdit.name,
          number: resellerToEdit.number,
          postCode: resellerToEdit.postCode,
          city: resellerToEdit.city,
          countryCode: resellerToEdit.countryCode,
          email: resellerToEdit.email,
        },
      });
      setReseller(resellerToEdit);
    } else {
      formik.resetForm({
        values: initialFormValues,
      });
      setReseller(undefined);
    }
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openDialog: openDialog,
  }));

  return (
    <Dialog
      show={open}
      handleClose={closeDialog}
      maxWidth="sm"
      title={reseller ? t(`ResellerManagement.EditDialog.TitleEdit`) : t(`ResellerManagement.EditDialog.TitleNew`)}
      content={
        <>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  label={t(`ResellerManagement.EditDialog.ResellerNo`)}
                  name="number"
                  formik={formik}
                  fullWidth
                  keepSpace={false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("Forms.CompanyName")}
                  name="name"
                  formik={formik}
                  fullWidth
                  required
                  keepSpace={false}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("Forms.Zip")}
                  name="postCode"
                  formik={formik}
                  fullWidth
                  required
                  keepSpace={false}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField label={t("Forms.City")} name="city" formik={formik} fullWidth required keepSpace={false} />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  formik={formik}
                  name="countryCode"
                  label={t("Forms.Country")}
                  fullWidth
                  required
                  keepSpace={false}
                >
                  {availableCountries &&
                    availableCountries.map((country) => (
                      <MenuItem key={country} value={country}>
                        {getCountryData("iso3", country).shortName}
                      </MenuItem>
                    ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("Forms.MailAddress")}
                  name="email"
                  formik={formik}
                  fullWidth
                  required
                  keepSpace={false}
                />
              </Grid>
            </Grid>
            <Stack direction="row-reverse" spacing={2}>
              <LoadingButton
                loading={formik.isSubmitting}
                type="submit"
                variant="contained"
                size="large"
                disabled={!formik.dirty || formik.isSubmitting}
              >
                {reseller ? t(`ResellerManagement.EditDialog.SaveEdit`) : t(`ResellerManagement.EditDialog.SaveNew`)}
              </LoadingButton>
              <Button variant="outlined" size="large" onClick={closeDialog}>
                {t(`Common.Cancel`)}
              </Button>
            </Stack>
          </form>
        </>
      }
    />
  );
});

export default ResellerEditDialog;
