import type { FC } from "react";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

interface ITabPanelProps {
  tabName: string;
  activeTab: string;
  children?: ReactNode;
}

const TabPanel: FC<ITabPanelProps> = (props) => {
  const { children, activeTab, tabName, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={activeTab !== tabName}
      id={`tabpanel-${tabName}`}
      aria-labelledby={`tab-${tabName}`}
      {...other}
    >
      {activeTab === tabName && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
