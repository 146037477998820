import type { FC } from "react";
import { Button, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import { useSnackbar } from "notistack";
import { TextField } from "../../../components/formFields";
import { LostPasswordFormValues, getValidationSchema } from "../formValidations/lostPassword";
import { UserApi } from "../../../api";
import { Link as RouterLink } from "react-router-dom";
import { SupportedLocale } from "../../../locales";

interface LostPasswordFormProps {
  onSuccessfulSubmit?: () => void;
}

const LostPasswordForm: FC<LostPasswordFormProps> = ({ onSuccessfulSubmit }) => {
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: LostPasswordFormValues = {
    email: "",
  };
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      UserApi.resetPassword(values.email, i18n.language as SupportedLocale)
        .then(() => {
          if (onSuccessfulSubmit) {
            onSuccessfulSubmit();
          }
          setStatus({ success: true });
        })
        .catch(() => {
          enqueueSnackbar(t("Common.UnspecifiedError"), {
            variant: "error",
          });
          setStatus({ success: false });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField formik={formik} label={t("Forms.Email")} name="email" type="email" required icon={<PersonIcon />} />
      <Button
        data-testid="lostPasswordForm-submit"
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        {t("LostPassword.SubmitBtn")}
      </Button>
      <Typography variant="body2" align="center" pt={2}>
        <Link component={RouterLink} data-testid="LostPasswordForm-login" to="/login" underline="none">
          {t("LostPassword.BackToLogin")}
        </Link>
      </Typography>
    </form>
  );
};

export default LostPasswordForm;
