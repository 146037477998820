import axios from "./../lib/axiosForApi";
import {
  LicenseCenterAddLicenseResult,
  LicenseCenterCancelSubscriptionResponse,
  LicenseCenterCheckLicenseResult,
  LicenseCenterRow,
  LicenseCenterSearchParams,
  LicenseChangeParameters,
  LicenseRemoveParameters,
  LicenseSettings,
  Reseller,
} from "../types/license";
import { AxiosResponse } from "axios";
import { SupportedLocale } from "../locales";
import { HierarchicalPriceData } from "../types/shop";

// Temporärer Typ wird gebraucht, da API für DateTime einen string zurückgibt
// und wir hier erst auf Date casten
type LicenseCenterRowResponse = LicenseCenterRow & {
  limitDate: string | null;
  invoiceDate: string | null;
};

const getDate = (value: string | null): Date | null => {
  return value ? new Date(value) : null;
};

class LicenseApi {
  async search(searchParams: LicenseCenterSearchParams): Promise<LicenseCenterRow[]> {
    const response = await axios.post("/license/search", searchParams, { timeout: 180000 });
    return response.data.map((row: LicenseCenterRowResponse) => {
      return {
        ...row,
        limitDate: getDate(row.limitDate),
        invoiceDate: getDate(row.invoiceDate),
      };
    });
  }

  async checkLicense(identifier: string): Promise<LicenseCenterCheckLicenseResult> {
    const response = await axios.post("/license/check", { identifier });
    return response.data;
  }

  async addLicense(registrationNumberOrLogin: string, email: string): Promise<LicenseCenterAddLicenseResult> {
    const response = await axios.post("/license/add", { registrationNumberOrLogin, email });
    let licenseToAdd = null;
    if (response.data.success === true) {
      licenseToAdd = {
        ...response.data.license,
        limitDate: getDate(response.data.license.limitDate),
        invoiceDate: getDate(response.data.license.invoiceDate),
      };
    }

    return {
      message: response.data.message,
      license: licenseToAdd,
    };
  }

  async calculatePrice(
    params: LicenseChangeParameters & { customerNo?: string }
  ): Promise<HierarchicalPriceData[] | null> {
    const response = await axios.post("/license/calculate-price", params);
    return response.status === 200 ? response.data : null;
  }

  async getQuote(params: LicenseChangeParameters, languageCode: SupportedLocale): Promise<Blob | null> {
    try {
      const response = await axios.post(`/license/get-quote?language-code=${languageCode}`, params, {
        responseType: "blob",
      });
      const fileBlob = new Blob([response.data], { type: "application/pdf;charset=utf-8" });
      return fileBlob;
    } catch (e) {
      return null;
    }
    return null;
  }

  async xmlExport(registrationNumber: string): Promise<AxiosResponse> {
    return axios.get("/license/xml-export", {
      params: {
        registrationNumber: registrationNumber,
      },
      responseType: "blob",
    });
  }

  async getResellers(customerNo?: string): Promise<Reseller[]> {
    let uri = "/license/resellers";
    if (customerNo) {
      uri += `?customerNo=${customerNo}`;
    }
    const response = await axios.get(uri);
    return response.data;
  }

  async getSettings(registrationNumber: string): Promise<LicenseSettings> {
    const response = await axios.get("/license/settings", {
      params: {
        regNo: registrationNumber,
      },
    });
    return { ...response.data, defaultLimit: getDate(response.data.defaultLimit) };
  }

  async cancelSubscription(registrationNumber: string): Promise<LicenseCenterCancelSubscriptionResponse> {
    const response = await axios.post("/license/cancel-subscription", { registrationNumber: registrationNumber });
    return response.data;
  }

  async checkUniqueEmail(email: string): Promise<boolean> {
    try {
      const response = await axios.post("/license/check-unique-email", { email: email });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  async removeLicense(data: LicenseRemoveParameters): Promise<boolean> {
    const response = await axios.post("/license/remove", data);
    return response.status === 200;
  }
}

export default new LicenseApi();
