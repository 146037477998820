import React, { ReactElement } from "react";
import type { FC } from "react";
import { default as MuiTab } from "@mui/material/Tab";
import { TabProps } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

export type TabStatus = "open" | "completed" | "locked";

interface ITabProps extends TabProps {
  tabStatus?: TabStatus;
}

const getIcon = (status: TabStatus): ReactElement => {
  switch (status) {
    case "completed":
      return <CheckIcon color="success" />;
    case "locked":
      return <LockIcon />;
    default:
      return <CheckIcon />;
  }
};

const Tab: FC<ITabProps> = (props) => {
  const { tabStatus, value, label, icon } = props;

  let iconToDisplay = icon;
  if (tabStatus && !icon) {
    iconToDisplay = getIcon(tabStatus);
  }
  return (
    <MuiTab
      to={value}
      component={Link}
      value={value}
      label={label}
      icon={iconToDisplay ?? ""}
      iconPosition="start"
      disabled={tabStatus === "locked"}
    />
  );
};

export default Tab;
