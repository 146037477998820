import type { FC } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { PasswordField } from "../../../components/formFields";
import { ResetPasswordFormValues, getValidationSchema } from "../formValidations/resetPassword";
import PasswordStrengthMeter from "../../login/components/PasswordStrengthMeter";
import { UserApi } from "../../../api";

interface ResetPasswordFormProps {
  resetHash: string;
  onSuccessfulSubmit?: () => void;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ resetHash, onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: ResetPasswordFormValues = {
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      UserApi.updatePasswordByResetHash(resetHash, values.newPassword)
        .then(() => {
          if (onSuccessfulSubmit) {
            onSuccessfulSubmit();
          }

          setStatus({ success: true });
        })
        .catch(() => {
          enqueueSnackbar(t("Common.UnspecifiedError"), {
            variant: "error",
          });
          setStatus({ success: false });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <PasswordField
        formik={formik}
        autoComplete="new-password"
        label={t("Forms.Password")}
        name="newPassword"
        required
      />
      <PasswordField
        formik={formik}
        autoComplete="off"
        label={t("Forms.PasswordConfirm")}
        name="newPasswordConfirm"
        required
      />
      <PasswordStrengthMeter password={formik.values.newPassword} />
      <Button color="primary" disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained">
        {t("Settings.SetPasswordBtn")}
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
