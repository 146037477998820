import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LostPasswordForm from "./components/LostPasswordForm";
import LostPasswordContainer from "./components/LostPasswordContainer";
import { styled } from "@mui/material/styles";

const StyledMailOutlineIcon = styled(MailOutlineIcon)(({ theme }) => ({
  right: theme.spacing(2),
  bottom: 0,
  fontSize: "15em",
  color: "rgba(0,0,0,.1)",
  position: "absolute",
}));

const LostPassword: FC = () => {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  function handleFormSubmitted() {
    setFormSubmitted(true);
  }

  if (!formSubmitted) {
    return (
      <LostPasswordContainer
        headline={t("LostPassword.Request.Headline")}
        subheader={t("LostPassword.Request.Subheader")}
      >
        <LostPasswordForm onSuccessfulSubmit={handleFormSubmitted} />
      </LostPasswordContainer>
    );
  }
  return (
    <LostPasswordContainer headline={t("LostPassword.MailSend.Headline")} subheader={t("LostPassword.MailSend.Info")}>
      <StyledMailOutlineIcon />
    </LostPasswordContainer>
  );
};

export default LostPassword;
