import * as React from "react";
import { DownloadFilter, DownloadFilterType } from "../../../types/downloads";
import { FC } from "react";
import { Checkbox, FormControlLabel, FormGroup, List, ListItem, ListSubheader, Typography } from "@mui/material";
import useDownloads from "../contextProviders/DownloadsContext";

interface DownloadsFiltersListProps {
  title: string;
  type: DownloadFilterType;
  items: DownloadFilter[];
}

const DownloadsFiltersList: FC<DownloadsFiltersListProps> = (props) => {
  const { title, type, items } = props;
  const {
    addToDownloadFilters,
    removeFromDownloadFilters,
    filters: activeFilters,
    labels: activeLabels,
  } = useDownloads();

  const activeFiltersAndLabels = [...activeFilters, ...activeLabels];

  const handleDownloadFilters = (isChecked: boolean, filterType: DownloadFilterType, filterId: number) => {
    if (isChecked) {
      addToDownloadFilters(filterType, filterId);
    } else {
      removeFromDownloadFilters(filterType, filterId);
    }
  };

  return (
    <List
      subheader={
        <ListSubheader disableGutters disableSticky sx={{ bgcolor: "transparent", pt: "4px" }}>
          <Typography variant="button" color="textPrimary">
            <strong>{title}</strong>
          </Typography>
        </ListSubheader>
      }
    >
      {items.map((item: DownloadFilter) => (
        <ListItem key={item.id} disableGutters disablePadding>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeFiltersAndLabels.includes(item.id)}
                  onChange={(event) => handleDownloadFilters(event.target.checked, type, item.id)}
                />
              }
              label={item.title}
            />
          </FormGroup>
        </ListItem>
      ))}
    </List>
  );
};

export default DownloadsFiltersList;
