import * as React from "react";
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { SelectField, TextField } from "./formFields";
import { SharedStateAction, useSharedState } from "../contextProviders/SharedState";
import { TFunction } from "i18next";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { default as MailApi, MailFormValues } from "../api/mail";
import { useSnackbar } from "notistack";

export const getValidationSchema = (t: TFunction): Yup.ObjectSchema<MailFormValues> => {
  return Yup.object().shape({
    subject: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("FeedbackForm.TypeLabel", "E-mail address"),
      }) as string
    ),
    message: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("FeedbackForm.MessageLabel"),
      }) as string
    ),
  });
};

export default function FeedbackDialog() {
  const { state, dispatch } = useSharedState();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    validationSchema: getValidationSchema(t),
    initialValues: {
      message: "",
      subject: "improvement",
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const isSend = await MailApi.submitFeedbackForm(values);
      if (isSend) {
        enqueueSnackbar(t("FeedbackForm.Success"), { variant: "success" });
        dispatch({ type: SharedStateAction.FeedbackClose });
        resetForm();
      } else {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  return (
    <MuiDialog
      maxWidth="md"
      open={state.feedbackForm.open}
      onClose={(event: unknown, reason: string) => {
        if (reason && reason == "backdropClick") {
          return;
        }
      }}
    >
      <DialogTitle>Geben Sie uns Feedback</DialogTitle>
      <Divider />
      <DialogContent sx={{ whiteSpace: "pre-line" }} dividers={false}>
        <form noValidate>
          <Typography variant="body1" mb={2}>
            {t("FeedbackForm.Intro")}
          </Typography>
          <SelectField fullWidth formik={formik} label={t("FeedbackForm.TypeLabel")} name="subject" required>
            <MenuItem value="improvement">{t("FeedbackForm.Type1")}</MenuItem>
            <MenuItem value="error">{t("FeedbackForm.Type2")}</MenuItem>
            <MenuItem value="feature">{t("FeedbackForm.Type3")}</MenuItem>
          </SelectField>
          <TextField
            multiline
            rows={4}
            formik={formik}
            label={t("FeedbackForm.MessageLabel")}
            name="message"
            required
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          disabled={formik.isSubmitting}
          onClick={() => dispatch({ type: SharedStateAction.FeedbackClose })}
        >
          {t("Forms.ButtonCancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          {t("FeedbackForm.Submit")}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}
