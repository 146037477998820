import axios from "../lib/axiosForApi";
import { SupportedLocale } from "../locales";
import { DashboardTile } from "../types/dashboard";

class DashboardApi {
  async getTiles(languageCode: SupportedLocale): Promise<DashboardTile[]> {
    const response = await axios.get("/dashboard/tiles", { params: { "language-code": languageCode } });
    return response.status === 200 ? response.data : [];
  }
}

export default new DashboardApi();
