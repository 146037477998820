import { ThemeType } from "../theme";
import { SupportedLocale } from "../locales";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid-pro";

export type LicenseCenterUserSettings = {
  columns: GridColumnVisibilityModel | null;
  sorting: GridSortModel | null;
};

export type UserSettings = {
  theme: ThemeType;
  language: SupportedLocale;
  licenseCenter: LicenseCenterUserSettings | null;
};

// identisch mit PHP-Enum in gd_storeapi/Classes/Enumeration/BusinessCentralPaymentMethod.php
export const enum BusinessCentralPaymentMethod {
  DATA_ERROR = 0,
  VK = 1,
  SEPA = 2,
  EP_LAST = 3,
  AZ_LAST = 4,
  U_S_BAR = 5,
  INKASSO = 6,
}

export type UserRoles = Array<string>;

export type User = {
  uid: number;
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  customerNo: string;
  company: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  adConsent: number;
  roles: UserRoles;
  settings: UserSettings;
  isMfaEnabled: boolean;
  isBlocked: boolean;
  currencyCode: string;
  taxPercentage: number;
  payment: BusinessCentralPaymentMethod;
};
