import React, { ReactNode, createContext, useContext, useReducer, Dispatch } from "react";

interface IFeedbackFormState {
  open: boolean;
}

interface ISharedState {
  feedbackForm: IFeedbackFormState;
}

export enum SharedStateAction {
  FeedbackOpen = "FEEDBACK_FORM_OPEN",
  FeedbackClose = "FEEDBACK_FORM_CLOSE",
}

const initialState: ISharedState = { feedbackForm: { open: false } };

const SharedStateContext = createContext<{
  state: ISharedState;
  dispatch: Dispatch<{ type: SharedStateAction; payload?: Partial<ISharedState> }>;
}>({
  state: initialState,
  dispatch: () => null,
});

function sharedStateReducer(
  state: ISharedState,
  action: { type: SharedStateAction; payload?: Partial<ISharedState> }
): ISharedState {
  switch (action.type) {
    case SharedStateAction.FeedbackOpen: {
      return { ...state, feedbackForm: { open: true } };
    }
    case SharedStateAction.FeedbackClose: {
      return { ...state, feedbackForm: { open: false } };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const useSharedState = () => {
  const context = useContext(SharedStateContext);
  if (context === null || context === undefined) throw new Error("Please add SharedStateProvider");
  return context;
};

export const SharedStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(sharedStateReducer, initialState);
  const value = { state, dispatch };

  return <SharedStateContext.Provider value={value}>{children}</SharedStateContext.Provider>;
};
