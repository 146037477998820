import * as Yup from "yup";
import { TFunction } from "i18next";
import { ObjectSchema, ObjectShape } from "yup";
import { ICheckoutCheckbox } from "../../../api/shop";

export type CheckoutFormValues = {
  [key: string]: string;
};

export const getValidationSchema = (
  t: TFunction,
  checkboxes: ICheckoutCheckbox[] | null
): ObjectSchema<CheckoutFormValues> => {
  const additions: ObjectShape = {};
  checkboxes?.forEach((checkbox) => {
    if (checkbox.mandatory) {
      additions[checkbox.identifier] = Yup.array().length(1, t("Forms.Required")).required(t("Forms.Required"));
    } else {
      additions[checkbox.identifier] = Yup.mixed().nullable();
    }
  });
  return Yup.object().shape(additions) as ObjectSchema<CheckoutFormValues>;
};
