import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import { SvgIcon } from "@mui/material";

function Marketing(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 1022 1024" {...props}>
      <path d="M859.136 730.112h-333.824l-173.056 173.056q-18.432 18.432-44.032 18.432t-44.032-17.92-18.432-44.544v-129.024h-80.896q-26.624 0-44.544-17.92t-17.92-44.544v-502.784q0-26.624 17.92-44.544t44.544-17.92h694.272q25.6 0 44.032 17.92t18.432 44.544v502.784q0 26.624-18.432 44.544t-44.032 17.92zM146.432 147.456v537.6h144.384v217.088l216.064-217.088h369.664v-537.6h-730.112zM235.52 280.576v-45.056h245.76v45.056h-245.76zM235.52 602.112v-25.6h53.248v-136.192h81.92v136.192h105.472v-196.608h81.92v196.608h104.448v-292.864h81.92v292.864h53.248v25.6h-562.176z"></path>
    </SvgIcon>
  );
}

export default Marketing;
