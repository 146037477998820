import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { INavSectionProps } from "../../NavSection";
import {
  AccessTimeOutlined,
  AccountCircleOutlined,
  ApartmentOutlined,
  AssignmentOutlined,
  BookOutlined,
  BugReportOutlined,
  CastForEducationOutlined,
  ChangeCircleOutlined,
  DashboardOutlined,
  EuroOutlined,
  FingerprintOutlined,
  GetAppOutlined,
  GroupOutlined,
  HelpCenterOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  InfoOutlined,
  LocalOfferOutlined,
  LockResetOutlined,
  ManageAccountsOutlined,
  ManageHistoryOutlined,
  MenuBookOutlined,
  MoreTimeOutlined,
  NotesOutlined,
  PhoneOutlined,
  SettingsOutlined,
  StorefrontOutlined,
  StreetviewOutlined,
  TipsAndUpdatesOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import { GData, Marketing, Presentation } from "../../../icons";

export function useAppSidebarSections() {
  const { t } = useTranslation();

  return useMemo(() => {
    const sections: Array<Omit<INavSectionProps, "pathname">> = [
      {
        title: t("Sidebar.Overview.Title"),
        icon: <HomeOutlined />,
        items: [
          {
            title: t("Sidebar.Overview.Dashboard"),
            path: "/app",
            icon: <DashboardOutlined />,
            dataTestId: "app-sidebar_dashboard",
          },
        ],
      },
    ];
    sections.push({
      title: "Admin",
      icon: <SettingsOutlined />,
      allowedRoles: ["support"],
      items: [
        {
          title: "Partner Viewer",
          path: "/app/shop/partner-viewer",
          icon: <StreetviewOutlined />,
          dataTestId: "app-sidebar_partner-viewer",
          allowedRoles: ["support"],
        },
      ],
    });
    sections.push({
      title: t("Sidebar.Shop.Title"),
      icon: <StorefrontOutlined />,
      items: [
        {
          title: t("Sidebar.Shop.BuyNew"),
          path: "/app/shop/buy-new",
          icon: <MoreTimeOutlined />,
          dataTestId: "app-sidebar_add-new-license",
          allowedRoles: ["shop"],
        },
        {
          title: t("Sidebar.Shop.LicenseCenter"),
          path: "/app/shop/license-center",
          icon: <ManageHistoryOutlined />,
          dataTestId: "app-sidebar_license-center",
          allowedRoles: ["reseller", "distributor"],
        },
        {
          title: t("Sidebar.Shop.ResellerManagement"),
          path: "/app/shop/reseller-management",
          icon: <ManageAccountsOutlined />,
          dataTestId: "app-sidebar_reseller-management",
          allowedRoles: ["distributor"],
        },
        {
          title: t("Sidebar.Shop.Invoices"),
          path: "/app/shop/invoices",
          icon: <AssignmentOutlined />,
          dataTestId: "app-sidebar_invoices",
          allowedRoles: ["invoice"],
        },
        {
          title: t("Sidebar.Shop.Crossgrades"),
          path: "/app/shop/crossgrades",
          icon: <ChangeCircleOutlined />,
          dataTestId: "app-sidebar_crossgrades",
          allowedRoles: ["crossgrades"],
        },
        {
          title: t("Sidebar.Shop.RequestOffer"),
          path: "/app/request-offer",
          icon: <LocalOfferOutlined />,
          dataTestId: "app-sidebar_request-offer",
        },
        {
          title: t("Sidebar.Shop.Projects"),
          path: "/app/projects",
          icon: <AssignmentOutlined />,
          dataTestId: "app-sidebar_projects",
          allowedRoles: ["dach"],
        },
        {
          title: t("Sidebar.Shop.Trials"),
          path: "/app/trials",
          icon: <AccessTimeOutlined />,
          dataTestId: "app-sidebar_trials",
          allowedRoles: ["reseller", "distributor"],
        },
        {
          title: t("Sidebar.Shop.Prices"),
          path: "/app/downloads?f=8",
          icon: <EuroOutlined />,
          dataTestId: "app-sidebar_price-list",
          allowedRoles: ["dach"],
        },
      ],
    });
    sections.push({
      title: t("Sidebar.SalesMarketing.Title"),
      icon: <Marketing />,
      items: [
        {
          title: t("Sidebar.SalesMarketing.Downloads"),
          path: "/app/downloads",
          icon: <GetAppOutlined />,
          dataTestId: "app-sidebar_download",
        },
        {
          title: t("Sidebar.SalesMarketing.BrandCenter"),
          path: t("Sidebar.SalesMarketing.BrandCenterPath"),
          icon: <GData />,
          dataTestId: "app-sidebar_brand-center",
        },
        {
          title: t("Sidebar.SalesMarketing.CaseStudies"),
          path: "/app/downloads?f=113",
          icon: <Presentation />,
          dataTestId: "app-sidebar_case-studies",
        },
      ],
    });
    sections.push({
      title: t("Sidebar.Knowledgebase.Title"),
      icon: <HelpOutlineOutlined />,
      items: [
        {
          title: t("Sidebar.Knowledgebase.Faqs"),
          path: "/app/faqs",
          icon: <HelpCenterOutlined />,
          dataTestId: "app-sidebar_faqs",
        },
        {
          title: t("Sidebar.Knowledgebase.Support"),
          path: "/app/support",
          icon: <PhoneOutlined />,
          dataTestId: "app-sidebar_support",
        },
        {
          title: t("Sidebar.Knowledgebase.Team"),
          path: "/app/team",
          icon: <GroupOutlined />,
          dataTestId: "app-sidebar_team",
          allowedRoles: ["dach"],
        },
        {
          title: t("Sidebar.Knowledgebase.SampleSubmission"),
          path: "/app/sample-submission",
          icon: <BugReportOutlined />,
          dataTestId: "app-sidebar_sample-submission",
        },
        {
          title: t("Sidebar.Knowledgebase.LoginReset"),
          path: t("Sidebar.Knowledgebase.LoginResetPath"),
          icon: <LockResetOutlined />,
          dataTestId: "app-sidebar_reset-login",
        },
        {
          title: t("Sidebar.Knowledgebase.Webinars"),
          path: t("Sidebar.Knowledgebase.WebinarsPath"),
          icon: <CastForEducationOutlined />,
          dataTestId: "app-sidebar_webinars-1",
        },
        {
          title: t("Sidebar.Knowledgebase.Guidebook"),
          path: t("Sidebar.Knowledgebase.GuidebookPath"),
          icon: <TipsAndUpdatesOutlined />,
          dataTestId: "app-sidebar_guidebook",
        },
      ],
    });
    sections.push({
      title: t("Sidebar.Information.Title"),
      icon: <InfoOutlined />,
      items: [
        {
          title: t("Sidebar.Information.Contracts"),
          path: "/app/contracts",
          icon: <AssignmentOutlined />,
          dataTestId: "app-sidebar_contracts",
        },
        {
          title: t("Sidebar.Information.Imprint"),
          path: "/app/p/imprint",
          icon: <BookOutlined />,
          dataTestId: "app-sidebar_imprint",
        },
        {
          title: t("Sidebar.Information.AGB"),
          path: "/app/p/terms-conditions",
          icon: <NotesOutlined />,
          dataTestId: "app-sidebar_terms-conditions",
        },
        {
          title: t("Sidebar.Information.EULA"),
          path: "/app/p/eula",
          icon: <MenuBookOutlined />,
          dataTestId: "app-sidebar_eula",
        },
        {
          title: t("Sidebar.Information.Privacy"),
          path: t("Sidebar.Information.PrivacyPath"),
          icon: <FingerprintOutlined />,
          dataTestId: "app-sidebar_privacy",
        },
      ],
    });
    sections.push({
      title: t("Sidebar.Settings.Title"),
      icon: <SettingsOutlined />,
      items: [
        {
          title: t("Sidebar.Settings.PersonalData"),
          path: "/app/settings/personal-data",
          icon: <AccountCircleOutlined />,
          dataTestId: "app-sidebar_personal-data",
        },
        {
          title: t("Sidebar.Settings.CompanyData"),
          path: "/app/settings/company-data",
          icon: <ApartmentOutlined />,
          dataTestId: "app-sidebar_company-data",
        },
        {
          title: t("Sidebar.Settings.Security"),
          path: "/app/settings/security",
          icon: <VpnKeyOutlined />,
          dataTestId: "app-sidebar_security",
        },
      ],
    });
    return sections;
  }, [t]);
}
