import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Alert, Button, FormControl, FormGroup, FormLabel, Grid, Link, MenuItem, Typography } from "@mui/material";
import { getValidationSchema, ProjectsFormValues } from "../formValidations/projects";
import { ContactFormsApi } from "../../../api";
import { CheckBox, DatePickerField, SelectField, TextField } from "../../../components/formFields";
import PersonIcon from "@mui/icons-material/Person";
import { products, additionalModules, licenses, provider } from "../../../static";
import SuccessBox from "../../../components/SuccessBox";
import ContentBox from "../../../components/ContentBox";

const ProjectsForm: FC = () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: ProjectsFormValues = {
    firstName: "",
    lastName: "",
    companyName: "",
    position: "" as unknown as number,
    street: "",
    postCode: "" as unknown as number,
    city: "",
    email: "",
    phone: "",
    product: "",
    licenseType: "",
    licenseVolume: "" as unknown as number,
    additionalModules: [""],
    currentProvider: "" as unknown as number,
    message: "",
    licenseExpirationDate: new Date(),
    projectStartDate: new Date(),
    acceptTerms: false,
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      ContactFormsApi.projectSubmit({
        ...values,
        licenseVolume: values.licenseVolume as number,
        acceptTerms: values.acceptTerms === true ? 1 : 0,
      })
        .then((response: { data: { success: boolean } }) => {
          if (response.data.success) {
            setStatus({ success: true });
            setIsSubmitted(true);
            resetForm({});
          } else {
            setStatus({ success: false });
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return isSubmitted ? (
    <ContentBox>
      <SuccessBox
        title={t("Projects.Response.Title")}
        text={t("Projects.Response.Message")}
        buttonEvt={() => setIsSubmitted(false)}
        buttonTitle={t("Projects.Response.BackBtn")}
      />
    </ContentBox>
  ) : (
    <ContentBox>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Typography variant="h5" component="h2" sx={{ mb: 4, borderBottom: 1, borderColor: "divider" }}>
          {t("Projects.Form.Customer")}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} md={6}>
            <TextField formik={formik} label={t("Forms.FirstName")} name="firstName" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField formik={formik} label={t("Forms.LastName")} name="lastName" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField formik={formik} label={t("Forms.CompanyName")} name="companyName" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectField formik={formik} label={t("Forms.Position")} name="position" required>
              <MenuItem value="1">{t("Common.Management")}</MenuItem>
              <MenuItem value="2">{t("Common.IT")}</MenuItem>
              <MenuItem value="3">
                {t("Common.Sales")} / {t("Common.Marketing")}
              </MenuItem>
              <MenuItem value="4">{t("Common.HR")}</MenuItem>
              <MenuItem value="5">{t("Common.Other")}</MenuItem>
            </SelectField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField formik={formik} label={t("Forms.Address")} name="street" required />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField formik={formik} label={t("Forms.Zip")} name="postCode" required />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField formik={formik} label={t("Forms.City")} name="city" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField formik={formik} type="email" label={t("Forms.Email")} name="email" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField formik={formik} label={t("Forms.Phone")} name="phone" required />
          </Grid>
        </Grid>
        <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 4, borderBottom: 1, borderColor: "divider" }}>
          {t("Forms.Product")}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} md={6}>
            <SelectField formik={formik} label={t("Forms.Product")} name="product" required>
              {products.map((product: { id: string; name: string }) => {
                return (
                  <MenuItem value={product.id} key={`product-${product.id}`}>
                    {product.name}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectField formik={formik} label={t("Forms.LicenseType")} name="licenseType" required>
              {licenses.map((license: { id: string; name: string }) => {
                return (
                  <MenuItem value={license.id} key={`licenseType-${license.id}`}>
                    {license.name}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              formik={formik}
              label={t("Forms.LicenseVolume")}
              name="licenseVolume"
              type="number"
              icon={<PersonIcon color="inherit" />}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ mt: -1, width: "100%" }} component="fieldset">
              <FormLabel component="legend">{t("Forms.AdditionalModules")}</FormLabel>
              <FormGroup>
                <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 4 }}>
                  {additionalModules.map((module: string, index: number) => {
                    return (
                      <Grid item xs={12} md={4} lg={3} key={`module-${index}`}>
                        <CheckBox formik={formik} name="additionalModules" value={module} title={module} />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h5" component="h2" sx={{ mt: 2, mb: 4, borderBottom: 1, borderColor: "divider" }}>
          {t("Projects.Form.ProjectDetails")}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <SelectField formik={formik} label={t("Projects.Form.CurrentProvider")} name="currentProvider" required>
              {provider.map((item: { id: number; name: string }) => {
                return (
                  <MenuItem value={item.id} key={`provider-${item.id}`}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePickerField
              formik={formik}
              label={t("Projects.Form.LicenseExpirationDate")}
              name="licenseExpirationDate"
              disablePast
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePickerField
              formik={formik}
              label={t("Projects.Form.ProjectStartDate")}
              name="projectStartDate"
              disablePast
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              formik={formik}
              label={t("Projects.Form.Message")}
              placeholder={t("Projects.Form.MessagePlaceholder")}
              name="message"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CheckBox formik={formik} name="acceptTerms" value="1" title={t("Projects.Form.AcceptTerms")} required />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs="auto">
            <Button
              size="large"
              type="submit"
              variant="contained"
              disabled={!formik.dirty || formik.isSubmitting}
              sx={{ pr: 4, pl: 4 }}
            >
              {t("Projects.Form.SubmitBtn")}
            </Button>
          </Grid>
        </Grid>

        <Alert variant="outlined" severity="info" sx={{ mt: 2 }}>
          <Trans
            i18nKey="Projects.Form.AcceptTermsAdditionalText"
            components={{
              linkPrivacy: <Link href={t("Footer.PrivacyHref")} target="_blank" />,
            }}
          />
        </Alert>
      </form>
    </ContentBox>
  );
};

export default ProjectsForm;
