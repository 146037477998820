import { TFunction } from "i18next";

import * as Yup from "yup";
import { ObjectSchema } from "yup";
import { LicenseApi } from "../../../api";
import { User } from "../../../types/user";
import UserUtils from "../../../utils/UserUtils";

export type RegistrationAddressFormValues = {
  reseller?: number;
  company?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  postcode?: string;
  addition?: string;
  city?: string;
  country?: string;
  email?: string;
  register?: boolean;
  comment?: string;
};

export const getValidationSchema = (
  t: TFunction,
  user: User | undefined,
  isBusinessProduct: boolean,
  is365Product: boolean,
  addressTouched: boolean
): ObjectSchema<RegistrationAddressFormValues> => {
  const errors = {
    toLong50: t("Forms.TooLong", { maxLength: "50" }),
    company: t("Forms.FieldIsRequired", { field: t("Forms.CompanyName") }),
    firstName: t("Forms.FieldIsRequired", { field: t("Forms.FirstName") }),
    lastName: t("Forms.FieldIsRequired", { field: t("Forms.LastName") }),
    street: t("Forms.FieldIsRequired", { field: t("Forms.Address") }),
    postcode: t("Forms.FieldIsRequired", { field: t("Forms.Zip") }),
    city: t("Forms.FieldIsRequired", { field: t("Forms.City") }),
    country: t("Forms.FieldIsRequired", { field: t("Forms.Country") }),
    reseller: t("Forms.FieldIsRequired", { field: t("Forms.Reseller") }),
    email: t("Forms.ValidEmail"),
  };

  const isDistributor = user ? UserUtils.hasPermission(["distributor"], user) : false;
  const checkPostcode = user ? !UserUtils.hasPermission(["reseller_latam", "distributor_latam"], user) : true;

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return Yup.object().shape({
    reseller: !isDistributor ? Yup.number() : Yup.number().min(1, errors.reseller).required(errors.reseller),
    company: isBusinessProduct
      ? Yup.string().max(50, errors.toLong50).required(errors.company)
      : Yup.string().max(50, errors.toLong50),
    firstName: Yup.string()
      .max(50, errors.toLong50)
      .when("register", {
        is: true,
        then: (schema) => (isBusinessProduct ? schema : schema.required(errors.firstName)),
        otherwise: (schema) =>
          !isBusinessProduct && addressTouched ? schema.required(errors.firstName) : schema.nullable(),
      }),
    lastName: Yup.string()
      .max(50, errors.toLong50)
      .when("register", {
        is: true,
        then: (schema) => (isBusinessProduct ? schema : schema.required(errors.lastName)),
        otherwise: (schema) =>
          !isBusinessProduct && addressTouched ? schema.required(errors.lastName) : schema.nullable(),
      }),
    street: Yup.string()
      .max(50, errors.toLong50)
      .when("register", {
        is: true,
        then: (schema) => schema.required(errors.street),
        otherwise: (schema) =>
          isBusinessProduct || addressTouched ? schema.required(errors.street) : schema.nullable(),
      }),
    postcode: Yup.string().when("register", {
      is: true,
      then: (schema) => (checkPostcode ? schema.required(errors.postcode) : schema.nullable()),
      otherwise: (schema) =>
        (isBusinessProduct || addressTouched) && checkPostcode ? schema.required(errors.postcode) : schema.nullable(),
    }),
    addition: Yup.string().max(50, errors.toLong50),
    city: Yup.string()
      .max(50, errors.toLong50)
      .when("register", {
        is: true,
        then: (schema) => schema.required(errors.city),
        otherwise: (schema) => (isBusinessProduct || addressTouched ? schema.required(errors.city) : schema.nullable()),
      }),
    country: Yup.string().when("register", {
      is: true,
      then: (schema) => schema.required(errors.country),
      otherwise: (schema) =>
        isBusinessProduct || addressTouched ? schema.required(errors.country) : schema.nullable(),
    }),
    email: Yup.string()
      .max(50, errors.toLong50)
      .matches(emailRegex, { message: errors.email, excludeEmptyString: true })
      .test("email", (email, context) => {
        if (email && is365Product) {
          return LicenseApi.checkUniqueEmail(email)
            .then((isUniqueEmail) => {
              if (!isUniqueEmail) {
                return context.createError({ message: t("Forms.EmailIsNotUnique") });
              } else {
                return true;
              }
            })
            .catch();
        }
        return true;
      })
      .when("register", {
        is: true,
        then: (schema) => schema.required(errors.email),
        otherwise: (schema) =>
          isBusinessProduct || addressTouched ? schema.required(errors.email) : schema.nullable(),
      }),
    register: Yup.boolean(),
    comment: Yup.string(),
  });
};
