import React, {
  FC,
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import { Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { getValidationSchema } from "../../../components/FeedbackDialog";
import { TextField } from "../../../components/formFields";
import { MailApi } from "../../../api";

const LicenseRequestForm: FC<{ loginName: string; handleCloseDialog: () => void }> = ({
  loginName,
  handleCloseDialog,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      subject: t("LicenseCenter.LicenseRequest.Title", { loginName: loginName }),
      message: "",
    },
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const isSend = await MailApi.submitOrderForm(values);
      if (isSend) {
        enqueueSnackbar(t("LicenseCenter.LicenseRequest.Success"), { variant: "success" });
        handleCloseDialog();
        resetForm();
      } else {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField
        multiline
        rows={4}
        formik={formik}
        label={t("LicenseCenter.LicenseRequest.Form.Message")}
        name="message"
        required
      />
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <Grid item xs="auto">
          <Button type="submit" variant="contained" disabled={!formik.dirty || formik.isSubmitting}>
            {t("LicenseCenter.LicenseRequest.Form.SubmitBtn")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export interface ILicenseCenterLicenseRequestDialogApi {
  handleShowDialog: (loginName: string) => void;
}

interface ILicenseCenterLicenseRequestDialogProps {
  ref: MutableRefObject<ILicenseCenterLicenseRequestDialogApi>;
}

const LicenseCenterLicenseRequestDialog: ForwardRefExoticComponent<
  Omit<ILicenseCenterLicenseRequestDialogProps, "ref"> & RefAttributes<ILicenseCenterLicenseRequestDialogApi>
> = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [loginName, setLoginName] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleShowDialog = (loginNameValue: string) => {
    setLoginName(loginNameValue);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  useImperativeHandle(ref, () => ({
    handleShowDialog: handleShowDialog,
  }));

  return (
    <Dialog
      show={showDialog}
      handleClose={handleCloseDialog}
      maxWidth="sm"
      title={t("LicenseCenter.LicenseRequest.Title", { loginName: loginName })}
      content={<LicenseRequestForm loginName={loginName} handleCloseDialog={handleCloseDialog} />}
    />
  );
});

export default LicenseCenterLicenseRequestDialog;
