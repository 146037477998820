import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ContractsTableItem from "./ContractsTableItem";
import { useGetContracts } from "../../../lib/queryClient";
import ContractSignDialog, { IContractSignDialogApi } from "./ContractSignDialog";

const ContractsTable: FC = () => {
  const { t } = useTranslation();
  const { data: contractsData, isLoading } = useGetContracts();
  const contractSignDialogRef = useRef<IContractSignDialogApi>(null);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>{t("Contracts.DocumentType")}</TableCell>
            <TableCell align="center">{t("Contracts.Status")}</TableCell>
            <TableCell align="center">{t("Contracts.SignedAt")}</TableCell>
            <TableCell align="center">{t("Contracts.ExpirationDate")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : contractsData && contractsData.length > 0 ? (
              <>
                {contractsData.map((contract, index) => (
                  <ContractsTableItem key={index} contract={contract} signDialogApi={contractSignDialogRef} />
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                  {t("Contracts.NoContracts")}
                </TableCell>
              </TableRow>
            )}
          </>
        </TableBody>
      </Table>
      <ContractSignDialog ref={contractSignDialogRef} />
    </TableContainer>
  );
};

export default ContractsTable;
