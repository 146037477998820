import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import { SvgIcon } from "@mui/material";

function GData(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 1022 1024" {...props}>
      <path d="M815.128 201.728q-27.648-11.264-64.512-19.968t-77.312-14.848-82.432-9.216-79.872-3.072q-38.912 0-80.896 3.072t-82.432 9.216-77.312 14.848-64.512 19.968l11.264 251.904q2.048 51.2 22.528 99.328t50.688 90.624 65.536 78.336 67.584 62.976 56.32 44.032 31.232 22.016q7.168-5.12 31.744-22.016t56.832-44.032 68.608-63.488 66.56-78.848 50.688-90.112 21.504-98.816l1.024-62.464q-27.648-12.288-63.488-20.48t-74.24-13.312-78.336-7.68-74.752-2.56v134.144q9.216 1.024 20.48 1.024h22.528q13.312 1.024 25.088 2.048t19.968 3.072q20.48 4.096 26.624 15.36 2.048 3.072 2.048 9.216v6.144q-2.048 24.576-16.384 45.568t-32.768 38.4-37.376 30.208-31.232 19.968h-6.144q-12.288-8.192-34.816-26.112t-45.056-43.52-39.424-58.368-17.92-70.656l-4.096-138.24h4.096q32.768-6.144 67.584-9.728t70.656-3.584q97.28 0 172.032 12.288t126.976 31.744zM869.4 163.84l-11.264 290.816q-2.048 60.416-26.112 116.736t-59.904 106.496-77.824 92.16-79.872 73.728-66.56 52.224-36.864 25.6q-9.216-5.12-37.376-25.6t-66.048-52.224-79.872-73.728-77.312-92.16-59.904-106.496-26.624-116.736l-11.264-291.84q31.744-15.36 74.752-26.624t90.624-18.944 97.28-11.264 94.72-3.584q44.032 0 93.696 3.584t97.28 11.264 91.136 18.944 76.288 26.624z"></path>
    </SvgIcon>
  );
}

export default GData;
