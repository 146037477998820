import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { Box, Button } from "@mui/material";
import ContentBox from "../../components/ContentBox";
import LicenseCenterCheckLicenseDialog from "./components/LicenseCenterCheckLicense";
import UserUtils from "../../utils/UserUtils";
import useAuth from "../../contextProviders/Authentication";
import LicenseCenterDistributorGrid from "./components/LicenseCenterDistributorGrid";
import LicenseCenterResellerGrid from "./components/LicenseCenterResellerGrid";
import { Link } from "react-router-dom";

const LicenseCenter: FC = () => {
  const { t } = useTranslation();
  const [showCheckLicenseDialog, setShowCheckLicenseDialog] = useState(false);

  // ToDo: Distri Shop Check eventuell vorher und in ContextProvider schreiben
  const { user } = useAuth();
  const isDistributor = UserUtils.hasPermission(["distributor"], user);

  const handleShowCheckLicenseDialog = () => {
    setShowCheckLicenseDialog(true);
  };

  const handleCloseCheckLicenseDialog = () => {
    setShowCheckLicenseDialog(false);
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Page
        title={t("Sidebar.Shop.LicenseCenter")}
        subtitle="Shop"
        maxWidth={"xl"}
        buttons={[
          isDistributor ? (
            <Button variant="outlined" component={Link} to={"/app/shop/reseller-management"}>
              {t("ResellerManagement.Title")}
            </Button>
          ) : (
            <></>
          ),
          <Button variant="outlined" onClick={handleShowCheckLicenseDialog}>
            {t("LicenseCenter.CheckLicense.Title")}
          </Button>,
        ]}
      >
        <ContentBox>
          <Box sx={{ width: "100%", height: "max(500px,80vh)" }}>
            {isDistributor ? <LicenseCenterDistributorGrid /> : <LicenseCenterResellerGrid />}
          </Box>
        </ContentBox>
      </Page>
      <LicenseCenterCheckLicenseDialog show={showCheckLicenseDialog} handleClose={handleCloseCheckLicenseDialog} />
    </>
  );
};

export default LicenseCenter;
