import { FC } from "react";
import { Fade, Zoom, Box, LinearProgress } from "@mui/material";
import LogoSq from "./LogoSq";

const SplashScreen: FC = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
      <Zoom in timeout={500}>
        <Box>
          <LogoSq width="7.45rem" />
        </Box>
      </Zoom>
      <Fade in timeout={1000}>
        <LinearProgress color="secondary" sx={{ width: "7.35rem", mt: 2 }} />
      </Fade>
    </Box>
  );
};

export default SplashScreen;
