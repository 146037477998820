import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SupportTile from "./components/SupportTile";
import Page from "../../components/Page";
import { Box, Grid, Typography, Link } from "@mui/material";
import { SharedStateAction, useSharedState } from "../../contextProviders/SharedState";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";

const renderContactLine = (type: "phone" | "mail", text: string, suffix?: string, smallPadding?: boolean) => {
  return (
    <Box
      sx={{
        columnGap: "8px",
        pb: smallPadding ? "4px" : "16px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {type == "phone" ? (
        <>
          <PhoneIcon fontSize="small" />
          <Link variant="body1" href={`tel:${text}`} color="inherit">
            {text}
          </Link>
          {suffix && <Typography>{suffix}</Typography>}
        </>
      ) : (
        <>
          <EmailIcon fontSize="small" />
          <Link variant="body1" href={`mailto:${text}`} color="inherit">
            {text}
          </Link>
          {suffix && <Typography>{suffix}</Typography>}
        </>
      )}
    </Box>
  );
};

const Support: FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { dispatch: sharedStateDispatch } = useSharedState();

  const openFeedbackForm = () => {
    sharedStateDispatch({ type: SharedStateAction.FeedbackOpen });
  };

  return (
    <Page title={t("Contacts.Title")} subtitle={t("Sidebar.Knowledgebase.Title")}>
      {i18n.language == "de" && (
        <Grid container spacing={4} sx={{ display: "flex", flexDirection: "row" }}>
          <SupportTile
            headline="Auftragsbearbeitung"
            subline="Sie haben Rückfragen zu einem Auftrag den Sie erteilt haben?"
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-150")}
                {renderContactLine("mail", "order@gdata.de")}
              </>
            }
          />
          <SupportTile
            headline="Lizenzverlängerung"
            subline="Sie möchten Ihre Lizenz verlängern, erweitern, downgraden oder das Produkt wechseln?"
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-356")}
                {renderContactLine("mail", "renewal@gdata.de")}
              </>
            }
          />
          <SupportTile
            headline="Partnerportal"
            subline="Sie haben Fragen zur Struktur und Inhalten des Partnerportals oder vermissen ein bestimmtes Feature? Wir freuen uns auf Ihr Feedback."
            content={<>{renderContactLine("phone", "+49 234 9762-170")}</>}
            cta="Feedback geben"
            action={openFeedbackForm}
          />
          <SupportTile
            headline="Buchhaltung"
            subline="Sie haben Fragen zur Zahlungsabwicklung?"
            content={<>{renderContactLine("phone", "+49 234 9762-354")}</>}
            cta="Zum Kontaktformular"
            link="https://www.gdata.de/support/kontakt/accounting"
          />
          <SupportTile
            headline="B2B Support"
            subline="Sie haben technische Fragen oder benötigen Unterstützung bei der Lösung eines B2B-Problems?"
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-902", "(DE)", true)}
                {renderContactLine("phone", "+43 800 400-360", "(AT)", true)}
                {renderContactLine("phone", "+41 435 501-448", "(CH)")}
              </>
            }
            cta="Zum Kontaktformular"
            link="https://www.gdata.de/support/kontakt/technical-business-support"
          />
          <SupportTile
            headline="B2C Support"
            subline="Sie haben technische Fragen oder benötigen Unterstützung bei der Lösung eines B2C-Problems?"
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-910", "(DE)", true)}
                {renderContactLine("phone", "+43 800 376-390", "(AT)", true)}
                {renderContactLine("phone", "+41 435 508-005", "(CH)")}
              </>
            }
            cta="Zum Kontaktformular"
            link="https://www.gdata.de/support/kontakt/technical-support"
          />
        </Grid>
      )}
      {i18n.language == "fr" && (
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
          <SupportTile
            headline="France"
            subline="Vous avez des questions techniques ou besoin d'aide pour résoudre un problème ?"
            content={
              <>
                <Typography variant="body1">Numéros de service (B2C)</Typography>
                {renderContactLine("phone", "+34 (0)902 88 75 89")}
                {renderContactLine("mail", "support@gdata.fr")}
                <Typography variant="body1">Numéros de service (B2B)</Typography>
                {renderContactLine("phone", "+33 (0)141 48 54 80")}
                {renderContactLine("mail", "b2b-support@gdata.fr")}
              </>
            }
            cta="Aller au formulaire de contact"
            link="https://www.gdata.fr/support/formulaire-de-contact/technical-support"
          />
          <SupportTile
            headline="België"
            subline="Vous avez des questions techniques ou besoin d'aide pour résoudre un problème ?"
            content={
              <>
                <Typography variant="body1">Numéros de service (B2C)</Typography>
                {renderContactLine("phone", "+32 (0)2 669 06 59")}
                {renderContactLine("mail", "support@gdata.fr")}
                <Typography variant="body1">Numéros de service (B2B)</Typography>
                {renderContactLine("phone", "+32 (0)2 669 08 48")}
                {renderContactLine("mail", "b2b-support@gdata.fr")}
              </>
            }
            cta="Aller au formulaire de contact"
            link="https://www.gdata.fr/support/formulaire-de-contact/technical-support"
          />
        </Grid>
      )}
      {i18n.language == "nl" && (
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
          <SupportTile
            headline="Netherlands"
            subline="Heeft u technische vragen of ondersteuning nodig bij het oplossen van een probleem?"
            content={
              <>
                <Typography variant="body1">Servicenummers (B2C)</Typography>
                {renderContactLine("phone", "+31 (0)20 808 08 34")}
                {renderContactLine("mail", "support@gdata.nl")}
                <Typography variant="body1">Servicenummers (B2B)</Typography>
                {renderContactLine("phone", "+31 (0)20 808 08 36")}
                {renderContactLine("mail", "b2b-support@gdata.nl")}
              </>
            }
            cta="Naar het contactformulier"
            link="https://www.gdata.nl/ondersteuning/contact/technical-support"
          />
          <SupportTile
            headline="Belgium-Dutch"
            subline="Heeft u technische vragen of ondersteuning nodig bij het oplossen van een probleem?"
            content={
              <>
                <Typography variant="body1">Servicenummers (B2C)</Typography>
                {renderContactLine("phone", "+32 (0)2 808 33 21")}
                {renderContactLine("mail", "support@gdata.be")}
                <Typography variant="body1">Servicenummers (B2B)</Typography>
                {renderContactLine("phone", "+32 (0)2 808 33-22")}
                {renderContactLine("mail", "b2b-support@gdata.be")}
              </>
            }
            cta="Naar het contactformulier"
            link="https://www.gdata.nl/ondersteuning/contact/technical-support"
          />
        </Grid>
      )}
      {i18n.language == "en" && (
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
          <SupportTile
            headline="Japan"
            subline="Relation Manager: Shinsuke Kishimoto"
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-303")}
                {renderContactLine("mail", "sales@gdata.co.jp")}
              </>
            }
          />
          <SupportTile
            headline="MEA"
            subline=""
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-177")}
                {renderContactLine("mail", "meat@gdatasoftware.com")}
              </>
            }
          />
          <SupportTile
            headline="SEA & SEA-EU"
            subline=""
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-177")}
                {renderContactLine("mail", "seaoceania@gdatasoftware.com")}
                {renderContactLine("mail", "india@gdatasoftware.com")}
                {renderContactLine("mail", "cee@gdatasoftware.com")}
                {renderContactLine("mail", "baltics@gdatasoftware.com")}
              </>
            }
          />
          <SupportTile
            headline="United Kingdom"
            subline=""
            content={
              <>
                {renderContactLine("phone", "+44 800 032 322 8")}
                {renderContactLine("mail", "b2b-support@gdata.co.uk")}
              </>
            }
          />
          <SupportTile headline="Portugal" subline="" content={<>{renderContactLine("phone", "+351 800 780320")}</>} />
          <SupportTile
            headline="Scandinavia"
            subline=""
            content={
              <>
                {renderContactLine("phone", "+49 234 9762-177")}
                {renderContactLine("mail", "scandinavia@gdatasoftware.com")}
              </>
            }
          />
          <SupportTile
            headline="China"
            subline=""
            content={
              <>
                {renderContactLine("phone", "+49 (0) 234 / 97 62-0")}
                {renderContactLine("mail", "sales@gdatasoftware.com")}
              </>
            }
          />
        </Grid>
      )}
      {i18n.language == "es" && (
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
          <SupportTile
            headline="Números de servicio"
            subline="¿Tiene preguntas técnicas o necesita ayuda para resolver un problema?"
            content={
              <>
                {renderContactLine("phone", "+34 (0)902 88 75 89")}
                {renderContactLine("mail", "support@gdata.es")}
              </>
            }
            cta="Al formulario de contacto"
            link="https://www.gdata.es/asistencia/formulario-de-contacto/technical-support"
          />
        </Grid>
      )}
      {i18n.language == "it" && (
        <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
          <SupportTile
            headline="Supporto"
            subline="Avete domande tecniche o bisogno di assistenza per risolvere un problema?"
            content={
              <>
                {renderContactLine("phone", "+39 848 694 682")}
                {renderContactLine("mail", "support@gdata.it")}
              </>
            }
            cta="Al modulo di contatto"
            link="https://www.gdata.it/servizio-clienti/contatti/modulo-di-contatto/technical-support"
          />
        </Grid>
      )}
    </Page>
  );
};

export default Support;
