import { FC } from "react";
import { DashboardTile as DashboardTileType } from "../../../types/dashboard";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  styled,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

// Styles für RSS Widget
import "../../../theme/rss-widget.css";

interface IDashboardTileProps {
  tile: DashboardTileType;
}

const DashboardCard = styled(Card)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  "& .plain.MuiCardContent-root": {
    fontFamily: theme.typography.fontFamily,
    height: "100%",
    "& h2, h4": theme.typography.h4,
    "& p, a, li": {
      fontWeight: theme.typography.body1.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      letterSpacing: theme.typography.body1.letterSpacing,
    },
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiCardActions-root": {
      position: "absolute",
      bottom: "0",
      background: theme.palette.background.paper,
      width: "100%",
      zIndex: 10,
    },
    "& .MuiCardActionArea-root, .dashboardTileContent": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
    },
    aspectRatio: "1 / 1",
    "&.size-2x1": {
      gridColumn: "span 2",
      aspectRatio: "2.1 / 1",
    },
    "&.size-2x2": {
      gridRow: "span 2",
      gridColumn: "span 2",
    },
  },
}));

const getCardContent = (tile: DashboardTileType) => {
  return (
    <>
      {tile.image && (
        <CardMedia
          component="img"
          image={`${process.env.REACT_APP_TYPO3_DOMAIN}${tile.image}` ?? null}
          alt="Dashboard Tile"
        />
      )}
      {tile.text && (tile.size == "2x2" || !tile.image) && (
        <CardContent className="plain" dangerouslySetInnerHTML={{ __html: tile.text }} />
      )}
    </>
  );
};

const isInternalLink = (url: string): boolean => {
  return url.startsWith(`/app/`);
};

const DashboardTile: FC<IDashboardTileProps> = (props) => {
  const { tile } = props;

  const content = getCardContent(tile);
  let mainLink = "";
  if (tile.linkWholeBox && tile.links && tile.links.length > 0) {
    mainLink = tile.links[0].url;
  }

  return (
    <DashboardCard key={tile.id} className={`size-${tile.size} ${tile.classes}`}>
      {!mainLink ? (
        <Box className="dashboardTileContent">{content}</Box>
      ) : isInternalLink(mainLink) ? (
        <CardActionArea to={mainLink} component={Link}>
          {content}
        </CardActionArea>
      ) : (
        <CardActionArea href={mainLink} component="a" target="_blank">
          {content}
        </CardActionArea>
      )}
      <CardActions>
        {tile.links &&
          tile.links.map((link) => {
            if (isInternalLink(link.url)) {
              return (
                <Button to={link.url} component={Link} color="primary" key={link.id}>
                  <Typography noWrap={true} component="span" variant="inherit">
                    {link.title}
                  </Typography>
                </Button>
              );
            } else {
              return (
                <Button href={link.url} color="primary" key={link.id} target="_blank">
                  <Typography noWrap={true} component="span" variant="inherit">
                    {link.title}
                  </Typography>
                </Button>
              );
            }
          })}
      </CardActions>
    </DashboardCard>
  );
};

export default DashboardTile;
