import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Stack, Toolbar } from "@mui/material";
import type { AppBarProps } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../Logo";
import { AppTitle } from "../../AppTitle";
import Navbar from "../../Navbar";
import * as React from "react";
import AccountPopover from "./AccountPopover";
import NotificationBar from "./NotificationBar";
import MiniBasket from "./MiniBasket";
import useAuth from "../../../contextProviders/Authentication";

interface NavbarAppLayoutProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const NavbarAppLayout: FC<NavbarAppLayoutProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();

  return (
    <Navbar {...other} elevation={1}>
      <Toolbar>
        <Box sx={{ display: { xs: "flex", lg: "none" } }}>
          {/*
          <Box sx={{ mr: 2 }}>
            <RouterLink to="/">
              <Logo width="120px" />
            </RouterLink>
          </Box>
          */}
          <IconButton color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "flex" } }}>
          <RouterLink to="/">
            <Logo width="115px" />
          </RouterLink>
          <AppTitle typography={{ variant: "h1" }} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Stack direction="row" spacing={1}>
          {!user?.isBlocked && <MiniBasket />}
          <NotificationBar />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </Navbar>
  );
};

export default NavbarAppLayout;
