import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../components/ContentBox";
import useAuth from "../../../contextProviders/Authentication";
import { debounce } from "lodash";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Step,
  Stepper,
  StepContent,
  StepLabel,
  AlertTitle,
  Stack,
  Skeleton,
} from "@mui/material";
import Dialog from "../../../components/Dialog";
import { BusinessCentralPaymentMethod } from "../../../types/user";
import { getValidationSchema, SepaFormValues } from "../formValidations/sepa";
import { useFormik } from "formik";
import { TextField } from "../../../components/formFields";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import BankUtils from "../../../utils/BankUtils";
import { PaymentApi } from "../../../api";
import { useGetSepaData, useInvalidateQueryClientData } from "../../../lib/queryClient";
import { useSnackbar } from "notistack";
import UserUtils from "../../../utils/UserUtils";

const SepaInformation: FC = () => {
  const { data, isLoading } = useGetSepaData();
  const { t, i18n } = useTranslation();
  if (isLoading || data == undefined) {
    return (
      <Box mt={2}>
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={120} />
      </Box>
    );
  }
  if (!data) {
    return <></>;
  }
  return (
    <Box mt={2}>
      <Typography variant="body1" fontWeight={600}>
        {t("Settings.Sepa.BankData")}:
      </Typography>
      <Typography variant="body1">{data.iban}</Typography>
      <Typography variant="body1">{data.bankName}</Typography>
      {data.mandatRef && (
        <>
          <Typography variant="body1">
            {t("Settings.Sepa.RefNo")}: {data.mandatRef}
          </Typography>
          <Typography variant="body1">
            ({t("Settings.Sepa.Granted")}: {DateTimeUtils.getLocalDate(i18n, data.mandatDate, "PPP")})
          </Typography>
        </>
      )}
    </Box>
  );
};

const PaymentForm: FC = () => {
  const { t, i18n } = useTranslation();
  const { user, updateUser } = useAuth();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const { invalidateSepaData, invalidateAllBasketData } = useInvalidateQueryClientData();
  const { enqueueSnackbar } = useSnackbar();

  const currentDate = Date.now() / 1000;
  const [activeStep, setActiveStep] = useState(0);
  const isEditAllowed = UserUtils.hasPermission(["shop"], user);
  const isSepaAllowed =
    (user?.payment == BusinessCentralPaymentMethod.SEPA || user?.payment == BusinessCentralPaymentMethod.U_S_BAR) &&
    UserUtils.hasPermission(["sepa"], user);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const initialFormValues: SepaFormValues = {
    accountHolder: user?.company ?? "",
    iban: "",
    bic: "",
    bankName: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      if (activeStep == 0) {
        handleNext();
      } else {
        PaymentApi.updateSepaData(values)
          .then(() => {
            invalidateSepaData().then(() => invalidateAllBasketData());
            enqueueSnackbar(t("Settings.Sepa.SuccessMsg"), { variant: "success" });
            updateUser({ payment: BusinessCentralPaymentMethod.SEPA });
            handleCloseDialog();
          })
          .catch(() => enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" }));
      }
    },
  });

  const getBankInfo = async (iban: string) => {
    let bankInfo = null;
    if (iban.length > 7) {
      bankInfo = await PaymentApi.getBankInfo(iban);
    }
    if (!bankInfo) {
      bankInfo = { bic: "", name: "" };
    }

    await formik.setFieldValue("bic", bankInfo.bic);
    await formik.setFieldValue("bankName", bankInfo.name);
    // Wir setzen die Formik-Errors manuell auf nicht vorhanden, da es auf Grund von Timing-Problemen automatisch
    // nicht funktioniert und wir bei einem Response davon ausgehen können, dass Daten vorhanden sind
    if (bankInfo.bic && bankInfo.name) {
      formik.setFieldError("bic", undefined);
      formik.setFieldError("bankName", undefined);
    }
  };

  const handleIbanChange = React.useRef(
    debounce(async (value) => {
      await getBankInfo(value);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      handleIbanChange.cancel();
    };
  }, [handleIbanChange]);

  const handleAcceptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowSubmit(event.target.checked && formik.isValid);
  };

  const handleOpenDialog = () => {
    formik.resetForm({ values: initialFormValues });
    setActiveStep(0);
    setShowDialog(true);
  };

  if (!user) {
    return <></>;
  }

  if (!isSepaAllowed || !isEditAllowed) {
    return (
      <ContentBox headline={t("Settings.PaymentTitle")}>
        <Typography>{t(`Settings.Payments.${user.payment}`)}</Typography>
      </ContentBox>
    );
  }

  return (
    <>
      <ContentBox headline={t("Settings.PaymentTitle")}>
        <Typography>{t("Settings.YourPayments")}:</Typography>
        <Typography>{t(`Settings.Payments.${user?.payment}`)}</Typography>
        {user?.payment == BusinessCentralPaymentMethod.SEPA && <SepaInformation />}
        <Button size="large" sx={{ mt: 3 }} fullWidth variant="contained" onClick={handleOpenDialog}>
          {user?.payment == BusinessCentralPaymentMethod.SEPA
            ? t("Settings.Sepa.Actions.Edit")
            : t("Settings.Sepa.Actions.Switch")}
        </Button>
      </ContentBox>
      <Dialog
        show={showDialog}
        maxWidth="md"
        title={t("Settings.Sepa.Title")}
        handleClose={handleCloseDialog}
        content={
          <>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step key="step-1">
                  <StepLabel>{t("Settings.Sepa.Step1Title")}</StepLabel>
                  <StepContent>
                    <Grid
                      container
                      columnSpacing={2}
                      sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                    >
                      <Grid item xs={12}>
                        <TextField
                          formik={formik}
                          label={t("Settings.Sepa.AccountHolder")}
                          name="accountHolder"
                          fullWidth
                          size="small"
                          required
                          keepSpace={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          formik={formik}
                          label={"IBAN"}
                          name="iban"
                          fullWidth
                          required
                          keepSpace={false}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleIbanChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          formik={formik}
                          label={"BIC/SWIFT"}
                          name="bic"
                          disabled={true}
                          fullWidth
                          required
                          keepSpace={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          formik={formik}
                          label={t("Settings.Sepa.BankName")}
                          name="bankName"
                          fullWidth
                          disabled={true}
                          size="small"
                          required
                          keepSpace={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          value="1"
                          name="step"
                          fullWidth
                          disabled={!formik.values.bic || !formik.isValid}
                        >
                          weiter
                        </Button>
                      </Grid>
                    </Grid>
                  </StepContent>
                </Step>
                <Step key="step-2">
                  <StepLabel>{t("Settings.Sepa.Step2Title")}</StepLabel>
                  <StepContent>
                    <Alert severity="info" variant="outlined" sx={{ mb: 2 }}>
                      <AlertTitle>{t("Settings.Sepa.Mandate.AlertTitle")}</AlertTitle>
                      {t("Settings.Sepa.Mandate.AlertText")}
                    </Alert>
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="h5" sx={{ mb: 2 }}>
                        {t("Settings.Sepa.Mandate.Title")}
                      </Typography>
                      <Table size="small" sx={{ mb: 2 }}>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("Settings.Sepa.Mandate.PaymentRecipient")}</TableCell>
                            <TableCell>G DATA CyberDefense AG Deutschland/Bochum</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Settings.Sepa.Mandate.CreditorIdentificationNo")}</TableCell>
                            <TableCell>DE11ZZZ00000017425</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Settings.Sepa.Mandate.Reference")}</TableCell>
                            <TableCell>{t("Settings.Sepa.Mandate.ReferenceInfo")}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Typography variant="body2" sx={{ mb: 2, whiteSpace: "pre-line" }}>
                        {t("Settings.Sepa.Mandate.InfoText")}
                      </Typography>
                      <Table size="small" sx={{ mb: 2 }}>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("Settings.Sepa.AccountHolder")}</TableCell>
                            <TableCell>{formik.values.accountHolder}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Settings.Sepa.Mandate.Address")}</TableCell>
                            <TableCell>
                              {user.address}, {user.zip} {user.city}, {user.country}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>IBAN</TableCell>
                            <TableCell>{BankUtils.printFormat(formik.values.iban)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>BIC/SWIFT</TableCell>
                            <TableCell>{formik.values.bic}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Settings.Sepa.BankName")}</TableCell>
                            <TableCell>{formik.values.bankName}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Typography variant="body2">
                        Bochum, {DateTimeUtils.getLocalDate(i18n, currentDate, "PPP")}, {user.firstName} {user.lastName}
                      </Typography>
                    </Paper>

                    <FormControlLabel
                      sx={{ mb: 2, mt: 2 }}
                      control={<Checkbox onChange={handleAcceptChange} />}
                      label={t("Settings.Sepa.Mandate.Accept")}
                    />
                    <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                      <Button color="primary" type="button" onClick={handleBack} variant="outlined">
                        {t("Settings.Sepa.Actions.Back")}
                      </Button>
                      <Button color="primary" disabled={!allowSubmit} type="submit" variant="contained">
                        {t("Settings.Sepa.Actions.Submit")}
                      </Button>
                    </Stack>
                  </StepContent>
                </Step>
              </Stepper>
            </form>
          </>
        }
      />
    </>
  );
};
export default PaymentForm;
