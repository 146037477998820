import React, { FC, useCallback, useEffect, useState } from "react";
import ContentBox from "../../components/ContentBox";
import { useTranslation } from "react-i18next";
import { Alert, Button, Grid, Skeleton, Typography } from "@mui/material";
import Dialog from "../../components/Dialog";
import { MfaApi } from "../../api";
import { useSnackbar } from "notistack";
import { useConfirmationDialog } from "../../contextProviders/ConfirmationDialog";
import ActivateOneTimePassword from "./components/ActivateOneTimePassword";
import GenerateRecoveryCodes from "./components/GenerateRecoveryCodes";

type DialogType = "one-time-password" | "recovery-codes";

const MfaSettings: FC = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [dialogType, setDialogType] = useState<DialogType>("one-time-password");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [remainingRecoveryCodes, setRemainingRecoveryCodes] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    MfaApi.oneTimePasswordIsActive().then((active) => {
      setIsActive(active);
    });

    if (isActive) {
      MfaApi.recoveryCodesRemainingCodes().then((remainingCodes) => {
        setRemainingRecoveryCodes(remainingCodes);
      });
    }
  }, [isActive]);

  const handleShowDialog = (type: DialogType) => {
    setDialogType(type);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleSetIsActive = () => {
    setIsActive(true);
  };

  const handleDeactivateOTP = useCallback(() => {
    getConfirmation({
      title: t("Mfa.OneTimePassword.Deactivate.Title"),
      body: t("Mfa.OneTimePassword.Deactivate.Text"),
      acceptText: t("Common.Ok"),
      declineText: t("Common.Cancel"),
      disableButtonAfterAction: true,
      onAccept: () =>
        MfaApi.oneTimePasswordDeactivate()
          .then((isDeactivated) => {
            if (isDeactivated) {
              setIsActive(false);
              enqueueSnackbar(t("Mfa.Messages.SuccessfullyDeactivated"), { variant: "success" });
            }
          })
          .catch(() => {
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }),
    });
  }, [enqueueSnackbar, getConfirmation, t]);

  const handleRegenerateRecoveryCodes = useCallback(() => {
    getConfirmation({
      title: t("Mfa.RecoveryCodes.Regenerate.Title"),
      body: t("Mfa.RecoveryCodes.Regenerate.Text"),
      acceptText: t("Common.Ok"),
      declineText: t("Common.Cancel"),
      disableButtonAfterAction: true,
      onAccept: () => handleShowDialog("recovery-codes"),
    });
  }, [getConfirmation, t]);

  return (
    <>
      {isActive !== null ? (
        <>
          {isActive ? (
            <>
              <ContentBox headline={t("Mfa.Title")} sx={{ mb: 4 }}>
                <Typography sx={{ mb: 2 }}>{t("Mfa.DescriptionSecured")}</Typography>
                <Button fullWidth variant="contained" size="large" color="error" onClick={handleDeactivateOTP}>
                  {t("Mfa.OneTimePassword.Deactivate.Title")}
                </Button>
              </ContentBox>
              <ContentBox headline={t("Mfa.RecoveryCodes.Title")}>
                {remainingRecoveryCodes !== null ? (
                  <Alert variant="outlined" color={remainingRecoveryCodes <= 0 ? "warning" : "info"} sx={{ mb: 2 }}>
                    {t("Mfa.RecoveryCodes.RemainingCodes", { count: remainingRecoveryCodes })}
                  </Alert>
                ) : (
                  <Skeleton variant="rectangular" height={38} sx={{ mb: 2 }} />
                )}
                <Typography sx={{ mb: 2 }}>{t("Mfa.RecoveryCodes.Generate")}</Typography>
                <Button fullWidth variant="contained" size="large" onClick={handleRegenerateRecoveryCodes}>
                  {t("Mfa.RecoveryCodes.Regenerate.Title")}
                </Button>
              </ContentBox>
            </>
          ) : (
            <ContentBox headline={t("Mfa.Title")}>
              <Typography sx={{ mb: 4 }}>{t("Mfa.DescriptionSetup")}</Typography>
              <Button fullWidth variant="contained" size="large" onClick={() => handleShowDialog("one-time-password")}>
                {t("Mfa.SetupBtn")}
              </Button>
            </ContentBox>
          )}
          <Dialog
            show={showDialog}
            handleClose={handleCloseDialog}
            title={t("Mfa.Title")}
            content={
              <Grid container spacing={1}>
                {dialogType === "one-time-password" && (
                  <ActivateOneTimePassword
                    handleSetIsActive={handleSetIsActive}
                    handleCloseDialog={handleCloseDialog}
                  />
                )}
                {dialogType === "recovery-codes" && <GenerateRecoveryCodes handleCloseDialog={handleCloseDialog} />}
              </Grid>
            }
          />
        </>
      ) : (
        <Skeleton variant="rectangular" height={314} sx={{ mb: 2 }} />
      )}
    </>
  );
};

export default MfaSettings;
