import { Reseller } from "../types/license";
import axios from "../lib/axiosForApi";

export const enum ApiResponseStatus {
  "OK" = 200,
  "NOT_FOUND" = 204,
  "VALIDATION_FAILED" = 422,
}

interface IResellerResponse {
  status: ApiResponseStatus;
  validationErrors: { [key: string]: string };
}

class ResellerApi {
  async update(reseller: Reseller): Promise<IResellerResponse> {
    return axios
      .patch("/license/reseller", { reseller })
      .then((response) => {
        return { status: response.status, validationErrors: {} };
      })
      .catch((error) => {
        return { status: error.response.status, validationErrors: error.response.data };
      });
  }

  async create(reseller: Omit<Reseller, "uid">): Promise<IResellerResponse> {
    return axios
      .post("/license/reseller", { reseller })
      .then((response) => {
        return { status: response.status, validationErrors: {} };
      })
      .catch((error) => {
        return { status: error.response.status, validationErrors: error.response.data };
      });
  }

  async delete(resellerId: number): Promise<number> {
    return axios
      .delete(`/license/reseller/${resellerId}`)
      .then(() => {
        return resellerId;
      })
      .catch(() => {
        return 0;
      });
  }
}

export default new ResellerApi();
