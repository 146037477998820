import type { FC } from "react";
import * as React from "react";
import { useState } from "react";
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import useAuth from "../../../contextProviders/Authentication";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { SharedStateAction, useSharedState } from "../../../contextProviders/SharedState";

const AccountPopover: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useAuth();
  const { dispatch: sharedStateDispatch } = useSharedState();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return user ? (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <PersonIcon data-testid={"account-popover_menu-open-icon"} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={RouterLink} to="/app/settings/personal-data" sx={{ alignItems: "flex-start" }}>
          <Avatar sx={{ mt: 1 }} />
          <ListItemText
            primary={`${user.firstName} ${user.lastName}`}
            secondary={
              <>
                {user.company}
                <br />
                {t("Common.CustomerNo")}: {user.customerNo}
              </>
            }
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => sharedStateDispatch({ type: SharedStateAction.FeedbackOpen })}>
          <ListItemIcon>
            <RateReviewOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("FeedbackForm.Title")}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <PowerSettingsNewIcon fontSize="small" data-testid={"account-popover_logout-icon"} />
          </ListItemIcon>
          {t("Common.Logout")}
        </MenuItem>
      </Menu>
    </>
  ) : (
    <></>
  );
};

export default AccountPopover;
