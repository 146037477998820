import React, { FC } from "react";
import { ContentElement } from "../../../../types/typo3Page";

export const Html: FC<{ data: ContentElement }> = ({ data }) => {
  return (
    <div
      className=""
      dangerouslySetInnerHTML={{
        __html: (data.content.bodytext as string).replace(`/app-pages/`, `/app/p/`),
      }}
    />
  );
};

export default Html;
