export const licenseDuration: number[] = [1, 2, 3];

export const licenses: { id: string; name: string }[] = [
  {
    id: "DEF",
    name: "Standard",
  },
  {
    id: "EDU",
    name: "Education",
  },
  {
    id: "GOV",
    name: "Government",
  },
  {
    id: "RNW",
    name: "Renewal",
  },
  {
    id: "CGR",
    name: "Crossgrade",
  },
];
