import axios from "axios";
import AuthApi from "../api/auth";
import TokenUtils from "../utils/TokenUtils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  responseType: "json",
  headers: {
    "content-type": "application/json;charset=UTF-8",
  },
});

/*
  Sets the Bearer Access-Token, if one is set in localStorage
 */
axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenUtils.getAccessToken();
    if (token) {
      const headers = config.headers || {};
      headers.Authorization = `Bearer ${token}`;
      config.headers = headers;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

async function logoutAndRedirect() {
  await AuthApi.logout();
  window.history.pushState(
    {
      usr: {
        from: window.location.pathname,
      },
    },
    "Login",
    "/login"
  );
  window.location.pathname = "/login";
}
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry && error.config.url !== "/auth/refresh") {
        originalConfig._retry = true;

        try {
          const newAccessToken = await AuthApi.refresh();
          axiosInstance.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
          return await axiosInstance(originalConfig);
        } catch (_error) {
          await logoutAndRedirect();
        }
      } else {
        if (error.response.status === 401 || (error.response.status === 403 && error.config.url !== "/auth/login")) {
          await logoutAndRedirect();
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
