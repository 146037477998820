import { i18n as i18next } from "i18next";

class DateTimeUtils {
  /**
   * Convert a date string to a new Date object.
   *
   * @param {String} dateString
   */
  static stringToDate = (dateString: string): Date | null => {
    try {
      return dateString != "" && dateString !== "0001-01-01T00:00:00" ? new Date(dateString) : null;
    } catch (err) {
      return null;
    }
  };

  /**
   * Convert a timestamp to a new Date object.
   *
   * @param {Number} timestamp
   */
  static timestampToDate = (timestamp: number): Date => {
    return new Date(timestamp * 1000);
  };

  /**
   * Return a local formatted date string.
   *
   * @param {i18next} i18n
   * @param {Number}  timestamp
   * @param {String}  format
   */
  static getLocalDate = (i18n: i18next, timestamp: number, format: string): string => {
    return i18n.format(DateTimeUtils.timestampToDate(timestamp), format, i18n.language);
  };

  /**
   * Return the remaining days until the passed timestamp is reached.
   *
   * @param {Number} timestamp
   */
  static getDaysLeft = (timestamp: number): number => {
    const oneDay = 24 * 60 * 60 * 1000;
    const givenDate = DateTimeUtils.timestampToDate(timestamp);
    const currentDate = new Date();

    return Math.round(Math.abs((givenDate.getTime() - currentDate.getTime()) / oneDay));
  };
}

export default DateTimeUtils;
