import * as React from "react";
import { FC, useEffect } from "react";
import RenderContentElement from "./elements/content/RenderContentElement";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../Page";
import { useGetTypo3Page } from "../../lib/queryClient";
import { getLanguage } from "../../locales";
import { Box, Skeleton } from "@mui/material";
import ContentBox from "../ContentBox";
import { useTheme } from "@mui/material/styles";
import { ContentElement } from "../../types/typo3Page";

const Typo3Page: FC = () => {
  const { i18n } = useTranslation();
  const { slug } = useParams() as { slug: string };
  const { data: typo3PageData, isLoading } = useGetTypo3Page(slug, getLanguage(i18n));
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    // action here
    document.addEventListener("click", function (ev: MouseEvent) {
      const elem = ev.target as HTMLAnchorElement;
      if (elem && elem.matches(".t3-content a") && elem.host === window.location.host) {
        ev.preventDefault();
        const href = elem.getAttribute("href") as string;
        // use React Router to link manually to href
        navigate(href, { replace: true });
      }
    });
  }, [navigate]);

  return (
    <>
      {isLoading ? (
        <>
          <Box p={2}>
            <Skeleton variant="text" sx={theme.typography.body1} width={150} />
            <Skeleton variant="text" sx={theme.typography.h4} width={350} />
          </Box>
        </>
      ) : typo3PageData ? (
        <>
          <Page title={typo3PageData.meta.title} subtitle={typo3PageData.meta.subtitle} maxWidth="md">
            <ContentBox sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
              {typo3PageData.content.colPos0
                ? typo3PageData.content.colPos0.map((item: ContentElement) => RenderContentElement(item))
                : ""}
            </ContentBox>
          </Page>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Typo3Page;
