import { useSnackbar } from "notistack";
import { LicenseApi } from "../../../api";
import { Trans, useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { LicenseCenterCancelSubscriptionResponse } from "../../../types/license";
import { useConfirmationDialog } from "../../../contextProviders/ConfirmationDialog";

export function useCancelSubscriptionService(): {
  cancelSubscription: (registrationNumber: string, invoiceDate: string) => void;
} {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getConfirmation } = useConfirmationDialog();
  const cancelSubscription = useCallback(
    (registrationNumber: string, invoiceDate: string) => {
      getConfirmation({
        title: t("LicenseCenter.CancelSubscription.Title"),
        body: (
          <Trans
            i18nKey="LicenseCenter.CancelSubscription.ExplanationPart"
            values={{ registrationNumber: registrationNumber, invoiceDate: invoiceDate }}
            components={{ bold: <strong /> }}
          />
        ),
        acceptText: t("LicenseCenter.CancelSubscription.SubmitBtn"),
        declineText: t("LicenseCenter.CancelSubscription.CancelBtn"),
        disableButtonAfterAction: true,
        onAccept: () =>
          LicenseApi.cancelSubscription(registrationNumber).then(
            (response: LicenseCenterCancelSubscriptionResponse) => {
              if (response.success === true) {
                enqueueSnackbar(t("LicenseCenter.CancelSubscription.SuccessMessage"), { variant: "success" });
              } else {
                enqueueSnackbar(t("LicenseCenter.CancelSubscription.ErrorMessage"), { variant: "error" });
              }
            }
          ),
      });
    },
    [enqueueSnackbar, getConfirmation, t]
  );

  return { cancelSubscription };
}
