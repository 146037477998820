import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import { useSnackbar } from "notistack";
import { PasswordField, TextField } from "../../../components/formFields";
import { LoginFormValues, getValidationSchema } from "../formValidations/login";
import useAuth from "../../../contextProviders/Authentication";

interface LoginFormProps {
  onSuccessfulSubmit?: () => void;
}

/**
 *  Stand alone Login-Form
 */
const LoginForm: FC<LoginFormProps> = ({ onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const loginResponse = await login(values.email, values.password);
      if (loginResponse === 200) {
        if (onSuccessfulSubmit) {
          onSuccessfulSubmit();
        }
      } else {
        setStatus({ success: false });
        setSubmitting(false);
        if (loginResponse === 403) {
          // login/password invalid
          setErrors({
            email: t("Login.LoginIncorrect"),
            password: t("Login.LoginIncorrect"),
          });
        } else {
          // server error
          enqueueSnackbar(t("Login.ServerError"), {
            variant: "error",
          });
        }
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField
        formik={formik}
        label={t("Forms.UsernameOrMail")}
        name="email"
        type="text"
        autoComplete="username"
        required
        icon={<PersonIcon />}
        InputLabelProps={{ required: false }}
        dataTestid={"login-form_username"}
      />
      <PasswordField
        formik={formik}
        label={t("Forms.Password")}
        name="password"
        autoComplete="current-password"
        required
        InputLabelProps={{ required: false }}
        dataTestid={"login-form_password"}
      />
      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        data-testid={"login-form_login-button"}
      >
        {t("Login.LoginBtn", "Login")}
      </Button>
      <Typography variant="body2" align="center" pt={2}>
        <Link component={RouterLink} data-testid="login-form_lost-password" to="/lost-password" underline="none">
          {t("Login.ForgotPassword", "Forgot your password?")}
        </Link>
      </Typography>
    </form>
  );
};

export default LoginForm;
