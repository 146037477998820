import { Basket } from "../../../types/basket";
import React, { BaseSyntheticEvent, FC, useEffect, useMemo, useState } from "react";
import { Button, Skeleton, TextField as MuiTextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { BasketApi } from "../../../api";
import { KEY_BASKET_GET } from "../../../lib/queryClient";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

interface IBasketCustomOrderIdFormProps {
  readOnly: boolean;
  basket: Basket;
}

export const BasketCustomOrderIdForm: FC<IBasketCustomOrderIdFormProps> = ({ readOnly, basket }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [externalDocumentNo, setExternalDocumentNo] = useState<string>("");
  const navigate = useNavigate();

  const debouncedChangeExternalDocumentNo = useMemo(
    () =>
      debounce(async (userInput) => {
        const updatedBasket = await BasketApi.update({ externalDocumentNumber: userInput });
        queryClient.setQueryData([KEY_BASKET_GET], updatedBasket);
      }, 750),
    [queryClient]
  );

  const handleExternalDocumentNoChange = (event: BaseSyntheticEvent) => {
    const changedExternalDocumentNo = event.target.value;
    debouncedChangeExternalDocumentNo(changedExternalDocumentNo);
    setExternalDocumentNo(changedExternalDocumentNo);
  };

  useEffect(() => {
    setExternalDocumentNo(basket.externalDocumentNumber ?? "");
    return () => {
      debouncedChangeExternalDocumentNo.cancel();
    };
  }, [basket, debouncedChangeExternalDocumentNo]);

  return (
    <>
      {readOnly ? (
        <>
          <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
            {t("Basket.CustomOrderId")}{" "}
            <Button size="small" sx={{ padding: 0 }} onClick={() => navigate("/app/shop/basket")}>
              {t("Basket.Checkout.BtnEditOrder")}
            </Button>
          </Typography>
          <Typography sx={{ mb: 3 }}>{externalDocumentNo}</Typography>
        </>
      ) : basket ? (
        <MuiTextField
          label={t("Basket.CustomOrderId")}
          size="small"
          value={externalDocumentNo}
          fullWidth
          onChange={handleExternalDocumentNoChange}
          sx={{ mt: 2, mb: 2, maxWidth: "350px" }}
          inputProps={{
            maxLength: 100,
          }}
        />
      ) : (
        <Skeleton width={"100%"} height={40} sx={{ mt: 2, mb: 4 }} />
      )}
    </>
  );
};
