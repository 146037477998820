export const countryData = [
  {
    iso2: "AD",
    iso3: "AND",
    name: 'Principat d"Andorra',
    nameEn: "Principality of Andorra",
    shortName: "Andorra",
    shortNameEn: "Andorra",
  },

  {
    iso2: "AE",
    iso3: "ARE",
    name: "الإمارات العربيّة المتّحدة",
    nameEn: "United Arab Emirates",
    shortName: "الإمارات العربيّة المتّحدة",
    shortNameEn: "United Arab Emirates",
  },

  {
    iso2: "AF",
    iso3: "AFG",
    name: "د افغانستان اسلامي دولت",
    nameEn: "Islamic Republic of Afghanistan",
    shortName: "افغانستان",
    shortNameEn: "Afghanistan",
  },

  {
    iso2: "AG",
    iso3: "ATG",
    name: "Antigua and Barbuda",
    nameEn: "Antigua and Barbuda",
    shortName: "Antigua and Barbuda",
    shortNameEn: "Antigua and Barbuda",
  },

  {
    iso2: "AI",
    iso3: "AIA",
    name: "Anguilla",
    nameEn: "Anguilla",
    shortName: "Anguilla",
    shortNameEn: "Anguilla",
  },

  {
    iso2: "AL",
    iso3: "ALB",
    name: "Republika e Shqipërisë",
    nameEn: "Republic of Albania",
    shortName: "Shqipëria",
    shortNameEn: "Albania",
  },

  {
    iso2: "AM",
    iso3: "ARM",
    name: "Հայաստանի Հանրապետություն",
    nameEn: "Republic of Armenia",
    shortName: "Հայաստան",
    shortNameEn: "Armenia",
  },

  {
    iso2: "AO",
    iso3: "AGO",
    name: "República de Angola",
    nameEn: "Republic of Angola",
    shortName: "Angola",
    shortNameEn: "Angola",
  },

  {
    iso2: "AQ",
    iso3: "ATA",
    name: "Antarctica",
    nameEn: "Antarctica",
    shortName: "Antarctica",
    shortNameEn: "Antarctica",
  },

  {
    iso2: "AR",
    iso3: "ARG",
    name: "República Argentina",
    nameEn: "Argentine Republic",
    shortName: "Argentina",
    shortNameEn: "Argentina",
  },

  {
    iso2: "AS",
    iso3: "ASM",
    name: "Amerika Samoa",
    nameEn: "American Samoa",
    shortName: "Amerika Samoa",
    shortNameEn: "American Samoa",
  },

  {
    iso2: "AT",
    iso3: "AUT",
    name: "Republik Österreich",
    nameEn: "Republic of Austria",
    shortName: "Österreich",
    shortNameEn: "Austria",
  },

  {
    iso2: "AU",
    iso3: "AUS",
    name: "Commonwealth of Australia",
    nameEn: "Commonwealth of Australia",
    shortName: "Australia",
    shortNameEn: "Australia",
  },

  {
    iso2: "AW",
    iso3: "ABW",
    name: "Aruba",
    nameEn: "Aruba",
    shortName: "Aruba",
    shortNameEn: "Aruba",
  },

  {
    iso2: "AZ",
    iso3: "AZE",
    name: "Azərbaycan Respublikası",
    nameEn: "Republic of Azerbaijan",
    shortName: "Azərbaycan",
    shortNameEn: "Azerbaijan",
  },

  {
    iso2: "BA",
    iso3: "BIH",
    name: "Bosna i Hercegovina / Босна и Херцеговина",
    nameEn: "Bosnia and Herzegovina",
    shortName: "BiH/БиХ",
    shortNameEn: "Bosnia and Herzegovina",
  },

  {
    iso2: "BB",
    iso3: "BRB",
    name: "Barbados",
    nameEn: "Barbados",
    shortName: "Barbados",
    shortNameEn: "Barbados",
  },

  {
    iso2: "BD",
    iso3: "BGD",
    name: "গনপ্রজাতন্ত্রী বাংলা",
    nameEn: "People’s Republic of Bangladesh",
    shortName: "বাংলাদেশ",
    shortNameEn: "Bangladesh",
  },

  {
    iso2: "BE",
    iso3: "BEL",
    name: "Koninkrijk België / Royaume de Belgique",
    nameEn: "Kingdom of Belgium",
    shortName: "Belgique",
    shortNameEn: "Belgium",
  },

  {
    iso2: "BF",
    iso3: "BFA",
    name: "Burkina Faso",
    nameEn: "Burkina Faso",
    shortName: "Burkina",
    shortNameEn: "Burkina Faso",
  },

  {
    iso2: "BG",
    iso3: "BGR",
    name: "Република България",
    nameEn: "Republic of Bulgaria",
    shortName: "Bulgaria",
    shortNameEn: "Bulgaria",
  },

  {
    iso2: "BH",
    iso3: "BHR",
    name: "مملكة البحرين",
    nameEn: "Kingdom of Bahrain",
    shortName: "البحري",
    shortNameEn: "Bahrain",
  },

  {
    iso2: "BI",
    iso3: "BDI",
    name: "Republika yu Burundi",
    nameEn: "Republic of Burundi",
    shortName: "Burundi",
    shortNameEn: "Burundi",
  },

  {
    iso2: "BJ",
    iso3: "BEN",
    name: "République du Bénin",
    nameEn: "Republic of Benin",
    shortName: "Bénin",
    shortNameEn: "Benin",
  },

  {
    iso2: "BM",
    iso3: "BMU",
    name: "Bermuda",
    nameEn: "Bermuda",
    shortName: "Bermuda",
    shortNameEn: "Bermuda",
  },

  {
    iso2: "BN",
    iso3: "BRN",
    name: "برني دارالسلام",
    nameEn: "Sultanate of Brunei",
    shortName: "دارالسلام",
    shortNameEn: "Brunei",
  },

  {
    iso2: "BO",
    iso3: "BOL",
    name: "Estado Plurinacional de Bolivia",
    nameEn: "Plurinational State of Bolivia",
    shortName: "Bolivia",
    shortNameEn: "Bolivia",
  },

  {
    iso2: "BR",
    iso3: "BRA",
    name: "República Federativa do Brasil",
    nameEn: "Federative Republic of Brazil",
    shortName: "Brasil",
    shortNameEn: "Brazil",
  },

  {
    iso2: "BS",
    iso3: "BHS",
    name: "Commonwealth of The Bahamas",
    nameEn: "Commonwealth of The Bahamas",
    shortName: "The Bahamas",
    shortNameEn: "The Bahamas",
  },

  {
    iso2: "BT",
    iso3: "BTN",
    name: "Druk-Yul",
    nameEn: "Kingdom of Bhutan",
    shortName: "Druk-Yul",
    shortNameEn: "Bhutan",
  },

  {
    iso2: "BV",
    iso3: "BVT",
    name: "Bouvet Island",
    nameEn: "Bouvet Island",
    shortName: "Bouvetøya",
    shortNameEn: "Bouvet Island",
  },

  {
    iso2: "BW",
    iso3: "BWA",
    name: "Republic of Botswana",
    nameEn: "Republic of Botswana",
    shortName: "Botswana",
    shortNameEn: "Botswana",
  },

  {
    iso2: "BY",
    iso3: "BLR",
    name: "Рэспубліка Беларусь",
    nameEn: "Republic of Belarus",
    shortName: "Беларусь",
    shortNameEn: "Belarus",
  },

  {
    iso2: "BZ",
    iso3: "BLZ",
    name: "Belize",
    nameEn: "Belize",
    shortName: "Belize",
    shortNameEn: "Belize",
  },

  {
    iso2: "CA",
    iso3: "CAN",
    name: "Canada",
    nameEn: "Canada",
    shortName: "Canada",
    shortNameEn: "Canada",
  },

  {
    iso2: "CC",
    iso3: "CCK",
    name: "Territory of Cocos (Keeling) Islands",
    nameEn: "Territory of Cocos (Keeling) Islands",
    shortName: "Cocos (Keeling) Islands",
    shortNameEn: "Cocos (Keeling) Islands",
  },

  {
    iso2: "CD",
    iso3: "COD",
    name: "République Démocratique du Congo",
    nameEn: "Democratic Republic of the Congo",
    shortName: "Congo",
    shortNameEn: "Congo",
  },

  {
    iso2: "CF",
    iso3: "CAF",
    name: "République centrafricaine",
    nameEn: "Central African Republic",
    shortName: "République centrafricaine",
    shortNameEn: "Central African Republic",
  },

  {
    iso2: "CG",
    iso3: "COG",
    name: "République du Congo",
    nameEn: "Republic of the Congo",
    shortName: "Congo-Brazzaville",
    shortNameEn: "Congo-Brazzaville",
  },

  {
    iso2: "CH",
    iso3: "CHE",
    name: "Confédération suisse / Schweizerische Eidgenossenschaft",
    nameEn: "Swiss Confederation",
    shortName: "Schweiz",
    shortNameEn: "Switzerland",
  },

  {
    iso2: "CI",
    iso3: "CIV",
    name: "République de Côte d’Ivoire",
    nameEn: 'Republic of Côte d"Ivoire',
    shortName: "Côte d’Ivoire",
    shortNameEn: "Côte d’Ivoire",
  },

  {
    iso2: "CK",
    iso3: "COK",
    name: "Cook Islands",
    nameEn: "Cook Islands",
    shortName: "Cook Islands",
    shortNameEn: "Cook Islands",
  },

  {
    iso2: "CL",
    iso3: "CHL",
    name: "República de Chile",
    nameEn: "Republic of Chile",
    shortName: "Chile",
    shortNameEn: "Chile",
  },

  {
    iso2: "CM",
    iso3: "CMR",
    name: "Republic of Cameroon / République du Cameroun",
    nameEn: "Republic of Cameroon",
    shortName: "Cameroun",
    shortNameEn: "Cameroon",
  },

  {
    iso2: "CN",
    iso3: "CHN",
    name: "中华人民共和国",
    nameEn: "People’s Republic of China",
    shortName: "中华",
    shortNameEn: "China",
  },

  {
    iso2: "CO",
    iso3: "COL",
    name: "República de Colombia",
    nameEn: "Republic of Colombia",
    shortName: "Colombia",
    shortNameEn: "Colombia",
  },

  {
    iso2: "CR",
    iso3: "CRI",
    name: "República de Costa Rica",
    nameEn: "Republic of Costa Rica",
    shortName: "Costa Rica",
    shortNameEn: "Costa Rica",
  },

  {
    iso2: "CU",
    iso3: "CUB",
    name: "República de Cuba",
    nameEn: "Republic of Cuba",
    shortName: "Cuba",
    shortNameEn: "Cuba",
  },

  {
    iso2: "CV",
    iso3: "CPV",
    name: "República de Cabo Verde",
    nameEn: "Republic of Cape Verde",
    shortName: "Cabo Verde",
    shortNameEn: "Cape Verde",
  },

  {
    iso2: "CX",
    iso3: "CXR",
    name: "Territory of Christmas Island",
    nameEn: "Territory of Christmas Island",
    shortName: "Christmas Island",
    shortNameEn: "Christmas Island",
  },

  {
    iso2: "CY",
    iso3: "CYP",
    name: "Κυπριακή Δημοκρατία / Kıbrıs Cumhuriyeti",
    nameEn: "Republic of Cyprus",
    shortName: "Κύπρος / Kıbrıs",
    shortNameEn: "Cyprus",
  },

  {
    iso2: "CZ",
    iso3: "CZE",
    name: "Česká republika",
    nameEn: "Czech Republic",
    shortName: "Česko",
    shortNameEn: "Czech Republic",
  },

  {
    iso2: "DE",
    iso3: "DEU",
    name: "Bundesrepublik Deutschland",
    nameEn: "Federal Republic of Germany",
    shortName: "Deutschland",
    shortNameEn: "Germany",
  },

  {
    iso2: "DJ",
    iso3: "DJI",
    name: "جمهورية جيبوتي / République de Djibouti",
    nameEn: "Republic of Djibouti",
    shortName: "جيبوتي /Djibouti",
    shortNameEn: "Djibouti",
  },

  {
    iso2: "DK",
    iso3: "DNK",
    name: "Kongeriget Danmark",
    nameEn: "Kingdom of Denmark",
    shortName: "Danmark",
    shortNameEn: "Denmark",
  },

  {
    iso2: "DM",
    iso3: "DMA",
    name: "Commonwealth of Dominica",
    nameEn: "Commonwealth of Dominica",
    shortName: "Dominica",
    shortNameEn: "Dominica",
  },

  {
    iso2: "DO",
    iso3: "DOM",
    name: "República Dominicana",
    nameEn: "Dominican Republic",
    shortName: "Quisqueya",
    shortNameEn: "Dominican Republic",
  },

  {
    iso2: "DZ",
    iso3: "DZA",
    name: "الجمهورية الجزائرية الديمقراطية",
    nameEn: "People’s Democratic Republic of Algeria",
    shortName: "الجزائ",
    shortNameEn: "Algeria",
  },

  {
    iso2: "EC",
    iso3: "ECU",
    name: "República del Ecuador",
    nameEn: "Republic of Ecuador",
    shortName: "Ecuador",
    shortNameEn: "Ecuador",
  },

  {
    iso2: "EE",
    iso3: "EST",
    name: "Eesti Vabariik",
    nameEn: "Republic of Estonia",
    shortName: "Eesti",
    shortNameEn: "Estonia",
  },

  {
    iso2: "EG",
    iso3: "EGY",
    name: "جمهوريّة مصر العربيّة",
    nameEn: "Arab Republic of Egypt",
    shortName: "مصر",
    shortNameEn: "Egypt",
  },

  {
    iso2: "EH",
    iso3: "ESH",
    name: "الصحراء الغربية",
    nameEn: "Western Sahara",
    shortName: "الصحراء الغربي",
    shortNameEn: "Western Sahara",
  },

  {
    iso2: "ER",
    iso3: "ERI",
    name: "ሃግሬ ኤርትራ",
    nameEn: "State of Eritrea",
    shortName: "ኤርትራ",
    shortNameEn: "Eritrea",
  },

  {
    iso2: "ES",
    iso3: "ESP",
    name: "Reino de España",
    nameEn: "Kingdom of Spain",
    shortName: "España",
    shortNameEn: "Spain",
  },

  {
    iso2: "ET",
    iso3: "ETH",
    name: "የኢትዮጵያ ፌዴራላዊ",
    nameEn: "Federal Democratic Republic of Ethiopia",
    shortName: "ኢትዮጵያ",
    shortNameEn: "Ethiopia",
  },

  {
    iso2: "FI",
    iso3: "FIN",
    name: "Suomen Tasavalta / Republiken Finland",
    nameEn: "Republic of Finland",
    shortName: "Suomi",
    shortNameEn: "Finland",
  },

  {
    iso2: "FJ",
    iso3: "FJI",
    name: "Republic of Fiji / Matanitu Tu-Vaka-i-koya ko Vi",
    nameEn: "Republic of Fiji",
    shortName: "Fiji / Viti",
    shortNameEn: "Fiji",
  },

  {
    iso2: "FK",
    iso3: "FLK",
    name: "Falkland Islands",
    nameEn: "Falkland Islands",
    shortName: "Falkland Islands",
    shortNameEn: "Falkland Islands",
  },

  {
    iso2: "FM",
    iso3: "FSM",
    name: "Federated States of Micronesia",
    nameEn: "Federated States of Micronesia",
    shortName: "Micronesia",
    shortNameEn: "Micronesia",
  },

  {
    iso2: "FO",
    iso3: "FRO",
    name: "Føroyar / Færøerne",
    nameEn: "Faroe Islands",
    shortName: "Føroyar / Færøerne",
    shortNameEn: "Faroes",
  },

  {
    iso2: "FR",
    iso3: "FRA",
    name: "République française",
    nameEn: "French Republic",
    shortName: "France Métropolitaine",
    shortNameEn: "France",
  },

  {
    iso2: "GA",
    iso3: "GAB",
    name: "République Gabonaise",
    nameEn: "Gabonese Republic",
    shortName: "Gabon",
    shortNameEn: "Gabon",
  },

  {
    iso2: "GB",
    iso3: "GBR",
    name: "United Kingdom of Great Britain and Northern",
    nameEn: "United Kingdom of Great Britain and Northern",
    shortName: "United Kingdom",
    shortNameEn: "United Kingdom",
  },

  {
    iso2: "GD",
    iso3: "GRD",
    name: "Grenada",
    nameEn: "Grenada",
    shortName: "Grenada",
    shortNameEn: "Grenada",
  },

  {
    iso2: "GE",
    iso3: "GEO",
    name: "საქართველო",
    nameEn: "Georgia",
    shortName: "საქართველო",
    shortNameEn: "Georgia",
  },

  {
    iso2: "GF",
    iso3: "GUF",
    name: "Guyane française",
    nameEn: "French Guiana",
    shortName: "Guyane française",
    shortNameEn: "French Guiana",
  },

  {
    iso2: "GH",
    iso3: "GHA",
    name: "Republic of Ghana",
    nameEn: "Republic of Ghana",
    shortName: "Ghana",
    shortNameEn: "Ghana",
  },

  {
    iso2: "GI",
    iso3: "GIB",
    name: "Gibraltar",
    nameEn: "Gibraltar",
    shortName: "Gibraltar",
    shortNameEn: "Gibraltar",
  },

  {
    iso2: "GL",
    iso3: "GRL",
    name: "Kalaallit Nunaat / Grønland",
    nameEn: "Greenland",
    shortName: "Grønland",
    shortNameEn: "Greenland",
  },

  {
    iso2: "GM",
    iso3: "GMB",
    name: "Republic of The Gambia",
    nameEn: "Republic of The Gambia",
    shortName: "Gambia",
    shortNameEn: "Gambia",
  },

  {
    iso2: "GN",
    iso3: "GIN",
    name: "République de Guinée",
    nameEn: "Republic of Guinea",
    shortName: "Guinée",
    shortNameEn: "Guinea",
  },

  {
    iso2: "GP",
    iso3: "GLP",
    name: "Département de la Guadeloupe",
    nameEn: "Department of Guadeloupe",
    shortName: "Guadeloupe",
    shortNameEn: "Guadeloupe",
  },

  {
    iso2: "GQ",
    iso3: "GNQ",
    name: "República de Guinea Ecuatorial",
    nameEn: "Republic of Equatorial Guinea",
    shortName: "Guinea Ecuatorial",
    shortNameEn: "Equatorial Guinea",
  },

  {
    iso2: "GR",
    iso3: "GRC",
    name: "Ελληνική Δημοκρατία",
    nameEn: "Hellenic Republic",
    shortName: "Ελλάδα",
    shortNameEn: "Greece",
  },

  {
    iso2: "GS",
    iso3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    nameEn: "South Georgia and the South Sandwich Islands",
    shortName: "South Georgia and the South Sandwich Islands",
    shortNameEn: "South Georgia and the South Sandwich Islands",
  },

  {
    iso2: "GT",
    iso3: "GTM",
    name: "República de Guatemala",
    nameEn: "Republic of Guatemala",
    shortName: "Guatemala",
    shortNameEn: "Guatemala",
  },

  {
    iso2: "GU",
    iso3: "GUM",
    name: "The Territory of Guam / Guåhån",
    nameEn: "The Territory of Guam",
    shortName: "Guåhån",
    shortNameEn: "Guam",
  },

  {
    iso2: "GW",
    iso3: "GNB",
    name: "República da Guiné-Bissau",
    nameEn: "Republic of Guinea-Bissau",
    shortName: "Guiné-Bissau",
    shortNameEn: "Guinea-Bissau",
  },

  {
    iso2: "GY",
    iso3: "GUY",
    name: "Co-operative Republic of Guyana",
    nameEn: "Co-operative Republic of Guyana",
    shortName: "Guyane",
    shortNameEn: "Guyana",
  },

  {
    iso2: "HK",
    iso3: "HKG",
    name: "香港特別行政區",
    nameEn: "Hong Kong SAR of the People’s Republic of China",
    shortName: "香港",
    shortNameEn: "Hong Kong SAR of China",
  },

  {
    iso2: "HN",
    iso3: "HND",
    name: "República de Honduras",
    nameEn: "Republic of Honduras",
    shortName: "Honduras",
    shortNameEn: "Honduras",
  },

  {
    iso2: "HR",
    iso3: "HRV",
    name: "Republika Hrvatska",
    nameEn: "Republic of Croatia",
    shortName: "Hrvatska",
    shortNameEn: "Croatia",
  },

  {
    iso2: "HT",
    iso3: "HTI",
    name: "Repiblik d Ayiti",
    nameEn: "Republic of Haiti",
    shortName: "Ayiti",
    shortNameEn: "Haiti",
  },

  {
    iso2: "HU",
    iso3: "HUN",
    name: "Magyar Köztársaság",
    nameEn: "Hungary",
    shortName: "Magyarország",
    shortNameEn: "Hungary",
  },

  {
    iso2: "ID",
    iso3: "IDN",
    name: "Republik Indonesia",
    nameEn: "Republic of Indonesia",
    shortName: "Indonesia",
    shortNameEn: "Indonesia",
  },

  {
    iso2: "IE",
    iso3: "IRL",
    name: "Poblacht na hÉireann / Republic of Ireland",
    nameEn: "Republic of Ireland",
    shortName: "Éire",
    shortNameEn: "Ireland",
  },

  {
    iso2: "IL",
    iso3: "ISR",
    name: "دولة إسرائيل / מדינת ישראלل",
    nameEn: "State of Israel",
    shortName: "ישראל",
    shortNameEn: "Israel",
  },

  {
    iso2: "IN",
    iso3: "IND",
    name: "Bharat; Republic of India",
    nameEn: "Republic of India",
    shortName: "India",
    shortNameEn: "India",
  },

  {
    iso2: "IO",
    iso3: "IOT",
    name: "British Indian Ocean Territory",
    nameEn: "British Indian Ocean Territory",
    shortName: "British Indian Ocean Territory",
    shortNameEn: "British Indian Ocean Territory",
  },

  {
    iso2: "IQ",
    iso3: "IRQ",
    name: "الجمهورية العراقية",
    nameEn: "Republic of Iraq",
    shortName: "العراق / عيَراق",
    shortNameEn: "Iraq",
  },

  {
    iso2: "IR",
    iso3: "IRN",
    name: "جمهوری اسلامی ايران",
    nameEn: "Islamic Republic of Iran",
    shortName: "ايران",
    shortNameEn: "Iran",
  },

  {
    iso2: "IS",
    iso3: "ISL",
    name: "Lýðveldið Ísland",
    nameEn: "Republic of Iceland",
    shortName: "Ísland",
    shortNameEn: "Iceland",
  },

  {
    iso2: "IT",
    iso3: "ITA",
    name: "Repubblica Italiana",
    nameEn: "Italian Republic",
    shortName: "Italia",
    shortNameEn: "Italy",
  },

  {
    iso2: "JM",
    iso3: "JAM",
    name: "Commonwealth of Jamaica",
    nameEn: "Commonwealth of Jamaica",
    shortName: "Jamaica",
    shortNameEn: "Jamaica",
  },

  {
    iso2: "JO",
    iso3: "JOR",
    name: "المملكة الأردنية الهاشمية",
    nameEn: "Hashemite Kingdom of Jordan",
    shortName: "أردنّ",
    shortNameEn: "Jordan",
  },

  {
    iso2: "JP",
    iso3: "JPN",
    name: "日本国",
    nameEn: "Japan",
    shortName: "日本",
    shortNameEn: "Japan",
  },

  {
    iso2: "KE",
    iso3: "KEN",
    name: "Jamhuri va Kenya",
    nameEn: "Republic of Kenia",
    shortName: "Kenya",
    shortNameEn: "Kenya",
  },

  {
    iso2: "KG",
    iso3: "KGZ",
    name: "Кыргызстан",
    nameEn: "Kyrgyzstan",
    shortName: "Кыргызстан",
    shortNameEn: "Kyrgyzstan",
  },

  {
    iso2: "KH",
    iso3: "KHM",
    name: "Preăh Réachéanachâkr Kâmpŭchea",
    nameEn: "Kingdom of Cambodia",
    shortName: "Kâmpŭchea",
    shortNameEn: "Cambodia",
  },

  {
    iso2: "KI",
    iso3: "KIR",
    name: "Republic of Kiribati",
    nameEn: "Republic of Kiribati",
    shortName: "Kiribati",
    shortNameEn: "Kiribati",
  },

  {
    iso2: "KM",
    iso3: "COM",
    name: "Udzima wa Komori /Union des Comores /اتحاد القمر",
    nameEn: "Union of the Comoros",
    shortName: "اتحاد القمر",
    shortNameEn: "Comoros",
  },

  {
    iso2: "KN",
    iso3: "KNA",
    name: "Federation of Saint Kitts and Nevis",
    nameEn: "Federation of Saint Kitts and Nevis",
    shortName: "Saint Kitts and Nevis",
    shortNameEn: "Saint Kitts and Nevis",
  },

  {
    iso2: "KP",
    iso3: "PRK",
    name: "조선민주주의인민화국",
    nameEn: "Democratic People’s Republic of Korea",
    shortName: "북조선",
    shortNameEn: "North Korea",
  },

  {
    iso2: "KR",
    iso3: "KOR",
    name: "대한민국",
    nameEn: "Republic of Korea",
    shortName: "한국",
    shortNameEn: "South Korea",
  },

  {
    iso2: "KW",
    iso3: "KWT",
    name: "دولة الكويت",
    nameEn: "State of Kuweit",
    shortName: "الكويت",
    shortNameEn: "Kuwait",
  },

  {
    iso2: "KY",
    iso3: "CYM",
    name: "Cayman Islands",
    nameEn: "Cayman Islands",
    shortName: "Cayman Islands",
    shortNameEn: "Cayman Islands",
  },

  {
    iso2: "KZ",
    iso3: "KAZ",
    name: "Қазақстан Республикасы /Республика Казахстан",
    nameEn: "Republic of Kazakhstan",
    shortName: "Қазақстан /Казахстан",
    shortNameEn: "Kazakhstan",
  },

  {
    iso2: "LA",
    iso3: "LAO",
    name: "ສາທາລະນະລັດປະຊາທິປະໄຕປະຊາຊົນລາວ",
    nameEn: "Lao People’s Democratic Republic",
    shortName: "ເມືອງລາວ",
    shortNameEn: "Laos",
  },

  {
    iso2: "LB",
    iso3: "LBN",
    name: "الجمهوريّة اللبنانيّة",
    nameEn: "Republic of Lebanon",
    shortName: "لبنان",
    shortNameEn: "Lebanon",
  },

  {
    iso2: "LC",
    iso3: "LCA",
    name: "Saint Lucia",
    nameEn: "Saint Lucia",
    shortName: "Saint Lucia",
    shortNameEn: "Saint Lucia",
  },

  {
    iso2: "LI",
    iso3: "LIE",
    name: "Fürstentum Liechtenstein",
    nameEn: "Principality of Liechtenstein",
    shortName: "Liechtenstein",
    shortNameEn: "Liechtenstein",
  },

  {
    iso2: "LK",
    iso3: "LKA",
    name: "ශ්‍රී ලංකා / இலங்கை சனநாயக சோஷலிசக் குடியரசு",
    nameEn: "Democratic Socialist Republic of Sri Lanka",
    shortName: "ශ්‍රී ලංකා / இலங்கை",
    shortNameEn: "Sri Lanka",
  },

  {
    iso2: "LR",
    iso3: "LBR",
    name: "Republic of Liberia",
    nameEn: "Republic of Liberia",
    shortName: "Liberia",
    shortNameEn: "Liberia",
  },

  {
    iso2: "LS",
    iso3: "LSO",
    name: "Muso oa Lesotho / Kingdom of Lesotho",
    nameEn: "Kingdon of Lesotho",
    shortName: "Lesotho",
    shortNameEn: "Lesotho",
  },

  {
    iso2: "LT",
    iso3: "LTU",
    name: "Lietuvos Respublika",
    nameEn: "Republic of Lithuania",
    shortName: "Lietuva",
    shortNameEn: "Lithuania",
  },

  {
    iso2: "LU",
    iso3: "LUX",
    name: "Grand-Duché de Luxembourg / Großherzogtum Luxemburg / Groussherzogtum Lëtzebuerg",
    nameEn: "Grand Duchy of Luxembourg",
    shortName: "Luxemburg",
    shortNameEn: "Luxembourg",
  },

  {
    iso2: "LV",
    iso3: "LVA",
    name: "Latvijas Republika",
    nameEn: "Republic of Latvia",
    shortName: "Latvija",
    shortNameEn: "Latvia",
  },

  {
    iso2: "LY",
    iso3: "LBY",
    name: "‏ليبيا‎",
    nameEn: "Libya",
    shortName: "‏ليبيا‎",
    shortNameEn: "Libya",
  },

  {
    iso2: "MA",
    iso3: "MAR",
    name: "المملكة المغربية",
    nameEn: "Kingdom of Morocco",
    shortName: "المغربية",
    shortNameEn: "Morocco",
  },

  {
    iso2: "MC",
    iso3: "MCO",
    name: "Principauté de Monaco / Principatu de Munegu",
    nameEn: "Principality of Monaco",
    shortName: "Monaco",
    shortNameEn: "Monaco",
  },

  {
    iso2: "MD",
    iso3: "MDA",
    name: "Republica Moldova",
    nameEn: "Republic of Moldova",
    shortName: "Moldova",
    shortNameEn: "Moldova",
  },

  {
    iso2: "MG",
    iso3: "MDG",
    name: 'Repoblikan"i Madagasikara / République de Madagascar',
    nameEn: "Republic of Madagascar",
    shortName: "Madagascar",
    shortNameEn: "Madagascar",
  },

  {
    iso2: "MH",
    iso3: "MHL",
    name: "Aolepān Aorōkin M̧ajeļ / Republic of the Marshall Islands",
    nameEn: "Republic of the Marshall Islands",
    shortName: "Marshall Islands",
    shortNameEn: "Marshall Islands",
  },

  {
    iso2: "MK",
    iso3: "MKD",
    name: "Република Македонија",
    nameEn: "Republic of Macedonia",
    shortName: "Македонија",
    shortNameEn: "Macedonia",
  },

  {
    iso2: "ML",
    iso3: "MLI",
    name: "République du Mali",
    nameEn: "Republik Mali",
    shortName: "Mali",
    shortNameEn: "Mali",
  },

  {
    iso2: "MM",
    iso3: "MMR",
    name: "Pyidaungzu Myanma Naingngandaw",
    nameEn: "Republic of the Union of Myanmar",
    shortName: "Myanmar",
    shortNameEn: "Myanmar",
  },

  {
    iso2: "MN",
    iso3: "MNG",
    name: "Монгол Улс",
    nameEn: "Mongolia",
    shortName: "Монгол Улс",
    shortNameEn: "Mongolia",
  },

  {
    iso2: "MO",
    iso3: "MAC",
    name: "中華人民共和國澳門特別行政區 / Região Administrativa Especial de Macau da República Popular da China",
    nameEn: "Macao SAR of the People’s Republic of China",
    shortName: "澳門",
    shortNameEn: "Macao SAR of China",
  },

  {
    iso2: "MP",
    iso3: "MNP",
    name: "Commonwealth of the Northern Mariana Islands",
    nameEn: "Commonwealth of the Northern Mariana Islands",
    shortName: "Northern Marianas",
    shortNameEn: "Northern Marianas",
  },

  {
    iso2: "MQ",
    iso3: "MTQ",
    name: "Département de la Martinique",
    nameEn: "Department of Martinique",
    shortName: "Martinique",
    shortNameEn: "Martinique",
  },

  {
    iso2: "MR",
    iso3: "MRT",
    name: "الجمهورية الإسلامية الموريتانية",
    nameEn: "Islamic Republic of Mauritania",
    shortName: "الموريتانية",
    shortNameEn: "Mauritania",
  },

  {
    iso2: "MS",
    iso3: "MSR",
    name: "Montserrat",
    nameEn: "Montserrat",
    shortName: "Montserrat",
    shortNameEn: "Montserrat",
  },

  {
    iso2: "MT",
    iso3: "MLT",
    name: "Republic of Malta",
    nameEn: "Republic of Malta",
    shortName: "Malta",
    shortNameEn: "Malta",
  },

  {
    iso2: "MU",
    iso3: "MUS",
    name: "Republic of Mauritius",
    nameEn: "Republic of Mauritius",
    shortName: "Mauritius",
    shortNameEn: "Mauritius",
  },

  {
    iso2: "MV",
    iso3: "MDV",
    name: "ދިވެހިރާއްޖޭގެ ޖުމުހޫރިއްޔާ",
    nameEn: "Republic of Maldives",
    shortName: "ޖުމުހޫރިއްޔ",
    shortNameEn: "Maldives",
  },

  {
    iso2: "MW",
    iso3: "MWI",
    name: "Republic of Malawi / Dziko la Malaŵi",
    nameEn: "Republic of Malawi",
    shortName: "Malawi",
    shortNameEn: "Malawi",
  },

  {
    iso2: "MX",
    iso3: "MEX",
    name: "Estados Unidos Mexicanos",
    nameEn: "United Mexican States",
    shortName: "México",
    shortNameEn: "Mexico",
  },

  {
    iso2: "MY",
    iso3: "MYS",
    name: "ڤرسكوتوان مليسيا",
    nameEn: "Malaysia",
    shortName: "مليسيا",
    shortNameEn: "Malaysia",
  },

  {
    iso2: "MZ",
    iso3: "MOZ",
    name: "República de Moçambique",
    nameEn: "Republic of Mozambique",
    shortName: "Moçambique",
    shortNameEn: "Mozambique",
  },

  {
    iso2: "NA",
    iso3: "NAM",
    name: "Republic of Namibia",
    nameEn: "Republic of Namibia",
    shortName: "Namibia",
    shortNameEn: "Namibia",
  },

  {
    iso2: "NC",
    iso3: "NCL",
    name: "Territoire de Nouvelle-Caledonie et Dépendances",
    nameEn: "Territory of New Caledonia",
    shortName: "Nouvelle-Calédonie",
    shortNameEn: "New Caledonia",
  },

  {
    iso2: "NE",
    iso3: "NER",
    name: "République du Niger",
    nameEn: "Republic of Niger",
    shortName: "Niger",
    shortNameEn: "Niger",
  },

  {
    iso2: "NF",
    iso3: "NFK",
    name: "Territory of Norfolk Island",
    nameEn: "Territory of Norfolk Island",
    shortName: "Norfolk Island",
    shortNameEn: "Norfolk Island",
  },

  {
    iso2: "NG",
    iso3: "NGA",
    name: "Federal Republic of Nigeria",
    nameEn: "Federal Republic of Nigeria",
    shortName: "Nigeria",
    shortNameEn: "Nigeria",
  },

  {
    iso2: "NI",
    iso3: "NIC",
    name: "República de Nicaragua",
    nameEn: "Republic of Nicaragua",
    shortName: "Nicaragua",
    shortNameEn: "Nicaragua",
  },

  {
    iso2: "NL",
    iso3: "NLD",
    name: "Koninkrijk der Nederlanden",
    nameEn: "Kingdom of the Netherlands",
    shortName: "Nederland",
    shortNameEn: "Netherlands",
  },

  {
    iso2: "NO",
    iso3: "NOR",
    name: "Kongeriket Norge",
    nameEn: "Kingdom of Norway",
    shortName: "Norge",
    shortNameEn: "Norway",
  },

  {
    iso2: "NP",
    iso3: "NPL",
    name: "सङ्घीय लोकतान्त्रिक गणतन्त्र नेपाल",
    nameEn: "Federal Democratic Republic of Nepal",
    shortName: "नेपाल",
    shortNameEn: "Nepal",
  },

  {
    iso2: "NR",
    iso3: "NRU",
    name: "Ripublik Naoero",
    nameEn: "Republic of Nauru",
    shortName: "Naoero",
    shortNameEn: "Nauru",
  },

  {
    iso2: "NU",
    iso3: "NIU",
    name: "Niue",
    nameEn: "Niue",
    shortName: "Niue",
    shortNameEn: "Niue",
  },

  {
    iso2: "NZ",
    iso3: "NZL",
    name: "New Zealand / Aotearoa",
    nameEn: "New Zealand",
    shortName: "New Zealand / Aotearoa",
    shortNameEn: "New Zealand",
  },

  {
    iso2: "OM",
    iso3: "OMN",
    name: "سلطنة عُمان",
    nameEn: "Sultanate of Oman",
    shortName: "عُمان",
    shortNameEn: "Oman",
  },

  {
    iso2: "PA",
    iso3: "PAN",
    name: "República de Panamá",
    nameEn: "Repulic of Panama",
    shortName: "Panamá",
    shortNameEn: "Panama",
  },

  {
    iso2: "PE",
    iso3: "PER",
    name: "República del Perú",
    nameEn: "Republic of Peru",
    shortName: "Perú",
    shortNameEn: "Peru",
  },

  {
    iso2: "PF",
    iso3: "PYF",
    name: "Polynésie française",
    nameEn: "French Polynesia",
    shortName: "Polynésie française",
    shortNameEn: "French Polynesia",
  },

  {
    iso2: "PG",
    iso3: "PNG",
    name: "Independent State of Papua New Guinea / Papua Niugini",
    nameEn: "Independent State of Papua New Guinea",
    shortName: "Papua New Guinea  / Papua Niugini",
    shortNameEn: "Papua New Guinea",
  },

  {
    iso2: "PH",
    iso3: "PHL",
    name: "Republika ng Pilipinas / Republic of the Philippines",
    nameEn: "Republic of the Philippines",
    shortName: "Philippines",
    shortNameEn: "Philippines",
  },

  {
    iso2: "PK",
    iso3: "PAK",
    name: "Islamic Republic of Pakistan / اسلامی جمہوریۂ پاکستان",
    nameEn: "Islamic Republic of Pakistan",
    shortName: "پاکستان",
    shortNameEn: "Pakistan",
  },

  {
    iso2: "PL",
    iso3: "POL",
    name: "Rzeczpospolita Polska",
    nameEn: "Republic of Poland",
    shortName: "Polska",
    shortNameEn: "Poland",
  },

  {
    iso2: "PM",
    iso3: "SPM",
    name: "Saint-Pierre-et-Miquelon",
    nameEn: "Saint Pierre and Miquelon",
    shortName: "Saint-Pierre-et-Miquelon",
    shortNameEn: "Saint Pierre and Miquelon",
  },

  {
    iso2: "PN",
    iso3: "PCN",
    name: "Pitcairn Islands",
    nameEn: "Pitcairn Islands",
    shortName: "Pitcairn Islands",
    shortNameEn: "Pitcairn Islands",
  },

  {
    iso2: "PR",
    iso3: "PRI",
    name: "Estado Libre Asociado de Puerto Rico / Commonwealth of Puerto Rico",
    nameEn: "Commonwealth of Puerto Rico",
    shortName: "Puerto Rico",
    shortNameEn: "Puerto Rico",
  },

  {
    iso2: "PT",
    iso3: "PRT",
    name: "República Portuguesa",
    nameEn: "Portuguese Republic",
    shortName: "Portugal",
    shortNameEn: "Portugal",
  },

  {
    iso2: "PW",
    iso3: "PLW",
    name: "Beluu era Belau / Republic of Palau",
    nameEn: "Republic of Palau",
    shortName: "Belau / Palau",
    shortNameEn: "Palau",
  },

  {
    iso2: "PY",
    iso3: "PRY",
    name: "República del Paraguay / Tetä Paraguáype",
    nameEn: "Republic of Paraguay",
    shortName: "Paraguay",
    shortNameEn: "Paraguay",
  },

  {
    iso2: "QA",
    iso3: "QAT",
    name: "دولة قطر",
    nameEn: "State of Qatar",
    shortName: "قطر",
    shortNameEn: "Qatar",
  },

  {
    iso2: "RE",
    iso3: "REU",
    name: "Département de la Réunion",
    nameEn: "Department of Réunion",
    shortName: "Réunion",
    shortNameEn: "Reunion",
  },

  {
    iso2: "RO",
    iso3: "ROU",
    name: "România",
    nameEn: "Romania",
    shortName: "România",
    shortNameEn: "Romania",
  },

  {
    iso2: "RU",
    iso3: "RUS",
    name: "Российская Федерация",
    nameEn: "Russian Federation",
    shortName: "Росси́я",
    shortNameEn: "Russia",
  },

  {
    iso2: "RW",
    iso3: "RWA",
    name: 'Repubulika y"u Rwanda / République Rwandaise',
    nameEn: "Republic of Rwanda",
    shortName: "Rwanda",
    shortNameEn: "Rwanda",
  },

  {
    iso2: "SA",
    iso3: "SAU",
    name: "المملكة العربية السعودية",
    nameEn: "Kingdom of Saudi Arabia",
    shortName: "السعودية",
    shortNameEn: "Saudi Arabia",
  },

  {
    iso2: "SB",
    iso3: "SLB",
    name: "Solomon Islands",
    nameEn: "Solomon Islands",
    shortName: "Solomon Islands",
    shortNameEn: "Solomon Islands",
  },

  {
    iso2: "SC",
    iso3: "SYC",
    name: "Repiblik Sesel / Republic of Seychelles / République des Seychelles",
    nameEn: "Republic of Seychelles",
    shortName: "Seychelles",
    shortNameEn: "Seychelles",
  },

  {
    iso2: "SD",
    iso3: "SDN",
    name: "جمهورية السودان",
    nameEn: "Republic of the Sudan",
    shortName: "السودان",
    shortNameEn: "Sudan",
  },

  {
    iso2: "SE",
    iso3: "SWE",
    name: "Konungariket Sverige",
    nameEn: "Kingdom of Sweden",
    shortName: "Sverige",
    shortNameEn: "Sweden",
  },

  {
    iso2: "SG",
    iso3: "SGP",
    name: "Republic of Singapore / 新加坡共和国 / Republik Singapura / சிங்கப்பூர் குடியரசு",
    nameEn: "Republic of Singapore",
    shortName: "Singapore",
    shortNameEn: "Singapore",
  },

  {
    iso2: "SI",
    iso3: "SVN",
    name: "Republika Slovenija",
    nameEn: "Republic of Slovenia",
    shortName: "Slovenija",
    shortNameEn: "Slovenia",
  },

  {
    iso2: "SJ",
    iso3: "SJM",
    name: "Svalbard",
    nameEn: "Svalbard",
    shortName: "Svalbard",
    shortNameEn: "Svalbard",
  },

  {
    iso2: "SK",
    iso3: "SVK",
    name: "Slovenská republika",
    nameEn: "Slovak Republic",
    shortName: "Slovensko",
    shortNameEn: "Slovakia",
  },

  {
    iso2: "SL",
    iso3: "SLE",
    name: "Republic of Sierra Leone",
    nameEn: "Republic of Sierra Leone",
    shortName: "Sierra Leone",
    shortNameEn: "Sierra Leone",
  },

  {
    iso2: "SM",
    iso3: "SMR",
    name: "Serenissima Repubblica di San Marino",
    nameEn: "Most Serene Republic of San Marino",
    shortName: "San Marino",
    shortNameEn: "San Marino",
  },

  {
    iso2: "SN",
    iso3: "SEN",
    name: "République de Sénégal",
    nameEn: "Republic of Senegal",
    shortName: "Sénégal",
    shortNameEn: "Senegal",
  },

  {
    iso2: "SO",
    iso3: "SOM",
    name: "Soomaaliya",
    nameEn: "Federal Republic of Somalia",
    shortName: "Soomaaliya",
    shortNameEn: "Somalia",
  },

  {
    iso2: "SR",
    iso3: "SUR",
    name: "Republiek Suriname",
    nameEn: "Republic of Surinam",
    shortName: "Suriname",
    shortNameEn: "Suriname",
  },

  {
    iso2: "ST",
    iso3: "STP",
    name: "República Democrática de São Tomé e Príncipe",
    nameEn: "Democratic Republic of São Tomé e Príncipe",
    shortName: "São Tomé e Príncipe",
    shortNameEn: "São Tomé e Príncipe",
  },

  {
    iso2: "SV",
    iso3: "SLV",
    name: "República de El Salvador",
    nameEn: "Republic of El Salvador",
    shortName: "El Salvador",
    shortNameEn: "El Salvador",
  },

  {
    iso2: "SY",
    iso3: "SYR",
    name: "الجمهوريّة العربيّة السّوريّة",
    nameEn: "Syrian Arab Republic",
    shortName: "سوري",
    shortNameEn: "Syria",
  },

  {
    iso2: "SZ",
    iso3: "SWZ",
    name: "Umboso weSwatini / Kingdom of Swaziland",
    nameEn: "Kingdom of Swaziland",
    shortName: "weSwatini",
    shortNameEn: "Swaziland",
  },

  {
    iso2: "TC",
    iso3: "TCA",
    name: "Turks and Caicos Islands",
    nameEn: "Turks and Caicos Islands",
    shortName: "Turks and Caicos Islands",
    shortNameEn: "Turks and Caicos Islands",
  },

  {
    iso2: "TD",
    iso3: "TCD",
    name: "جمهورية تشاد / République du Tchad",
    nameEn: "Republic of Chad",
    shortName: "تشاد / Tchad",
    shortNameEn: "Chad",
  },

  {
    iso2: "TF",
    iso3: "ATF",
    name: "Terres australes françaises",
    nameEn: "French Southern Territories",
    shortName: "Terres australes françaises",
    shortNameEn: "French Southern Territories",
  },

  {
    iso2: "TG",
    iso3: "TGO",
    name: "République Togolaise",
    nameEn: "Republic of Togo",
    shortName: "Togo",
    shortNameEn: "Togo",
  },

  {
    iso2: "TH",
    iso3: "THA",
    name: "ราชอาณาจักรไทย",
    nameEn: "Kingdom of Thailand",
    shortName: "ไทย",
    shortNameEn: "Thailand",
  },

  {
    iso2: "TJ",
    iso3: "TJK",
    name: "Ҷумҳурии Тоҷикистон",
    nameEn: "Republic of Tajikistan",
    shortName: "Тоҷикистон",
    shortNameEn: "Tajikistan",
  },

  {
    iso2: "TK",
    iso3: "TKL",
    name: "Tokelau",
    nameEn: "Tokelau",
    shortName: "Tokelau",
    shortNameEn: "Tokelau",
  },

  {
    iso2: "TM",
    iso3: "TKM",
    name: "Türkmenistan Jumhuriyäti",
    nameEn: "Republic of Turkmenistan",
    shortName: "Türkmenistan",
    shortNameEn: "Turkmenistan",
  },

  {
    iso2: "TN",
    iso3: "TUN",
    name: "الجمهورية التونسية",
    nameEn: "Republic of Tunisia",
    shortName: "التونسية",
    shortNameEn: "Tunisia",
  },

  {
    iso2: "TO",
    iso3: "TON",
    name: "Kingdom of Tonga",
    nameEn: "Kingdom of Tonga",
    shortName: "Tonga",
    shortNameEn: "Tonga",
  },

  {
    iso2: "TL",
    iso3: "TLS",
    name: "República Democrática de Timor-Leste",
    nameEn: "Democratic Republic of Timor-Leste",
    shortName: "Timor Lorosae",
    shortNameEn: "Timor-Leste",
  },

  {
    iso2: "TR",
    iso3: "TUR",
    name: "Türkiye Cumhuriyeti",
    nameEn: "Republic of Turkey",
    shortName: "Türkiye",
    shortNameEn: "Turkey",
  },

  {
    iso2: "TT",
    iso3: "TTO",
    name: "Republic of Trinidad and Tobago",
    nameEn: "Republic of Trinidad and Tobago",
    shortName: "Trinidad and Tobago",
    shortNameEn: "Trinidad and Tobago",
  },

  {
    iso2: "TV",
    iso3: "TUV",
    name: "Tuvalu",
    nameEn: "Tuvalu",
    shortName: "Tuvalu",
    shortNameEn: "Tuvalu",
  },

  {
    iso2: "TW",
    iso3: "TWN",
    name: "中華民國",
    nameEn: "Republic of China",
    shortName: "台灣",
    shortNameEn: "Taiwan",
  },

  {
    iso2: "TZ",
    iso3: "TZA",
    name: "Jamhuri ya Muungano wa Tanzania",
    nameEn: "United Republic of Tanzania",
    shortName: "Tanzania",
    shortNameEn: "Tanzania",
  },

  {
    iso2: "UA",
    iso3: "UKR",
    name: "Україна",
    nameEn: "Ukraine",
    shortName: "Україна",
    shortNameEn: "Ukraine",
  },

  {
    iso2: "UG",
    iso3: "UGA",
    name: "Republic of Uganda",
    nameEn: "Republic of Uganda",
    shortName: "Uganda",
    shortNameEn: "Uganda",
  },

  {
    iso2: "UM",
    iso3: "UMI",
    name: "United States Minor Outlying Islands",
    nameEn: "United States Minor Outlying Islands",
    shortName: "United States Minor Outlying Islands",
    shortNameEn: "United States Minor Outlying Islands",
  },

  {
    iso2: "US",
    iso3: "USA",
    name: "United States of America",
    nameEn: "United States of America",
    shortName: "United States",
    shortNameEn: "United States",
  },

  {
    iso2: "UY",
    iso3: "URY",
    name: "República Oriental del Uruguay",
    nameEn: "Eastern Republic of Uruguay",
    shortName: "Uruguay",
    shortNameEn: "Uruguay",
  },

  {
    iso2: "UZ",
    iso3: "UZB",
    name: "O‘zbekiston Respublikasi",
    nameEn: "Republic of Uzbekistan",
    shortName: "O‘zbekiston",
    shortNameEn: "Uzbekistan",
  },

  {
    iso2: "VA",
    iso3: "VAT",
    name: "Status Civitatis Vaticanae / Città del Vaticano",
    nameEn: "Vatican City",
    shortName: "Vaticano",
    shortNameEn: "Vatican City",
  },

  {
    iso2: "VC",
    iso3: "VCT",
    name: "Saint Vincent and the Grenadines",
    nameEn: "Saint Vincent and the Grenadines",
    shortName: "Saint Vincent and the Grenadines",
    shortNameEn: "Saint Vincent and the Grenadines",
  },

  {
    iso2: "VE",
    iso3: "VEN",
    name: "República Bolivariana de Venezuela",
    nameEn: "Bolivarian Republic of Venezuela",
    shortName: "Venezuela",
    shortNameEn: "Venezuela",
  },

  {
    iso2: "VG",
    iso3: "VGB",
    name: "British Virgin Islands",
    nameEn: "British Virgin Islands",
    shortName: "British Virgin Islands",
    shortNameEn: "British Virgin Islands",
  },

  {
    iso2: "VI",
    iso3: "VIR",
    name: "United States Virgin Islands",
    nameEn: "United States Virgin Islands",
    shortName: "US Virgin Islands",
    shortNameEn: "US Virgin Islands",
  },

  {
    iso2: "VN",
    iso3: "VNM",
    name: "Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam",
    nameEn: "Socialist Republic of Vietnam",
    shortName: "Việt Nam",
    shortNameEn: "Vietnam",
  },

  {
    iso2: "VU",
    iso3: "VUT",
    name: "Ripablik blong Vanuatu / Republic of Vanuatu / République du Vanuatu",
    nameEn: "Republic of Vanuatu",
    shortName: "Vanuatu",
    shortNameEn: "Vanuatu",
  },

  {
    iso2: "WF",
    iso3: "WLF",
    name: "Territoire de Wallis et Futuna",
    nameEn: "Territory of Wallis and Futuna Islands",
    shortName: "Wallis et Futuna",
    shortNameEn: "Wallis and Futuna",
  },

  {
    iso2: "WS",
    iso3: "WSM",
    name: 'Malo Sa"oloto Tuto"atasi o Samoa / Independent State of Samoa',
    nameEn: "Independent State of Samoa",
    shortName: "Samoa",
    shortNameEn: "Samoa",
  },

  {
    iso2: "YE",
    iso3: "YEM",
    name: "الجمهوريّة اليمنية",
    nameEn: "Republic of Yemen",
    shortName: "اليمنية",
    shortNameEn: "Yemen",
  },

  {
    iso2: "YT",
    iso3: "MYT",
    name: "Mayotte",
    nameEn: "Mayotte",
    shortName: "Mayotte",
    shortNameEn: "Mayotte",
  },

  {
    iso2: "ZA",
    iso3: "ZAF",
    name: "Republic of South Africa / Republiek van Suid-Afrika / Rephaboliki ya Afrika-Borwa",
    nameEn: "Republic of South Africa",
    shortName: "Afrika-Borwa",
    shortNameEn: "South Africa",
  },

  {
    iso2: "ZM",
    iso3: "ZMB",
    name: "Republic of Zambia",
    nameEn: "Republic of Zambia",
    shortName: "Zambia",
    shortNameEn: "Zambia",
  },

  {
    iso2: "ZW",
    iso3: "ZWE",
    name: "Republic of Zimbabwe",
    nameEn: "Republic of Zimbabwe",
    shortName: "Zimbabwe",
    shortNameEn: "Zimbabwe",
  },

  {
    iso2: "PS",
    iso3: "PSE",
    name: "دولة فلسطين",
    nameEn: "State of Palestine",
    shortName: "فلسطين",
    shortNameEn: "Palestine",
  },

  {
    iso2: "AX",
    iso3: "ALA",
    name: "Landskapet Åland",
    nameEn: "Åland Islands",
    shortName: "Åland",
    shortNameEn: "Åland",
  },

  {
    iso2: "HM",
    iso3: "HMD",
    name: "Heard Island and McDonald Islands",
    nameEn: "Heard Island and McDonald Islands",
    shortName: "Heard Island and McDonald Islands",
    shortNameEn: "Heard Island and McDonald Islands",
  },

  {
    iso2: "EN",
    iso3: "INT",
    name: "International",
    nameEn: "International",
    shortName: "International",
    shortNameEn: "International",
  },

  {
    iso2: "RS",
    iso3: "SRB",
    name: "Republika Srbija",
    nameEn: "Republic of Serbia",
    shortName: "Srbija",
    shortNameEn: "Serbia",
  },

  {
    iso2: "JE",
    iso3: "JEY",
    name: "Bailiwick of Jersey",
    nameEn: "Bailiwick of Jersey",
    shortName: "Jersey",
    shortNameEn: "Jersey",
  },

  {
    iso2: "GG",
    iso3: "GGY",
    name: "Bailiwick of Guernsey",
    nameEn: "Bailiwick of Guernsey",
    shortName: "Guernsey",
    shortNameEn: "Guernsey",
  },

  {
    iso2: "IM",
    iso3: "IMN",
    name: "Isle of Man / Ellan Vannin",
    nameEn: "Isle of Man",
    shortName: "Mann / Mannin",
    shortNameEn: "Isle of Man",
  },

  {
    iso2: "MF",
    iso3: "MAF",
    name: "Collectivité de Saint-Martin",
    nameEn: "Collectivity of Saint Martin",
    shortName: "Saint-Martin",
    shortNameEn: "Saint Martin",
  },

  {
    iso2: "BL",
    iso3: "BLM",
    name: "Collectivité de Saint-Barthélemy",
    nameEn: "Collectivity of Saint Barthélemy",
    shortName: "Saint-Barthélemy",
    shortNameEn: "Saint Barthélemy",
  },

  {
    iso2: "CW",
    iso3: "CUW",
    name: "Curaçao",
    nameEn: "Curaçao",
    shortName: "Curaçao",
    shortNameEn: "Curaçao",
  },

  {
    iso2: "SX",
    iso3: "SXM",
    name: "Sint Maarten",
    nameEn: "Sint Maarten",
    shortName: "Sint Maarten",
    shortNameEn: "Sint Maarten",
  },

  {
    iso2: "SS",
    iso3: "SSD",
    name: "Republic of South Sudan",
    nameEn: "Republic of South Sudan",
    shortName: "South Sudan",
    shortNameEn: "South Sudan",
  },

  {
    iso2: "ME",
    iso3: "MNE",
    name: "Republike Crne Gore",
    nameEn: "Montenegro",
    shortName: "Crna Gora",
    shortNameEn: "Montenegro",
  },
];

export interface ICountryData {
  iso2: string;
  iso3: string;
  shortNameEn: string;
  shortName: string;
  name: string;
  nameEn: string;
}

export const getCountryData = (findBy: string, needle: string): ICountryData => {
  const result = countryData.filter((country: { [index: string]: string }) => country[findBy] === needle);
  if (result.length > 0) return result[0];
  return {
    iso2: "",
    iso3: "",
    shortNameEn: "unknown",
    shortName: "unknown",
    name: "unknown",
    nameEn: "unknown",
  };
};

export const getCountryNameByIso3 = (iso3: string): string => {
  const result = countryData.filter((country: { [index: string]: string }) => country.iso3 === iso3);
  if (result.length > 0) return result[0].shortName;
  return "unknown";
};

export default getCountryData;
