import type { FC } from "react";
import * as React from "react";
import { useState } from "react";
import { Button, Divider, FormControlLabel, Grid, MenuItem, Typography, Checkbox, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { SelectField, TextField } from "../../../components/formFields";
import { getValidationSchema, EndpointProtectionTrialsFormValues } from "../formValidations/EndpointProtectionTrials";
import useAuth from "../../../contextProviders/Authentication";
import TrialsApi from "../../../api/trials";
import { useSnackbar } from "notistack";
import { getCountryNameByIso3 } from "../../../utils/CountryDataUtils";
import { ArrowCircleLeftOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { EndpointProtectionTrialsResponse, EndpointProtectionTrialsReseller } from "../../../types/trials";
import { useGetEndpointProtectionTrialSettings } from "../../../lib/queryClient";
import { Link } from "react-router-dom";
import RoleGuard from "../../../components/RoleGuard";

const EndpointProtectionTrialsForm: FC = () => {
  const [formResponse, setFormResponse] = useState<EndpointProtectionTrialsResponse | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data: formSettings, isLoading } = useGetEndpointProtectionTrialSettings();

  const initialFormValues: EndpointProtectionTrialsFormValues = {
    companyName: "",
    customerFirstName: "",
    customerLastName: "",
    customerMail: "",
    customerCountry: "",
    customerPostcode: "",
    licenseCount: "" as unknown as number,
    productCode: "",
    oldProduct: "",
    additionalMessage: "",
    reseller: 0,
  };
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { resetForm, setStatus }) => {
      setFormDisabled(true);
      TrialsApi.requestPartnerTrial(values)
        .then((response) => {
          setFormResponse(response);
          setStatus({ success: true });
          setIsSubmitted(true);
          resetForm({});
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setFormDisabled(false);
        });
    },
  });

  function backToForm() {
    formik.resetForm();
    setIsSubmitted(false);
  }

  // Loading Screen
  if (isLoading || !formSettings || !user) {
    return (
      <>
        <Typography component="div" variant="body1">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="body1" width="70%" sx={{ mb: 2 }}>
          <Skeleton />
        </Typography>
        <Typography component="div" variant="body2" width="50%" sx={{ mb: 5 }}>
          <Skeleton />
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={300} sx={{ mt: 2 }} />
      </>
    );
  }

  // Response after form submit
  if (isSubmitted && formResponse) {
    return (
      <>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t("Trials.Response.Header")}
        </Typography>

        <Typography variant="h5" component="div" sx={{ mb: 1 }}>
          {t("Trials.Response.YourTrial")}
        </Typography>
        <Typography sx={{ mb: 1 }} color="text.secondary">
          {t("Trials.Response.Message")}
        </Typography>
        <Typography>
          <strong>{t("Trials.Response.Username")}</strong>{" "}
          <span data-testid="acceptance_b2bTrials_compareLoginWithEmail">{formResponse.login}</span>
        </Typography>
        <Typography>
          <strong>{t("Trials.Response.Password")}</strong>{" "}
          <span data-testid="acceptance_b2bTrials_comparePassWithEmail">{formResponse.password}</span>
        </Typography>

        <Button
          variant="contained"
          fullWidth
          startIcon={<FileDownloadOutlined />}
          component={Link}
          to={formResponse.downloadLinks.default}
          target="_blank"
          rel="noopener noreferrer"
          size="large"
          color="primary"
          sx={{ mt: 2, mb: 2 }}
        >
          {t("Trials.Response.Download")}
        </Button>

        <Button variant="outlined" fullWidth startIcon={<ArrowCircleLeftOutlined />} onClick={backToForm} size="large">
          {t("Trials.Response.BackInfo")}
        </Button>
      </>
    );
  }

  // form
  return (
    <>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t("Trials.Intro")}
      </Typography>
      <Typography variant="body2" sx={{ mb: 5 }}>
        * {t("Trials.Info")}
      </Typography>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Typography variant="h5" component="h2" gutterBottom>
          {t("Trials.Form.PersonalData")}
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              formik={formik}
              name="email"
              label={t("Trials.Form.EmailLabel")}
              keepSpace={false}
              value={user.email}
              disabled
            />
            <RoleGuard allowedRoles={["reseller"]}>
              <FormControlLabel
                control={<Checkbox name="sendLoginToCustomer" value="1" />}
                label={t("Trials.Form.EmailInfo")}
                sx={{ mb: 2 }}
              />
            </RoleGuard>
          </Grid>
          {formSettings.resellersOfDistri != null && (
            <RoleGuard allowedRoles={["distributor"]}>
              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  formik={formik}
                  name="reseller"
                  label={t("Trials.Form.ResellerLabel")}
                  keepSpace={false}
                  required
                >
                  {formSettings.resellersOfDistri.length > 0 &&
                    formSettings.resellersOfDistri.map((reseller: EndpointProtectionTrialsReseller) => (
                      <MenuItem key={reseller.uid} value={reseller.uid}>
                        {reseller.company}
                      </MenuItem>
                    ))}
                </SelectField>
              </Grid>
            </RoleGuard>
          )}
        </Grid>
        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 2 }}>
          {t("Trials.Form.CustomerData")}
        </Typography>
        <Divider sx={{ mb: 5 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              formik={formik}
              name="companyName"
              label={t("Trials.Form.CompanyLabel")}
              keepSpace={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              formik={formik}
              type="text"
              name="customerFirstName"
              label={t("Trials.Form.FirstNameLabel")}
              keepSpace={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              formik={formik}
              type="text"
              name="customerLastName"
              label={t("Trials.Form.LastNameLabel")}
              keepSpace={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              formik={formik}
              type="email"
              name="customerMail"
              label={t("Trials.Form.CustomerMailLabel")}
              keepSpace={false}
              required
            />
          </Grid>

          {formSettings.availableCountries != undefined && formSettings.availableCountries.length > 0 && (
            <Grid item xs={12} sm={8} md={8}>
              <SelectField
                formik={formik}
                name="customerCountry"
                label={t("Trials.Form.CountryLabel")}
                keepSpace={false}
                required
              >
                {formSettings.availableCountries.map((countryIso3: string, idx: number) => {
                  return (
                    <MenuItem key={idx} value={countryIso3}>
                      {getCountryNameByIso3(countryIso3)}
                    </MenuItem>
                  );
                })}
              </SelectField>
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={4}>
            <TextField
              formik={formik}
              name="customerPostcode"
              label={t("Trials.Form.PostcodeLabel")}
              keepSpace={false}
              required={
                !["BRA", "ECU", "CHL", "MEX", "PER", "VEN", "PAN", "BOL", "COL"].includes(formik.values.customerCountry)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              formik={formik}
              type="number"
              name="licenseCount"
              label={t("Trials.Form.LicenseCountLabel")}
              required
              helperText={t("Trials.Form.LicenseCountInfo")}
              keepSpace={false}
              inputProps={{ min: 5 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <SelectField
              formik={formik}
              name="productCode"
              label={t("Trials.Form.ProductLabel")}
              keepSpace={false}
              required
            >
              {formSettings.products != null &&
                Object.entries(formSettings.products).map(([key, name]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  );
                })}
            </SelectField>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SelectField
              formik={formik}
              name="oldProduct"
              label={t("Trials.Form.OldProductLabel")}
              color="secondary"
              variant="outlined"
              keepSpace={false}
              fullWidth
            >
              {formSettings.oldProducts != null &&
                Object.entries(formSettings.oldProducts).map(([key, name]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  );
                })}
            </SelectField>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              formik={formik}
              name="additionalMessage"
              label={t("Trials.Form.MessageLabel")}
              multiline
              keepSpace={false}
              rows="5"
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" mt={1}>
          <Grid item xs="auto">
            <Button fullWidth size="large" type="submit" variant="contained" disabled={!formik.dirty || formDisabled}>
              {t("Trials.Form.Submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EndpointProtectionTrialsForm;
