import React, { RefObject } from "react";
import _ from "lodash";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridFilterInputMultipleSingleSelect,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Chip, IconButton, Stack, Theme, Tooltip } from "@mui/material";
import { differenceInCalendarDays, differenceInCalendarMonths } from "date-fns";
import { LicenseCenterRow, Reseller } from "../../../types/license";
import EditIcon from "@mui/icons-material/Edit";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import LinkIcon from "@mui/icons-material/Link";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import { TFunction, i18n as i18nType } from "i18next";
import { ILicenseCenterLicenseEditDialogApi } from "./LicenseCenterLicenseEditDialog";
import { ILicenseCenterLicenseRequestDialogApi } from "./LicenseCenterLicenseRequestDialog";
import { Basket } from "../../../types/basket";
import { Link as RouterLink } from "react-router-dom";
import GridActionsCellItemWithTooltip from "../../../components/DataGrid/GridActionsCellItemWithTooltip";
import { ILicenseCenterLicenseRemoveDialogApi } from "./LicenseCenterLicenseRemoveDialog";

export function getLicenseCenterColumnDefinition(
  t: TFunction,
  theme: Theme,
  i18n: i18nType,
  productNamesInList: string[],
  isDistributor: boolean,
  licenseEditDialogRef: RefObject<ILicenseCenterLicenseEditDialogApi>,
  licenseRequestDialogRef: RefObject<ILicenseCenterLicenseRequestDialogApi>,
  licenseRemoveDialogRef?: RefObject<ILicenseCenterLicenseRemoveDialogApi>,
  handleMESExportClick?: { (registrationNumber: string): void },
  handleCancelSubscriptionClick?: { (registrationNumber: string, invoiceDate: string): void },
  basket?: Basket,
  resellers?: Reseller[],
  isEmulated?: boolean
): GridColumns {
  const characteristics = ["B2B", "B2C", "EDU", "GOV", "NFR", "OEM", "SUB", "TRL"].map((chara) => {
    return { value: chara, label: t(`LicenseCenter.Chips.${chara}`) };
  });

  const isLicenseInBasket = (registrationNumber: string): boolean =>
    _.find(basket?.basketItems ?? [], ["registrationNumbers", registrationNumber]) !== undefined;

  const productGroups = [
    "Desktop",
    "DesktopAntiVirus",
    "DesktopInternetSecurity",
    "DesktopTotalProtection",
    "DesktopTrialAntiVirus",
    "DesktopTrialInternetSecurity",
    "DesktopTrialTotalProtection",
    "Business",
    "BusinessAntiVirus",
    "BusinessClientSecurity",
    "BusinessEndpointProtection",
    "BusinessTrialAntiVirus",
    "BusinessTrialClientSecurity",
    "BusinessTrialEndpointProtection",
    "Mobile",
    "MobileTrial",
    "MacAntiVirus",
    "MacAntiVirusTrial",
    "MailProtection",
  ].map((productGroup) => {
    return { value: productGroup, label: t(`LicenseCenter.ProductGroup.${productGroup}`) };
  });

  const hasAnyOfFilterOperator: GridFilterOperator = {
    label: t("LicenseCenter.HasAnyOfFilterOperation"),
    value: "hasAnyOf",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return filterItem.value.every((filterValue: string) => {
          return params.value.includes(filterValue);
        });
      };
    },
    InputComponent: GridFilterInputMultipleSingleSelect,
    InputComponentProps: { type: "singleSelect" },
  };

  const columns: GridColumns = [
    {
      field: "registrationNumber",
      headerName: t("LicenseCenter.Columns.RegNo"),
      type: "string",
      width: 250,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) =>
        isDistributor
          ? ["equals"].includes(operator.value)
          : !["isNotEmpty", "isAnyOf", "isEmpty"].includes(operator.value)
      ),
      getApplyQuickFilterFn: (value: string) => {
        if (!value) {
          return null;
        }
        return (params: GridCellParams): boolean => {
          return params.value.includes(value.replaceAll("-", "|").toUpperCase());
        };
      },
    },
    {
      field: "loginName",
      headerName: t("LicenseCenter.Columns.Login"),
      type: "string",
      width: 150,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) =>
        isDistributor
          ? ["equals"].includes(operator.value)
          : !["isNotEmpty", "isAnyOf", "isEmpty"].includes(operator.value)
      ),
    },
    {
      field: "licenseOwner",
      headerName: t("LicenseCenter.Columns.LicenseOwner"),
      type: "string",
      flex: 0.5,
      minWidth: 225,
      filterable: !isDistributor,
      filterOperators: getGridStringOperators().filter(
        (operator) => !["isNotEmpty", "isAnyOf", "isEmpty"].includes(operator.value)
      ),
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.company) {
          return `${params.row.company}, ${params.row.firstName} ${params.row.lastName} `;
        }
        return `${params.row.firstName} ${params.row.lastName} `;
      },
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value == null) {
          return "";
        }
        const nameParts = params.value.split(",");
        return (
          <div style={{ lineHeight: 1 }}>
            <span>{nameParts[0]}</span>
            {nameParts[1] && (
              <>
                <br />
                <span style={{ fontSize: "0.875em", color: theme.palette.text.secondary }}>{nameParts[1]}</span>
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "productName",
      headerName: t("LicenseCenter.Columns.ProductName"),
      type: "string",
      minWidth: 250,
      filterable: true,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value == (isDistributor ? "is" : "isAnyOf")
      ),
      valueOptions: isDistributor ? productGroups : productNamesInList,
    },
    {
      field: "characteristics",
      headerName: t("LicenseCenter.Columns.Characteristics"),
      flex: 1,
      type: "string",
      filterable: !isDistributor,
      filterOperators: [hasAnyOfFilterOperator],
      valueOptions: characteristics,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.value && params.value.length > 0) {
          return params.value.join(",");
        }
        return "";
      },
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <Stack direction="row" spacing={1}>
            {params &&
              params.value &&
              params.value.split(",").map((group, index) => {
                return (
                  <Chip
                    key={`chip-${index}`}
                    label={t(`LicenseCenter.Chips.${group}`)}
                    size="small"
                    variant="outlined"
                    color="default"
                  />
                );
              })}
          </Stack>
        );
      },
    },
    {
      field: "licenseCount",
      headerName: t("LicenseCenter.Columns.LicenseCount"),
      type: "number",
      width: 125,
      headerAlign: "center",
      align: "center",
      filterable: !isDistributor,
      filterOperators: getGridNumericOperators().filter(
        (operator) => !["isNotEmpty", "isEmpty"].includes(operator.value)
      ),
    },
    {
      field: "unlimited",
      headerName: t("LicenseCenter.Columns.Unlimited"),
      type: "boolean",
      width: 125,
      headerAlign: "center",
      align: "center",
      filterable: !isDistributor,
    },
    {
      field: "limitDate",
      headerName: t("LicenseCenter.Columns.LimitDate"),
      description: t("LicenseCenter.Tooltips.LimitDate"),
      type: "date",
      width: 180,
      headerAlign: "center",
      align: "center",
      filterOperators: getGridDateOperators().filter((operator) =>
        ["onOrAfter", "onOrBefore"].includes(operator.value)
      ),
      valueFormatter: ({ value }) => (value ? value.toISOString().slice(0, 10) : ""), // YYYY-MM-DD Format für CSV-Export
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.limitDate ?? params.row.invoiceDate;
      },
      renderCell: (params: GridRenderCellParams<Date>) => {
        const formattedDate = i18n.format(params.value, "P", i18n.language);
        if (!params.row.unlimited) {
          const now = new Date();
          if (differenceInCalendarDays(params.value as Date, now) < 7) {
            return (
              <Tooltip title={t("LicenseCenter.Tooltips.ExpiresIn7Days")}>
                <span style={{ color: theme.palette.error.dark, cursor: "help" }}>{formattedDate}</span>
              </Tooltip>
            );
          }
          if (differenceInCalendarMonths(params.value as Date, now) < 3) {
            return (
              <Tooltip title={t("LicenseCenter.Tooltips.ExpiresIn3Months")}>
                <span style={{ color: theme.palette.warning.dark, cursor: "help" }}>{formattedDate}</span>
              </Tooltip>
            );
          }
        }
        return <span style={{ color: theme.palette.text.primary }}>{formattedDate}</span>;
      },
    },

    {
      field: "actions",
      headerName: t("LicenseCenter.Columns.Actions"),
      width: 60,
      type: "actions",
      hideable: false,
      disableExport: true,
      renderHeader: () => "",
      getActions: (params: GridRowParams<LicenseCenterRow>) => {
        const editAllowed = params.row.allowed.renew || params.row.allowed.extend || params.row.allowed.upgrade;
        const actions = [];
        const licenseInBasket = isLicenseInBasket(params.row.registrationNumber);

        if (editAllowed && !licenseInBasket) {
          actions.push(
            <Tooltip title={t("LicenseCenter.Actions.EditLicense")}>
              <GridActionsCellItem
                icon={<EditIcon />}
                label={t("LicenseCenter.Actions.EditLicense")}
                className="textPrimary"
                onClick={() => licenseEditDialogRef?.current?.handleShowDialog(params.row)}
                color="primary"
                showInMenu={false}
                data-testid={"license-center-action_edit-action"}
              />
            </Tooltip>
          );
        }
        if (!editAllowed && licenseRequestDialogRef) {
          actions.push(
            <GridActionsCellItemWithTooltip
              icon={<EmailOutlinedIcon />}
              label={t("LicenseCenter.Actions.ContactSupport")}
              className="textPrimary"
              onClick={() => licenseRequestDialogRef?.current?.handleShowDialog(params.row.loginName)}
              color="primary"
              disabled={isEmulated}
              showInMenu={false}
            />
          );
        }
        if (params.row.allowed.mesExport && handleMESExportClick) {
          actions.push(
            <GridActionsCellItemWithTooltip
              icon={<SimCardDownloadOutlinedIcon />}
              label={t("LicenseCenter.Actions.MESExport")}
              className="textPrimary"
              onClick={() => handleMESExportClick(params.row.registrationNumber)}
              color="primary"
              showInMenu={true}
              disabled={isEmulated}
            />
          );
        }
        if (params.row.productCode === "BM" && process.env.REACT_APP_365_BACKEND !== "") {
          const linkTo365Backend = `${process.env.REACT_APP_365_BACKEND}?regNumber=${params.row.registrationNumber}&mavid=${params.row.gd365MavId}`;
          actions.push(
            <GridActionsCellItem
              icon={<LinkIcon />}
              label={t("LicenseCenter.Actions.MESExport")}
              className="textPrimary"
              color="primary"
              showInMenu={false}
              onClick={() => window.open(linkTo365Backend, "_blank", "noopener")}
            />
          );
        }
        if (params.row.allowed.cancelSubscription && handleCancelSubscriptionClick) {
          actions.push(
            <GridActionsCellItemWithTooltip
              icon={<DoNotDisturbIcon />}
              label={t("LicenseCenter.Actions.CancelSubscription")}
              className="textPrimary"
              color="primary"
              disabled={isEmulated || licenseInBasket}
              showInMenu={true}
              onClick={() =>
                handleCancelSubscriptionClick(
                  params.row.registrationNumber,
                  i18n.format(params.row.invoiceDate, "P", i18n.language) || ""
                )
              }
            />
          );
        }
        if (params.row.allowed.remove && !isDistributor && licenseRemoveDialogRef) {
          actions.push(
            <GridActionsCellItemWithTooltip
              icon={<DoNotDisturbIcon />}
              label={t("LicenseCenter.Actions.RemoveLicense")}
              className="textPrimary"
              color="primary"
              disabled={isEmulated || licenseInBasket}
              showInMenu={true}
              onClick={() => licenseRemoveDialogRef?.current?.handleShowDialog(params.row)}
            />
          );
        }
        if (licenseInBasket) {
          actions.push(
            <GridActionsCellItemWithTooltip
              icon={
                <IconButton color="inherit" component={RouterLink} to="/app/shop/basket">
                  <ShoppingCartIcon />
                </IconButton>
              }
              label={t("LicenseCenter.Actions.Basket")}
              className="textPrimary"
              onClick={() => {}}
              color="primary"
              showInMenu={false}
              data-testid={"license-center-action_basket-action"}
            />
          );
        }
        return actions;
      },
    },
  ];

  if (isDistributor) {
    columns.splice(2, 0, {
      field: "reseller",
      headerName: "Reseller",
      type: "singleSelect",
      width: 200,
      valueOptions: _.map(resellers, "name"),
      filterable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.reseller !== null ? params.row.reseller.name : "";
      },
    });
  }
  return columns;
}
