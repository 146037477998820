import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Typography } from "@mui/material";
import SelectField from "../../../components/formFields/SelectField";
import { TextField } from "../../../components/formFields";
import { useFormik } from "formik";
import { getValidationSchema } from "../formValidations/CrossgradeStep1";
import PersonIcon from "@mui/icons-material/Person";
import CrossgradesApi from "../../../api/crossgrades";
import { CrossgradeConfiguration } from "../../../types/crossgrades";
import { SupportedLocale } from "../../../locales";
import useAuth from "../../../contextProviders/Authentication";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { HierarchicalPriceData } from "../../../types/shop";

interface ICrossgradesStep1FormProps {
  setActiveStep: (step: number) => void;
  setCalculatedPrices: (prices: HierarchicalPriceData[] | null) => void;
  setConfiguration: (config: CrossgradeConfiguration | null) => void;
}

const CrossgradesStep1Form: FC<ICrossgradesStep1FormProps> = (props) => {
  const { setCalculatedPrices, setActiveStep, setConfiguration } = props;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { user } = useAuth();
  const initialFormValues: CrossgradeConfiguration = {
    product: "",
    licenseCount: 5,
    duration: "" as unknown as number,
  };
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      setLoading(true);
      CrossgradesApi.calculate(values, user?.settings.language as SupportedLocale)
        .then((response) => {
          setLoading(false);
          if (response) {
            setCalculatedPrices(response);
            setConfiguration(formik.values);
            setActiveStep(1);
          } else {
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      <Typography variant="body1" style={{ whiteSpace: "pre-line" }} sx={{ mb: 4 }}>
        {t("Crossgrades.IntroStep1")}
      </Typography>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={6}>
            <SelectField
              formik={formik}
              name="product"
              label={t("Crossgrades.ProductLabel")}
              fullWidth
              required
              data-testid={"crossgrades_product-select"}
            >
              <MenuItem value="" disabled>
                <em>--- {t("Forms.Choose")} ---</em>
              </MenuItem>
              <MenuItem value="AVB">{t("Products.AVB")}</MenuItem>
              <MenuItem value="AVE">{t("Products.AVE")}</MenuItem>
              <MenuItem value="CSB">{t("Products.CSB")}</MenuItem>
              <MenuItem value="CSE">{t("Products.CSE")}</MenuItem>
              <MenuItem value="EPPB">{t("Products.EPPB")}</MenuItem>
              <MenuItem value="EPPE">{t("Products.EPPE")}</MenuItem>
            </SelectField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              formik={formik}
              label={t("Crossgrades.LicenseCountLabel")}
              name="licenseCount"
              type="number"
              icon={<PersonIcon color="inherit" />}
              dataTestid={"crossgrades_license-count"}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              formik={formik}
              name="duration"
              label={t("Crossgrades.LicenseDurationLabel")}
              required
              data-testid={"crossgrades_duration-select"}
            >
              <MenuItem value="0" disabled>
                <em>--- {t("Forms.Choose")} ---</em>
              </MenuItem>
              <MenuItem value="12">12 {t("Common.Months")}</MenuItem>
              <MenuItem value="24">24 {t("Common.Months")}</MenuItem>
              <MenuItem value="36">36 {t("Common.Months")}</MenuItem>
            </SelectField>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={!formik.dirty || formik.isSubmitting}
                  loading={loading}
                  data-testid={"crossgrades_button-calculate"}
                >
                  {t("Crossgrades.BtnCalculate")}
                </LoadingButton>
              </>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CrossgradesStep1Form;
