import * as React from "react";
import { Dispatch, FC, SetStateAction, useState, useMemo } from "react";
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import { useTranslation } from "react-i18next";
import { FileSelector } from "./FileSelector";
import { LoadingButton } from "@mui/lab";
import CrossgradesApi from "../../../api/crossgrades";
import useAuth from "../../../contextProviders/Authentication";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import FormatUtils from "../../../utils/FormatUtils";
import { KEY_BASKET_GET } from "../../../lib/queryClient";
import { useQueryClient } from "@tanstack/react-query";
import { HierarchicalPriceData } from "../../../types/shop";
import { CrossgradeConfiguration } from "../../../types/crossgrades";
import _ from "lodash";

interface ICrossgradeDataProps {
  activeStep: number;
  configuration: CrossgradeConfiguration | null;
  calculatedPrices: HierarchicalPriceData[] | null;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const CrossgradesStep2Form: FC<ICrossgradeDataProps> = (props) => {
  const { activeStep, calculatedPrices, setActiveStep, configuration } = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const defaultPrice = useMemo(() => _.find(calculatedPrices, ["type", "new"]), [calculatedPrices]);
  const crossgradePrice = useMemo(() => _.find(calculatedPrices, ["type", "crossgrade"]), [calculatedPrices]);

  const addToBasket = () => {
    if (!configuration) {
      return;
    }
    setLoading(true);
    CrossgradesApi.buy(configuration)
      .then((basket) => {
        setLoading(false);
        if (basket) {
          setActiveStep(0);
          queryClient.setQueryData([KEY_BASKET_GET], basket);
          enqueueSnackbar(t("Crossgrades.AddBasketSuccessMsg"), { variant: "success" });
          navigate("/app/shop/basket");
        } else {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      });
  };

  if (!defaultPrice || !crossgradePrice || !configuration) {
    return <></>;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1">{t("Crossgrades.IntroStep2")}</Typography>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <LocalOfferOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("Crossgrades.ProductLabel")}
                secondary={t("Crossgrades.SumProductTitle", {
                  title: t(`Products.${configuration.product}`),
                  seats: configuration.licenseCount,
                  duration: configuration.duration,
                })}
                data-testid={"crossgrades_product-summary"}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <LocalOfferOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("Crossgrades.SumPriceLabel", {
                  price: FormatUtils.getFormattedPrice(crossgradePrice.finalPrice, i18n, user),
                })}
                secondary={
                  <span style={{ display: "block" }}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("Crossgrades.SumPriceNotice", {
                          price: FormatUtils.getFormattedPrice(defaultPrice.finalPrice, i18n, user),
                        }),
                      }}
                    />
                    <br />
                    <br />
                    {t("Crossgrades.SumPriceInfo")}
                  </span>
                }
                data-testid={"crossgrades_price-net"}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <CardMembershipOutlinedIcon />
              </ListItemIcon>
              {activeStep === 1 && (
                <ListItemText
                  primary={t("Crossgrades.SumCertificateLabel")}
                  secondary={t("Crossgrades.SumCertificateDesc")}
                />
              )}
              {activeStep === 2 && (
                <ListItemText
                  primary={t("Crossgrades.SumCertificateLabel")}
                  secondary={
                    <span style={{ display: "block" }}>
                      {t("Crossgrades.SumCertificateSuccess")}
                      <br />
                      {selectedFile?.name}
                    </span>
                  }
                />
              )}
            </ListItem>
          </List>
          {activeStep === 1 && (
            <Grid container sx={{ pl: 7 }}>
              <Grid item xs={12}>
                <FileSelector setActiveStep={setActiveStep} onSelected={(file) => setSelectedFile(file)} />
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <>
              <Grid container justifyContent="flex-end">
                <Grid item xs="auto">
                  <>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      onClick={addToBasket}
                      loading={loading}
                      data-testid={"crossgrade_button-buy-and-basket"}
                    >
                      {t("Crossgrades.BtnBasket")}
                    </LoadingButton>
                  </>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CrossgradesStep2Form;
