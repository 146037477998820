import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { DownloadFilterType, DownloadLanguageType, DownloadSortingType } from "../../../types/downloads";
import { useTranslation } from "react-i18next";
import { SupportedLocale } from "../../../locales";
import { useSearchParams } from "react-router-dom";

interface IDownloadsState {
  searchText: string;
  language: DownloadLanguageType;
  sortingType: DownloadSortingType;
  sortingOrder: "asc" | "desc";
  labels: number[];
  filters: number[];
  tags: string[];
}

interface IDownloadsContext extends IDownloadsState {
  setDownloadSearchText: (searchText: string) => void;
  setDownloadLanguage: (language: DownloadLanguageType) => void;
  setDownloadSortingType: (sortingType: DownloadSortingType) => void;
  setDownloadSortingOrder: (sortingOrder: "asc" | "desc") => void;
  addToDownloadFilters: (filterType: DownloadFilterType, filterId: number) => void;
  removeFromDownloadFilters: (filterType: DownloadFilterType, filterId: number) => void;
}

const DownloadsContext = createContext<IDownloadsContext>({} as IDownloadsContext);

export function DownloadsProvider({ children }: { children: ReactNode }): JSX.Element {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IDownloadsState>({
    searchText: "",
    language: i18n.language as SupportedLocale,
    sortingType: "title",
    sortingOrder: "asc",
    filters: [],
    labels: [],
    tags: [],
  });

  // Set initial filters from url
  useEffect(() => {
    function parseParam(queryParam: string | null) {
      if (queryParam !== null) {
        const tempArray = queryParam?.split(",");
        if (tempArray && tempArray.length > 0) {
          const returnArray: number[] = tempArray.map((filterId) => {
            return parseInt(filterId);
          });
          return returnArray;
        }
      }
      return [];
    }
    const initLabels = searchParams.get("l");
    const initFilters = searchParams.get("f");
    if (initLabels || initFilters) {
      setState((prevState) => {
        return {
          ...prevState,
          filters: parseParam(initFilters),
          labels: parseParam(initLabels),
        };
      });
    }
    setSearchParams("");
  }, [searchParams, setSearchParams]);

  const setDownloadSearchText = (searchText: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        searchText: searchText,
      };
    });
  };

  const setDownloadLanguage = (language: DownloadLanguageType) => {
    setState((prevState) => {
      return {
        ...prevState,
        language: language,
      };
    });
  };

  const setDownloadSortingType = (sortingType: DownloadSortingType) => {
    setState((prevState) => {
      return {
        ...prevState,
        sortingType: sortingType,
      };
    });
  };

  const setDownloadSortingOrder = (sortingOrder: "asc" | "desc") => {
    setState((prevState) => {
      return {
        ...prevState,
        sortingOrder: sortingOrder,
      };
    });
  };

  const addToDownloadFilters = (filterType: DownloadFilterType, filterId: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        [filterType]: [...prevState[filterType], ...[filterId]],
      };
    });
  };

  const removeFromDownloadFilters = (filterType: DownloadFilterType, filterId: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        [filterType]: prevState[filterType].filter((id: number) => id !== filterId),
      };
    });
  };

  const memoedValue = useMemo(
    () => ({
      ...state,
      setDownloadSearchText,
      setDownloadLanguage,
      setDownloadSortingType,
      setDownloadSortingOrder,
      addToDownloadFilters,
      removeFromDownloadFilters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <DownloadsContext.Provider value={memoedValue}>{children}</DownloadsContext.Provider>;
}

export default function useDownloads() {
  return useContext(DownloadsContext);
}
