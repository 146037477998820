import React, { FC, useCallback, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Badge, IconButton, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NotificationsApi } from "../../../api";
import useAuth from "../../../contextProviders/Authentication";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { useTranslation } from "react-i18next";
import { useAppNotificationsState, NotificationAction } from "../../../contextProviders/AppNotifications";

const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
const NotificationBar: FC = () => {
  const { state: notificationBarState, dispatch: dispatchNotifications } = useAppNotificationsState();
  const [fetchCounter, setFetchCounter] = React.useState(0);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  const fetchInterval = parseInt(process.env.REACT_APP_NOTIFICATION_FETCH_INTERVAL ?? "120000");

  const { data: notifications } = useQuery({
    queryKey: ["Notifications", user?.uid],
    queryFn: async () => {
      // Nur beim ersten Aufruf auf neue GroupNotifications prüfen, um DB-Performance zu sparen
      const appNotifications = NotificationsApi.get(5, fetchCounter === 0);
      if (fetchCounter === 0) {
        setFetchCounter(1);
      }
      return appNotifications;
    },
    refetchInterval: fetchInterval,
    refetchIntervalInBackground: false,
    notifyOnChangeProps: ["data", "error"],
    meta: { counter: 0 },
  });

  const markNotificationsRead = useCallback(() => {
    if (notifications && notificationBarState.unreadNotificationsCount > 0) {
      const ids = notifications.map(function (n) {
        return n.uid;
      });
      dispatchNotifications({
        type: NotificationAction.SetUnreadNotificationsCount,
        payload: { unreadNotificationsCount: 0 },
      });
      NotificationsApi.markRead(ids).catch(() => {
        return;
      });
    }
  }, [notifications, dispatchNotifications, notificationBarState.unreadNotificationsCount]);

  const toggleNotificationBar = useCallback(
    (open?: boolean) => {
      if (open) {
        dispatchNotifications({ type: NotificationAction.ToggleNotificationBar, payload: { isSidebarOpen: open } });
      } else {
        dispatchNotifications({ type: NotificationAction.ToggleNotificationBar, payload: {} });
      }
    },
    [dispatchNotifications]
  );

  useEffect(() => {
    dispatchNotifications({
      type: NotificationAction.SetNotifications,
      payload: { notifications: notifications },
    });
  }, [dispatchNotifications, notifications]);

  useEffect(() => {
    if (notificationBarState.isSidebarOpen) {
      markNotificationsRead();
    }
  }, [markNotificationsRead, notificationBarState.isSidebarOpen]);

  return useMemo(
    () => (
      <>
        <IconButton color="inherit" onClick={() => toggleNotificationBar()}>
          <Badge badgeContent={notificationBarState.unreadNotificationsCount} color="primary">
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
        <SwipeableDrawer
          anchor="right"
          open={notificationBarState.isSidebarOpen}
          onClose={() => toggleNotificationBar(false)}
          onOpen={() => toggleNotificationBar(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.appBar - 1,
          }}
        >
          <Box role="presentation" sx={{ width: "min(80vw, 350px)" }} pt={8}>
            <Typography variant="h4" p={3}>
              {t("Notifications.Title")}
            </Typography>
            <Divider />
            <Box pr={3} pl={3}>
              {notificationBarState.notifications && notificationBarState.notifications.length > 0 ? (
                <List>
                  {notificationBarState.notifications.map((notification) => (
                    <Box key={`notification${notification.uid}`}>
                      <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                        <ListItemText
                          primary={notification.subject}
                          primaryTypographyProps={{
                            component: "p",
                            variant: "h6",
                          }}
                          secondaryTypographyProps={{
                            component: "div",
                          }}
                          secondary={
                            <React.Fragment>
                              <Typography component="p" variant="body2" color="text.secondary">
                                {t("Notifications.Published")}{" "}
                                {DateTimeUtils.getLocalDate(i18n, notification.publish_date, "PPP")}
                              </Typography>
                              <Typography
                                component="p"
                                variant="body2"
                                color="text.primary"
                                sx={{ mt: 1, whiteSpace: "pre-line" }}
                              >
                                {notification.message}
                              </Typography>
                              <Stack direction="row" spacing={1} mt={2} sx={{ justifyContent: "end" }}>
                                {notification.link && notification.link_label && (
                                  <Button variant="contained" size="small">
                                    {notification.link_label}
                                  </Button>
                                )}
                              </Stack>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" mt={2}>
                  {t("Notifications.Empty")}
                </Typography>
              )}
            </Box>
          </Box>
        </SwipeableDrawer>
      </>
    ),
    [
      t,
      i18n,
      notificationBarState.isSidebarOpen,
      notificationBarState.notifications,
      notificationBarState.unreadNotificationsCount,
      toggleNotificationBar,
    ]
  );
};

export default NotificationBar;
