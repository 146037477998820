import { HierarchicalDiscount } from "./shop";

export type Basket = {
  orderId: string;
  externalDocumentNumber: string;
  sumPriceNet: number;
  sumPriceGross: number;
  tax: number;
  paymentType: number;
  payment: number;
  basketItems: BasketItem[];
  onlyNetPrices: boolean;
};

// @todo Evaluieren, welche Parameter tatsächlich alle im output erforderlich sind
export type BasketItem = {
  uid: number;
  title: string;
  description: string;
  priceNet: number;
  quantity: number;
  duration: number;
  abbreviation: string;
  registrationAddress?: Partial<RegistrationAddress>;
  registrationNumbers: string;
  isDependingItem: boolean;
  isQuantityChangeAllowed: boolean;
  isParentItem: boolean;
  validUntil: string;
  coupon?: Coupon;
  rebate: number;
  rebateNet: number;
  licenseCount: number;
  isSubscription: boolean;
  subscriptionDuration: number;
  subscriptionPriceNet: number;
  comment?: string;
  articleVariantType: ArticleVariantType;
  discounts: HierarchicalDiscount[];
  basePriceNet: number;
  retailPriceNet: number;
  registerObligatory: boolean;
};

export type BasketAddress = {
  uid: number;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  addition: string;
  city: string;
  postcode: string;
  country: string;
  email: string;
};

export type RegistrationAddress = BasketAddress & {
  reseller?: number;
  register?: boolean;
};

export type Coupon = {
  title: string;
  value: number;
};

export const enum ArticleVariantType {
  B2B_NEW = 0,
  B2C_NEW = 1,
  B2C_RENEWAL = 2,
  B2C_EXTEND = 3,
  B2C_UPGRADE = 4,
  B2B_RENEWAL = 5,
  B2B_EXTEND = 6,
  B2B_UPGRADE = 7,
  ADDON_NEW = 8,
  ADDON_RENEWAL = 9,
  ADDON_UPGRADE = 10,
}
