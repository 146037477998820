import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { Alert, Grid, Skeleton, Step, StepIcon, StepLabel, Stepper, Typography } from "@mui/material";
import useAuth from "../../contextProviders/Authentication";
import CrossgradesStep1Form from "./components/CrossgradesStep1Form";
import ContentBox from "../../components/ContentBox";
import CrossgradesStep2Form from "./components/CrossgradesStep2Form";
import { CrossgradesApi } from "../../api";
import { CrossgradeConfiguration, CrossgradesInitResult } from "../../types/crossgrades";
import { HierarchicalPriceData } from "../../types/shop";

const Crossgrades: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [calculatedPrices, setCalculatedPrices] = useState<HierarchicalPriceData[] | null>(null);
  const [configuration, setConfiguration] = useState<CrossgradeConfiguration | null>(null);
  const [currentCrossgradeData, setCurrentCrossgradeData] = useState<CrossgradesInitResult | null>(null);

  useEffect(() => {
    CrossgradesApi.init().then((response: CrossgradesInitResult) => {
      setCurrentCrossgradeData(response);
    });
  }, []);

  const steps = [
    t("Crossgrades.TitleStep1"),
    t("Crossgrades.TitleStep2"),
    t("Crossgrades.TitleStep3"),
    t("Crossgrades.TitleStep4"),
  ];

  const goBackToStep0 = () => {
    if (activeStep > 0) {
      setActiveStep(0);
    }
  };
  const goBackToStep1 = () => {
    if (activeStep > 1) {
      setActiveStep(1);
    }
  };

  return user && currentCrossgradeData ? (
    <Page title={t("Crossgrades.PageTitle")} subtitle={t("Sidebar.Shop.Title")} maxWidth="md">
      <ContentBox>
        <Typography>{t("Crossgrades.PageSubtitle")}</Typography>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            {currentCrossgradeData.forbidden || user.roles.indexOf("crossgrades") === -1 ? (
              <Alert severity="warning">{t("Crossgrades.AlertAccess")}</Alert>
            ) : (
              <>
                <Stepper activeStep={activeStep} alternativeLabel={true} sx={{ mt: 4, mb: 4 }}>
                  {steps.map((label, step) => (
                    <Step key={step} onClick={step === 0 ? goBackToStep0 : step === 1 ? goBackToStep1 : () => null}>
                      <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {currentCrossgradeData.hasOpenBasket && <Alert severity="info">{t("Crossgrades.AlertBasket")}</Alert>}
                {activeStep === 0 && (
                  <CrossgradesStep1Form
                    setActiveStep={setActiveStep}
                    setCalculatedPrices={setCalculatedPrices}
                    setConfiguration={setConfiguration}
                  />
                )}
                {(activeStep === 1 || activeStep === 2) && calculatedPrices !== null ? (
                  <CrossgradesStep2Form
                    activeStep={activeStep}
                    configuration={configuration}
                    setActiveStep={setActiveStep}
                    calculatedPrices={calculatedPrices}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </Grid>
        </Grid>
      </ContentBox>
    </Page>
  ) : (
    <Page title={t("Crossgrades.PageTitle")} subtitle={t("Sidebar.Shop.Title")}>
      <ContentBox>
        <Skeleton variant="rectangular" width="100%" height={300} sx={{ mt: 2 }} />
      </ContentBox>
    </Page>
  );
};

export default Crossgrades;
