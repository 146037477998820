import axios from "./../lib/axiosForApi";
import { LicenseChangeParameters } from "../types/license";
import { Basket, BasketItem } from "../types/basket";
import { ArticleConfig } from "../types/shop";

class BasketItemApi {
  async addChangedLicenseToBasket(params: LicenseChangeParameters): Promise<Basket> {
    const response = await axios.post("shop/basket-item/change-license", params);
    return response.data;
  }

  async addNewLicenseToBasket(params: ArticleConfig): Promise<Basket> {
    const response = await axios.post("shop/basket-item/new-license", params);
    return response.data;
  }

  async delete(basketItemUid: number): Promise<Basket> {
    const response = await axios.delete(`shop/basket/basket-item/${basketItemUid}`);
    return response.data;
  }

  async change(basketItemUid: number, params: Partial<BasketItem>): Promise<Basket> {
    const response = await axios.patch(`shop/basket/basket-item/${basketItemUid}`, params);
    return response.data;
  }
}

export default new BasketItemApi();
