import { FC } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MaterialUiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";
import { FormikProps, getIn } from "formik";

type SelectFieldProps<FormValues> = MuiSelectProps & {
  formik: FormikProps<FormValues>;
  label: string;
  name: string;
  keepSpace?: boolean;
  hideHelperText?: boolean;
};

const SelectField = <FormValues,>(props: SelectFieldProps<FormValues>): ReturnType<FC> => {
  const { formik, label, name, size, keepSpace, hideHelperText, children, ...rest } = props;
  return (
    <FormControl
      fullWidth
      size={size ?? "medium"}
      className={keepSpace == true || keepSpace == undefined ? "form-formGroup-keep" : "form-formGroup"}
    >
      <InputLabel>{props.required ? `${label}*` : label}</InputLabel>
      <MaterialUiSelect
        label={label}
        name={name}
        variant="outlined"
        value={getIn(formik.values, name)}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))}
        {...rest}
      >
        {children}
      </MaterialUiSelect>
      {!hideHelperText && getIn(formik.touched, name) && getIn(formik.errors, name) && (
        <FormHelperText error>{getIn(formik.touched, name) && getIn(formik.errors, name)}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectField;
