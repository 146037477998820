import React, { FC, useEffect, useState } from "react";
import Page from "../../components/Page";
import ContentBox from "../../components/ContentBox";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Link, Skeleton, Typography } from "@mui/material";
import useAuth from "../../contextProviders/Authentication";
import { useInvalidateQueryClientData } from "../../lib/queryClient";
import { Basket } from "../../types/basket";
import { BasketApi } from "../../api";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../hooks/useEffectOnce";

const OrderCompleted: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [basket, setBasket] = useState<Basket>();

  const { invalidateAllBasketData } = useInvalidateQueryClientData();
  const { basketId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    invalidateAllBasketData();
  });

  useEffect(() => {
    BasketApi.getById(parseInt(basketId ?? ""))
      .then((basketFromResponse) => {
        setBasket(basketFromResponse);
      })
      .catch(() => {
        setBasket(undefined);
      })
      .finally(() => setIsLoading(false));
  }, [basketId]);

  return (
    <Page title={t("Basket.Title")} subtitle={t("Sidebar.Shop.Title")} maxWidth={"lg"}>
      <ContentBox headline={t("Basket.OrderCompleted.Headline")}>
        {isLoading || !user ? (
          <>
            <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="30%" />
          </>
        ) : (
          <></>
        )}
        {!isLoading &&
          (basket ? (
            <>
              <Typography mb={1}>
                <Trans
                  i18nKey="Basket.OrderCompleted.Line1"
                  values={{ orderId: basket.orderId }}
                  components={{ bold: <strong /> }}
                />
              </Typography>
              <Typography mb={1}>
                <Trans
                  i18nKey="Basket.OrderCompleted.Line2"
                  values={{ email: user?.email }}
                  components={{ bold: <strong /> }}
                />
              </Typography>
              <Typography>
                <Trans
                  i18nKey="Basket.OrderCompleted.Line3"
                  components={{
                    supportLink: <Link variant="body1" href="app/support" />,
                  }}
                />
              </Typography>
            </>
          ) : (
            <Alert variant="outlined" severity="error">
              {t("Common.UnspecifiedError")}
            </Alert>
          ))}
      </ContentBox>
    </Page>
  );
};

export default OrderCompleted;
