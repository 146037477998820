import { useSnackbar } from "notistack";
import { LicenseApi } from "../../../api";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

export function useMesExportService(): {
  downloadExport: (registrationNumber: string) => void;
} {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const downloadExport = (registrationNumber: string) => {
    LicenseApi.xmlExport(registrationNumber)
      .then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `export_${registrationNumber.replace("|", "-")}.zip`);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            enqueueSnackbar(t("LicenseCenter.MESExport.NO_DATA"), { variant: "info" });
          } else if (error.response.status === 500) {
            enqueueSnackbar(t("LicenseCenter.MESExport.ZIP_ARCHIVE_ERROR"), { variant: "error" });
          } else {
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }
        }
      });
  };

  return { downloadExport };
}
