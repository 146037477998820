import { styled } from "@mui/material/styles";
import { AppBar } from "@mui/material";

const Navbar = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  [theme.breakpoints.up("lg")]: {
    zIndex: theme.zIndex.drawer + 100,
  },
  "& .appTitle": {
    paddingLeft: theme.spacing(2),
    fontSize: "2.125em !important",
  },
  " .MuiToolbar-root": {
    minHeight: 64,
    lineHeight: "1",
  },
}));

export default Navbar;
