import React, { FC, MouseEventHandler, ReactNode } from "react";
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Breakpoint } from "@mui/system";

interface DialogProps {
  show: boolean;
  handleClose: MouseEventHandler;
  title: string;
  content: ReactNode;
  footer?: ReactNode;
  maxWidth?: Breakpoint;
  scroll?: "body" | "paper";
  noContentPadding?: boolean;
}
const Dialog: FC<DialogProps> = (props: DialogProps) => {
  const { show, handleClose, title, content, footer, maxWidth, scroll, noContentPadding } = props;

  return (
    <MuiDialog onClose={handleClose} open={show} fullWidth maxWidth={maxWidth ?? "md"} scroll={scroll ?? "paper"}>
      <DialogTitle
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        data-testid={"dialog_title"}
      >
        <Typography component="span" noWrap={true} variant="inherit">
          {title}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: noContentPadding ? 0 : 4, pt: noContentPadding ? 0 : 2, pb: noContentPadding ? 0 : 2 }}>
        {content}
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </MuiDialog>
  );
};

export default Dialog;
