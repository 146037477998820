import type { FC } from "react";
import { useTheme } from "@mui/material/styles";

interface LogoProps {
  width: string;
}

const LogoDark: FC<LogoProps> = ({ width }) => (
  <img src="/app-files/static/logos/logo-for-dark.svg" style={{ width }} alt="G DATA Logo" />
);

const LogoLight: FC<LogoProps> = ({ width }) => (
  <img src="/app-files/static/logos/logo-for-light.svg" style={{ width }} alt="G DATA Logo" />
);

const Logo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  return theme.palette.mode === "light" ? <LogoLight {...props} /> : <LogoDark {...props} />;
};

export default Logo;
