import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import ContentBox from "../../components/ContentBox";
import { Faq } from "../../types/faqs";
import { Box, Button, InputAdornment, Skeleton, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetFaqs } from "../../lib/queryClient";
import TextField from "@mui/material/TextField";
import { Search } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FilterUtils from "../../utils/FilterUtils";

const Accordion = styled(MuiAccordion)(() => ({
  padding: 0,
  ".MuiCollapse-root": {
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 16px 0",
  p: {
    whitespace: "normal",
  },
}));

const Faqs: FC = () => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState<Faq[]>([]);
  const { data: faq, isLoading } = useGetFaqs();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    function getFilteredArray(arr: Faq[], _searchText: string): Faq[] {
      return _searchText.length === 0 ? arr : FilterUtils.filterArrayByString(arr, _searchText);
    }

    setFilteredData(getFilteredArray(faq as Faq[], searchText));
  }, [faq, searchText]);

  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Page
      title={t("Faqs.Title")}
      subtitle={t("Sidebar.Knowledgebase.Title")}
      maxWidth="md"
      buttons={[
        <Button variant="outlined" href="https://gdata.help" target="_blank">
          {t("Faqs.GoToOnlineHelp")}
        </Button>,
      ]}
    >
      <ContentBox sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
        <TextField
          fullWidth
          name="message"
          placeholder={t("Faqs.SearchPlaceholder")}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        {isLoading ? (
          <>
            <Typography component="div" variant="body1">
              <Skeleton variant="rectangular" width="100%" height={300} sx={{ mt: 2 }} />
            </Typography>
          </>
        ) : (
          <>
            {filteredData !== undefined && filteredData.length > 0 ? (
              <Box sx={{ pt: 4 }}>
                {filteredData.map((faqResult) => (
                  <Accordion
                    key={faqResult.id}
                    expanded={expanded === faqResult.id}
                    onChange={handleChange(faqResult.id)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <HelpOutlineIcon sx={{ mr: 1 }} />
                      <Typography sx={{ fontWeight: 600 }}>{faqResult.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: faqResult.answer.replace(/[\r\n]/gm, "") }}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            ) : (
              <Typography sx={{ pt: 4 }}>{t("Faqs.NoResultFound")}</Typography>
            )}
          </>
        )}
      </ContentBox>
    </Page>
  );
};

export default Faqs;
