import { ChangeEvent } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField } from "@mui/material";
import { getLanguage, SupportedLocale } from "../locales";
import { languageOptions } from "./LanguagePopover";
import { useSnackbar } from "notistack";
import { useUserAppSettings } from "../contextProviders/UserAppSettings";

const LanguageSelect: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setLanguage } = useUserAppSettings();

  const handleChangeLanguage = (event: ChangeEvent<{ value: unknown }>): void => {
    const language = event.target.value as SupportedLocale;
    setLanguage(language)
      .then(() => {
        enqueueSnackbar(t("Settings.SaveSuccess"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("Settings.SaveError"), { variant: "error" });
      });
  };

  const selectedOption = getLanguage(i18n);

  return (
    <TextField
      fullWidth
      select
      label={t("Common.Language", "Language")}
      data-testid="LanguageSelect-languageField"
      value={selectedOption}
      variant="outlined"
      onChange={handleChangeLanguage}
    >
      {(Object.keys(languageOptions) as SupportedLocale[]).map((language) => (
        <MenuItem data-testid={`LanguageSelect-languageItem${language}`} key={`lang${language}`} value={language}>
          {languageOptions[language].label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LanguageSelect;
