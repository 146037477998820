import { FC, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { Container, Stack } from "@mui/material";
import PageTitle from "./PageTitle";
import { Breakpoint, SxProps } from "@mui/system";

interface PageProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  buttons?: ReactNode[];
  maxWidth?: Breakpoint | false;
  sx?: SxProps;
}

const PageContainer = styled(Container)(() => ({
  height: "100%",
  paddingLeft: "1.5rem",
  display: "flex",
  flexDirection: "column",
  maxHeight: "100%",
  width: "100%",
  margin: 0,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  minHeight: "64px",
  display: "block",
  alignItems: "flex-end",
  marginBottom: "16px",
  "&.with-title": {
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("md")]: {
    height: "64px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
}));

const Page: FC<PageProps> = (props) => {
  const { title, subtitle, buttons, sx, maxWidth, children } = props;
  return (
    <PageContainer maxWidth={maxWidth == undefined ? "lx" : maxWidth} sx={sx}>
      <HeaderContainer className={title ? "with-title" : ""}>
        {title && <PageTitle title={title ?? ""} subtitle={subtitle} />}
        {buttons && (
          <Stack direction="row" spacing={3}>
            {buttons.map((button, index) => (
              <Fragment key={`page-btn-${index}`}>{button}</Fragment>
            ))}
          </Stack>
        )}
      </HeaderContainer>
      {children}
    </PageContainer>
  );
};

export default Page;
