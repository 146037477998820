import axios from "./../lib/axiosForApi";
import { Basket } from "../types/basket";

class BasketApi {
  async get(): Promise<Basket> {
    const response = await axios.get("/api/shop/basket/basket");
    return response.data;
  }

  async getById(id: number): Promise<Basket> {
    const response = await axios.get(`/api/shop/basket/basket/${id}`);
    return response.data;
  }

  async update(params: Partial<Basket>): Promise<Basket> {
    const response = await axios.patch("/api/shop/basket/basket", { ...params });
    return response.data;
  }

  async updatePayment(params: Partial<Basket>): Promise<Basket | null> {
    try {
      const response = await axios.patch("/shop/basket/payment", { ...params });
      return response.status === 200 ? response.data : null;
    } catch (e) {
      return null;
    }
  }
}

export default new BasketApi();
