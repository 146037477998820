import { TFunction } from "i18next";
import * as Yup from "yup";
import { ObjectSchema } from "yup";

export type ResellerFormValues = {
  number: string;
  email: string;
  name: string;
  postCode: string;
  city: string;
  countryCode: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<ResellerFormValues> => {
  return Yup.object().shape({
    number: Yup.string()
      .default("")
      .max(
        20,
        t("Forms.TooLong", {
          maxLength: "20",
        })
      ),
    email: Yup.string()
      .email(t("Forms.ValidEmail"))
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.Email"),
        })
      ),
    name: Yup.string()
      .max(
        50,
        t("Forms.TooLong", {
          maxLength: "50",
        })
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.Name"),
        })
      ),
    postCode: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.PostCode"),
      })
    ),
    city: Yup.string()
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.City"),
        })
      )
      .max(
        50,
        t("Forms.TooLong", {
          maxLength: "50",
        })
      ),
    countryCode: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Country"),
      })
    ),
  });
};
