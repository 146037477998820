import { Box, Drawer, FormGroup, Grid, styled } from "@mui/material";

export const BasketArea = styled(Box)(({ theme }) => ({
  display: "grid",
  gridColumnGap: "32px",
  gridRowGap: "16px",
  gridTemplateAreas: `"data" "payment" "basket"`,
  [theme.breakpoints.up("md")]: {
    gridTemplateAreas: `"data payment" "basket basket"`,
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 3fr",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `"data basket" "payment basket"`,
  },
  ".payment-box": {
    gridArea: "payment",
  },
  ".data-box": {
    gridArea: "data",
  },
  ".basket-box": {
    gridArea: "basket",
    [theme.breakpoints.up("md")]: {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderTop: "none",
      paddingTop: "0",
      paddingLeft: theme.spacing(4),
    },
  },
}));

export const BasketCheckboxWrapper = styled(FormGroup)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: `0 ${theme.spacing(1)}`,
  border: `1px solid ${theme.palette.divider}`,
}));

export const BasketItemsBoxHeader = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
}));
export const BasketItemHeader = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    minHeight: "2.2em",
  },
}));

export const PaymentLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const SummaryBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  textAlign: "right",
}));

export const Sidebar = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "min(80vw, 420px)",
    height: "calc(100% - 64px)",
    marginTop: "64px",
    overflow: "auto",
    zIndex: theme.zIndex.appBar - 1,
  },
  "& .Sticky-Footer": {
    width: "min(80vw, 420px)",
    position: "fixed",
    bottom: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: `linear-gradient(0deg, ${theme.palette.background.paper} 65%, transparent 100%);`,
    zIndex: 10,
  },
}));

export const SidebarHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
