import axios from "../lib/axiosForApi";
import {
  ArticleConfig,
  AvailableProduct,
  HierarchicalPriceData,
  Payment,
  SalesLicenseType,
  SpecialEdition,
} from "../types/shop";
import { ProductCode } from "../types/license";
import { CheckoutFormValues } from "../pages/shop/formValidations/checkout";
import { SupportedLocale } from "../locales";

export interface ICalculatedPrice {
  default: HierarchicalPriceData;
  subscription: HierarchicalPriceData | null;
}

interface IGetQuoteParams {
  productCode: string;
  quantity: number;
  licenseCount: number;
  durations: number[];
  salesLicenseType: SalesLicenseType;
}

export interface ICheckoutCheckbox {
  identifier: string;
  mandatory: boolean;
  link?: string;
}

class ShopApi {
  async getProducts(customerNo?: string): Promise<{
    availableProducts: Record<ProductCode, AvailableProduct>;
    specialEditions: SpecialEdition[];
  } | null> {
    try {
      let uri = "/shop/products";
      if (customerNo) {
        uri += `?customerNo=${customerNo}`;
      }
      const response = await axios.get(uri);
      return response.status === 200 ? response.data : null;
    } catch (e) {
      return null;
    }
  }

  async calculatePrice(params: ArticleConfig & { customerNo?: string }): Promise<ICalculatedPrice | null> {
    try {
      const response = await axios.post("/shop/price", params);
      return response.status === 200 ? response.data : null;
    } catch (e) {
      return null;
    }
  }

  async getQuote(params: IGetQuoteParams, languageCode: SupportedLocale): Promise<Blob | null> {
    try {
      const response = await axios.post(`/shop/quote?language-code=${languageCode}`, params, { responseType: "blob" });
      return new Blob([response.data], { type: "application/pdf;charset=utf-8" });
    } catch (e) {
      return null;
    }
  }

  async getPayments(): Promise<{ payments: Payment[] } | null> {
    try {
      const response = await axios.get("/shop/payments");
      return response.status === 200 ? response.data : null;
    } catch (e) {
      return null;
    }
  }

  async getCountries(): Promise<{ countries: string[] } | null> {
    try {
      const response = await axios.get("/shop/countries");
      return response.status === 200 ? response.data : null;
    } catch (e) {
      return null;
    }
  }

  async finish(
    params: CheckoutFormValues
  ): Promise<{ status: number; redirectUrl?: string; basketId?: number; errorCode?: number }> {
    const response = await axios.post("/shop/finish", params);
    return {
      status: response.status,
      ...response.data,
    };
  }

  async getCheckboxes(): Promise<ICheckoutCheckbox[]> {
    try {
      const response = await axios.get("/shop/checkboxes");
      return response.status === 200 ? response.data : [];
    } catch (e) {
      // ToDo: Fehler!
      return [];
    }
  }
}

export default new ShopApi();
