import type { FC } from "react";
import { PasswordForm } from "./components";
import Page from "../../components/Page";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import MfaSettings from "../mfa/MfaSettings";

const Security: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Sidebar.Settings.Security")} subtitle={t("Sidebar.Settings.Title")}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={5}>
          <PasswordForm />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <MfaSettings />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Security;
