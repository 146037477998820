/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FC, ReactNode } from "react";
import { matchPath } from "react-router-dom";
import { List, ListSubheader, Typography } from "@mui/material";
import type { ListProps } from "@mui/material";
import NavItem from "./NavItem";
import { useMemo } from "react";
import useAuth from "../contextProviders/Authentication";
import UserUtils from "../utils/UserUtils";
import { UserRoles } from "../types/user";

interface INavSectionItem {
  path: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: INavSectionItem[];
  title: string;
  dataTestId: string;
  allowedRoles?: UserRoles;
}

export interface INavSectionProps extends ListProps {
  items: INavSectionItem[];
  icon?: ReactNode;
  pathname: string;
  title: string;
  allowedRoles?: UserRoles;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
}: {
  items: INavSectionItem[];
  pathname: string;
  depth?: number;
}): JSX.Element => (
  <List disablePadding sx={{ pl: "16px" }}>
    {items.reduce<Array<JSX.Element>>(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: INavSectionItem;
  depth: number;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname
        )
      : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        dataTestId={item.dataTestId}
        allowedRoles={item.allowedRoles}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        dataTestId={item.dataTestId}
        allowedRoles={item.allowedRoles}
      />
    );
  }

  return acc;
};

const NavSection: FC<INavSectionProps> = (props) => {
  const { items, icon, pathname, title, allowedRoles, ...other } = props;

  const { user } = useAuth();
  const hasPermission = useMemo(() => UserUtils.hasPermission(allowedRoles, user), [allowedRoles, user]);
  if (!hasPermission) {
    return null;
  }

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "1rem",
            lineHeight: 2.5,
            mb: "12px",
            textTransform: "uppercase",
            display: "flex",
            alignItems: "center",
            position: "relative",
            "&::after": {
              content: '" "',
              display: "block",
              position: "absolute",
              width: "65px",
              borderBottom: "1px solid #979797",
              bottom: "-3px",
              left: "-12px",
            },
          }}
        >
          {icon}
          <Typography sx={{ pl: "4px", fontWeight: 600 }}>{title}</Typography>
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
      })}
    </List>
  );
};

export default NavSection;
