import { ProductCode } from "./license";

// identisch mit PHP-Enum in gd_store/Classes/Enumeration/SalesLicenseType.php
export const enum SalesLicenseType {
  DEFAULT = 0,
  EDUCATION = 1,
  GOVERNMENT = 2,
}

export type AvailableProduct = {
  productCode: ProductCode;
  licenseCountMin: number;
  licenseCountMax: number;
  salesLicenseTypes: Array<SalesLicenseType>;
  durations: number[];
  category: "BUSINESS" | "SECURITY";
};

export type SpecialEdition = {
  productCode: ProductCode;
  licenseCountMin: number;
  licenseCountMax: number;
  duration: number;
  validUntil: number; // timestamp
};

export type ArticleConfig = {
  productCode: string;
  quantity: number;
  licenseCount: number;
  duration: number;
  subscription?: boolean;
  salesLicenseType: SalesLicenseType;
};

export type Payment = {
  paymentUid: number;
  paymentTypeUid: number;
  name: string;
  icons: string[];
};

export type HierarchicalDiscount = {
  name: string;
  hierarchyLevel: number;
  percentage: number;
  amountExclVat: number;
  amountInclVat: number;
  subTotalExclVat: number;
  subTotalInclVat: number;
};

export type HierarchicalPriceData = {
  type?: "renew" | "upgrade" | "crossgrade" | "new";
  finalPrice: number;
  basePrice: number;
  retailPrice: number;
  discounts: HierarchicalDiscount[];
};
