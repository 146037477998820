import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box, Drawer, Grid } from "@mui/material";
import ContentBox from "../../../components/ContentBox";
import DownloadsFilters from "./DownloadsFilters";
import * as React from "react";
import DownloadsHeader from "./DownloadsHeader";
import useDownloads from "../contextProviders/DownloadsContext";
import { DownloadsApi } from "../../../api";
import { DownloadSearchResult } from "../../../types/downloads";
import DownloadsSearchResultItem from "./DownloadsSearchResultItem";
import _ from "lodash";
import FilterUtils from "../../../utils/FilterUtils";
import { useGetDownloadFilters, useGetDownloadLabels } from "../../../lib/queryClient";

const DownloadsModule: FC = () => {
  const { t } = useTranslation();
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);
  const {
    searchText,
    language,
    sortingType,
    sortingOrder,
    filters: selectedFilters,
    labels: selectedLabels,
    tags,
  } = useDownloads();
  const [searchResults, setSearchResults] = useState<DownloadSearchResult[]>([]);
  const [filteredSearchResults, setFilteredSearchResults] = useState<DownloadSearchResult[]>([]);
  const [isReloading, setIsReloading] = useState<boolean>(false);

  const { data: downloadFilters } = useGetDownloadFilters();
  const { data: downloadLabels } = useGetDownloadLabels();
  const downloadCategories = downloadFilters && downloadLabels ? [...downloadFilters, ...downloadLabels] : [];

  useEffect(() => {
    const getFilteredResults = (items: DownloadSearchResult[]) => {
      let arr = items;
      arr = arr.filter((item) => item.language === language || language === "all");
      return searchText.length === 0 ? arr : FilterUtils.filterArrayByString(arr, searchText);
    };

    setFilteredSearchResults(_.orderBy(getFilteredResults(searchResults), [sortingType], [sortingOrder]));
  }, [language, searchResults, searchText, sortingOrder, sortingType]);

  useEffect(() => {
    const doSearch = setTimeout(() => {
      setIsReloading(true);
      DownloadsApi.search(selectedFilters, selectedLabels, tags).then((results) => {
        setSearchResults(results);
        setIsReloading(false);
      });
    }, 500);
    return () => clearTimeout(doSearch);
  }, [selectedFilters, selectedLabels, tags]);

  const handleFilterDrawerToggle = () => {
    setShowFilterDrawer(!showFilterDrawer);
  };

  return (
    <Box>
      <Box>
        <Drawer
          variant="temporary"
          open={showFilterDrawer}
          onClose={handleFilterDrawerToggle}
          sx={{ display: { xs: "block", lg: "none" } }}
          PaperProps={{ sx: { py: 5, px: 3 } }}
        >
          <DownloadsFilters />
        </Drawer>
      </Box>
      <ContentBox>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} lg={3} xl={2} sx={{ display: { xs: "none", lg: "block" } }}>
            <DownloadsFilters />
          </Grid>
          <Grid item xs={12} lg={9} xl={10}>
            <DownloadsHeader handleFilterDrawerToggle={handleFilterDrawerToggle} />
            {isReloading ? (
              <>
                <DownloadsSearchResultItem />
                <DownloadsSearchResultItem />
              </>
            ) : filteredSearchResults && filteredSearchResults.length > 0 ? (
              filteredSearchResults.map((result: DownloadSearchResult) => (
                <DownloadsSearchResultItem key={result.id} result={result} allCategories={downloadCategories} />
              ))
            ) : (
              <Alert variant="outlined" severity="info" sx={{ my: 2 }}>
                {t("Downloads.NoDownloadsAvailable")}
              </Alert>
            )}
          </Grid>
        </Grid>
      </ContentBox>
    </Box>
  );
};

export default DownloadsModule;
