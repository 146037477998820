import { FC, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, Divider, Drawer, styled } from "@mui/material";
import Logo from "../../Logo";
import NavSection from "../../NavSection";
import Scrollbar from "../../Scrollbar";
import { AppTitle } from "../../AppTitle";
import { useAppSidebarSections } from "./AppSidebarSections";

interface AppSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DesktopSidebarBox = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
}));

const MobileSidebarBox = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("xs")]: {
    display: "block",
  },
}));

const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const AppTitleContentBox = styled(Box)(({ theme }) => ({
  padding: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  img: {
    display: "block",
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const NavSectionBox = styled(Box)(() => ({
  padding: 12,
  paddingTop: 26,
}));

const MobileNavSectionBox = styled(Box)(() => ({
  overflowX: "auto",
  position: "fixed",
  bottom: 90,
  height: "calc(100% - 90px) !important",
  width: 280,
}));

const AppSidebar: FC<AppSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const sections = useAppSidebarSections();

  const content = (
    <ContentBox>
      <AppTitleContentBox>
        <RouterLink to="/">
          <Logo width="80px" />
        </RouterLink>
        <AppTitle typography={{ align: "center" }} />
      </AppTitleContentBox>
      <Divider />
      <NavSectionBox>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </NavSectionBox>
    </ContentBox>
  );

  return (
    <>
      <MobileSidebarBox>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          <MobileNavSectionBox>
            <Scrollbar>{content}</Scrollbar>
          </MobileNavSectionBox>
        </Drawer>
      </MobileSidebarBox>
      <DesktopSidebarBox>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 272,
            },
          }}
          variant="persistent"
        >
          <Scrollbar>{content}</Scrollbar>
        </Drawer>
      </DesktopSidebarBox>
    </>
  );
};

export default AppSidebar;
