import React, {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select as MaterialUiSelect,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBox, SelectField, TextField } from "../../../components/formFields";
import { Sidebar, SidebarHeader } from "../../../theme/styledComponents/Basket";
import { useTranslation } from "react-i18next";
import { ArticleVariantType, BasketItem } from "../../../types/basket";
import { FormikProps, useFormik } from "formik";
import useAuth from "../../../contextProviders/Authentication";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { KEY_BASKET_GET, useGetCountries, useGetResellerForDistributor } from "../../../lib/queryClient";
import UserUtils from "../../../utils/UserUtils";
import CollapsableContent, { ICollapsableContentApi } from "../../../components/CollapsableContent";
import { getValidationSchema, RegistrationAddressFormValues } from "../formValidations/registrationAddress";
import { BasketItemApi } from "../../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import getCountryData from "../../../utils/CountryDataUtils";

export interface IBasketItemSidebarApi {
  open: (item: BasketItem, added: boolean, errorMsg?: string) => void;
  close: () => void;
}

export interface IBasketItemSidebarProps {
  ref: MutableRefObject<IBasketItemSidebarApi>;
}

const ResellerSelector: FC<{ formik: FormikProps<RegistrationAddressFormValues> }> = (props) => {
  const { formik } = props;
  const { t } = useTranslation();
  const { data: resellers } = useGetResellerForDistributor();

  if (!resellers) {
    return (
      <MaterialUiSelect name="reseller" label={t("Forms.Reseller")} size="small" disabled={true} fullWidth value="" />
    );
  }

  return (
    <SelectField formik={formik} name="reseller" label={t("Forms.Reseller")} size="small" keepSpace={false}>
      <MenuItem value="0">
        <em>None</em>
      </MenuItem>
      {resellers &&
        resellers.map((reseller) => {
          return (
            <MenuItem key={`reseller-${reseller.uid}`} value={reseller.uid}>
              {reseller.name}
            </MenuItem>
          );
        })}
    </SelectField>
  );
};

export const BasketItemSidebar: ForwardRefExoticComponent<
  Omit<IBasketItemSidebarProps, "ref"> & RefAttributes<IBasketItemSidebarApi>
> = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [basketItem, setBasketItem] = useState<BasketItem>();
  const [justAdded, setJustAdded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const addressRef = useRef<ICollapsableContentApi>(null);
  const [addressTouched, setAddressTouched] = useState<boolean>(false);
  const [is365Product, set365Product] = useState<boolean>(false);
  const [isBusinessProduct, setIsBusinessProduct] = useState<boolean>(false);
  const [isNewPurchase, setIsNewPurchase] = useState<boolean>(true);
  const isDistributor = UserUtils.hasPermission(["distributor"], user);
  const isLatamUser = UserUtils.hasPermission(["reseller_latam", "distributor_latam"], user);
  const [redirectToBasket, setRedirectToBasket] = useState<boolean>(false);
  const location = useLocation();
  const queryClient = useQueryClient();
  const { data: countries } = useGetCountries();

  const validationSchema = isNewPurchase
    ? getValidationSchema(t, user, isBusinessProduct, is365Product, addressTouched)
    : null;

  const formik = useFormik({
    initialValues: {} as RegistrationAddressFormValues,
    validationSchema: validationSchema,
    validateOnChange: !is365Product,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const { comment, reseller, ...registrationAddress } = values;
      const params: Partial<BasketItem> = { comment: comment ?? "" };
      if (isNewPurchase) {
        params.registrationAddress = registrationAddress ?? null;
      }

      if (isDistributor) {
        if (isNewPurchase) {
          params.registrationAddress = registrationAddress ?? null;
          params.registrationAddress.reseller = reseller;
        } else {
          params.registrationAddress = { ...basketItem, reseller: reseller };
        }
      }

      BasketItemApi.change(basketItem?.uid ?? 0, params)
        .then((updatedBasket) => {
          queryClient.setQueryData([KEY_BASKET_GET], updatedBasket);
          enqueueSnackbar(t("Common.SaveSuccess"), { variant: "success" });
          setErrorMessage("");
          if (redirectToBasket) {
            navigate("/app/shop/basket");
          }
          setOpen(false);
        })
        .catch(() => {
          enqueueSnackbar(t(`Common.Error`), { variant: "error" });
        });
    },
  });

  // Nach Submit die Adresse ausklappen, wenn Form nicht valide ist
  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      addressRef.current?.setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitCount]);

  useEffect(() => {
    let newValueForAddressTouched = false;
    let allFieldsEmpty = true;
    const addressFields: Array<keyof RegistrationAddressFormValues> = [
      "company",
      "firstName",
      "lastName",
      "street",
      "postcode",
      "addition",
      "city",
      "country",
      "email",
    ];
    addressFields.forEach((fieldName) => {
      if (formik.values[fieldName]) {
        allFieldsEmpty = false;
      }
      if (formik.values[fieldName] != formik.initialValues[fieldName]) {
        newValueForAddressTouched = true;
      }
    });

    if (allFieldsEmpty) {
      // Wenn alle Felder leer sind, wird angenommen, dass die Adresse gelöscht werden soll. Also keine Validierung durch
      // addressTouched anstoßen
      newValueForAddressTouched = false;
    }
    setAddressTouched(newValueForAddressTouched);
  }, [formik.values, formik.initialValues]);

  const closeSidebar = () => {
    setOpen(false);
  };

  const openSidebar = (item: BasketItem, added: boolean, errorMsg?: string) => {
    const type = item.articleVariantType;
    const isBusiness =
      type == ArticleVariantType.B2B_NEW ||
      type == ArticleVariantType.B2B_UPGRADE ||
      type == ArticleVariantType.B2B_EXTEND ||
      type == ArticleVariantType.B2B_RENEWAL;
    const isNew =
      type === ArticleVariantType.B2B_NEW ||
      type === ArticleVariantType.B2C_NEW ||
      type === ArticleVariantType.ADDON_NEW;
    setJustAdded(added);
    setErrorMessage(errorMsg ?? "");
    setIsBusinessProduct(isBusiness);
    set365Product(item.abbreviation == "BM");
    setBasketItem(item);
    setIsNewPurchase(isNew);

    const resetData = isNew
      ? {
          company: item?.registrationAddress?.company || "",
          firstName: item?.registrationAddress?.firstName || "",
          lastName: item?.registrationAddress?.lastName || "",
          street: item?.registrationAddress?.street || "",
          postcode: item?.registrationAddress?.postcode || "",
          addition: item?.registrationAddress?.addition || "",
          city: item?.registrationAddress?.city || "",
          country: item?.registrationAddress?.country || "",
          email: item?.registrationAddress?.email || "",
          register: item?.registrationAddress?.register || false,
          comment: item?.comment || "",
          reseller: item?.registrationAddress?.reseller || 0,
        }
      : { comment: item?.comment || "" };

    if (isDistributor && !isNew) {
      resetData.reseller = item?.registrationAddress?.reseller || 0;
    }

    formik.resetForm({
      values: resetData,
    });
    setOpen(true);
  };

  useEffect(() => {
    closeSidebar();
  }, [location]);

  useImperativeHandle(ref, () => ({
    open: openSidebar,
    close: closeSidebar,
  }));

  return (
    <Sidebar anchor="right" open={open}>
      <SidebarHeader>
        <Typography variant="h4">{t("Basket.Edit")}</Typography>
        <IconButton onClick={closeSidebar}>
          <CloseIcon />
        </IconButton>
      </SidebarHeader>

      {justAdded && (
        <Alert
          icon={<CheckCircleOutlinedIcon />}
          severity="success"
          variant="outlined"
          sx={{ mt: 2, mr: 2, mb: 0, ml: 2 }}
        >
          {t("Basket.Messages.ItemAddedToBasket")}
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" variant="outlined" sx={{ mt: 2, mr: 2, mb: 0, ml: 2 }}>
          {errorMessage}
        </Alert>
      )}
      {basketItem && (
        <>
          <Box sx={{ mt: 1, pt: 2, pr: 2, pb: 6, pl: 2 }}>
            <Typography>
              <strong>{basketItem.title}</strong>
            </Typography>
            <Typography sx={{ ml: 2, mb: 4 }}>
              {t("Common.Devices")}: {basketItem.licenseCount}
              {", "}
              {!basketItem.validUntil
                ? t("Basket.Duration", { duration: basketItem.duration })
                : `${t("Basket.ExpirationDate")}: ${i18n.format(new Date(basketItem.validUntil), "P", i18n.language)}`}
            </Typography>
            <form noValidate>
              <TextField
                formik={formik}
                label={t("Basket.Sidebar.Comment")}
                name="comment"
                type="string"
                size="small"
                multiline
                rows={2}
                InputLabelProps={{ shrink: true }}
                placeholder={t("Basket.Sidebar.CommentPlaceholder")}
                keepSpace={false}
                inputProps={{
                  maxLength: 100,
                }}
              />
              {isDistributor && <ResellerSelector formik={formik} />}
              {isNewPurchase && (
                <>
                  {!isDistributor && (
                    <FormGroup>
                      <CheckBox
                        formik={formik}
                        name="register"
                        value="1"
                        disabled={basketItem.registerObligatory}
                        checked={formik.values.register || basketItem.registerObligatory}
                        title={t("Basket.RegisterLicense.Title")}
                        description={t("Basket.RegisterLicense.Description")}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addressRef.current?.setCollapsed(false);
                          }
                          formik.handleChange(e);
                        }}
                      />
                    </FormGroup>
                  )}
                  <CollapsableContent
                    headline={`${t("Forms.CustomerData")}${isBusinessProduct || formik.values.register ? "*" : ""}`}
                    initialCollapsed={
                      !(
                        isBusinessProduct ||
                        basketItem.registerObligatory ||
                        basketItem.registrationAddress?.register ||
                        basketItem.registrationAddress?.email ||
                        errorMessage
                      )
                    }
                    ref={addressRef}
                  >
                    <>
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            formik={formik}
                            label={t("Forms.CompanyName")}
                            name="company"
                            type="string"
                            size="small"
                            keepSpace={false}
                            required={isBusinessProduct}
                            dataTestid={"basket-item-sidebar_company"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            formik={formik}
                            label={t("Forms.FirstName")}
                            name="firstName"
                            type="string"
                            size="small"
                            required={!isBusinessProduct}
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_first-name"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            formik={formik}
                            label={t("Forms.LastName")}
                            name="lastName"
                            type="string"
                            size="small"
                            required={!isBusinessProduct}
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_last-name"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            formik={formik}
                            label={t("Forms.Address")}
                            name="street"
                            type="string"
                            size="small"
                            required={true}
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_street"}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            formik={formik}
                            label={t("Forms.Zip")}
                            name="postcode"
                            type="string"
                            size="small"
                            required={!isLatamUser}
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_postcode"}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <TextField
                            formik={formik}
                            label={t("Forms.City")}
                            name="city"
                            type="string"
                            size="small"
                            required={true}
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_city"}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            formik={formik}
                            label={t("Forms.AddressAddition")}
                            name="addition"
                            type="string"
                            size="small"
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_address-addition"}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SelectField
                            formik={formik}
                            name="country"
                            label={t("Forms.Country")}
                            size="small"
                            required={true}
                            keepSpace={false}
                            data-testid={"basket-item-sidebar_country"}
                          >
                            <MenuItem value={""} key={`country-none`}>
                              ---
                            </MenuItem>
                            {countries &&
                              countries.map((country: string) => {
                                return (
                                  <MenuItem value={country} key={`country-${country}`}>
                                    {getCountryData("iso3", country).shortName}
                                  </MenuItem>
                                );
                              })}
                          </SelectField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            formik={formik}
                            label={t("Forms.CustomerEmail")}
                            name="email"
                            type="email"
                            size="small"
                            required={true}
                            keepSpace={false}
                            dataTestid={"basket-item-sidebar_email"}
                          />
                        </Grid>
                      </Grid>
                    </>
                  </CollapsableContent>
                </>
              )}
            </form>
          </Box>
          <Stack spacing={2} direction="row" className="Sticky-Footer">
            {justAdded &&
              (formik.dirty ? (
                <Button
                  onClick={() => {
                    setRedirectToBasket(true);
                    formik.handleSubmit();
                  }}
                  fullWidth
                  type="button"
                  variant="contained"
                  disabled={!formik.dirty || formik.isSubmitting}
                  data-testid={"basket-item-sidebar_save-and-basket"}
                >
                  {t("Basket.Sidebar.BtnSaveAndBasket")}
                </Button>
              ) : (
                <Button fullWidth type="button" variant="contained" component={Link} to="/app/shop/basket">
                  {t("Basket.Sidebar.BtnBasket")}
                </Button>
              ))}
            <Button
              type="submit"
              sx={{ pr: 4, pl: 4, flexBasis: justAdded ? "content" : "auto" }}
              onClick={() => {
                setRedirectToBasket(false);
                formik.handleSubmit();
              }}
              fullWidth={!justAdded}
              variant={justAdded ? "outlined" : "contained"}
              disabled={!formik.dirty || formik.isSubmitting}
              data-testid={"basket-item-sidebar_save"}
            >
              {t("Forms.Save")}
            </Button>
          </Stack>
        </>
      )}
    </Sidebar>
  );
});
