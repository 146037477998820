import { FC } from "react";
import { Chip } from "@mui/material";
import * as React from "react";

const DownloadsCategoryChips: FC<{ categories: string[] }> = (props) => {
  const { categories } = props;

  return (
    <>
      {categories.map((category: string, index: number) => {
        return <Chip key={index} label={category} sx={{ mt: 1, mr: 1 }} size="small" variant="outlined" />;
      })}
    </>
  );
};

export default DownloadsCategoryChips;
