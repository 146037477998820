import { i18n as i18next } from "i18next";
import { User } from "../types/user";
import { LicenseCenterRow } from "../types/license";

class FormatUtils {
  /**
   * Format the given price value into the user's currency format.
   *
   * @param {Number} value
   * @param {i18n} i18n
   * @param {User} user
   * @param {boolean} divideBy100
   */
  static getFormattedPrice(value: number, i18n: i18next, user: User | undefined, divideBy100 = true): string {
    return Intl.NumberFormat(i18n.language, { style: "currency", currency: user ? user.currencyCode : "EUR" }).format(
      divideBy100 ? value / 100 : value
    );
  }

  static getFormattedPercent(value: number, i18n: i18next): string {
    return Intl.NumberFormat(i18n.language, {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / 10000);
  }

  static getFormattedLicenseLabel = (row: LicenseCenterRow) => {
    let title = row.registrationNumber;
    const name = (row.company ? row.company : `${row.firstName} ${row.lastName}`).trim();
    if (name) {
      title = `${title} (${name})`;
    }
    return title;
  };
}

export default FormatUtils;
