import { TFunction } from "i18next";
import * as Yup from "yup";
import { ObjectSchema } from "yup";

export type ResetPasswordFormValues = {
  newPassword: string;
  newPasswordConfirm: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<ResetPasswordFormValues> => {
  return Yup.object().shape({
    newPassword: Yup.string()
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.Password"),
        }) as string
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W+]).{8,255}$/,
        t(
          "Forms.PasswordInvalid",
          "Must be at least 8 characters long and contain one lowercase, one uppercase letter, one special character and one number."
        ) as string
      ),
    newPasswordConfirm: Yup.string()
      .required(t("Forms.PasswordsNotEqual") ?? "")
      .oneOf([Yup.ref("newPassword")], t("Forms.PasswordsNotEqual") ?? ""),
  });
};
