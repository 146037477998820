import { Button, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../components/ContentBox";
import { Link } from "react-router-dom";

const ProjectsOverview: FC = () => {
  const { t } = useTranslation();

  return (
    <ContentBox>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        {t("Projects.Overview.Title")}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t("Projects.Overview.Paragraph1")}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t("Projects.Overview.Paragraph2")}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t("Projects.Overview.Paragraph3")}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t("Projects.Overview.Paragraph4")}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t("Projects.Overview.Paragraph5")}
      </Typography>
      <Typography variant="body1" mb={1}>
        {t("Projects.Overview.Paragraph6")}
      </Typography>
      <Grid container justifyContent="flex-end">
        <Grid item xs="auto" mt={2}>
          <Button variant="contained" to="/app/projects/form" component={Link} size="large">
            {t("Projects.Overview.FormBtn")}
          </Button>
        </Grid>
      </Grid>
    </ContentBox>
  );
};

export default ProjectsOverview;
