import { Box, Grid } from "@mui/material";
import React, { FC, MouseEventHandler, ReactNode } from "react";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import ContentBox from "../../../components/ContentBox";

interface ISupportTileProps {
  action?: MouseEventHandler;
  classes?: string;
  content: ReactNode;
  cta?: string;
  headline?: string;
  link?: string;
  subline?: string;
}

const SupportTile: FC<ISupportTileProps> = (props: ISupportTileProps) => {
  const { content, headline, subline, cta, action, link } = props;

  return (
    <Grid item xs={12} md={4} xl={3}>
      <ContentBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          "& hr": {
            width: "100%",
          },
        }}
        sxChild={{
          pt: 0,
          pb: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
        headline={headline}
      >
        <Box>
          {subline && <Typography sx={{ flexGrow: 1, pb: 2 }}>{subline}</Typography>}
          {content}
        </Box>
        <Box sx={{ pt: 2, display: "flex", justifyContent: "flex-end", width: "100%" }}>
          {action ? (
            <Button size="small" variant="contained" onClick={action}>
              {cta}
            </Button>
          ) : (
            link && (
              <Button size="small" variant="contained" href={link} target="_blank">
                {cta}
              </Button>
            )
          )}
        </Box>
      </ContentBox>
    </Grid>
  );
};

export default SupportTile;
