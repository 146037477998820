/*
  React 18 introduced a huge breaking change, when in Strict Mode, all components mount and unmount, then mount again.
  The reason for this is for paving the way for a feature that isn't in React yet, so as far as React 18 is concerned,
  there is no reason.

  For React Hooks in React 18, this means a useEffect() with zero dependencies will be executed twice.
  Here is a custom hook that can be used instead of useEffect(), with zero dependencies, that will give the
  old (pre React 18) behaviour back, i.e. it works around the breaking change.

  // instead of this:
  useEffect( ()=> {
    console.log('my effect is running');
      return () => console.log('my effect is destroying');
  }, []);

  // do this:
  useEffectOnce( ()=> {
    console.log('my effect is running');
      return () => console.log('my effect is destroying');
  });

  From: https://blog.ag-grid.com/avoiding-react-18-double-mount/
*/
import { useEffect, useRef, useState } from "react";

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const effectFn = useRef<() => void | (() => void)>(effect);
  const destroyFn = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const rendered = useRef(false);
  const [, setVal] = useState<number>(0);

  if (effectCalled.current) {
    rendered.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!rendered.current) {
        return;
      }

      // otherwise this is not a dummy destroy, so call the destroy func
      if (destroyFn.current) {
        destroyFn.current();
      }
    };
  }, []);
};
