import React, {
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
  FC,
} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import { LicenseCenterRow, LicenseChangeParameters, LicenseSettings } from "../../../types/license";
import { Alert, Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { CheckBox, SelectField, TextField } from "../../../components/formFields";
import { TextField as MuiTextField } from "@mui/material";
import { useFormik } from "formik";
import { EditLicenseFormValues, getValidationSchema } from "../formValidations/editLicense";
import { LicenseApi, BasketItemApi } from "../../../api";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { i18n as i18next } from "i18next";
import { saveAs } from "file-saver";
import FormatUtils from "../../../utils/FormatUtils";
import useAuth from "../../../contextProviders/Authentication";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KEY_BASKET_GET, useInvalidateQueryClientData } from "../../../lib/queryClient";
import { SupportedLocale } from "../../../locales";
import { useShop } from "../../../contextProviders/Shop";
import { HierarchicalPriceData } from "../../../types/shop";
import { RebatePopover } from "./RebatePopover";
import _ from "lodash";

export interface ILicenseCenterLicenseEditDialogApi {
  handleShowDialog: (licenseCenterRow: LicenseCenterRow) => void;
}

interface ILicenseCenterLicenseEditDialogProps {
  ref: MutableRefObject<ILicenseCenterLicenseEditDialogApi>;
}

type DialogState = "FORM" | "BUYBOX" | "LOADING";

const getNewExpirationDate = (limitDate: Date, duration: number, i18n: i18next) => {
  let baseDate = new Date(limitDate);
  const now = new Date();
  if (baseDate < now) {
    baseDate = now;
  }
  const newDate = baseDate.setMonth(baseDate.getMonth() + duration);
  return i18n.format(new Date(newDate), "P", i18n.language);
};

const BuyBox: FC<{
  calculatedPrices: HierarchicalPriceData[];
  dialogState: DialogState;
  changeParams: LicenseChangeParameters;
  handleCloseDialog: () => void;
}> = (props) => {
  const { state: shopState } = useShop();
  const { calculatedPrices, dialogState, changeParams, handleCloseDialog } = props;
  const textColor = dialogState === "BUYBOX" ? "text.primary" : "text.disabled";
  const buttonsDisabled = dialogState !== "BUYBOX" || shopState.emulatedUser !== null;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { invalidatePayments } = useInvalidateQueryClientData();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const userForCalculation = shopState.emulatedUser ?? user;

  const getPriceSum = (p: HierarchicalPriceData[], priceType: "finalPrice" | "retailPrice") => {
    const sum = _.sumBy(p, function (o) {
      return o[priceType];
    });
    return FormatUtils.getFormattedPrice(sum, i18n, userForCalculation, true);
  };

  const downloadQuote = async (params: LicenseChangeParameters) => {
    const pdf = await LicenseApi.getQuote(params, i18n.language as SupportedLocale);
    if (pdf !== null) {
      saveAs(pdf, `GDATA_Price_Information.pdf`);
    } else {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    }
  };

  const addToBasket = useMutation({
    mutationFn: (paramsLicense: LicenseChangeParameters) =>
      BasketItemApi.addChangedLicenseToBasket(paramsLicense).then(async (response) => {
        await invalidatePayments();
        return response;
      }),
    onSuccess: (basket) => {
      queryClient.setQueryData([KEY_BASKET_GET], basket);
      enqueueSnackbar(t("LicenseCenter.EditLicense.SuccessMsg"), { variant: "success" });
      handleCloseDialog();
    },
    onError: () => {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    },
  });

  return (
    <Box sx={{ mt: 2, pt: 2, borderTop: 1, borderColor: "divider", textAlign: "right" }}>
      <>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", color: textColor }}
          data-testid={"license-center_edit-license_purchasing-price"}
        >
          {t("Common.PurchasingPrice")}&nbsp;{getPriceSum(calculatedPrices, "finalPrice")}
        </Typography>
        <Typography sx={{ color: textColor }}>
          {t("Common.SellingPrice")}&nbsp;{getPriceSum(calculatedPrices, "retailPrice")}
        </Typography>
      </>
      <RebatePopover
        id={1}
        prices={calculatedPrices}
        disabled={dialogState !== "BUYBOX"}
        label={t("Shop.PriceBox.YourRebate")}
      />
      <Box sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          disabled={buttonsDisabled}
          sx={{ mr: 1 }}
          onClick={() => downloadQuote(changeParams)}
        >
          {t("LicenseCenter.EditLicense.SaveBtn")}
        </Button>
        <Button
          variant="contained"
          disabled={buttonsDisabled}
          onClick={() => addToBasket.mutate(changeParams)}
          data-testid={"license-center_edit-license_add-to-basket"}
        >
          {t("LicenseCenter.EditLicense.AddToBasketBtn")}
        </Button>
      </Box>
    </Box>
  );
};

const LicenseCenterLicenseEditDialog: ForwardRefExoticComponent<
  Omit<ILicenseCenterLicenseEditDialogProps, "ref"> & RefAttributes<ILicenseCenterLicenseEditDialogApi>
> = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [licenseCenterRow, setLicenseCenterRow] = useState<LicenseCenterRow | null>(null);
  const [calculatedPrices, setCalculatedPrices] = useState<HierarchicalPriceData[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [dialogState, setDialogState] = useState<DialogState>("LOADING");
  const { state: shopState } = useShop();

  const [settings, setSettings] = useState<LicenseSettings>({
    allowedDurations: [],
    allowedProducts: [],
    minLicenseCount: 0,
    maxLicenseCount: 0,
    defaultLimit: new Date(),
    subscriptionAllowed: false,
    messages: [],
  });

  const [initialValues, setInitialValues] = useState<EditLicenseFormValues>({
    productCode: "",
    licenseCount: 0,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    duration: "", // default Wert muss ein leerer String sein, damit im Form nichts selektiert wird
    subscription: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: getValidationSchema(t, settings),
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (licenseCenterRow) {
        setDialogState("LOADING");
        setSubmitting(true);
        const params: LicenseChangeParameters & { customerNo?: string } = {
          registrationNumber: licenseCenterRow.registrationNumber,
          duration: values.duration,
          productCode: values.productCode,
          licenseCount: values.licenseCount,
          subscription: values.subscription,
        };
        if (shopState.emulatedUser) {
          params.customerNo = shopState.emulatedUser.customerNo;
        }
        LicenseApi.calculatePrice(params)
          .then((priceData) => {
            setCalculatedPrices(priceData);
            if (priceData === null) {
              enqueueSnackbar(t("LicenseCenter.EditLicense.Alert.NoPriceFound"), { variant: "info" });
              setDialogState("FORM");
            } else {
              setDialogState("BUYBOX");
            }
          })
          .catch(() => {
            setDialogState("FORM");
            setCalculatedPrices(null);
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          })
          .finally(() => {});
      }
    },
  });

  const handleShowDialog = (data: LicenseCenterRow) => {
    setLicenseCenterRow(data);
    setCalculatedPrices(null);
    setShowDialog(true);
    LicenseApi.getSettings(data.registrationNumber)
      .then((response: LicenseSettings) => {
        setSettings(response);
        const newValues = {
          productCode: data.productCode,
          licenseCount: data.licenseCount,
          duration: response.defaultLimit ? 0 : response.allowedDurations[0],
          subscription: false,
        };
        formik.setValues(newValues);
        setInitialValues(newValues);
        setDialogState("FORM");
      })
      .catch(() => {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        setShowDialog(false);
      });
  };

  useImperativeHandle(ref, () => ({
    handleShowDialog: handleShowDialog,
  }));

  useEffect(() => {
    setDialogState("FORM");
    // Wenn keine Verlängerung gewählt, darf Abo nicht angehakt sein
    if (!formik.values.duration) {
      formik.values.subscription = false;
    }
  }, [formik.values]);

  const handleCloseDialog = () => {
    setShowDialog(false);
    formik.resetForm();
  };

  if (!licenseCenterRow) {
    return <></>;
  }
  return (
    <Dialog
      show={showDialog}
      handleClose={handleCloseDialog}
      maxWidth="md"
      title={t("LicenseCenter.EditLicense.Title", { label: FormatUtils.getFormattedLicenseLabel(licenseCenterRow) })}
      content={
        <>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 1, mb: 3 }}>{t("LicenseCenter.EditLicense.Configuration")}</Typography>
                <MuiTextField
                  label={t("Forms.Product")}
                  value={licenseCenterRow.productName}
                  disabled
                  fullWidth
                  sx={{ mb: 4 }}
                />
                <MuiTextField
                  label={t("Forms.LicenseVolume")}
                  value={licenseCenterRow.licenseCount}
                  disabled
                  fullWidth
                  sx={{ mb: 4 }}
                />
                <MuiTextField
                  label={t(
                    licenseCenterRow.limitDate
                      ? "LicenseCenter.EditLicense.ExpirationDate"
                      : "LicenseCenter.EditLicense.InvoiceDate"
                  )}
                  value={i18n.format(licenseCenterRow.limitDate ?? licenseCenterRow.invoiceDate, "P", i18n.language)}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 1, mb: 3 }}>{t("LicenseCenter.EditLicense.NewConfiguration")}</Typography>
                <SelectField
                  formik={formik}
                  label={t("LicenseCenter.EditLicense.Form.NewProduct")}
                  name="productCode"
                  disabled={dialogState === "LOADING"}
                  required
                >
                  {settings.allowedProducts.map((product: string) => {
                    return (
                      <MenuItem value={product} key={`productItem-${product}`}>
                        {t(`Products.${product}`)}
                      </MenuItem>
                    );
                  })}
                </SelectField>
                <TextField
                  formik={formik}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: settings.minLicenseCount,
                    max: settings.maxLicenseCount,
                  }}
                  label={t("LicenseCenter.EditLicense.Form.NewLicenseCount")}
                  name="licenseCount"
                  type={"number"}
                  disabled={dialogState === "LOADING"}
                  required
                  data-testid={"license-center_edit-license_new-license-count"}
                />
                <SelectField
                  formik={formik}
                  label={t(
                    licenseCenterRow.limitDate
                      ? "LicenseCenter.EditLicense.Form.NewExpirationDate"
                      : "LicenseCenter.EditLicense.Form.NewInvoiceDate"
                  )}
                  name="duration"
                  disabled={dialogState === "LOADING"}
                  required
                  data-testid={"license-center_edit-license_duration"}
                >
                  {settings.defaultLimit && (
                    <MenuItem value={0} key="duration-0">
                      {i18n.format(settings.defaultLimit, "P", i18n.language)}
                    </MenuItem>
                  )}
                  {settings.allowedDurations.map((allowedDuration) => {
                    return (
                      <MenuItem value={allowedDuration} key={`duration-${allowedDuration}`}>
                        {(licenseCenterRow.limitDate instanceof Date || licenseCenterRow.invoiceDate instanceof Date) &&
                          i18n.format(
                            getNewExpirationDate(
                              licenseCenterRow.limitDate !== null
                                ? (licenseCenterRow.limitDate as Date)
                                : (licenseCenterRow.invoiceDate as Date),
                              allowedDuration,
                              i18n
                            ),
                            "P",
                            i18n.language
                          )}{" "}
                        (+ {allowedDuration} {t("Common.Months")})
                      </MenuItem>
                    );
                  })}
                </SelectField>

                {settings.subscriptionAllowed && (
                  <Box sx={{ mt: "-16px", mb: "16px", zIndex: 200, position: "relative" }}>
                    <CheckBox
                      formik={formik}
                      name="subscription"
                      value="1"
                      checked={formik.values.subscription == true}
                      title={t("LicenseCenter.EditLicense.SubscriptionPriceText", { rebate: 5 })}
                      disabled={dialogState === "LOADING" || formik.values.duration == 0}
                    />
                  </Box>
                )}
              </Grid>
              {settings.messages.length > 0 && (
                <Grid item xs={12}>
                  {settings.messages.map((message) => {
                    return (
                      <Alert
                        variant="outlined"
                        severity={message.severity}
                        sx={{ mt: -1, mb: 3 }}
                        key={`message-${message.code}`}
                      >
                        {t(`LicenseCenter.EditLicense.Messages.${message.code}`)}
                      </Alert>
                    );
                  })}
                </Grid>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item xs="auto">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={dialogState !== "FORM" || (!formik.dirty && settings.defaultLimit != null)}
                    loading={dialogState === "LOADING"}
                    data-testid={"license-center_edit-license_calculate"}
                  >
                    {t("LicenseCenter.EditLicense.Form.Calculate")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
          {calculatedPrices && (
            <BuyBox
              calculatedPrices={calculatedPrices}
              dialogState={dialogState}
              handleCloseDialog={handleCloseDialog}
              changeParams={{
                ...formik.values,
                registrationNumber: licenseCenterRow.registrationNumber,
              }}
            />
          )}
        </>
      }
    />
  );
});

export default LicenseCenterLicenseEditDialog;
