import React, { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CrossgradesApi } from "../../../api";
import { CloudUpload } from "@mui/icons-material";

interface IFileSelectorProps {
  onSelected: (file: File) => void;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export const FileSelector: React.FC<IFileSelectorProps> = ({ onSelected, setActiveStep }) => {
  const onSelectedRef = useRef(onSelected);
  const { t } = useTranslation();

  onSelectedRef.current = onSelected;

  const dropHandler = useCallback(
    (acceptedFiles: File[]) => {
      // silently ignore if nothing to do
      if (acceptedFiles.length < 1) {
        return;
      }
      const file = acceptedFiles[0];
      CrossgradesApi.upload(file).then(() => {
        setActiveStep(2);
        onSelected(file);
      });
    },
    [onSelected, setActiveStep]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: dropHandler,
    accept: { "application/pdf": [".pdf"] },
    multiple: false,
  });
  return (
    <Box
      sx={{
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        p: "4em",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "4px",
      }}
      {...getRootProps()}
    >
      <CloudUpload fontSize="large" />
      <input {...getInputProps()} data-testid={"file-selector_input"} />
      <Box>{isDragActive ? t("Crossgrades.DropzoneDrop") : t("Crossgrades.DropzoneInfo")}</Box>
    </Box>
  );
};
