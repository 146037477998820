import axios from "../lib/axiosForApi";

export type MailFormValues = {
  subject: string;
  message: string;
};

type MailType = "feedback" | "order";

class MailApi {
  async submitFeedbackForm(formData: MailFormValues): Promise<boolean> {
    return this.submitForm(formData, "feedback");
  }

  async submitOrderForm(formData: MailFormValues): Promise<boolean> {
    return this.submitForm(formData, "order");
  }

  async submitForm(formData: MailFormValues, type: MailType): Promise<boolean> {
    const response = await axios.post("/mail/submit", {
      subject: formData.subject,
      message: formData.message,
      type: type,
    });
    return response.status === 200;
  }
}

export default new MailApi();
