import { useMemo, useState } from "react";
import type { FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, Button, Collapse, ListItem } from "@mui/material";
import type { ListItemProps } from "@mui/material";
import ChevronDownIcon from "../icons/ChevronDown";
import ChevronRightIcon from "../icons/ChevronRight";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { styled } from "@mui/material/styles";
import useAuth from "../contextProviders/Authentication";
import UserUtils from "../utils/UserUtils";
import { UserRoles } from "../types/user";

interface INavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path: string;
  title: string;
  dataTestId: string;
  allowedRoles?: UserRoles;
}

const PREFIX = "NavItem";
const StyledListItem = styled(ListItem)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

const classes = {
  button: `${PREFIX}-button`,
};
const StyledButtonRoot = styled("div")(() => ({
  width: "100%",
  [`& .${classes.button}`]: {
    justifyContent: "flex-start",
    textAlign: "left",
    pr: "8px",
    textTransform: "none",
    width: "100%",
  },
}));

const NavItem: FC<INavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    dataTestId,
    allowedRoles,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp ?? false);

  const { user } = useAuth();
  const hasPermission = useMemo(() => UserUtils.hasPermission(allowedRoles, user), [allowedRoles, user]);
  if (!hasPermission) {
    return null;
  }
  const isExternalLink = path.startsWith("http");

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };
  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <StyledListItem
        disableGutters
        sx={{
          display: "block",
        }}
        {...other}
      >
        <StyledButtonRoot>
          <Button
            endIcon={!open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
            onClick={handleToggle}
            startIcon={icon}
            sx={{
              color: "text.primary",
              fontWeight: "fontWeightMedium",
              pl: `${paddingLeft}px`,
              py: "12px",
            }}
            variant="text"
            className={classes.button}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
        </StyledButtonRoot>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  }

  const buttonProps = {
    variant: "text",
    startIcon: icon,
    "data-testid": dataTestId,
    className: classes.button,
    sx: {
      color: "text.primary",
      fontWeight: "fontWeightMedium",
      pl: `${paddingLeft}px`,
      py: "6px",
      fontSize: "0.9375rem",
      ...(active && {
        color: "primary.main",
        fontWeight: "fontWeightBold",
        "& svg": {
          color: "primary.main",
        },
      }),
    },
  };

  // Leaf
  return (
    <StyledListItem
      disableGutters
      sx={{
        display: "flex",
      }}
    >
      <StyledButtonRoot>
        {!isExternalLink ? (
          <Button {...buttonProps} component={RouterLink} variant="text" to={path}>
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
        ) : (
          <Button {...buttonProps} variant="text" target="_blank" href={path}>
            <Box sx={{ flexGrow: 1 }}>
              {title} <LaunchOutlinedIcon sx={{ height: ".5em" }} />
            </Box>
            {info}
          </Button>
        )}
      </StyledButtonRoot>
    </StyledListItem>
  );
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
