import React, { FC } from "react";
import { RawHeadline } from "../base";
import { ContentElement } from "../../../../types/typo3Page";

export const Headline: FC<{ data: ContentElement }> = ({ data }) => {
  return (
    <RawHeadline
      header={data.content.header}
      headerLayout={data.content.headerLayout}
      subheader={data.content.subheader}
    />
  );
};

export default Headline;
