import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { Button, Grid, Typography } from "@mui/material";
import useAuth from "../../contextProviders/Authentication";
import axios from "../../lib/axiosForApi";
import ContentBox from "../../components/ContentBox";

class SampleSubmissionApi {
  async getEncodedData(): Promise<{ data: { key: string } }> {
    return axios.get("/sample-submission/encoded-data");
  }
}

const SampleSubmission: FC = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [encodedData, setEncodedData] = useState<{ key: string } | null>(null);

  function openSampleSubmissionPopup(event: React.MouseEvent<HTMLElement>, value: string) {
    const popupWidth = "1000";
    const popupHeight = "925";

    let url = process.env.REACT_APP_SAMPLE_SUBMISSION_URL;

    const encodedKey = encodedData?.key || "";

    switch (value) {
      case "file": {
        url += "/sample";
        break;
      }
      case "app": {
        url += "/mobile";
        break;
      }
      case "url": {
        url += "/url";
        break;
      }
      default:
        break;
    }
    url += `?pi=${encodedKey}`;
    if (i18n.language === "de") {
      url += "&lang=de";
    } else {
      url += "&lang=en";
    }

    const vHWin = window.open(url, "FEopenLink", `scrollbars=1,width=${popupWidth},height=${popupHeight},`);
    vHWin?.focus();
    return false;
  }

  useEffect(() => {
    new SampleSubmissionApi().getEncodedData().then((response) => {
      setEncodedData(response.data);
    });
  }, []);

  const renderSampleBox = (title: string, text: string, key: string) => {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <ContentBox headline={title}>
          <Typography sx={{ minHeight: 120, mb: 2 }}>{text}</Typography>
          <Button
            variant="contained"
            onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => openSampleSubmissionPopup(ev, key)}
          >
            {t("SampleSubmission.Submit")}
          </Button>
        </ContentBox>
      </Grid>
    );
  };

  return encodedData && user ? (
    <Page title={t("SampleSubmission.PageTitle")} subtitle={t("Sidebar.Knowledgebase.Title")}>
      <Grid container spacing={4} justifyContent="flex-center">
        {renderSampleBox(t("SampleSubmission.FileHeader"), t("SampleSubmission.FileText"), "file")}
        {renderSampleBox(t("SampleSubmission.UrlHeader"), t("SampleSubmission.UrlText"), "url")}
        {renderSampleBox(t("SampleSubmission.AppHeader"), t("SampleSubmission.AppText"), "app")}
      </Grid>
    </Page>
  ) : (
    <></>
  );
};

export default SampleSubmission;
