import type { FC, ReactNode } from "react";
import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../contextProviders/Authentication";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isInitialized, isAuthenticated, isMfaEnabled, isMfaVerified } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;

  if (!isInitialized) {
    return <></>;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: pathname }} />;
  }

  if (isMfaEnabled && !isMfaVerified) {
    return <Navigate to="/mfa/verify/one-time-password" state={{ from: pathname }} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
