import React, { FC } from "react";
import Page from "../../components/Page";
import { useGetDashboardTiles } from "../../lib/queryClient";
import DashboardTile from "./components/DashboardTile";
import { Box, Skeleton, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const DashboardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto",
  gridColumnGap: "2rem",
  gridRowGap: "2rem",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    gridAutoFlow: "dense",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  },
}));

const GridLoader: FC = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="100%"
        sx={{ aspectRatio: "1/1", display: "block", height: "auto", gridRow: "span 2", gridColumn: "span 2" }}
      />
      <Skeleton variant="rectangular" width="100%" sx={{ aspectRatio: "1/1", display: "block", height: "auto" }} />
      <Skeleton variant="rectangular" width="100%" sx={{ aspectRatio: "1/1", display: "block", height: "auto" }} />
      <Skeleton variant="rectangular" width="100%" sx={{ aspectRatio: "1/1", display: "block", height: "auto" }} />
    </>
  );
};

const Dashboard: FC = () => {
  const { data: tiles, isLoading } = useGetDashboardTiles();
  const { t } = useTranslation();

  return (
    <Page title={t("Sidebar.Overview.Dashboard")} subtitle={t("Sidebar.Overview.Title")} maxWidth={false}>
      <DashboardBox>
        {isLoading ? <GridLoader /> : <>{tiles && tiles.map((tile) => <DashboardTile tile={tile} key={tile.id} />)}</>}
      </DashboardBox>
    </Page>
  );
};

export default Dashboard;
