import { FC, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Typography, Zoom } from "@mui/material";

const StyledPaper = styled(Paper)(() => ({
  maxWidth: "480px",
  position: "relative",
  whiteSpace: "pre-line",
}));

interface LostPasswordContainerProps {
  headline: string;
  subheader: string;
  children?: ReactNode;
}

const LostPasswordContainer: FC<LostPasswordContainerProps> = ({ headline, subheader, children }) => {
  return (
    <Zoom in timeout={500}>
      <StyledPaper>
        <Typography variant="h1" sx={{ mb: 3 }}>
          {headline}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subheader}
        </Typography>
        {children && <Box sx={{ mt: 4 }}>{children}</Box>}
      </StyledPaper>
    </Zoom>
  );
};

export default LostPasswordContainer;
