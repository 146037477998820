import axios from "./../lib/axiosForApi";
import { AppNotification } from "../types/app";

class NotificationsApi {
  async get(limit: number, groupCheck: boolean): Promise<AppNotification[]> {
    const response = await axios.get("/notifications/get", {
      params: { limit: limit, checkGroup: groupCheck ? 1 : 0 },
    });
    return response.data.notifications ?? [];
  }

  async markRead(notificationIds: Array<number>): Promise<void> {
    return axios.post("/notifications/mark-read", { nIds: notificationIds });
  }
}

export default new NotificationsApi();
