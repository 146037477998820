import type { FC } from "react";
import { Container, Box, Grid, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemIcon from "@mui/material/ListItemIcon";
import CallIcon from "@mui/icons-material/Call";
import CopyrightIcon from "@mui/icons-material/Copyright";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PublicIcon from "@mui/icons-material/Public";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { TFunction } from "i18next";
import { styled } from "@mui/material/styles";

interface ISection {
  title: string;
  links: ISectionLink[];
}

interface ISectionLink {
  title: string;
  href: string;
  target?: string;
  icon: JSX.Element;
}

const getSections = (t: TFunction): ISection[] => [
  {
    title: t("Footer.Contact"),
    links: [
      {
        title: "+49 234 9762 999",
        href: "tel:+49 234 9762 999",
        icon: <CallIcon />,
      },
      {
        title: "partner@gdata.de",
        href: "mailto:partner@gdata.de",
        icon: <MailOutlineIcon />,
      },
    ],
  },
  {
    title: t("Footer.Disclaimer"),
    links: [
      {
        title: t("Footer.Imprint"),
        href: t("Footer.ImprintHref"),
        icon: <PublicIcon />,
        target: "_blank",
      },
      {
        title: t("Footer.Privacy"),
        href: t("Footer.PrivacyHref"),
        icon: <VpnKeyIcon />,
        target: "_blank",
      },
      {
        title: t("Footer.Eula"),
        href: t("Footer.EulaHref"),
        icon: <CopyrightIcon />,
        target: "_blank",
      },
    ],
  },
];

const PREFIX = "DefaultFooter";
const classes = {
  header: `${PREFIX}-header`,
  container: `${PREFIX}-container`,
  gridItem: `${PREFIX}-gridItem`,
  navItem: `${PREFIX}-navItem`,
  navItemLink: `${PREFIX}-navItemLink`,
};
const StyledBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(3),
  background: theme.palette.background.paper,
  [`& .${classes.container}`]: {
    margin: "0 auto",
  },
  [`& .${classes.header}`]: {
    fontWeight: 400,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
    marginBottom: "4px",
  },
  [`& .${classes.gridItem}`]: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  [`& .${classes.navItem}`]: {
    padding: 0,
    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
      marginRight: theme.spacing(1),
    },
    "& .MuiListItemText-root": {
      margin: "2px 0",
    },
    "& svg": {
      fontSize: ".875rem",
    },
  },
  [`& .${classes.navItemLink}`]: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
  },
}));

const Footer: FC = (props) => {
  const { t } = useTranslation();
  return (
    <StyledBox {...props}>
      <Container maxWidth="lg">
        <Grid container spacing={0} className={classes.container}>
          {getSections(t).map((section) => (
            <Grid item key={section.title} sm={6} md={3} xs={12} className={classes.gridItem}>
              <Typography className={classes.header} variant="body2">
                {section.title}
              </Typography>
              <List disablePadding dense>
                {section.links.map((link) => (
                  <ListItem disableGutters key={link.title} className={classes.navItem}>
                    {link.icon && <ListItemIcon>{link.icon}</ListItemIcon>}
                    <ListItemText
                      primary={
                        <Link className={classes.navItemLink} href={link.href} target={link.target ?? "_self"}>
                          {link.title}
                        </Link>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default Footer;
