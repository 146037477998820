import Divider from "@mui/material/Divider";
import React from "react";
import * as T3Elements from "./index";
import { ContentElement } from "../../../../types/typo3Page";

function RenderContentElement(element: ContentElement) {
  switch (element.type) {
    case "header":
      return <T3Elements.Headline key={element.id} data={element} />;
    case "text":
      return <T3Elements.Text key={element.id} data={element} />;
    case "html":
      return <T3Elements.Html key={element.id} data={element} />;
    case "table":
      return <T3Elements.Table key={element.id} data={element} />;
    case "divider":
      return <Divider />;
    default:
      return <div key={element.id}>No Rendering definition for type ${element.type}</div>;
  }
}

export default RenderContentElement;
