import React, { FC, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import { Contract, ContractStatus } from "../../../types/contracts";
import { Button, Chip, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { BorderColorOutlined, GetApp, InfoOutlined } from "@mui/icons-material";
import { ContractsApi } from "../../../api";
import { useSnackbar } from "notistack";
import { IContractSignDialogApi } from "./ContractSignDialog";

interface IContractsTableItemProps {
  contract: Contract;
  signDialogApi: MutableRefObject<IContractSignDialogApi | null>;
}

const ContractsTableItem: FC<IContractsTableItemProps> = (props) => {
  const { contract, signDialogApi } = props;
  const { t, i18n } = useTranslation();
  const dateFormat = "PPP";
  const { enqueueSnackbar } = useSnackbar();
  const getStatusColor = (statusType: ContractStatus) => {
    switch (statusType) {
      case "new":
        return "default";
      case "inactive":
        return "error";
      case "signed":
        return "success";
      case "requested":
        return "error";
    }
  };

  const handleShowSignDialog = () => {
    if (signDialogApi) {
      signDialogApi.current?.openDialog(contract);
    }
  };

  const handleDownloadContract = () => {
    ContractsApi.download(contract)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(response.data);
          link.download = `${contract.contractType}.pdf`;
          link.click();
        } else {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      });
  };

  return (
    <>
      <TableRow>
        <TableCell>{t(`Contracts.ContractType.${contract.contractType}`, contract.contractName)}</TableCell>
        <TableCell align="center">
          <Chip
            color={getStatusColor(contract.status)}
            variant="outlined"
            size="small"
            label={
              contract.expiryDate
                ? t(`Contracts.State.${contract.status}`, {
                    expirationDate: DateTimeUtils.getDaysLeft(contract.expiryDate),
                  })
                : t(`Contracts.State.${contract.status}`)
            }
          />
        </TableCell>
        <TableCell align="center">
          {contract.signedDate && DateTimeUtils.getLocalDate(i18n, contract.signedDate, dateFormat)}
        </TableCell>
        <TableCell align="center">
          {contract.expiryDate ? DateTimeUtils.getLocalDate(i18n, contract.expiryDate, dateFormat) : "-"}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {contract.status === "signed" ? (
            <>
              {contract.hasDownloadFile ? (
                <Button size="small" variant="text" title={t("Contracts.Download")} onClick={handleDownloadContract}>
                  <GetApp />
                </Button>
              ) : (
                <Tooltip disableFocusListener title={t("Contracts.Info")}>
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <Button size="small" variant="text" title={t("Contracts.Sign")} onClick={handleShowSignDialog}>
              <BorderColorOutlined />
            </Button>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContractsTableItem;
