import axios from "./../lib/axiosForApi";
import { EndpointProtectionTrialsFormValues } from "../pages/trials/formValidations/EndpointProtectionTrials";
import { EndpointProtectionTrialsFormSettings, EndpointProtectionTrialsResponse } from "../types/trials";

class TrialsApi {
  async requestPartnerTrial(values: EndpointProtectionTrialsFormValues): Promise<EndpointProtectionTrialsResponse> {
    const response = await axios.post("/api/b2b-trial/create", values);
    return response.data;
  }

  async getFormSettings(): Promise<EndpointProtectionTrialsFormSettings> {
    const response = await axios.get("/api/b2b-trial/settings");
    return response.data;
  }
}

export default new TrialsApi();
