import React, { FC } from "react";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PriceBox } from "./PriceBox";
import useAuth from "../../../contextProviders/Authentication";
import { HierarchicalPriceData } from "../../../types/shop";
import { useShop } from "../../../contextProviders/Shop";
import { useTranslation } from "react-i18next";

interface IRebatePopoverProps {
  id: number;
  prices: HierarchicalPriceData[];
  disabled?: boolean;
  label?: string;
}

export const RebatePopover: FC<IRebatePopoverProps> = (props) => {
  const { id, prices, label, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { user } = useAuth();
  const { t } = useTranslation();

  const { state: shopState } = useShop();
  const userForCalculation = shopState.emulatedUser ?? user;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let hasDiscounts = false;
  for (let i = 0; i < prices.length; i++) {
    if (prices[i].discounts) {
      hasDiscounts = true;
    }
  }

  if (!userForCalculation || !hasDiscounts) {
    return <></>;
  }

  return (
    <>
      {!label ? (
        <IconButton
          size="small"
          color="primary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          disabled={disabled}
        >
          <InfoOutlinedIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <Button
          size="small"
          color="primary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          disabled={disabled}
          endIcon={<InfoOutlinedIcon fontSize="inherit" />}
        >
          {label}
        </Button>
      )}
      <Popover
        id={`rebate-info-${id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          pointerEvents: "none",
        }}
        disableRestoreFocus
      >
        <Box sx={{ my: 1, mx: 2 }}>
          {prices.length == 1 ? (
            <PriceBox
              priceData={{ default: prices[0], subscription: null }}
              showSubscriptionPrice={false}
              userForCalculation={userForCalculation}
            />
          ) : (
            prices.map((price, index) => {
              if (!price.discounts || !price.basePrice) {
                return <></>;
              }
              return (
                <Box sx={{ mb: index < prices.length - 1 ? 2 : 0 }} key={`priceBox-{id}-${index}`}>
                  <Typography variant="h6">
                    {price.type == "upgrade" ? t("Shop.PriceBox.ProductChange") : t("Shop.PriceBox.LicenseRenew")}:
                  </Typography>
                  <PriceBox
                    sx={{ ml: 2 }}
                    priceData={{ default: price, subscription: null }}
                    showSubscriptionPrice={false}
                    userForCalculation={userForCalculation}
                  />
                </Box>
              );
            })
          )}
        </Box>
      </Popover>
    </>
  );
};
