import axios from "axios";
import { Typo3Page } from "../types/typo3Page";

class Typo3PageApi {
  async getT3Page(route: string, languageCode = "de"): Promise<Typo3Page> {
    let urlPrefix = "";
    if (languageCode !== "de") {
      urlPrefix = `/${languageCode}`;
    }

    const uri = `${process.env.REACT_APP_TYPO3_DOMAIN}${urlPrefix}/app-pages/${route}`;
    const response = await axios.get(uri, {});

    return response.status === 200 ? response.data : null;
  }
}

export default new Typo3PageApi();
