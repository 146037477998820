import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Toolbar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Logo from "../../Logo";
import { AppTitle } from "../../AppTitle";
import MuiNavbar from "../../Navbar";
import Stack from "@mui/material/Stack";
import LanguagePopover from "../../LanguagePopover";
import AccountPopover from "./AccountPopover";
import useAuth from "../../../contextProviders/Authentication";

const NavbarDefaultLayout: FC = () => {
  const { isAuthenticated, isMfaEnabled, isMfaVerified } = useAuth();

  return (
    <MuiNavbar elevation={1}>
      <Toolbar>
        <Box sx={{ display: "flex" }}>
          <RouterLink to="/">
            <Logo width="115px" />
          </RouterLink>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <AppTitle typography={{ variant: "h1" }} />
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 1 }}>
          {isAuthenticated ? (
            <>
              {isMfaEnabled && !isMfaVerified ? (
                <LanguagePopover />
              ) : (
                <Stack direction="row" spacing={3}>
                  <IconButton color="inherit" component={RouterLink} to="/app">
                    <HomeIcon />
                  </IconButton>
                  <AccountPopover />
                </Stack>
              )}
            </>
          ) : (
            <LanguagePopover />
          )}
        </Box>
      </Toolbar>
    </MuiNavbar>
  );
};

export default NavbarDefaultLayout;
