import axios from "../lib/axiosForApi";
import { SepaFormValues } from "../pages/settings/formValidations/sepa";

export interface IBankInfoResponse {
  name: string;
  bic: string;
}

export interface ISepaDataResponse {
  bankName: string;
  iban: string;
  mandatRef: string;
  mandatDate: number;
}

class PaymentApi {
  async getBankInfo(iban: string): Promise<IBankInfoResponse | null> {
    const response = await axios.get("/payment/bank-info", { params: { iban: iban } });
    return response.status === 200 ? response.data : null;
  }

  async getSepaData(): Promise<ISepaDataResponse | null> {
    const response = await axios.get("/payment/sepa");
    return response.status === 200 ? response.data : null;
  }

  async updateSepaData(values: SepaFormValues): Promise<boolean> {
    const response = await axios.post("/payment/sepa", values);
    return response.status === 200;
  }
}

export default new PaymentApi();
