import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Contract } from "../../../types/contracts";
import { useFormik } from "formik";
import { ContractsApi } from "../../../api";
import { useSnackbar } from "notistack";
import SuccessBox from "../../../components/SuccessBox";
import { Box, Button, CircularProgress, Divider, Grid } from "@mui/material";
import { CheckBox } from "../../../components/formFields";
import { getValidationSchema, ContractFormValues } from "../formValidations/contracts";
import { useInvalidateQueryClientData } from "../../../lib/queryClient";

interface ContractsIframeProps {
  contract: Contract;
  language: string;
}

interface ContractsSignFormProps {
  contract: Contract;
  handleCloseSignDialog: MouseEventHandler;
}

function ContractsIframe(props: ContractsIframeProps) {
  const { contract, language } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState<string>("");

  useEffect(() => {
    setIsLoading(true);
    ContractsApi.preview(contract, language).then((pdfData) => {
      if (pdfData) {
        setIframeUrl(URL.createObjectURL(pdfData));
      }
    });
  }, [contract, language]);

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sx={{
          position: "relative",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading && <CircularProgress />}
        <iframe
          onLoad={() => setIsLoading(false)}
          src={iframeUrl}
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", border: "none" }}
          title="contract-preview"
        ></iframe>
      </Grid>
    </Grid>
  );
}

const ContractsSignForm: FC<ContractsSignFormProps> = (props: ContractsSignFormProps) => {
  const { contract, handleCloseSignDialog } = props;
  const { t, i18n } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { invalidateContracts } = useInvalidateQueryClientData();
  const initialFormValues: ContractFormValues = {
    accepted: false,
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      ContractsApi.sign(contract, i18n.language)
        .then((response: { data: { success: boolean } }) => {
          if (response.data.success) {
            setStatus({ success: true });
            // Timeout, da wir abwarten wollen, bis das CRM die Sign-Message verarbeitet hat, bevor wir die Ansicht aktualisieren
            setTimeout(() => invalidateContracts(), 2000);
            setIsSubmitted(true);
            resetForm({});
          } else {
            setStatus({ success: false });
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return isSubmitted ? (
    <Box sx={{ p: 2 }}>
      <SuccessBox
        title={t("Contracts.Response.Title")}
        text={t("Contracts.Response.Message")}
        buttonEvt={handleCloseSignDialog}
        buttonTitle={t("Common.Close")}
      />
    </Box>
  ) : (
    <>
      <ContractsIframe contract={contract} language={i18n.language} />
      <Divider />
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={0}
          rowSpacing={1}
          sx={{
            py: 2,
            px: 3,
          }}
        >
          <Grid item xs={12} md="auto">
            <CheckBox formik={formik} name="accepted" value="1" title={t("Contracts.SignLabel")} required />
          </Grid>
          <Grid item xs={12} md="auto">
            <Button type="submit" variant="contained" disabled={!formik.dirty || formik.isSubmitting}>
              {t("Contracts.Sign")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ContractsSignForm;
