import { StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserAppSettingsProvider } from "./contextProviders/UserAppSettings";
import BrowserRouter from "./components/BrowserRouter";
import { AuthProvider } from "./contextProviders/Authentication";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./theme/source-sans-pro.css";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GData: any;
  }
}
window.GData = window.GData || {};

// see options https://react-query.tanstack.com/reference/useQuery
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 6 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  },
});

ReactDOMClient.createRoot(document.getElementById("root") as HTMLDivElement).render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserAppSettingsProvider>
              <App />
            </UserAppSettingsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
