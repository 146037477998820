import axios from "./../lib/axiosForApi";
import TokenUtils from "../utils/TokenUtils";

interface IVerify {
  accessToken: string;
  isValid: boolean;
  isLocked: boolean;
}

interface IOneTimePasswordGenerate {
  secret: string;
  checksum: string;
  totpAuthUrl: string;
}

class MfaApi {
  async verify(verificationCode: string, type: "one-time-password" | "recovery-code"): Promise<IVerify> {
    const params = type === "one-time-password" ? { totp: verificationCode, type } : { rc: verificationCode, type };
    const response = await axios.post("/mfa/verify", params);

    if (response.data.isValid) {
      // Add the new accessToken (with "isMfaEnabled: true") to local storage
      // Otherwise the user must input the verification code on every site reload
      TokenUtils.setTokens(response.data.accessToken, response.data.refreshToken);
    }

    return response.data;
  }

  async oneTimePasswordGenerate(): Promise<IOneTimePasswordGenerate> {
    const response = await axios.post("/mfa/one-time-password/generate");
    return response.data;
  }

  async oneTimePasswordActivate(verificationCode: string, secret: string, checksum: string): Promise<boolean> {
    const response = await axios.post("/mfa/one-time-password/activate", { totp: verificationCode, secret, checksum });
    if (response.data.isActivated) {
      TokenUtils.setTokens(response.data.accessToken, response.data.refreshToken);
    }
    return response.data.isActivated;
  }

  async oneTimePasswordIsActive(): Promise<boolean> {
    const response = await axios.get("/mfa/one-time-password/is-active");
    return response.data.isActive;
  }

  async oneTimePasswordDeactivate(): Promise<boolean> {
    const response = await axios.post("/mfa/one-time-password/deactivate");
    return response.data.isDeactivated;
  }

  async recoveryCodesGenerate(): Promise<string> {
    const response = await axios.get("/mfa/recovery-codes/generate");
    return response.data.recoveryCodes;
  }

  async recoveryCodesRemainingCodes(): Promise<number> {
    const response = await axios.get("/mfa/recovery-codes/remaining-codes");
    return response.data.remainingCodes;
  }
}

export default new MfaApi();
