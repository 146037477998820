export const additionalModules: string[] = [
  "Linux Mail Security Gateway",
  "Linux Web Security Gateway",
  "Mail Security",
  "Policy Management",
];

export const products: { id: string; name: string }[] = [
  {
    id: "CDAT",
    name: "Cyber Defense Awareness Trainings",
  },
  {
    id: "EPPB",
    name: "Endpoint Protection Business",
  },
  {
    id: "MES",
    name: "Managed Endpoint Security",
  },
  {
    id: "MESA",
    name: "Managed Endpoint Security powered by MS Azure",
  },
  {
    id: "MEDR",
    name: "G DATA 365 | Managed EDR",
  },
  {
    id: "CSB",
    name: "Clientsecurity Business",
  },
  {
    id: "AVB",
    name: "Antivirus Business",
  },
];
