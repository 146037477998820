import merge from "lodash/merge";
import { createTheme as createMuiTheme, responsiveFontSizes } from "@mui/material/styles";
import type { Direction, Theme, ThemeOptions } from "@mui/material";
import { lightShadows, darkShadows } from "./shadows";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "1e702ae90edb39882edc3f4fb04241ffT1JERVI6Mzc0NTMsRVhQSVJZPTE2NzU5MzI3MjcwMDAsS0VZVkVSU0lPTj0x"
);

export type ThemeType = "LIGHT" | "DARK";

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme: ThemeType;
}

// ToDo: declare module in andere File verschieben?
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    ms: true; // adds the `medium small` breakpoint
    lx: true;
  }
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ms: 720,
      md: 1024,
      lg: 1400,
      lx: 1640,
      xl: 1920,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: "1px 16px",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#root": {
          height: "100vh",
          whiteSpace: "pre-line",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::-ms-reveal, &::-ms-clear": {
            display: "none",
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          padding: "8px 0",
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
      styleOverrides: {
        root: {
          padding: "16px 32px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px 32px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: "6px 16px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: {
            fontWeight: "bold",
          },
          svg: {
            display: "block",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 3,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.form-formGroup-keep": {
            minHeight: "88px",
            paddingBottom: "8px",
          },
          "&.form-formGroup": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  },
  typography: {
    fontWeightBold: 600,
    button: {
      fontWeight: 600,
    },
    fontFamily: '"Source Sans Pro", sans-serif',
    h1: {
      fontWeight: 300,
      fontSize: "2.125rem",
      letterSpacing: "-0.021875rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.3,
      letterSpacing: "0.015625em",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h4: {
      fontWeight: 300,
      fontSize: "1.5rem",
      lineHeight: 1.3,
      letterSpacing: "0.015625em",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.125rem",
      letterSpacing: "0.009375rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      letterSpacing: "0.025rem",
    },
    overline: {
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: 1,
      letterSpacing: "0.03125rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: "0.009375rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      letterSpacing: "0.009375rem",
    },
  },
};

const themesOptions: Record<ThemeType, ThemeOptions> = {
  LIGHT: {
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(250, 250, 250,.64)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px #FAFAFA inset",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#F4F5F7",
        paper: "#FAFAFA",
      },
      error: {
        contrastText: "#ffffff",
        main: "#F44336",
        dark: "#D32F2F",
        light: "#E57373",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#5691D1",
        dark: "#3D6794",
        light: "#76A7DB",
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#C20E1A",
        dark: "#890000",
        light: "#FC5143",
      },
      success: {
        contrastText: "#ffffff",
        main: "#52C258",
        dark: "#0C902A",
        light: "#7DE17B",
      },
      text: {
        primary: "#1F2125",
        secondary: "rgba(31,33,37,.7)",
        disabled: "rgba(0,0,0,.23)",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#FFC247",
        dark: "#EC9616",
        light: "#FFCB76",
      },
      info: {
        contrastText: "#FFFFFF",
        main: "#2196F3",
        dark: "#0B79D0",
        light: "#BBDEFB",
      },
    },
    shadows: lightShadows,
  },
  DARK: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px #444444 inset",
            },
          },
        },
      },
    },
    palette: {
      background: {
        default: "#2c2c2c",
        paper: "#3a3a3a",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#F44336",
        dark: "#D32F2F",
        light: "#E57373",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#5691D1",
        dark: "#3D6794",
        light: "#76A7DB",
      },
      secondary: {
        contrastText: "#ffffff",
        main: "#C20E1A",
        dark: "#890000",
        light: "#FC5143",
      },
      success: {
        contrastText: "#ffffff",
        main: "#52C258",
        dark: "#0C902A",
        light: "#87F587",
      },
      text: {
        primary: "#ffffff",
        secondary: "rgba(255,255,255,.7)",
        disabled: "rgba(255,255,255,.23)",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#FFC247",
        dark: "#EC9616",
        light: "#FFCB76",
      },
      info: {
        contrastText: "#FFFFFF",
        main: "#2196F3",
        dark: "#0B79D0",
        light: "#2196F3",
      },
    },
    shadows: darkShadows,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const createTheme = (config: ThemeConfig): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions.LIGHT;
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
