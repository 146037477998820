import { User, UserRoles } from "../types/user";

class UserUtils {
  static hasPermission(allowedUserRoles: UserRoles | null | undefined, user: User | undefined) {
    const userRoles = user ? user.roles : [];
    /**
     * If allowedUserRoles is not defined
     * Pass and allow for all
     */
    if (allowedUserRoles === null || allowedUserRoles === undefined) {
      return true;
    }
    if (allowedUserRoles.length === 0) {
      /**
       * if allowedUserRoles is empty means,
       * allow only for guests (null or empty[])
       */
      return userRoles.length === 0;
    }
    /**
     * Check if user has grants
     */
    return allowedUserRoles.some((r) => userRoles.indexOf(r) >= 0);
  }
}

export default UserUtils;
