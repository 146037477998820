import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { Box, Tabs, Typography } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import { useParams } from "react-router-dom";
import Tab from "../../components/Tab";
import EndpointProtectionTrialsForm from "./components/EndpointProtectionTrialsForm";
import AwarenessTrainingsTrialsForm from "./components/AwarenessTrainingsTrialsForm";
import ContentBox from "../../components/ContentBox";

const Trials: FC = () => {
  const { t } = useTranslation();
  const { tabName } = useParams();
  const tabToDisplay = tabName ?? "endpoint-protection";

  return (
    <Page title={t("Trials.PageTitle")} subtitle={t("Sidebar.Shop.Title")} maxWidth="md">
      <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabToDisplay}>
          <Tab label="Endpoint Protection" value="endpoint-protection" />
          <Tab label="Awareness Trainings" value="awareness-trainings" />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel activeTab={tabToDisplay} tabName="endpoint-protection">
          <ContentBox>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Endpoint Protection Testversion
            </Typography>
            <EndpointProtectionTrialsForm />
          </ContentBox>
        </TabPanel>
        <TabPanel activeTab={tabToDisplay} tabName="awareness-trainings">
          <ContentBox>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Security Awareness Trainings
            </Typography>
            <AwarenessTrainingsTrialsForm />
          </ContentBox>
        </TabPanel>
      </Box>
    </Page>
  );
};

export default Trials;
