import React, { ReactNode, useContext, useMemo } from "react";
import i18n from "i18next";
import type { ThemeType } from "../theme";
import usePersistedState from "./../hooks/usePersistedState";
import useAuth from "./Authentication";
import { SupportedLocale } from "../locales";
import { LicenseCenterUserSettings, UserSettings } from "../types/user";

interface IAppUserSettingsContext {
  settings: UserSettings;
  setTheme: (theme: ThemeType) => Promise<boolean>;
  setLanguage: (language: SupportedLocale) => Promise<boolean>;
  setLicenseCenterSettings: (settings: LicenseCenterUserSettings) => Promise<boolean>;
}

const UserAppSettingsContext = React.createContext({} as IAppUserSettingsContext);

/*
    Note: Do not store sensitive data in the browser storage (localSettings) !!!

    Settings order:
    1. INITIAL_STATE is initial
    2. Settings in LocalStorage (LocalSettings) overrides the initialState
    3. Settings from Database overrides the LocalStorage Settings
 */

const INITIAL_STATE: UserSettings = {
  theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "DARK" : "LIGHT",
  language: i18n.language as SupportedLocale,
  licenseCenter: null,
};

function UserAppSettingsProvider({ children }: { children: ReactNode }) {
  const [localSettings, setLocalSettings] = usePersistedState("localSettings", INITIAL_STATE);
  const { isAuthenticated, user, updateUserSettings } = useAuth();

  const mergedSettings = useMemo(() => {
    if (!isAuthenticated || !user) {
      return localSettings;
    }
    return { ...localSettings, ...user.settings };
  }, [localSettings, user, isAuthenticated]);

  const setTheme = async (theme: ThemeType): Promise<boolean> => {
    setLocalSettings({ ...localSettings, theme: theme });
    if (await updateUserSettings({ theme: theme })) {
      return true;
    }
    return false;
  };

  const setLanguage = async (language: SupportedLocale): Promise<boolean> => {
    setLocalSettings({ ...localSettings, language: language });
    if (!isAuthenticated) {
      return true;
    }
    return updateUserSettings({ language: language });
  };

  const setLicenseCenterSettings = async (settings: LicenseCenterUserSettings | null): Promise<boolean> => {
    if (await updateUserSettings({ licenseCenter: settings })) {
      return true;
    }
    return false;
  };

  const value = {
    settings: mergedSettings,
    setTheme,
    setLanguage,
    setLicenseCenterSettings,
  };

  return <UserAppSettingsContext.Provider value={value}>{children}</UserAppSettingsContext.Provider>;
}

function useUserAppSettings() {
  const context = useContext(UserAppSettingsContext);
  if (context === undefined) {
    throw new Error("useUserAppSettings must be used within a UserAppSettingsProvider");
  }
  return context;
}

export { UserAppSettingsProvider, useUserAppSettings };
