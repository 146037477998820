import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import ContentBox from "../../components/ContentBox";
import { Box, Chip, IconButton } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { getLicenseCenterLocales } from "./components";
import { SupportedLocale } from "../../locales";
import { InvoiceRow } from "../../types/invoice";
import { CreditCard, Download } from "@mui/icons-material";
import { useGetInvoices } from "../../lib/queryClient";
import { InvoiceApi } from "../../api";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";

const Invoices: FC = () => {
  const { t, i18n } = useTranslation();
  const gridLocales = getLicenseCenterLocales(i18n.language as SupportedLocale);
  const [pageSize, setPageSize] = useState<number>(25);
  const { data: invoiceData, isLoading } = useGetInvoices();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadClick = (invoiceRow: InvoiceRow) => {
    InvoiceApi.download(invoiceRow.link)
      .then((pdf) => {
        if (pdf !== null) {
          saveAs(pdf, `${invoiceRow.number}.pdf`);
        } else {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      });
  };

  const columns: GridColumns = [
    {
      field: "date",
      headerName: t("Invoices.Columns.Date"),
      type: "date",
      flex: 2,
      minWidth: 200,
      filterable: true,
      renderCell: (params: GridRenderCellParams<Date>) => {
        if (typeof params.value === "undefined" || params.value === null) {
          return "";
        }

        return i18n.format(new Date(params.value), "P", i18n.language);
      },
    },
    {
      field: "number",
      headerName: t("Invoices.Columns.Number"),
      type: "string",
      flex: 2,
      minWidth: 200,
      filterable: true,
    },
    {
      field: "sumNet",
      headerName: t("Invoices.Columns.SumNet"),
      type: "number",
      flex: 1,
      minWidth: 150,
      filterable: true,
      align: "right",
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.sumNet / 100;
      },
      renderCell: (params: GridRenderCellParams<number>) => {
        if (typeof params.value === "undefined" || params.value === null) {
          return "";
        }
        return new Intl.NumberFormat(i18n.language, { style: "currency", currency: params.row.currencyCode }).format(
          params.value
        );
      },
    },
    {
      field: "sumGross",
      headerName: t("Invoices.Columns.SumGross"),
      type: "number",
      flex: 1,
      minWidth: 150,
      filterable: true,
      align: "right",
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.sumGross / 100;
      },
      renderCell: (params: GridRenderCellParams<number>) => {
        if (typeof params.value === "undefined" || params.value === null) {
          return "";
        }
        return new Intl.NumberFormat(i18n.language, { style: "currency", currency: params.row.currencyCode }).format(
          params.value
        );
      },
    },
    {
      field: "status",
      headerName: t("Invoices.Columns.State"),
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      filterable: true,
      align: "right",
      headerAlign: "right",
      valueOptions: [
        { value: 1, label: t(`Invoices.State.Open`) },
        { value: 2, label: t(`Invoices.State.Paid`) },
      ],
      renderCell: (params: GridRenderCellParams<number>) => {
        if (typeof params.value === "undefined" || params.value === null) {
          return "";
        }

        switch (params.value) {
          case 1:
            return <Chip label={t("Invoices.State.Open")} size="small" variant="outlined" color="info" />;
          case 2:
            return <Chip label={t("Invoices.State.Paid")} size="small" variant="outlined" color="success" />;
          default:
            return <Chip label={t("Invoices.State.Unknown")} size="small" variant="outlined" />;
        }
      },
    },
    {
      field: t("Invoices.Columns.Actions"),
      type: "actions",
      flex: 1,
      minWidth: 100,
      hideable: false,
      align: "right",
      getActions: (params: GridRowParams<InvoiceRow>) => {
        const actions = [];

        const paymentUrl = () => {
          if (process.env.REACT_APP_APP_CONTEXT !== "production") {
            return "https://de-int.gdatasoftware.com/rechnung/direct-pay/";
          }

          switch (i18n.language) {
            case "de":
              return "https://www.gdata.de/rechnung/direct-pay/";
            case "fr":
              return "https://www.gdata.fr/facture/direct-pay/";
            case "es":
              return "https://www.gdata.es/factura/direct-pay/";
            case "nl":
              return "https://www.gdata.nl/onlinebetaling/direct-pay/";
            case "it":
              return "https://www.gdata.it/fattura/direct-pay/";
            default:
              return "https://www.gdatasoftware.com/invoice/direct-pay/";
          }
        };

        if (params.row.status === 1 && params.row.isOnlinePayable) {
          actions.push(
            <IconButton href={`${paymentUrl()}${params.row.link}`} target="_blank">
              <CreditCard />
            </IconButton>
          );
        }

        if (params.row.isDownloadable) {
          actions.push(
            <IconButton onClick={() => handleDownloadClick(params.row)}>
              <Download />
            </IconButton>
          );
        }

        return actions;
      },
    },
  ];

  return (
    <Page title={t("Sidebar.Shop.Invoices")} subtitle="Shop" maxWidth={"xl"}>
      <ContentBox>
        <Box sx={{ width: "100%", height: "max(500px,80vh)" }}>
          <DataGridPro
            sx={{ height: "100%", border: "none" }}
            columns={columns}
            disableSelectionOnClick={true}
            rows={invoiceData ?? []}
            getRowId={(row) => row.number}
            loading={isLoading}
            localeText={gridLocales}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            components={{
              Toolbar: () => {
                return (
                  <>
                    <GridToolbarContainer>
                      <GridToolbarFilterButton />
                    </GridToolbarContainer>
                  </>
                );
              },
            }}
          />
        </Box>
      </ContentBox>
    </Page>
  );
};

export default Invoices;
