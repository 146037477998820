import type { FC } from "react";
import { useTheme } from "@mui/material/styles";

interface LogoProps {
  width: string;
}

const LogoSqDark: FC<LogoProps> = ({ width }) => (
  <img src="/app-files/static/logos/logo-sq-for-dark.svg" style={{ width }} alt="G DATA Logo" />
);

const LogoSqLight: FC<LogoProps> = ({ width }) => (
  <img src="/app-files/static/logos/logo-sq-for-light.svg" style={{ width }} alt="G DATA Logo" />
);

const LogoSq: FC<LogoProps> = (props) => {
  const theme = useTheme();
  return theme.palette.mode === "light" ? <LogoSqLight {...props} /> : <LogoSqDark {...props} />;
};

export default LogoSq;
