import { useState } from "react";
import type { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppSidebar from "./components/AppSidebar";
import NavbarAppLayout from "./components/NavbarAppLayout";
import RoleGuard from "../RoleGuard";
import { AppNotificationsProvider } from "../../contextProviders/AppNotifications";
import { SharedStateProvider } from "../../contextProviders/SharedState";
import FeedbackDialog from "../FeedbackDialog";

interface AppLayoutProps {
  children?: ReactNode;
}

const AppLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  overflow: "hidden",
  width: "100%",
}));

const AppLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "272px",
  },
}));

const AppLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const AppLayoutContent = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const AppLayout: FC<AppLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  return (
    <SharedStateProvider>
      <AppNotificationsProvider>
        <AppLayoutRoot>
          <NavbarAppLayout onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
          <AppSidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
          <AppLayoutWrapper>
            <AppLayoutContainer>
              <AppLayoutContent>
                <RoleGuard renderError>
                  <Outlet />
                </RoleGuard>
              </AppLayoutContent>
            </AppLayoutContainer>
          </AppLayoutWrapper>
        </AppLayoutRoot>
        <FeedbackDialog />
      </AppNotificationsProvider>
    </SharedStateProvider>
  );
};

export default AppLayout;
