import { FC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import * as React from "react";
import { DownloadsProvider } from "./contextProviders/DownloadsContext";
import DownloadsModule from "./components/DownloadsModule";

const Downloads: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Downloads.Title")} subtitle={t("Sidebar.SalesMarketing.Title")}>
      <DownloadsProvider>
        <DownloadsModule />
      </DownloadsProvider>
    </Page>
  );
};

export default Downloads;
