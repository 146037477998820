import React from "react";

type Header = {
  headerLayout: number;
  header: string;
  subheader: string;
};

export function RawHeadline(data: Header): JSX.Element {
  switch (data.headerLayout) {
    case 1:
      return (
        <>
          <h1>{data.header}</h1>
          {data.subheader && <h2>{data.subheader}</h2>}
        </>
      );
    case 2:
      return (
        <>
          <h2>{data.header}</h2>
          {data.subheader && <h3>{data.subheader}</h3>}
        </>
      );
    case 3:
      return (
        <>
          <h3>{data.header}</h3>
          {data.subheader && <h4>{data.subheader}</h4>}
        </>
      );
    case 4:
      return (
        <>
          <h4>{data.header}</h4>
          {data.subheader && <h5>{data.subheader}</h5>}
        </>
      );
    case 5:
      return (
        <>
          <h5>{data.header}</h5>
          {data.subheader && <h6>{data.subheader}</h6>}
        </>
      );
    case 100:
      return <></>;
    default:
      return (
        <>
          <h3>{data.header}</h3>
          {data.subheader && <h4>{data.subheader}</h4>}
        </>
      );
  }
}

export default RawHeadline;
