import React, {
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useImperativeHandle,
  useMemo,
  useState,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import { LicenseCenterRow, LicenseRemoveParameters } from "../../../types/license";
import { Button, Grid, MenuItem, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { provider } from "../../../static";
import { SelectField } from "../../../components/formFields";
import { useFormik } from "formik";
import { getValidationSchema } from "../formValidations/removeLicense";
import FormatUtils from "../../../utils/FormatUtils";
import { LoadingButton } from "@mui/lab";
import { LicenseApi } from "../../../api";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { GridRowId } from "@mui/x-data-grid";

export interface ILicenseCenterLicenseRemoveDialogApi {
  handleShowDialog: (licenseCenterRow: LicenseCenterRow) => void;
}

interface ILicenseCenterLicenseRemoveDialogProps {
  ref: MutableRefObject<ILicenseCenterLicenseRemoveDialogApi>;
  onRemove: (rowId: GridRowId) => void;
}

const LicenseCenterLicenseRemoveDialog: ForwardRefExoticComponent<
  Omit<ILicenseCenterLicenseRemoveDialogProps, "ref"> & RefAttributes<ILicenseCenterLicenseRemoveDialogApi>
> = forwardRef((props, ref) => {
  const { onRemove } = props;
  const { t } = useTranslation();
  const [licenseCenterRow, setLicenseCenterRow] = useState<LicenseCenterRow | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    reason: 0,
    competitor: 0,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: getValidationSchema(t),
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (!licenseCenterRow) {
        return;
      }
      setLoading(true);
      const params: LicenseRemoveParameters = {
        registrationNumber: licenseCenterRow.registrationNumber ?? "",
        reason: values.reason,
      };
      if (values.reason == 1) {
        params.competitor = values.competitor;
      }

      LicenseApi.removeLicense(params)
        .then((success) => {
          setShowDialog(false);
          onRemove(licenseCenterRow.registrationNumber);
          formik.resetForm({ values: initialValues });
          if (success === true) {
            enqueueSnackbar(t("LicenseCenter.RemoveLicense.SuccessMsg"), { variant: "success" });
          } else {
            enqueueSnackbar(t("LicenseCenter.RemoveLicense.ErrorMsg"), { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleShowDialog = (data: LicenseCenterRow) => {
    setLicenseCenterRow(data);
    setShowDialog(true);
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    handleShowDialog: handleShowDialog,
  }));

  const handleCloseDialog = () => {
    setShowDialog(false);
    formik.resetForm();
  };

  const reasons = useMemo(
    () => [
      { id: 0, name: "---" },
      { id: 1, name: t("LicenseCenter.RemoveLicense.Reasons.1") },
      { id: 5, name: t("LicenseCenter.RemoveLicense.Reasons.5") },
      { id: 122690008, name: t("LicenseCenter.RemoveLicense.Reasons.122690008") },
      { id: 122690010, name: t("LicenseCenter.RemoveLicense.Reasons.122690010") },
      { id: 122690016, name: t("LicenseCenter.RemoveLicense.Reasons.122690016") },
      { id: 122690011, name: t("LicenseCenter.RemoveLicense.Reasons.122690011") },
      { id: 122690012, name: t("LicenseCenter.RemoveLicense.Reasons.122690012") },
      { id: 122690013, name: t("LicenseCenter.RemoveLicense.Reasons.122690013") },
      { id: 122690018, name: t("LicenseCenter.RemoveLicense.Reasons.122690018") },
      { id: 122690015, name: t("LicenseCenter.RemoveLicense.Reasons.122690015") },
      { id: 122690017, name: t("LicenseCenter.RemoveLicense.Reasons.122690017") },
    ],
    [t]
  );
  if (!licenseCenterRow) {
    return <></>;
  }

  let licenseOwner = `${licenseCenterRow.firstName} ${licenseCenterRow.lastName} `;
  if (licenseCenterRow.company) {
    licenseOwner = `${licenseCenterRow.company}, ${licenseOwner}`;
  }

  return (
    <Dialog
      show={showDialog}
      handleClose={handleCloseDialog}
      maxWidth="ms"
      title={t("LicenseCenter.RemoveLicense.Title", { label: FormatUtils.getFormattedLicenseLabel(licenseCenterRow) })}
      content={
        <>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Typography>{t("LicenseCenter.RemoveLicense.Description")}</Typography>
            <Grid container columnSpacing={2} rowSpacing={0} mt={2}>
              <Grid item xs={12} md={12}>
                <SelectField
                  formik={formik}
                  label={t("Forms.Reason")}
                  name="reason"
                  size="small"
                  keepSpace={false}
                  required
                >
                  {_.sortBy(reasons, ["name"]).map((item: { id: number; name: string }) => {
                    return (
                      <MenuItem value={item.id} key={`reason-${item.id}`}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </SelectField>
              </Grid>
              {formik.values.reason == 1 && (
                <Grid item xs={12} md={12}>
                  <SelectField
                    formik={formik}
                    label={t("Forms.Competitor")}
                    name="competitor"
                    size="small"
                    keepSpace={false}
                    required
                  >
                    <MenuItem value={0}>---</MenuItem>
                    {provider.map((item: { id: number; name: string }) => {
                      return (
                        <MenuItem value={item.id} key={`competitor-${item.id}`}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </SelectField>
                </Grid>
              )}
            </Grid>
            <Typography mt={1}>{t("LicenseCenter.RemoveLicense.AreYouSure")}</Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 0 }}>{t("LicenseCenter.Columns.RegNo")}:</TableCell>
                  <TableCell>{licenseCenterRow.registrationNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 0 }}>{t("LicenseCenter.Columns.Login")}:</TableCell>
                  <TableCell>{licenseCenterRow.loginName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 0, borderBottom: "none" }}>
                    {t("LicenseCenter.Columns.LicenseOwner")}:
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{licenseOwner}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack direction="row" spacing={2} mt={2} mb={1}>
              <Button
                fullWidth
                type="button"
                variant="outlined"
                size="medium"
                disabled={loading}
                onClick={handleCloseDialog}
              >
                {t("Common.Cancel")}
              </Button>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="medium"
                loading={loading}
                disabled={!formik.isValid || !formik.dirty}
              >
                {t("LicenseCenter.RemoveLicense.BtnConfirm")}
              </LoadingButton>
            </Stack>
          </form>
        </>
      }
    />
  );
});

export default LicenseCenterLicenseRemoveDialog;
