import { AppRouteObject } from "./types/app";
import { Navigate, Outlet } from "react-router-dom";
import DefaultLayout from "./components/layouts/DefaultLayout";
import Login from "./pages/login/Login";
import LostPassword from "./pages/lostPassword/LostPassword";
import ResetPassword from "./pages/lostPassword/ResetPassword";
import Projects from "./pages/projects/Projects";
import Contracts from "./pages/contracts/Contracts";
import Error404 from "./pages/errors/Error404";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import AppLayout from "./components/layouts/AppLayout";
import RequestOffer from "./pages/requestOffer/RequestOffer";
import SampleSubmission from "./pages/sampleSubmission/SampleSubmission";
import PersonalData from "./pages/settings/PersonalData";
import CompanyData from "./pages/settings/CompanyData";
import Security from "./pages/settings/Security";
import Trials from "./pages/trials/Trials";
import Faqs from "./pages/faqs/Faqs";
import Typo3Page from "./components/Typo3Page/Typo3Page";
import Downloads from "./pages/downloads/Downloads";
import Shop from "./pages/shop/Shop";
import Dashboard from "./pages/dashboard/Dashboard";
import Support from "./pages/contacts/Support";
import Team from "./pages/contacts/Team";
import ResellerManagement from "./pages/shop/ResellerManagement";
import Invoices from "./pages/shop/Invoices";
import Crossgrades from "./pages/crossgrades/Crossgrades";
import Basket from "./pages/shop/Basket";
import LicenseCenter from "./pages/shop/LicenseCenter";
import MfaGuard from "./components/MfaGuard";
import MfaVerify from "./pages/mfa/MfaVerify";
import { ShopProvider } from "./contextProviders/Shop";
import OrderCompleted from "./pages/shop/OrderCompleted";
import PartnerViewer from "./pages/shop/PartnerViewer";

const routes: AppRouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
  {
    path: "*",
    element: (
      <GuestGuard>
        <DefaultLayout centeredContent />
      </GuestGuard>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "lost-password",
        children: [
          {
            path: "",
            element: <LostPassword />,
          },
          {
            // If you change this route, remember to change the route in the
            // PasswordRecovery.html template of the fe_login extension as well!
            path: "reset/:resetHash",
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
  {
    path: "app",
    element: (
      <AuthGuard>
        <AppLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "request-offer",
        element: <RequestOffer />,
      },
      {
        path: "projects",
        element: <Projects />,
        children: [
          {
            path: ":tabName",
            element: <Projects />,
          },
        ],
      },
      {
        path: "contracts",
        element: <Contracts />,
      },
      {
        path: "shop",
        element: (
          <ShopProvider>
            <Outlet />
          </ShopProvider>
        ),
        children: [
          {
            path: "buy-new",
            element: <Shop />,
            allowedRoles: ["shop"],
          },
          {
            path: "license-center",
            element: <LicenseCenter />,
            allowedRoles: ["reseller", "distributor"],
          },
          {
            path: "partner-viewer",
            element: <PartnerViewer />,
            allowedRoles: ["support"],
          },
          {
            path: "basket/completed/:basketId",
            element: <OrderCompleted />,
            allowedRoles: ["shop"],
          },
          {
            path: "basket/:checkoutStep?",
            element: <Basket />,
            allowedRoles: ["shop"],
          },
          {
            path: "reseller-management",
            element: <ResellerManagement />,
            allowedRoles: ["distributor"],
          },
          {
            path: "invoices",
            element: <Invoices />,
            allowedRoles: ["invoice"],
          },
          {
            path: "crossgrades",
            element: <Crossgrades />,
            allowedRoles: ["crossgrades"],
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "personal-data",
            element: <PersonalData />,
          },
          {
            path: "company-data",
            element: <CompanyData />,
          },
          {
            path: "security",
            element: <Security />,
          },
        ],
      },
      {
        path: "trials",
        element: <Trials />,
        allowedRoles: ["reseller", "distributor"],
        children: [
          {
            path: ":tabName",
            element: <Trials />,
          },
        ],
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
      {
        path: "downloads",
        element: <Downloads />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        path: "team",
        element: <Team />,
        children: [
          {
            path: ":tabName",
            element: <Team />,
          },
        ],
      },
      {
        path: "sample-submission",
        element: <SampleSubmission />,
      },
      {
        path: "p/:slug",
        element: <Typo3Page />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
  {
    path: "mfa",
    element: (
      <MfaGuard>
        <DefaultLayout centeredContent />
      </MfaGuard>
    ),
    children: [
      {
        path: "verify/one-time-password",
        element: <MfaVerify verificationType="one-time-password" />,
      },
      {
        path: "verify/recovery-code",
        element: <MfaVerify verificationType="recovery-code" />,
      },
    ],
  },
];
export default routes;
