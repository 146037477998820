import type { FC } from "react";
import { CompanyDataForm } from "./components";
import Page from "../../components/Page";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PaymentForm from "./components/PaymentForm";

const CompanyData: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Sidebar.Settings.CompanyData")} subtitle={t("Sidebar.Settings.Title")}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <CompanyDataForm />
        </Grid>
        <Grid item xs={12} lg={4}>
          <PaymentForm />
        </Grid>
      </Grid>
    </Page>
  );
};

export default CompanyData;
