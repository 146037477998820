import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import ContentBox from "../../../components/ContentBox";
import { PasswordChangeFormValues, getValidationSchema } from "../formValidations/passwordChange";
import { PasswordField } from "../../../components/formFields";
import PasswordStrengthMeter from "../../login/components/PasswordStrengthMeter";
import { UserApi } from "../../../api";
import TokenUtils from "../../../utils/TokenUtils";

const PasswordForm: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: PasswordChangeFormValues = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      UserApi.changePassword(values.currentPassword, values.newPassword)
        .then((response) => {
          if (response.status === 200) {
            TokenUtils.setRefreshToken(response.data.refreshToken);
            enqueueSnackbar(t("Settings.PasswordSaved"), { variant: "success" });
            resetForm({ values: initialFormValues });
          } else {
            enqueueSnackbar(t("Settings.PasswordNotSaved"), { variant: "error" });
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            enqueueSnackbar(t("Settings.PasswordNotSavedDueToOldPw"), {
              variant: "error",
            });
          } else {
            enqueueSnackbar(t("Settings.PasswordNotSaved"), { variant: "error" });
          }
          setStatus({ success: false });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <ContentBox headline={t("Settings.ChangePassword")}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <PasswordField
          formik={formik}
          autoComplete="off"
          label={t("Forms.CurrentPassword")}
          name="currentPassword"
          required
          data-testid={"settings-password_current"}
        />
        <PasswordField
          formik={formik}
          autoComplete="off"
          label={t("Settings.NewPassword")}
          name="newPassword"
          required
          data-testid={"settings-password_new"}
        />
        <PasswordField
          formik={formik}
          autoComplete="off"
          dataTestid="passwordForm-confirmPassword"
          label={t("Forms.PasswordConfirm")}
          name="newPasswordConfirm"
          required
          data-testid={"settings-password_new-confirm"}
        />
        <PasswordStrengthMeter password={formik.values.newPassword} />
        <Button
          color="primary"
          disabled={formik.isSubmitting || !formik.dirty}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          data-testid={"settings-password_set-password-button"}
        >
          {t("Settings.SetPasswordBtn")}
        </Button>
      </form>
    </ContentBox>
  );
};

export default PasswordForm;
