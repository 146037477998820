import axios from "./../lib/axiosForApi";
import { User, UserSettings } from "../types/user";
import { AxiosResponse } from "axios";
import { SupportedLocale } from "../locales";

class UserApi {
  /*
    Get the current logged-in user
    HTTP Response: 200 and User-Object on success, 401 on invalid auth token
  */
  async getCurrentUser(): Promise<User> {
    const response = await axios.get("/user/get");

    return response.data.user.data;
  }

  /*
    Change the logged-in user password
    HTTP Response: 200 on success, 403 on old password is wrong, 401 if no logged-in user, 500 on server error
  */
  async changePassword(currentPassword: string, newPassword: string): Promise<AxiosResponse> {
    return axios.post("/user/password/change", { currentPassword, newPassword });
  }

  /*
    Reset user password and trigger mail with reset link
    HTTP Response: always 200, 500 on server error
  */
  async resetPassword(email: string, language: SupportedLocale): Promise<boolean> {
    await axios.post("/user/password/reset", { email, language });
    return true;
  }

  /*
    Check if a given password reset forgot hash is valid
    HTTP Response: always 200 with "{isValid: true|false}"
   */
  async verifyPasswordResetHash(resetHash: string): Promise<boolean> {
    const response = await axios.get("/user/password/verify-reset-hash", { params: { resetHash } });
    return response.status === 200 ? response.data.isValid : false;
  }

  /*
    Update the password of a user by given password reset hash
    HTTP Response: 200 on success, 500 on server error
   */
  async updatePasswordByResetHash(resetHash: string, newPassword: string): Promise<boolean> {
    const response = await axios.post("user/password/update-by-reset-hash", { resetHash, newPassword });
    return response.status == 200;
  }

  /*
    Update the user settings (e.g. theme and language)
    HTTP Response: 200 and userSettings object on success, HTTP 401 if no logged-in user, HTTP 500 on server error
   */
  async updateSettings(settings: Partial<UserSettings>): Promise<UserSettings> {
    const response = await axios.post("/user/update-settings", { settings });
    return response.data.settings;
  }

  /*
    Accepts the sending of advertisements & newsletters,
    sends the setting to the CRM and sets a cookie in the client
    HTTP Response: 200 on success, 401 if no logged-in user
   */
  async acceptAdvertising(accepted: boolean): Promise<AxiosResponse> {
    return axios.post("/user/accept-advertising", { accepted });
  }

  /*
    Send a change request to CRM
    HTTP Response: 200 on success, 401 if no logged-in user, HTTP 400 on unsuccessfully requests
  */
  async changeCompanyData(address: string, zip: string, city: string): Promise<boolean> {
    const response = await axios.post("/user/change-company-data", { address, zip, city });
    return response.status == 200;
  }
}

export default new UserApi();
