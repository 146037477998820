import type { FC } from "react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../components/ContentBox";
import { Grid, TextField } from "@mui/material";
import useAuth from "../../../contextProviders/Authentication";

const PersonalDataForm: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return user ? (
    <ContentBox headline={t("Settings.PersonalData")}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <TextField label={t("Forms.FirstName")} value={user.firstName} fullWidth disabled />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField label={t("Forms.LastName")} value={user.lastName} fullWidth disabled />
        </Grid>
        <Grid item xs={12}>
          <TextField label={t("Forms.Email")} value={user.email} fullWidth disabled />
        </Grid>
      </Grid>
    </ContentBox>
  ) : (
    <></>
  );
};

export default PersonalDataForm;
