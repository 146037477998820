import React, { BaseSyntheticEvent, MutableRefObject } from "react";
import { debounce } from "lodash";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  GridFilterItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { ILicenseCenterAddLicenseDialogApi } from "./LicenseCenterAddLicense";
import { LicenseCenterSearchParams, Reseller } from "../../../types/license";

interface IToolbarParams {
  apiRef?: React.MutableRefObject<GridApiPro>;
  updateFilterModel: (newFilterItems: GridFilterItem[]) => void;
  gridIsLoading: boolean;
  isDistributor: boolean;
  searchParams?: LicenseCenterSearchParams;
  resellers?: Reseller[];
  licenseAddDialogRef: MutableRefObject<ILicenseCenterAddLicenseDialogApi>;
  isEmulated: boolean;
}

export function LicenseCenterToolbar(params: IToolbarParams) {
  const { t } = useTranslation();
  const { licenseAddDialogRef, gridIsLoading, isDistributor, isEmulated, searchParams, updateFilterModel, resellers } =
    params;

  const handleSearchtextInput = debounce((e: BaseSyntheticEvent) => {
    const inputValue = e.target.value;
    const regNoRegex1 = /((.{5})\|(.{5})\|(.{5})\|(.{5})\|(.{5}))/;
    const regNoRegex2 = /((.{5})-(.{5})-(.{5})-(.{5})-(.{5}))/;

    if (regNoRegex1.test(inputValue) || regNoRegex2.test(inputValue)) {
      updateFilterModel([
        { columnField: "loginName", operatorValue: "equals", value: "", id: "loginName" },
        {
          columnField: "registrationNumber",
          operatorValue: "equals",
          value: inputValue,
          id: "regNo",
        },
      ]);
    } else {
      updateFilterModel([
        { columnField: "loginName", operatorValue: "equals", value: inputValue ?? "", id: "loginName" },
        { columnField: "registrationNumber", operatorValue: "equals", value: "", id: "regNo" },
      ]);
    }
  }, 750);

  const handleResellerChange = (e: SelectChangeEvent) => {
    const inputValue = e.target.value;
    updateFilterModel([{ columnField: "reseller", operatorValue: "is", value: inputValue ?? "", id: "reseller" }]);
  };
  return (
    <>
      <GridToolbarContainer>
        {isDistributor ? (
          <>
            <GridToolbarFilterButton />
            <FormControl variant="standard" sx={{ marginTop: "-16px", marginRight: 1, marginLeft: 2, width: 250 }}>
              <InputLabel>Reseller</InputLabel>
              <Select
                size="small"
                value={searchParams && searchParams.resellerName ? searchParams.resellerName : ""}
                onChange={handleResellerChange}
                label="Reseller"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {resellers &&
                  resellers.map((reseller) => {
                    return (
                      <MenuItem key={`reseller-${reseller.uid}`} value={reseller.name}>
                        {reseller.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <TextField
              label={t("LicenseCenter.Actions.LoginSearch")}
              variant="standard"
              size="small"
              onChange={handleSearchtextInput}
              sx={{ flexGrow: 1, marginTop: "-16px" }}
            />
            <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
          </>
        ) : (
          <>
            <GridToolbarFilterButton />
            <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
            <GridToolbarQuickFilter
              debounceMs={750}
              disabled={gridIsLoading}
              variant="standard"
              sx={{ flexGrow: 1 }}
              inputProps={{ "data-testid": "license-center-toolbar_quick-filter-input" }}
            />
            <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
          </>
        )}
        <Button
          size="small"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => licenseAddDialogRef.current?.openDialog()}
          disabled={gridIsLoading || isEmulated}
        >
          {t("LicenseCenter.Actions.AddLicense")}
        </Button>
        <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
        <GridToolbarExport disabled={gridIsLoading} />
      </GridToolbarContainer>
    </>
  );
}
