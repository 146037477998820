import * as Yup from "yup";
import { TFunction } from "i18next";
import { ObjectSchema } from "yup";

export type RequestOfferFormValues = {
  product: string;
  additionalModules?: string[];
  licenseVolume?: number;
  licenseDuration?: number;
  message: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<RequestOfferFormValues> => {
  return Yup.object().shape({
    product: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Product"),
      }) ?? ""
    ),
    additionalModules: Yup.array().optional(),
    licenseVolume: Yup.number()
      .moreThan(
        0,
        t("Forms.MoreThan", {
          field: t("Forms.LicenseVolume"),
          value: 0,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.LicenseVolume"),
        }) ?? ""
      ),
    licenseDuration: Yup.number()
      .moreThan(
        -1,
        t("Forms.MoreThan", {
          field: t("Forms.LicenseDuration"),
          value: 0,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.LicenseDuration"),
        }) ?? ""
      ),
    message: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Message"),
      }) ?? ""
    ),
  });
};
