import React from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Tooltip } from "@mui/material";

function GridActionsCellItemWithTooltip(props: GridActionsCellItemProps) {
  if (props.disabled) {
    return <GridActionsCellItem {...props} ref={props.ref as React.RefObject<HTMLButtonElement>} />;
  }
  return (
    <Tooltip title={props.label}>
      <GridActionsCellItem {...props} ref={props.ref as React.RefObject<HTMLButtonElement>} />
    </Tooltip>
  );
}

export default GridActionsCellItemWithTooltip;
