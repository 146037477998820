import axios from "./../lib/axiosForApi";
import TokenUtils from "../utils/TokenUtils";

class AuthApi {
  /*
    Log the user in using their login data,
    create an access token and store it in the client local storage
    HTTP Response: 200 on success, 403 on invalid login credentials
   */
  async login(username: string, password: string) {
    const response = await axios.post("/auth/login", { username, password });
    TokenUtils.setTokens(response.data.accessToken, response.data.refreshToken);
  }

  /*
    Attempts to fetch and store a new AccessToken using the stored RefreshToken.
   */
  async refresh(): Promise<string> {
    const refreshToken = TokenUtils.getRefreshToken();
    if (refreshToken) {
      const response = await axios.post("/auth/refresh", { refreshToken: refreshToken });
      TokenUtils.setTokens(response.data.accessToken, response.data.refreshToken);
      return response.data.accessToken;
    }
    throw new Error(`No token provided`);
  }

  /*
    Log out the currently logged-in user
    by deleting the access token from the client local storage
   */
  async logout() {
    await axios.get("/auth/logout");
    TokenUtils.deleteTokens();
  }

  /*
    Determine whether the stored access token is valid
   */
  isValid() {
    const accessToken = TokenUtils.getAccessToken();

    if (accessToken) {
      try {
        if (!TokenUtils.isExpired(accessToken)) {
          return true;
        }
      } catch (err) {
        TokenUtils.deleteTokens();
        return false;
      }
    }
    TokenUtils.deleteTokens();
    return false;
  }
}

export default new AuthApi();
