import { TFunction } from "i18next";
import * as Yup from "yup";

export type EndpointProtectionTrialsFormValues = {
  companyName: string;
  customerFirstName: string;
  customerLastName: string;
  customerMail: string;
  customerCountry: string;
  customerPostcode: string;
  licenseCount: number;
  productCode: string;
  oldProduct?: string;
  additionalMessage?: string;
  reseller?: number;
};

export const getValidationSchema = (t: TFunction): Yup.ObjectSchema<EndpointProtectionTrialsFormValues> => {
  return Yup.object().shape({
    companyName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.CompanyLabel"),
        }) ?? ""
      ),
    customerFirstName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.FirstNameLabel"),
        }) ?? ""
      ),
    customerLastName: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.LastNameLabel"),
        }) ?? ""
      ),
    customerMail: Yup.string()
      .email(t("Forms.ValidEmail") ?? "")
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.CustomerMailLabel"),
        }) ?? ""
      ),
    customerCountry: Yup.string()
      .max(
        255,
        t("Forms.TooLong", {
          maxLength: "255",
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Trials.Form.CountryLabel"),
        }) ?? ""
      ),
    licenseCount: Yup.number().required(
      t("Forms.FieldIsRequired", {
        field: t("Trials.Form.LicenseCountLabel"),
      }) ?? ""
    ),
    productCode: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Trials.Form.ProductLabel"),
      }) ?? ""
    ),
    oldProduct: Yup.string().optional(),
    additionalMessage: Yup.string().optional(),
    reseller: Yup.number().optional(),
    customerPostcode: Yup.string()
      .ensure()
      .when("customerCountry", ([customerCountry], schema) => {
        return !["BRA", "ECU", "CHL", "MEX", "PER", "VEN", "PAN", "BOL", "COL"].includes(customerCountry)
          ? schema.required(
              t("Forms.FieldIsRequired", {
                field: t("Trials.Form.PostcodeLabel"),
              })
            )
          : schema;
      }),
  });
};
