import axios from "./../lib/axiosForApi";
import { AxiosResponse } from "axios";
import { Contract } from "../types/contracts";

class ContractsApi {
  /*
    Return all contracts of the logged-in user
    HTTP Response: 200 on success with {"success": true, "contracts": [...]} payload, 200 on failure with {"success": false} payload, 401 on invalid auth token
   */
  async getAll(): Promise<Contract[]> {
    const response = await axios.get("/contracts/get-all");

    return response.data.success ? response.data.contracts : null;
  }

  /*
    Return all unsigned contracts of the logged-in user
    HTTP Response: 200 on success with {"success": true, "contracts": [...]} payload, 200 on failure with {"success": false} payload, 401 on invalid auth token
   */
  async getAllUnsigned(): Promise<Contract[]> {
    const response = await axios.get("/contracts/get-all-unsigned");

    return response.status === 200 ? response.data : null;
  }

  /*
    Mark a contract as "pending". Once the data is synced from CRM, the contract is then marked as "signed".
    HTTP Response: 200 on success with {"success": true} payload, 200 on failure with {"success": false} payload, 401 on invalid auth token
   */
  async sign(contract: Contract, language: string): Promise<AxiosResponse> {
    return axios.post("/contracts/sign", {
      agreementId: contract.agreementId,
      language: language,
    });
  }

  /*
    Download the contract as a PDF file
    HTTP Response: 200 on success, 404 if the PDF file could not be found
   */
  async download(contract: Contract): Promise<AxiosResponse> {
    return axios.get("/contracts/get-pdf", {
      params: {
        contractType: contract.contractType,
        signedDate: contract.signedDate,
      },
      responseType: "blob",
    });
  }

  /*
    Renders a preview of a PDF file
    HTTP Response: 200 on success, 404 if the PDF file could not be rendered
   */
  async preview(contract: Contract, language: string): Promise<Blob | null> {
    try {
      const response = await axios.post(
        "/contracts/preview",
        {
          contractType: contract.contractType,
          language: language,
        },
        { responseType: "blob" }
      );
      return new Blob([response.data], { type: "application/pdf;charset=utf-8" });
    } catch (e) {
      return null;
    }
  }
}

export default new ContractsApi();
