import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import TeamMemberTile from "./components/TeamMemberTile";
import useAuth from "../../contextProviders/Authentication";
import Page from "../../components/Page";
import { Box, Grid, Skeleton, Tabs, Typography } from "@mui/material";
import { useSearchContacts } from "../../lib/queryClient";
import { Contact } from "../../types/contacts";
import getCountryData from "../../utils/CountryDataUtils";
import TabPanel from "../../components/TabPanel";
import Tab from "../../components/Tab";
import { useParams } from "react-router-dom";

const renderContacts = (groupTitle: string, data: Contact[], category: number) => {
  return data ? (
    <>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        {groupTitle}
      </Typography>
      <Grid container spacing={2} mb={4}>
        {data
          .filter((_contact) => _contact.categories.indexOf(category.toString()) > -1)
          .map((contact, index) => (
            <Grid item xs={12} md={4} lg={3} sx={{ pb: 2 }} key={`cat${category.toString()}-${index}`}>
              <TeamMemberTile key={`cat${category.toString()}-${index}`} teamMember={contact} />
            </Grid>
          ))}
      </Grid>
    </>
  ) : (
    <></>
  );
};

const renderLoader = () => {
  return (
    <>
      <Typography noWrap variant="h4" sx={{ mb: 2, mt: 4 }}>
        <Skeleton width={350} />
      </Typography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={4} lg={3} sx={{ pb: 2 }}>
          <Skeleton variant="rectangular" width="100%" height="auto" sx={{ aspectRatio: "0.72" }} />
        </Grid>
        <Grid item xs={12} md={4} lg={3} sx={{ pb: 2 }}>
          <Skeleton variant="rectangular" width="100%" height="auto" sx={{ aspectRatio: "0.72" }} />
        </Grid>
      </Grid>
    </>
  );
};

const Team: FC = () => {
  const { t } = useTranslation();
  const countries = ["deu", "aut", "che"];
  let { tabName } = useParams();
  const { user } = useAuth();
  const { data: contactData, isLoading } = useSearchContacts();

  const initTab = (_countries: Array<string>) => {
    if (tabName === undefined) {
      tabName = _countries.indexOf(user !== undefined ? user.country : "") !== -1 ? (user?.country as string) : "deu";
    }
    return tabName;
  };

  const tabToDisplay = initTab(countries);

  return (
    <Page title={t("Team.Title")} subtitle={t("Sidebar.Knowledgebase.Title")}>
      <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabToDisplay}>
          {countries.map((country) => (
            <Tab
              key={`tablink-${country}`}
              label={getCountryData("iso3", country.toUpperCase()).shortName}
              value={country}
            />
          ))}
        </Tabs>
      </Box>
      {isLoading ? (
        renderLoader()
      ) : contactData ? (
        <>
          <TabPanel activeTab={tabToDisplay} tabName="deu">
            {renderContacts("Head of Sales", contactData, 13)}
            {renderContacts("Regional Sales Team", contactData, 14)}
            {renderContacts("Sales Engineers", contactData, 15)}
          </TabPanel>
          <TabPanel activeTab={tabToDisplay} tabName="aut">
            {renderContacts("", contactData, 127)}
          </TabPanel>
          <TabPanel activeTab={tabToDisplay} tabName="che">
            {renderContacts("", contactData, 128)}
          </TabPanel>
        </>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default Team;
