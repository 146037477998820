import { TFunction } from "i18next";
import * as Yup from "yup";
import { ObjectSchema } from "yup";

export type LostPasswordFormValues = {
  email: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<LostPasswordFormValues> => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t("Forms.ValidEmail") ?? "")
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("Forms.Email"),
        }) ?? ""
      ),
  });
};
