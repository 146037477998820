import { TFunction } from "i18next";
import * as Yup from "yup";
import { ObjectSchema } from "yup";
import { LicenseRemoveParameters } from "../../../types/license";

export const getValidationSchema = (
  t: TFunction
): ObjectSchema<Omit<LicenseRemoveParameters, "registrationNumber">> => {
  const errorMsgReason = t("Forms.FieldIsRequired", {
    field: t("Forms.Reason"),
  });
  const errorMsgCompetitor = t("Forms.FieldIsRequired", {
    field: t("Forms.Competitor"),
  });
  return Yup.object().shape({
    reason: Yup.number().required(errorMsgReason).min(1, errorMsgReason),
    competitor: Yup.number().when("reason", {
      is: 1,
      then: (schema) => schema.required(errorMsgCompetitor).min(1, errorMsgCompetitor),
      otherwise: (schema) => schema.nullable(),
    }),
  });
};
