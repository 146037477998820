import { useEffect, useState, FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import SplashScreen from "../../components/SplashScreen";
import LostPasswordContainer from "./components/LostPasswordContainer";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { UserApi } from "../../api";

type ResetPasswordState = "initLoading" | "resetForm" | "tokenNotValid" | "resetComplete";

const ResetPassword: FC = () => {
  const [view, setView] = useState<ResetPasswordState>("initLoading");
  const { resetHash } = useParams() as { resetHash: string };
  const { t } = useTranslation();

  useEffect(() => {
    UserApi.verifyPasswordResetHash(resetHash)
      .then((isValid) => {
        if (isValid) {
          setView("resetForm");
        } else {
          setView("tokenNotValid");
        }
      })
      .catch(() => {
        setView("tokenNotValid");
      });
  }, [resetHash]);

  function handleResetComplete() {
    setView("resetComplete");
  }

  switch (view) {
    case "resetForm":
      return (
        <LostPasswordContainer
          headline={t("LostPassword.Reset.Headline")}
          subheader={t("LostPassword.Reset.Subheader")}
        >
          <ResetPasswordForm onSuccessfulSubmit={handleResetComplete} resetHash={resetHash} />
        </LostPasswordContainer>
      );
    case "tokenNotValid":
      return (
        <LostPasswordContainer
          headline={t("Common.UnspecifiedError")}
          subheader={t("LostPassword.Reset.ErrorDescription")}
        />
      );
    case "resetComplete":
      return (
        <LostPasswordContainer
          headline={t("LostPassword.ResetComplete.Headline")}
          subheader={t("LostPassword.ResetComplete.Info")}
        >
          <Typography variant="body2" align="center">
            <Button component={Link} color="primary" variant="text" to="/login">
              {t("LostPassword.ResetComplete.ToLogin")}
            </Button>
          </Typography>
        </LostPasswordContainer>
      );
    default:
      return <SplashScreen />;
  }
};

export default ResetPassword;
