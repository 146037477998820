import { BaseSyntheticEvent, FC, MouseEventHandler } from "react";
import { debounce } from "lodash";
import { styled } from "@mui/material/styles";
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import * as React from "react";
import useDownloads from "../contextProviders/DownloadsContext";
import { DownloadSortingType } from "../../../types/downloads";

interface DownloadsHeaderProps {
  handleFilterDrawerToggle: MouseEventHandler;
}

const IconButton = styled(Button)(() => ({
  paddingTop: "16px",
  paddingBottom: "16px",
}));

const DownloadsHeader: FC<DownloadsHeaderProps> = (props) => {
  const { handleFilterDrawerToggle } = props;
  const { t } = useTranslation();
  const sorting: DownloadSortingType[] = ["title", "publishDate"];
  const { sortingType, sortingOrder, setDownloadSearchText, setDownloadSortingType, setDownloadSortingOrder } =
    useDownloads();

  const handleSearchtextInput = debounce((e: BaseSyntheticEvent) => {
    setDownloadSearchText(e.target.value);
  }, 500);

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
      <Grid item xs={4} sm={2} md={1} sx={{ display: { lg: "none" } }}>
        <IconButton fullWidth variant="contained" size="large" onClick={handleFilterDrawerToggle}>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid item xs={8} sm={10} md={6} lg={8}>
        <TextField
          fullWidth
          variant="outlined"
          name="search"
          type="search"
          label={t("Downloads.Search")}
          onChange={handleSearchtextInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlinedIcon color="inherit" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={4} sm={2} md={1} order={{ md: 4 }}>
        <IconButton
          fullWidth
          variant="contained"
          size="large"
          onClick={() => setDownloadSortingOrder(sortingOrder === "asc" ? "desc" : "asc")}
        >
          {sortingOrder === "asc" ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={8} sm={10} md={4} lg={3} order={{ md: 3 }}>
        <FormControl fullWidth>
          <InputLabel>{t("Downloads.Form.SortingLabel")}</InputLabel>
          <Select
            id="sorting"
            value={sortingType}
            label={t("Downloads.Form.SortingLabel")}
            onChange={(e) => setDownloadSortingType(e.target.value as DownloadSortingType)}
          >
            {sorting.map((value: string) => (
              <MenuItem key={value} value={value}>
                {t(`Downloads.Form.Sorting.${value}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DownloadsHeader;
