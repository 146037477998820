import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Skeleton, TextField as MuiTextField, Typography } from "@mui/material";
import { MfaApi } from "../../../api";
import QRCode from "qrcode";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { TextField } from "../../../components/formFields";
import { VerifyFormValues, getValidationSchema } from "../formValidations/verify";
import RecoveryCodesDialogContent from "./GenerateRecoveryCodes";

interface GenerateOneTimePasswordProps {
  handleSetIsActive: () => void;
  handleCloseDialog: () => void;
}

const ActivateOneTimePassword: FC<GenerateOneTimePasswordProps> = ({ handleSetIsActive, handleCloseDialog }) => {
  const { t } = useTranslation();
  const [secret, setSecret] = useState<string>("");
  const [checksum, setChecksum] = useState<string>("");
  const [qrCode, setQrCode] = useState<string>("");
  const [showRecoveryCodes, setShowRecoveryCodes] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: VerifyFormValues = {
    verificationCode: "",
  };

  useEffect(() => {
    MfaApi.oneTimePasswordGenerate().then((response) => {
      setSecret(response.secret);
      setChecksum(response.checksum);
      QRCode.toDataURL(response.totpAuthUrl).then((url: string) => {
        setQrCode(url);
      });
    });
  }, []);

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t, "one-time-password"),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      resetForm({});

      MfaApi.oneTimePasswordActivate(values.verificationCode, secret, checksum)
        .then((isActivated) => {
          if (isActivated) {
            setStatus({ success: true });
            setShowRecoveryCodes(true);
            handleSetIsActive();
            enqueueSnackbar(t("Mfa.Messages.SuccessfullyActivated"), { variant: "success" });
          } else {
            setStatus({ success: false });
            enqueueSnackbar(t("Mfa.Messages.InvalidVerificationCode"), { variant: "error" });
          }
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <Grid container spacing={2}>
      {showRecoveryCodes ? (
        <Grid item xs={12}>
          <RecoveryCodesDialogContent handleCloseDialog={handleCloseDialog} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={7}>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
              {t("Mfa.OneTimePassword.Activate.ScanCode.Title")}
            </Typography>
            <Typography sx={{ mb: 2 }}>{t("Mfa.OneTimePassword.Activate.ScanCode.Description")}</Typography>
            {qrCode !== "" ? (
              <img src={`${qrCode}`} width="210" alt="Code" />
            ) : (
              <Skeleton variant="rectangular" width={210} height={210} />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
              {t("Mfa.OneTimePassword.Activate.CopySecret.Title")}
            </Typography>
            <Typography sx={{ mb: 1 }}>{t("Mfa.OneTimePassword.Activate.CopySecret.Description")}</Typography>
            {secret !== "" ? (
              <MuiTextField
                fullWidth
                size="small"
                name="secret"
                disabled
                InputProps={{
                  readOnly: true,
                }}
                value={secret}
              />
            ) : (
              <Skeleton variant="rectangular" height={40} sx={{ mt: 1 }} />
            )}
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
              {t("Mfa.OneTimePassword.Activate.VerifyCode.Title")}
            </Typography>
            <Typography sx={{ mb: 1 }}>{t("Mfa.OneTimePassword.Activate.VerifyCode.Description")}</Typography>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                fullWidth
                size="small"
                autoComplete="off"
                disabled={formik.isSubmitting || secret === ""}
                formik={formik}
                label={t("Mfa.VerifyForm.VerificationCode")}
                name="verificationCode"
                type="string"
                required
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  minLength: 6,
                  maxLength: 6,
                }}
              />
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ mt: 4, mb: 1 }}
                  disabled={!formik.dirty || formik.isSubmitting}
                >
                  {t("Mfa.OneTimePassword.Activate.SubmitBtn")}
                </Button>
              </Grid>
            </form>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ActivateOneTimePassword;
