import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import ContentBox from "../../components/ContentBox";
import ContractsTable from "./components/ContractsTable";
import { Typography } from "@mui/material";

const Contracts: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Contracts.Title")} subtitle={t("Sidebar.Information.Title")}>
      <ContentBox>
        <Typography sx={{ mb: 4 }} dangerouslySetInnerHTML={{ __html: t("Contracts.Description") }} />
        <ContractsTable />
      </ContentBox>
    </Page>
  );
};

export default Contracts;
