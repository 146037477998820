import { TFunction } from "i18next";
import { ObjectSchema } from "yup";
import * as Yup from "yup";

export type CheckLicenseFormValues = {
  registrationNumberOrLogin: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<CheckLicenseFormValues> => {
  return Yup.object().shape({
    registrationNumberOrLogin: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("LicenseCenter.CheckLicense.Form.RegistrationNumberOrLogin"),
      }) as string
    ),
  });
};
