import { TFunction } from "i18next";
import * as Yup from "yup";
import { ObjectSchema } from "yup";

export type CompanyDataFormValues = {
  address: string;
  zip: string;
  city: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<CompanyDataFormValues> => {
  return Yup.object().shape({
    address: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("Forms.Address", "Current Address"),
      }) ?? ""
    ),
    zip: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("Forms.Zip", "Current Zip"),
      }) ?? ""
    ),
    city: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("Forms.City", "Current City"),
      }) ?? ""
    ),
  });
};
