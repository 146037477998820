import { TFunction } from "i18next";
import { ObjectSchema } from "yup";
import * as Yup from "yup";
import { LicenseChangeParameters, LicenseSettings } from "../../../types/license";

export type EditLicenseFormValues = Omit<LicenseChangeParameters, "registrationNumber">;

export const getValidationSchema = (t: TFunction, settings: LicenseSettings): ObjectSchema<EditLicenseFormValues> => {
  return Yup.object().shape({
    productCode: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("LicenseCenter.EditLicense.Form.NewProduct"),
      }) ?? ""
    ),
    licenseCount: Yup.number()
      .min(
        settings.minLicenseCount,
        t("Forms.Min", {
          field: t("LicenseCenter.EditLicense.Form.NewLicenseCount"),
          minValue: settings.minLicenseCount,
        }) ?? ""
      )
      .max(
        settings.maxLicenseCount,
        t("Forms.Max", {
          field: t("LicenseCenter.EditLicense.Form.NewLicenseCount"),
          maxValue: settings.maxLicenseCount,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("LicenseCenter.EditLicense.Form.NewLicenseCount"),
        }) ?? ""
      ),
    duration: Yup.number().required(
      t("Forms.FieldIsRequired", {
        field: t("LicenseCenter.EditLicense.Form.NewExpirationDate"),
      }) ?? ""
    ),
    subscription: Yup.boolean()
      .oneOf([true, false], t("Forms.Required") ?? "")
      .required(t("Forms.Required") ?? ""),
  });
};
