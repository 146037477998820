import type { FC, ReactNode } from "react";
import { useLocation, matchRoutes } from "react-router-dom";
import useAuth from "../contextProviders/Authentication";
import routes from "../routes";
import UserUtils from "../utils/UserUtils";
import { Error403 } from "../pages/errors";
import { AppRouteObject } from "../types/app";
import { UserRoles } from "../types/user";
import { RouteObject } from "react-router";

interface RoleGuardProps {
  children: ReactNode;
  allowedRoles?: UserRoles;
  renderError?: boolean;
}

const RoleGuard: FC<RoleGuardProps> = (props) => {
  const { children, allowedRoles, renderError } = props;
  const { user } = useAuth();
  const location = useLocation();

  let allowedRolesToCheck: UserRoles | undefined = undefined;

  if (!allowedRoles) {
    // no allowedRoles set, get allowedRoles from routing-configuration
    const matched = matchRoutes(routes as RouteObject[], location.pathname);
    if (matched !== null) {
      for (let i = 0; i < matched.length; i++) {
        if ((matched[i].route as AppRouteObject).allowedRoles) {
          allowedRolesToCheck = (matched[i].route as AppRouteObject).allowedRoles;
        }
      }
    }
  } else {
    allowedRolesToCheck = allowedRoles;
  }

  if (!UserUtils.hasPermission(allowedRolesToCheck, user)) {
    return renderError ? <Error403 /> : <></>;
  }

  return <>{children}</>;
};

export default RoleGuard;
