import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, FormHelperText, FormLabel, Switch, Typography } from "@mui/material";
import ContentBox from "../../../components/ContentBox";
import useAuth from "../../../contextProviders/Authentication";
import { UserApi } from "../../../api";
import { useSnackbar } from "notistack";

const AdvertisementSettings: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isAccepted, setIsAccepted] = useState(user ? user.adConsent === 2 : false);

  const acceptAdvertising = () => {
    const accepted = true;
    UserApi.acceptAdvertising(accepted)
      .then((response) => {
        if (response.status === 200) {
          setIsAccepted(accepted);
          enqueueSnackbar(t("Settings.SaveSuccess"), { variant: "success" });
        } else {
          enqueueSnackbar(t("Settings.SaveError"), { variant: "error" });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("Settings.SaveError"), { variant: "error" });
      });
  };

  return (
    <ContentBox headline={t("Settings.Advertisement.Title")}>
      <Typography sx={{ mb: 3 }}>{t("Settings.Advertisement.Description")}</Typography>
      <FormLabel>{t("Settings.Advertisement.Title")}</FormLabel>
      <FormControlLabel
        control={<Switch sx={{ ml: 1 }} onChange={acceptAdvertising} checked={isAccepted} disabled={isAccepted} />}
        label={t("Settings.Advertisement.Label")}
      />
      <FormHelperText>
        {t("Settings.Advertisement.Help") + (isAccepted ? t("Settings.Advertisement.HelpAddition") : "")}
      </FormHelperText>
    </ContentBox>
  );
};

export default AdvertisementSettings;
