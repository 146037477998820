import { FC } from "react";
import { Alert, Box, Button, Link, Paper, Typography, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "../../contextProviders/Authentication";
import { useSnackbar } from "notistack";
import { VerifyFormValues, getValidationSchema } from "./formValidations/verify";
import { MfaApi } from "../../api";
import { useFormik } from "formik";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import TextField from "../../components/formFields/TextField";

const MfaVerify: FC<{ verificationType: "one-time-password" | "recovery-code" }> = ({ verificationType }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setMfaVerified } = useAuth();
  const navigate = useNavigate();
  const initialFormValues: VerifyFormValues = {
    verificationCode: "",
  };

  const typeSettings = {
    "one-time-password": {
      verificationCodeLength: 6,
      error: t("Mfa.Messages.InvalidVerificationCode"),
      description: t("Mfa.OneTimePassword.Verify.Description"),
      link: "/mfa/verify/recovery-code",
      linkLabel: t("Mfa.OneTimePassword.Verify.UseRecoveryCode"),
      formLabel: t("Mfa.VerifyForm.VerificationCode"),
    },
    "recovery-code": {
      verificationCodeLength: 8,
      error: t("Mfa.Messages.InvalidRecoveryCode"),
      description: t("Mfa.RecoveryCodes.Verify.Description"),
      link: "/mfa/verify/one-time-password",
      linkLabel: t("Mfa.RecoveryCodes.Verify.UseOneTimePassword"),
      formLabel: t("Mfa.VerifyForm.RecoveryCode"),
    },
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t, verificationType),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      resetForm({});

      MfaApi.verify(values.verificationCode, verificationType)
        .then((response) => {
          if (response.isValid) {
            setStatus({ success: true });
            setMfaVerified().then(() => {
              navigate("/");
            });
          } else {
            setStatus({ success: false });

            if (response.isLocked) {
              enqueueSnackbar(t("Mfa.Messages.LoginLocked"), { variant: "error" });
            } else {
              enqueueSnackbar(typeSettings[verificationType].error, { variant: "error" });
            }
          }
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <Zoom in timeout={500}>
      <Paper sx={{ maxWidth: "480px" }}>
        <Typography variant="h1" sx={{ mb: 1 }}>
          {t("Mfa.Title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("Login.Subheader")}
        </Typography>
        <Box sx={{ mt: 4 }}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Alert variant="outlined" severity="info" sx={{ mb: 4 }}>
              {typeSettings[verificationType].description}
            </Alert>
            <TextField
              autoComplete="off"
              formik={formik}
              label={typeSettings[verificationType].formLabel}
              name="verificationCode"
              type="string"
              autoFocus={true}
              required
              disabled={formik.isSubmitting}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                minLength: typeSettings[verificationType].verificationCodeLength,
                maxLength: typeSettings[verificationType].verificationCodeLength,
              }}
            />
            <Button
              color="primary"
              disabled={!formik.dirty || formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t("Mfa.VerifyForm.AuthenticateBtn")}
            </Button>
            <Typography variant="body2" align="center" pt={2}>
              <Link component={RouterLink} to={typeSettings[verificationType].link} underline="none">
                {typeSettings[verificationType].linkLabel}
              </Link>
            </Typography>
          </form>
        </Box>
      </Paper>
    </Zoom>
  );
};

export default MfaVerify;
