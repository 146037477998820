import React, { FC } from "react";
import { Typography } from "@mui/material";
import useAuth from "../../../contextProviders/Authentication";
import { useTranslation } from "react-i18next";
export const BasketAddressData: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3" sx={{ mb: 1 }}>
        {t("Basket.YourData")}
      </Typography>
      {user && (
        <>
          <Typography>{user.company}</Typography>
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography>{user.address}</Typography>
          <Typography>
            {user.zip} {user.city}
          </Typography>
          <Typography>{user.email}</Typography>
        </>
      )}
    </>
  );
};
