import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import { SvgIcon } from "@mui/material";

function Presentation(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 1022 1024" {...props}>
      <path d="M919.552 241.664v416.768q0 14.336-10.752 25.088t-25.088 10.752h-350.208v208.896h117.76q19.456 2.048 33.792 14.336t19.456 29.696h-385.024q5.12-17.408 19.456-29.696t33.792-14.336h116.736v-208.896h-350.208q-14.336 0-24.576-10.752t-10.24-25.088v-416.768q-28.672 0-40.96-10.752t-12.288-25.088v-93.184q0-14.336 10.24-25.088t24.576-10.752h850.944q14.336 0 25.088 10.752t10.752 25.088v93.184q0 14.336-12.8 25.088t-40.448 10.752zM927.744 121.856h-832.512v74.752h832.512v-74.752zM874.496 241.664h-726.016v407.552h726.016v-407.552zM677.888 553.984q-23.552 0-44.544-9.216t-36.864-24.576-25.088-36.352-9.216-45.568q0-23.552 9.216-44.544t25.088-36.352 36.864-24.576 44.544-9.216 44.544 9.216 36.864 24.576 25.088 36.352 9.216 44.544q0 24.576-9.216 45.568t-25.088 36.352-36.864 24.576-44.544 9.216zM249.856 388.096v-45.056h231.424v45.056h-231.424zM249.856 534.528v-45.056h231.424v45.056h-231.424zM677.888 367.616q-29.696 0-50.176 20.48t-20.48 50.176 20.48 50.688 50.176 20.992 50.176-20.992 20.48-50.688-20.48-50.176-50.176-20.48z"></path>
    </SvgIcon>
  );
}

export default Presentation;
