import React from "react";
import { BrowserHistory, createBrowserHistory } from "history";
import { Router } from "react-router";

// Fork of the BrowserRouter from react-router. Exposes the history instance.

export const browserHistory = createBrowserHistory({ window });

export interface BrowserRouterProps {
  children?: React.ReactNode;
}

/**
 * A <Router> for use in web browsers. Provides the cleanest URLs.
 */
export default function BrowserRouter({
  children,
}: // window,
BrowserRouterProps): React.ReactElement {
  const historyRef = React.useRef<BrowserHistory>(browserHistory);

  const history = historyRef.current;
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      // eslint-disable-next-line react/no-children-prop
      children={children}
      location={state.location}
      navigator={history}
    />
  );
}
