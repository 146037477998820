import type { FC } from "react";
import { Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
  subtitle?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title, subtitle }) => {
  return (
    <header>
      {subtitle && (
        <Typography color="textPrimary" variant="overline" height=".875rem" display="inline-block">
          {subtitle}
        </Typography>
      )}
      <Typography color="textPrimary" component="h2" variant="h4" mb={1} data-testid={"page_title"}>
        {title}
      </Typography>
    </header>
  );
};

export default PageTitle;
