import React, {
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import ContractsSignForm from "./ContractsSignForm";
import { Contract } from "../../../types/contracts";

export interface IContractSignDialogApi {
  openDialog: (contract: Contract) => void;
}

interface IContractSignDialogProps {
  ref: MutableRefObject<IContractSignDialogApi>;
}

const ContractSignDialog: ForwardRefExoticComponent<
  Omit<IContractSignDialogProps, "ref"> & RefAttributes<IContractSignDialogApi>
> = forwardRef((props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [contract, setContract] = useState<Contract>();
  const { t } = useTranslation();

  const closeDialog = () => {
    setOpen(false);
  };

  const openDialog = (contractToSign: Contract) => {
    setContract(contractToSign);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openDialog: openDialog,
  }));

  return (
    <Dialog
      show={open}
      handleClose={closeDialog}
      title={t("Contracts.Sign")}
      noContentPadding
      content={contract && <ContractsSignForm contract={contract} handleCloseSignDialog={closeDialog} />}
    />
  );
});

export default ContractSignDialog;
