import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";
import LanguageSelect from "../../../components/LanguageSelect";
import ContentBox from "../../../components/ContentBox";
import React from "react";
import { useSnackbar } from "notistack";
import { useUserAppSettings } from "../../../contextProviders/UserAppSettings";

const AppSettings: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { settings, setTheme } = useUserAppSettings();

  function handleThemeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const theme = event.target.checked ? "DARK" : "LIGHT";

    setTheme(theme)
      .then(() => {
        enqueueSnackbar(t("Settings.SaveSuccess"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("Settings.SaveError"), { variant: "error" });
      });
  }

  return (
    <ContentBox headline={t("Settings.AppSettings")}>
      <LanguageSelect />
      <FormControlLabel
        sx={{ mt: 4 }}
        control={
          <Switch
            name="darkModeActive"
            color="primary"
            checked={settings.theme === "DARK"}
            onChange={handleThemeChange}
          />
        }
        label={t("Settings.DarkMode").toString()}
      />
    </ContentBox>
  );
};

export default AppSettings;
