import type { FC } from "react";
import * as React from "react";
import { Button, Divider, Grid, Link, MenuItem, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { CheckBox, SelectField, TextField } from "../../../components/formFields";
import useAuth from "../../../contextProviders/Authentication";
import getCountryData, { ICountryData } from "../../../utils/CountryDataUtils";
import { Link as RouterLink } from "react-router-dom";
import { AwarenessTrainingsTrialsFormValues, getValidationSchema } from "../formValidations/AwarenessTrainingTrials";
import { useSnackbar } from "notistack";
import { ContactFormsApi } from "../../../api";
import { useGetCountries } from "../../../lib/queryClient";
import { useMemo, useState } from "react";
import SuccessBox from "../../../components/SuccessBox";

const AwarenessTrainingsTrialsForm: FC = () => {
  const { data: availableCountries } = useGetCountries();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const initialFormValues: AwarenessTrainingsTrialsFormValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    companyName: user?.company || "",
    email: user?.email || "",
    countryCode: user?.country || "",
    postCode: user?.zip || "",
    city: user?.city || "",
    street: user?.address || "",
    privacyPolicy: false,
  };
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      ContactFormsApi.awarenessTrialsSubmit({ ...values, privacyPolicy: values.privacyPolicy === true ? 1 : 0 })
        .then((response) => {
          if (response.data.success) {
            setIsSubmitted(true);
          } else {
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally();
    },
  });

  const countriesList: ICountryData[] = useMemo(() => {
    if (!availableCountries) {
      return [];
    }
    const list: ICountryData[] = [];
    availableCountries.forEach((iso3) => {
      list.push(getCountryData("iso3", iso3));
    });
    return list;
  }, [availableCountries]);

  return user && availableCountries ? (
    isSubmitted ? (
      <SuccessBox
        title={t("RequestOffer.Response.Title")}
        text={t("RequestOffer.Response.Message")}
        buttonEvt={() => setIsSubmitted(false)}
        buttonTitle={t("RequestOffer.Response.BackBtn")}
      />
    ) : (
      <>
        <Typography sx={{ mb: 2 }}>{t("Trials.CDA.Form.Title")}</Typography>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Typography sx={{ mb: 2 }}>{t("Trials.CDA.Form.SubTitle")}</Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            {t("Trials.CDA.Form.DataLabel")}
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                formik={formik}
                name="firstName"
                label={t("Trials.Form.FirstNameLabel")}
                keepSpace={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                formik={formik}
                name="lastName"
                label={t("Trials.Form.LastNameLabel")}
                keepSpace={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                formik={formik}
                name="companyName"
                label={t("Trials.Form.CompanyLabel")}
                keepSpace={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                formik={formik}
                name="postCode"
                label={t("Trials.Form.PostcodeLabel")}
                keepSpace={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={8} md={5}>
              <TextField
                formik={formik}
                name="street"
                label={t("Trials.Form.StreetLabel")}
                keepSpace={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField formik={formik} name="city" label={t("Trials.Form.CityLabel")} keepSpace={false} required />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SelectField
                formik={formik}
                name="countryCode"
                label={t("Trials.Form.CountryLabel")}
                keepSpace={false}
                required
              >
                {countriesList.map((country) => (
                  <MenuItem key={`country-${country.iso2}`} value={country.iso2}>
                    {country.shortName}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                formik={formik}
                name="email"
                label={t("Trials.Form.CustomerMailLabel")}
                keepSpace={false}
                required
              />
            </Grid>
          </Grid>
          <CheckBox
            formik={formik}
            name="privacyPolicy"
            value="1"
            title={t("Trials.CDA.Form.PrivacyPolicy")}
            required
          />
          <Typography sx={{ mt: 2, mb: 4 }}>
            {`${t("Trials.CDA.Form.PrivacyPolicyText1")} `}
            <Link component={RouterLink} to="https://www.gdata.de/privacy" target="_blank" underline="none">
              {`${t("Trials.CDA.Form.PrivacyPolicyText2")}`}
            </Link>
            {` ${t("Trials.CDA.Form.PrivacyPolicyText3")}`}
          </Typography>
          <Grid container justifyContent="flex-end" columnSpacing={2} rowSpacing={1}>
            <Grid item xs="auto">
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!formik.dirty || formik.isSubmitting}
              >
                {t("Trials.CDA.Form.SubmitBtn")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    )
  ) : (
    <>
      <Typography component="div" variant="body1">
        <Skeleton />
      </Typography>
      <Typography component="div" variant="body1" width="70%" sx={{ mb: 2 }}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant="body2" width="50%" sx={{ mb: 5 }}>
        <Skeleton />
      </Typography>
      <Skeleton variant="rectangular" width="100%" height={300} sx={{ mt: 2 }} />
    </>
  );
};

export default AwarenessTrainingsTrialsForm;
