import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { RawHeadline } from "../base";
import { ContentElement } from "../../../../types/typo3Page";

const StyledBox = styled(Box)(() => ({
  "& p": {
    margin: "0 0 0.5em 0",
  },
}));

export const Text: FC<{ data: ContentElement }> = ({ data }) => {
  return (
    <Box>
      <RawHeadline
        header={data.content.header}
        headerLayout={data.content.headerLayout}
        subheader={data.content.subheader}
      />
      <StyledBox
        dangerouslySetInnerHTML={{
          __html: (data.content.bodytext as string).replace(`/app-pages/`, `/app/p/`).replace(/[\r\n]/gm, ""),
        }}
      />
    </Box>
  );
};

export default Text;
