import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  DataGridPro,
  GridColumns,
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridLinkOperator,
} from "@mui/x-data-grid-pro";
import { getLicenseCenterColumnDefinition } from "./LicenseCenterColumnDefinition";
import { getLicenseCenterLocales } from "./LicenseCenterLocales";
import { SupportedLocale } from "../../../locales";
import { LicenseCenterToolbar } from "./LicenseCenterToolbar";
import { LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { LicenseCenterAddLicenseResult, LicenseCenterRow, LicenseCenterSearchParams } from "../../../types/license";
import { LicenseApi } from "../../../api";
import _ from "lodash";
import { useGetBasket, useGetResellerForDistributor } from "../../../lib/queryClient";
import LicenseCenterLicenseEditDialog, { ILicenseCenterLicenseEditDialogApi } from "./LicenseCenterLicenseEditDialog";
import LicenseCenterLicenseRequestDialog, {
  ILicenseCenterLicenseRequestDialogApi,
} from "./LicenseCenterLicenseRequestDialog";
import { useMesExportService } from "../lib/useMesExportService";
import LicenseCenterAddLicenseDialog, { ILicenseCenterAddLicenseDialogApi } from "./LicenseCenterAddLicense";
import { useCancelSubscriptionService } from "../lib/useCancelSubscriptionService";
import { useUserAppSettings } from "../../../contextProviders/UserAppSettings";
import { useShop } from "../../../contextProviders/Shop";

function LicenseCenterDistributorNoRowsOverlay() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
      }}
    >
      <Typography>Keine Lizenzen gefunden</Typography>
    </div>
  );
}

const LicenseCenterDistributorGrid: FC = () => {
  const { t, i18n } = useTranslation();
  const { state: shopState } = useShop();
  const theme = useTheme();
  const { data: resellers } = useGetResellerForDistributor(shopState.emulatedUser?.customerNo ?? undefined);
  const { data: basket } = useGetBasket();
  const { settings: appSettings, setLicenseCenterSettings } = useUserAppSettings();
  const licenseEditDialogRef = useRef<ILicenseCenterLicenseEditDialogApi>(null);
  const licenseRequestDialogRef = useRef<ILicenseCenterLicenseRequestDialogApi>(null);
  const licenseAddDialogRef = useRef<ILicenseCenterAddLicenseDialogApi>(null);
  const { downloadExport } = useMesExportService();
  const { cancelSubscription } = useCancelSubscriptionService();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();

  useEffect(() => {
    if (!columnVisibilityModel && appSettings.licenseCenter?.columns) {
      setColumnVisibilityModel(appSettings.licenseCenter.columns);
    }
  }, [appSettings, columnVisibilityModel]);

  const columns: GridColumns = useMemo(
    () =>
      getLicenseCenterColumnDefinition(
        t,
        theme,
        i18n,
        [],
        true,
        licenseEditDialogRef,
        licenseRequestDialogRef,
        undefined,
        downloadExport,
        cancelSubscription,
        basket,
        resellers ?? [],
        shopState.emulatedUser != null
      ),
    [t, theme, i18n, downloadExport, cancelSubscription, basket, resellers, shopState.emulatedUser]
  );
  const gridLocales = getLicenseCenterLocales(i18n.language as SupportedLocale);

  const [licenseData, setLicenseData] = useState<LicenseCenterRow[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ linkOperator: GridLinkOperator.And, items: [] });
  const [searchParams, setSearchParams] = useState<LicenseCenterSearchParams>({
    page: 0,
    pageSize: 100,
    withResellerInfo: true,
    distributorNo: shopState.emulatedUser ? shopState.emulatedUser.customerNo : undefined,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onLicenseAddSuccess = (response: LicenseCenterAddLicenseResult) => {
    if (response.license) {
      setLicenseData([response.license]);
    }
  };

  useEffect(() => {
    const getValueFor = (column: string, operator?: string): string => {
      const searchParam: { [key: string]: string } = { columnField: column };
      if (operator) {
        searchParam.operatorValue = operator;
      }
      const filter = _.find(filterModel.items, searchParam) ?? null;
      return filter ? (filter as GridFilterItem).value : "";
    };
    setSearchParams((prevParams) => {
      return {
        ...prevParams,
        ...{
          loginName: getValueFor("loginName"),
          registrationNumber: getValueFor("registrationNumber"),
          resellerName: getValueFor("reseller"),
          limitTo: getValueFor("limitDate", "onOrBefore"),
          limitFrom: getValueFor("limitDate", "onOrAfter"),
          productGroup: getValueFor("productName", "is"),
        },
      };
    });
  }, [filterModel]);

  useEffect(() => {
    if (
      searchParams.registrationNumber ||
      (searchParams.loginName && searchParams.loginName.length > 5) ||
      searchParams.resellerName ||
      searchParams.limitTo ||
      searchParams.limitFrom ||
      searchParams.productGroup
    ) {
      setIsLoading(true);
      LicenseApi.search(searchParams)
        .then((result) => {
          setLicenseData(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setLicenseData([]);
    }
  }, [searchParams]);

  return (
    <>
      <DataGridPro
        sx={{ height: "100%", border: "none", ".MuiDataGrid-row:hover": { background: "rgba(86, 145, 209, 0.08)" } }}
        columns={columns}
        disableSelectionOnClick={true}
        rows={licenseData ?? []}
        getRowId={(row) => row.registrationNumber}
        loading={isLoading}
        localeText={gridLocales}
        pageSize={searchParams.pageSize}
        paginationMode="server"
        rowCount={99999}
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {
          setFilterModel(newFilterModel);
        }}
        onPageChange={(newPage) => {
          setSearchParams((prevParams) => {
            return { ...prevParams, ...{ page: newPage } };
          });
        }}
        onPageSizeChange={(newPageSize) => {
          setSearchParams((prevParams) => {
            return { ...prevParams, ...{ pageSize: newPageSize } };
          });
        }}
        rowsPerPageOptions={[100, 250, 500]}
        pagination={true}
        components={{
          NoRowsOverlay: LicenseCenterDistributorNoRowsOverlay,
          Toolbar: LicenseCenterToolbar,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          filterPanel: {
            linkOperators: [GridLinkOperator.And],
          },
          toolbar: {
            gridIsLoading: isLoading,
            isDistributor: true,
            searchParams: searchParams,
            resellers: resellers,
            licenseAddDialogRef: licenseAddDialogRef,
            isEmulated: shopState.emulatedUser != null,
            updateFilterModel: (newFilterItems: GridFilterItem[]) => {
              setFilterModel((prevModel) => {
                const items = filterModel.items;
                newFilterItems.forEach((newFilterItem: GridFilterItem) => {
                  const index = _.findIndex(filterModel.items, { columnField: newFilterItem.columnField });
                  if (index > -1 && !newFilterItem.value) {
                    items.splice(index, 1);
                  } else if (index > -1) {
                    items.splice(index, 1, newFilterItem);
                  } else {
                    items.push(newFilterItem);
                  }
                });
                return { ...prevModel, items: items };
              });
            },
          },

          pagination: {
            page: searchParams.page,
            sx: {
              ".MuiTablePagination-displayedRows": {
                display: "none",
              },
              ".MuiTablePagination-actions": {
                marginLeft: 0,
              },
            },
          },
        }}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
          setLicenseCenterSettings({ columns: newModel, sorting: null }).then();
        }}
        columnVisibilityModel={columnVisibilityModel ?? { unlimited: false, licenseOwner: false }}
      />
      <LicenseCenterLicenseEditDialog ref={licenseEditDialogRef} />
      <LicenseCenterLicenseRequestDialog ref={licenseRequestDialogRef} />
      <LicenseCenterAddLicenseDialog ref={licenseAddDialogRef} onSuccess={onLicenseAddSuccess} />
    </>
  );
};

export default LicenseCenterDistributorGrid;
