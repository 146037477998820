import type { FC } from "react";
import { Grid } from "@mui/material";
import { AdvertisementSettings, AppSettings, PersonalDataForm } from "./components";
import Page from "../../components/Page";
import { useTranslation } from "react-i18next";

const PersonalData: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Sidebar.Settings.PersonalData")} subtitle={t("Sidebar.Settings.Title")}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <AppSettings />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <PersonalDataForm />
        </Grid>
        <Grid item xs={12} md={6} lg={8} ml="auto">
          <AdvertisementSettings />
        </Grid>
      </Grid>
    </Page>
  );
};

export default PersonalData;
