import * as Yup from "yup";
import { TFunction } from "i18next";
import { ObjectSchema } from "yup";

export type ContractFormValues = {
  accepted: boolean;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<ContractFormValues> => {
  return Yup.object().shape({
    accepted: Yup.boolean()
      .oneOf([true], t("Forms.Required") ?? "")
      .required(t("Forms.Required") ?? ""),
  });
};
