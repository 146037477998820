class TokenUtils {
  static getAccessToken() {
    return window.GData.accessToken ?? "";
  }

  static getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  static setAccessToken(accessToken: string) {
    window.GData.accessToken = accessToken;
  }

  static setRefreshToken(refreshToken: string) {
    localStorage.setItem("refreshToken", refreshToken);
  }

  static setTokens(accessToken: string, refreshToken: string) {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  static deleteTokens() {
    localStorage.removeItem("refreshToken");
    window.GData.accessToken = "";
  }

  static decode(token: string) {
    return token ? JSON.parse(atob(token.split(".")[1])) : null;
  }

  static isExpired(token: string, offsetInSeconds = 0) {
    const payload = this.decode(token);
    if (payload) {
      return payload.exp < Math.floor(Date.now() / 1000) + offsetInSeconds;
    }
    return true;
  }
}

export default TokenUtils;
