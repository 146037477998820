import { Faq } from "../types/faqs";
import axios from "../lib/axiosForApi";
import { SupportedLocale } from "../locales";

class FaqsApi {
  async getAll(languageCode: SupportedLocale): Promise<Faq[]> {
    const response = await axios.get("/faq/get", { params: { "language-code": languageCode } });
    return response.status === 200 ? response.data.faq : null;
  }
}

export default new FaqsApi();
