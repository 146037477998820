import React, { BaseSyntheticEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import {
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField as MaterialUiTextField,
} from "@mui/material";
import ContentBox from "../../components/ContentBox";
import UserUtils from "../../utils/UserUtils";
import useAuth from "../../contextProviders/Authentication";
import LicenseCenterDistributorGrid from "./../shop/components/LicenseCenterDistributorGrid";
import LicenseCenterResellerGrid from "./../shop/components/LicenseCenterResellerGrid";
import { ShopAction, useShop } from "../../contextProviders/Shop";
import NewLicenseCalculator from "../shop/components/NewLicenseCalculator";
import { PartnerViewerApi } from "../../api";

const PartnerViewer: FC = () => {
  const { t } = useTranslation();
  const [partnerMail, setPartnerMail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatch: dispatchShop, state: shopState } = useShop();
  const isDistributor = shopState.emulatedUser
    ? UserUtils.hasPermission(["distributor"], shopState.emulatedUser)
    : false;
  const shopAllowed = shopState.emulatedUser ? UserUtils.hasPermission(["shop"], shopState.emulatedUser) : false;
  const licenseCenterAllowed = shopState.emulatedUser
    ? UserUtils.hasPermission(["reseller", "distributor"], shopState.emulatedUser)
    : false;

  const { user } = useAuth();

  const handleEmulateUser = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (partnerMail) {
      setIsLoading(true);
      setErrorMsg("");
      PartnerViewerApi.findUser(partnerMail)
        .then((emulatedUser) => {
          dispatchShop({ type: ShopAction.SetEmulatedUser, payload: { emulatedUser: emulatedUser } });
          if (emulatedUser == null) {
            setErrorMsg(t("PartnerViewer.NotFound", { username: partnerMail }));
          }
        })
        .catch(() => {
          dispatchShop({ type: ShopAction.SetEmulatedUser, payload: { emulatedUser: null } });
          setErrorMsg(t("Common.UnspecifiedError"));
        })
        .finally(() => setIsLoading(false));
    }
  };

  function cleanup() {
    dispatchShop({ type: ShopAction.SetEmulatedUser, payload: { emulatedUser: null } });
  }

  useEffect(() => {
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Page title="Partner Viewer" subtitle="Admin" maxWidth={"xl"}>
        <ContentBox sx={{ mb: 2 }}>
          <form onSubmit={handleEmulateUser}>
            <Stack direction="row" spacing={1}>
              <MaterialUiTextField
                label={t("PartnerViewer.InputLabel")}
                size="small"
                name="partnerMail"
                fullWidth={true}
                sx={{ maxWidth: "300px" }}
                onChange={(e) => setPartnerMail(e.target.value)}
              />
              <Button type="submit" variant="contained" color="primary">
                {t("PartnerViewer.ButtonLabel")}
              </Button>
            </Stack>
          </form>
        </ContentBox>
        {errorMsg && (
          <Alert severity="error" variant="outlined" sx={{ mt: 1, mb: 1 }}>
            {errorMsg}
          </Alert>
        )}
        {isLoading && <LinearProgress />}
        {shopState.emulatedUser && (
          <>
            <ContentBox sx={{ mt: 2 }} headline={t("PartnerViewer.PartnerData")}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: isDistributor ? "#fa1fd0" : "#1f6cfa" }}>
                    {shopState.emulatedUser.firstName[0]}
                    {shopState.emulatedUser.lastName[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${shopState.emulatedUser.firstName} ${shopState.emulatedUser.lastName}`}
                  secondary={
                    <>
                      {shopState.emulatedUser.company} ({shopState.emulatedUser.customerNo})
                      <br />
                      <br />
                      <strong>{t("Common.CustomerNo")}:</strong> {shopState.emulatedUser.customerNo}
                      <br />
                      <strong>{t("PartnerViewer.UserGroups")}:</strong> {shopState.emulatedUser.roles.join(", ")}
                      <br />
                      {shopState.emulatedUser.isBlocked && (
                        <Alert severity="error" variant="outlined" sx={{ mt: 1, mb: 1 }}>
                          {t("PartnerViewer.PartnerIsBlocked")}
                        </Alert>
                      )}
                    </>
                  }
                  secondaryTypographyProps={{
                    paragraph: false,
                  }}
                />
              </ListItem>
            </ContentBox>
            {licenseCenterAllowed && (
              <ContentBox sx={{ mt: 2, mb: 2 }} headline="Lizenzen">
                <Box sx={{ width: "100%", height: "620px" }}>
                  {isDistributor ? (
                    <LicenseCenterDistributorGrid key={`distri-${shopState.emulatedUser.email}`} />
                  ) : (
                    <LicenseCenterResellerGrid key={`reseller-${shopState.emulatedUser.email}`} />
                  )}
                </Box>
              </ContentBox>
            )}
            {shopAllowed && <NewLicenseCalculator key={`new-${shopState.emulatedUser.email}`} />}
          </>
        )}
      </Page>
    </>
  );
};

export default PartnerViewer;
