import { TFunction } from "i18next";
import * as Yup from "yup";
import { CrossgradeConfiguration } from "../../../types/crossgrades";

export const getValidationSchema = (t: TFunction): Yup.ObjectSchema<CrossgradeConfiguration> => {
  return Yup.object().shape({
    product: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Crossgrades.ProductLabel"),
      }) ?? ""
    ),
    licenseCount: Yup.number()
      .moreThan(
        0,
        t("Forms.MoreThan", {
          field: t("Crossgrades.LicenseCountLabel"),
          value: 0,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Crossgrades.LicenseCountLabel"),
        }) ?? ""
      ),
    duration: Yup.number()
      .moreThan(
        0,
        t("Forms.MoreThan", {
          field: t("Crossgrades.LicenseDurationLabel"),
          value: 0,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Crossgrades.LicenseDurationLabel"),
        }) ?? ""
      ),
  });
};
