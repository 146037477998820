import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box, styled, Table, TableBody, TableCell, TableFooter, TableRow } from "@mui/material";
import { ICalculatedPrice } from "../../../api/shop";
import FormatUtils from "../../../utils/FormatUtils";
import { User } from "../../../types/user";
import { SxProps } from "@mui/system";

export interface IPriceBoxProps {
  priceData: ICalculatedPrice;
  showSubscriptionPrice: boolean;
  userForCalculation: User;
  isLoading?: boolean;
  sx?: SxProps;
}

const PriceBoxTable = styled(Table)(({ theme }) => ({
  width: "auto",
  minWidth: "min(100%, 320px)",
  tr: {
    td: {
      borderBottom: "none",
      color: theme.palette.text.primary,
      padding: "4px 0",
      "&:last-child": {
        paddingLeft: "8px",
      },
    },
    "&.subtotal td": {
      color: theme.palette.text.secondary,
    },
  },
  tfoot: {
    margin: "8px 0 0 0",
    tr: {
      td: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.primary,
      },
      "&.sum td": {
        fontSize: theme.typography.h5.fontSize,
        borderTop: `1px solid ${theme.palette.divider}`,
        fontWeight: 700,
      },
    },
  },
  "&.is-loading": {
    "tr td, tfoot tr td": {
      color: theme.palette.text.disabled,
    },
  },
}));

export const PriceBox: FC<IPriceBoxProps> = (props) => {
  const { priceData, showSubscriptionPrice, userForCalculation, isLoading, sx } = props;
  const prices = showSubscriptionPrice ? priceData.subscription : priceData.default;
  const { t, i18n } = useTranslation();
  if (prices == null) {
    return <></>;
  }
  return (
    <Box sx={sx}>
      <PriceBoxTable size="small" className={isLoading ? "is-loading" : ""}>
        <TableBody>
          <TableRow>
            <TableCell>{t("Shop.PriceBox.PriceWithoutRebates")}</TableCell>
            <TableCell align="right">
              {FormatUtils.getFormattedPrice(prices.basePrice, i18n, userForCalculation, true)}
            </TableCell>
          </TableRow>
          {prices.discounts.map((discount, index) => (
            <Fragment key={`price-lines-${index}`}>
              <TableRow>
                <TableCell>{discount.name}</TableCell>
                <TableCell align="right">
                  -{FormatUtils.getFormattedPrice(discount.amountExclVat, i18n, userForCalculation, true)}
                </TableCell>
              </TableRow>
              {index + 1 != prices.discounts.length && (
                <TableRow className="subtotal">
                  <TableCell>Zwischensumme</TableCell>
                  <TableCell align="right">
                    {FormatUtils.getFormattedPrice(discount.subTotalExclVat, i18n, userForCalculation, true)}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow className="sum">
            <TableCell>{t("Shop.PriceBox.YourPrice")}</TableCell>
            <TableCell align="right">
              {FormatUtils.getFormattedPrice(prices.finalPrice, i18n, userForCalculation, true)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("Shop.PriceBox.SellingPrice")}</TableCell>
            <TableCell align="right">
              {FormatUtils.getFormattedPrice(prices.retailPrice, i18n, userForCalculation, true)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </PriceBoxTable>
    </Box>
  );
};
