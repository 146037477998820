import { FC, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import createI18nInstance from "./createI18nInstance";
import routes from "./routes";
import { createTheme } from "./theme";
import { ConfirmationDialogProvider } from "./contextProviders/ConfirmationDialog";
import useAuth from "./contextProviders/Authentication";
import SplashScreen from "./components/SplashScreen";
import { MTMProvider } from "./contextProviders/MatomoTagManager";
import { useUserAppSettings } from "./contextProviders/UserAppSettings";
import { RouteObject } from "react-router";

const i18n = createI18nInstance();

const App: FC = () => {
  const content = useRoutes(routes as RouteObject[]);
  const { settings } = useUserAppSettings();
  const { isInitialized, isAuthenticated, user } = useAuth();
  const theme = createTheme({
    responsiveFontSizes: true,
    roundedCorners: false,
    theme: settings.theme,
  });

  // Setzt die jeweils aktuelle Sprache der App
  useEffect(() => {
    if (settings.language && settings.language != i18n.language) {
      i18n.changeLanguage(settings.language).then();
    }
  }, [settings]);

  const mtmParams = {
    id: process.env.REACT_APP_MATOMO_CONTAINER_ID as string,
    injectScript: isAuthenticated,
    userId: user ? user.email : undefined,
  };

  return (
    <MTMProvider state={mtmParams}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <ConfirmationDialogProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ whiteSpace: "pre-line" }}
            >
              <CssBaseline />
              {isInitialized ? content : <SplashScreen />}
            </SnackbarProvider>
          </ConfirmationDialogProvider>
        </I18nextProvider>
      </ThemeProvider>
    </MTMProvider>
  );
};

export default App;
