import { TFunction } from "i18next";
import * as Yup from "yup";
import { ObjectSchema } from "yup";
import BankUtils from "../../../utils/BankUtils";

export type SepaFormValues = {
  accountHolder: string;
  iban: string;
  bic: string;
  bankName: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<SepaFormValues> => {
  return Yup.object().shape({
    accountHolder: Yup.string().required(t("Forms.Required")),
    iban: Yup.string()
      .required(t("Settings.Sepa.IbanError"))
      .test(`test-valid-iban`, t("Settings.Sepa.IbanError"), function (value) {
        return BankUtils.isValidIBAN(value ?? "");
      }),
    bic: Yup.string().required(t("Forms.Required")),
    bankName: Yup.string().required(t("Forms.Required")),
  });
};
