import { DownloadFilter, DownloadSearchResult } from "../../../types/downloads";
import { FC, useMemo } from "react";
import { Box, Grid, IconButton, Link, Skeleton, Typography } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import * as React from "react";
import DownloadsCategoryChips from "./DownloadsCategoryChips";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import axios from "../../../lib/axiosForApi";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const ItemContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0 0 16px 0",
  marginBottom: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiSkeleton-text": {
    display: "inline-block",
    marginRight: 8,
  },
}));

export const Loader = () => {
  return (
    <>
      <Grid item xs={8} md={9}>
        <Typography noWrap variant="h6">
          <Skeleton width={300} />
        </Typography>
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={70} />
        <Skeleton variant="text" width={95} />
        <Skeleton variant="text" width={50} />
      </Grid>
      <Grid item xs={2} md={2} sx={{ textAlign: "right" }}>
        <Skeleton width={50} sx={{ display: "inline-block" }} />
      </Grid>
      <Grid item xs={2} md={1} sx={{ textAlign: "right" }}>
        <Skeleton variant="circular" width={40} height={40} />
      </Grid>
    </>
  );
};

const DownloadsSearchResultItem: FC<{ result?: DownloadSearchResult; allCategories?: DownloadFilter[] }> = (props) => {
  const { t } = useTranslation();
  const { result, allCategories } = props;
  const { enqueueSnackbar } = useSnackbar();

  const itemCategories: string[] = useMemo(() => {
    const c: string[] = [];
    if (allCategories && result) {
      result.labels.forEach((label: number) => {
        const category = _.find(allCategories ?? [], { id: label });
        if (typeof category !== "undefined" && category !== null) {
          c.push(category.title);
        }
      });
    }
    return c.sort();
  }, [result, allCategories]);

  const isExternalUrl = (url: string) => {
    return url.includes("https://secure.gd/");
  };

  const handleDownload = (url: string) => {
    return axios({
      url: `${process.env.REACT_APP_TYPO3_DOMAIN}/${url}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const href = window.URL.createObjectURL(response.data);
        const anchorElement = document.createElement("a");
        const urlSegments = url.split("/");
        const filename = urlSegments[2];

        anchorElement.href = href;
        anchorElement.download = filename;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(() => {
        enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
      });
  };

  return (
    <ItemContainer>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        {result ? (
          <>
            <Grid item xs={result.size ? 8 : 10} md={result.size ? 9 : 11}>
              <Typography noWrap variant="h6">
                {result.title}{" "}
                <Typography variant="caption" color="textSecondary">
                  [{result.language}]
                </Typography>
              </Typography>
              {result.description !== "" ? (
                <Typography noWrap color="textSecondary">
                  {result.description}
                </Typography>
              ) : null}

              <DownloadsCategoryChips categories={itemCategories} />
            </Grid>
            {result.size && (
              <Grid item xs={2} md={2} sx={{ textAlign: "right" }}>
                {result.size}
              </Grid>
            )}
            <Grid item xs={2} md={1} sx={{ textAlign: "right" }}>
              {isExternalUrl(result.url) ? (
                <IconButton
                  aria-label="Download"
                  color="primary"
                  component={Link}
                  href={result.url}
                  target="_blank"
                  rel="noopener"
                >
                  <DownloadOutlinedIcon sx={{ fontSize: "1.25em" }} />
                </IconButton>
              ) : (
                <IconButton aria-label="Download" color="primary" onClick={() => handleDownload(result.url)}>
                  <DownloadOutlinedIcon sx={{ fontSize: "1.25em" }} />
                </IconButton>
              )}
            </Grid>
          </>
        ) : (
          <Loader />
        )}
      </Grid>
    </ItemContainer>
  );
};

export default DownloadsSearchResultItem;
