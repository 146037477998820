import type { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../contextProviders/Authentication";

interface IGuestGuardProps {
  children?: ReactNode;
}
const GuestGuard: FC<IGuestGuardProps> = ({ children }) => {
  const { isAuthenticated, isMfaEnabled, isMfaVerified } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;
  const { from } = (location.state as { from: string }) || {
    from: { pathname: "/app" },
  };

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  if (isMfaEnabled && !isMfaVerified) {
    return <Navigate to="/mfa/verify/one-time-password" state={{ from: pathname }} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
