import { useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from "@mui/material";
import { getLanguage, SupportedLocale } from "../locales";
import { styled } from "@mui/material/styles";
import { useUserAppSettings } from "../contextProviders/UserAppSettings";

type LanguageOption = {
  icon: string;
  label: string;
};

export const languageOptions: Record<SupportedLocale, LanguageOption> = {
  en: {
    icon: "/app-files/static/flags/en.svg",
    label: "English",
  },
  de: {
    icon: "/app-files/static/flags/de.svg",
    label: "Deutsch",
  },
  es: {
    icon: "/app-files/static/flags/es.svg",
    label: "Español",
  },
  fr: {
    icon: "/app-files/static/flags/fr.svg",
    label: "Français",
  },
  it: {
    icon: "/app-files/static/flags/it.svg",
    label: "Italiano",
  },
  nl: {
    icon: "/app-files/static/flags/nl.svg",
    label: "Nederlands",
  },
};

const StyledBox = styled(Box)(() => ({
  display: "flex",
  height: 20,
  width: 20,
  "& img": {
    width: "100%",
  },
}));

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { setLanguage } = useUserAppSettings();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: SupportedLocale): void => {
    setLanguage(language);
    setOpen(false);
  };

  const selectedOption = languageOptions[getLanguage(i18n)];

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <StyledBox>
          <img alt={selectedOption.label} src={selectedOption.icon} />
        </StyledBox>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {(Object.keys(languageOptions) as SupportedLocale[]).map((language) => (
          <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
            <ListItemIcon>
              <StyledBox>
                <img alt={languageOptions[language].label} src={languageOptions[language].icon} />
              </StyledBox>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
