import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { getValidationSchema, RequestOfferFormValues } from "../formValidations/requestOffer";
import { useSnackbar } from "notistack";
import { Button, FormControl, FormGroup, FormLabel, Grid, MenuItem, Typography } from "@mui/material";
import { CheckBox, TextField, SelectField } from "../../../components/formFields";
import PersonIcon from "@mui/icons-material/Person";
import { ContactFormsApi } from "../../../api";
import { products, additionalModules, licenseDuration } from "../../../static";
import SuccessBox from "../../../components/SuccessBox";

const RequestOfferForm: FC = () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues: RequestOfferFormValues = {
    product: "",
    additionalModules: [""],
    licenseVolume: "" as unknown as number,
    licenseDuration: "" as unknown as number,
    message: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      ContactFormsApi.requestOfferSubmit({
        ...values,
        licenseVolume: values.licenseVolume as number,
        licenseDuration: values.licenseDuration as number,
      })
        .then((response: { data: { success: boolean } }) => {
          if (response.data.success) {
            setStatus({ success: true });
            setIsSubmitted(true);
            resetForm({});
          } else {
            setStatus({ success: false });
            enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
          }
        })
        .catch(() => {
          setStatus({ success: false });
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return isSubmitted ? (
    <SuccessBox
      title={t("RequestOffer.Response.Title")}
      text={t("RequestOffer.Response.Message")}
      buttonEvt={() => setIsSubmitted(false)}
      buttonTitle={t("RequestOffer.Response.BackBtn")}
    />
  ) : (
    <>
      <Typography sx={{ mb: 4 }}>{t("RequestOffer.Description")}</Typography>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 3 }}>
          <Grid item xs={12} lg={6} order={{ lg: 1 }}>
            <SelectField formik={formik} label={t("Forms.Product")} name="product" required>
              {products.map((product: { id: string; name: string }) => {
                return (
                  <MenuItem value={product.id} key={`productItem-${product.id}`}>
                    {product.name}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} order={{ lg: 2 }}>
            <TextField
              formik={formik}
              label={t("Forms.LicenseVolume")}
              name="licenseVolume"
              type="number"
              inputProps={{ min: 1 }}
              icon={<PersonIcon color="inherit" />}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={3} order={{ lg: 3 }}>
            <SelectField formik={formik} label={t("Forms.LicenseDuration")} name="licenseDuration" required>
              {licenseDuration.map((duration) => {
                return (
                  <MenuItem value={duration - 1} key={`duration-${duration}`}>
                    {duration} {t(`Common.${duration <= 1 ? "Year" : "Years"}`)}
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
          <Grid item xs={12} order={{ lg: 4 }}>
            <FormControl sx={{ mt: -1 }} component="fieldset">
              <FormLabel component="legend">{t("Forms.AdditionalModules")}</FormLabel>
              <FormGroup>
                <Grid container columnSpacing={2} rowSpacing={0} sx={{ mb: 2 }}>
                  {additionalModules.map((module: string, index: number) => {
                    return (
                      <Grid item xs={12} md={6} key={`module-${index}`}>
                        <CheckBox formik={formik} name="additionalModules" value={module} title={module} />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} order={{ xs: 5 }}>
            <TextField multiline rows={4} formik={formik} label={t("Forms.Message")} name="message" required />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs="auto">
            <Button type="submit" variant="contained" size="large" disabled={!formik.dirty || !formik.isValid}>
              {t("RequestOffer.Form.SubmitBtn")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default RequestOfferForm;
