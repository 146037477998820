import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page";

import NewLicenseCalculator from "./components/NewLicenseCalculator";

const Shop: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Sidebar.Shop.BuyNew")} subtitle={t("Sidebar.Shop.Title")} maxWidth={"md"}>
      <NewLicenseCalculator />
    </Page>
  );
};

export default Shop;
