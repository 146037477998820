import type { ReactNode } from "react";
import { Box, Collapse, Typography } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes, useImperativeHandle, useState } from "react";
import { SxProps } from "@mui/system";

export interface ICollapsableContentApi {
  setCollapsed: (collapsed: boolean) => void;
}
interface ICollapsableContentProps {
  headline: string;
  children: ReactNode;
  initialCollapsed: boolean;
  indentContent?: boolean;
  sx?: SxProps;
  headlineSx?: SxProps;
  contentSx?: SxProps;
}

const CollapsableContent: ForwardRefExoticComponent<
  Omit<ICollapsableContentProps, "ref"> & RefAttributes<ICollapsableContentApi>
> = forwardRef((props, ref) => {
  const { headline, children, sx, headlineSx, contentSx, indentContent, initialCollapsed } = props;
  const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);

  useImperativeHandle(ref, () => ({
    setCollapsed: setCollapsed,
  }));

  const toggleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };
  return (
    <Box sx={sx}>
      <Typography
        sx={{ mb: 2, mt: 2, fontWeight: 600, display: "flex", alignItems: "center", cursor: "pointer", ...headlineSx }}
        onClick={toggleCollapsed}
      >
        {collapsed ? <ExpandMoreOutlinedIcon sx={{ transform: "rotate(-90deg)" }} /> : <ExpandMoreOutlinedIcon />}
        <span>{headline}</span>
      </Typography>
      <Collapse sx={{ pl: indentContent ? "25px" : 0, ...contentSx }} in={!collapsed}>
        {children}
      </Collapse>
    </Box>
  );
});

export default CollapsableContent;
