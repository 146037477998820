import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../components/ContentBox";
import useAuth from "../../../contextProviders/Authentication";
import { Alert, Button, Grid, Link, TextField as MuiTextField } from "@mui/material";
import TextField from "../../../components/formFields/TextField";
import { UserApi } from "../../../api";
import { getValidationSchema, CompanyDataFormValues } from "../formValidations/companyDataChange";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import UserUtils from "../../../utils/UserUtils";

const CompanyDataForm: FC = () => {
  const [isFormSend, setIsFormSend] = useState(false);
  const { t } = useTranslation();
  const { user } = useAuth();
  const editUser = UserUtils.hasPermission(["dach"], user) && UserUtils.hasPermission(["shop"], user);
  const initialFormValues: CompanyDataFormValues = {
    address: user?.address || "",
    zip: user?.zip || "",
    city: user?.city || "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      UserApi.changeCompanyData(values.address, values.zip, values.city)
        .then((response) => {
          if (response) {
            setStatus({ success: true });
            setIsFormSend(true);
            enqueueSnackbar(t("Settings.PersonalDataSaved"), { variant: "success" });
          } else {
            setStatus({ success: false });
            enqueueSnackbar(t("Settings.SaveError"), { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("Settings.SaveError"), { variant: "error" });
          setStatus({ success: false });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return user ? (
    <ContentBox headline={t("Settings.CompanyAddress")}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={3}>
            <MuiTextField label={t("Forms.CustomerNumber")} value={user.customerNo} fullWidth disabled />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MuiTextField label={t("Forms.CompanyName")} value={user.company} fullWidth disabled />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <TextField
              formik={formik}
              name={"address"}
              label={t("Forms.Address")}
              fullWidth
              required={editUser}
              disabled={!editUser}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <TextField
              formik={formik}
              name={"zip"}
              label={t("Forms.Zip")}
              fullWidth
              required={editUser}
              disabled={!editUser}
            />
          </Grid>
          <Grid item xs={8} lg={4}>
            <TextField
              formik={formik}
              name={"city"}
              label={t("Forms.City")}
              fullWidth
              required={editUser}
              disabled={!editUser}
            />
          </Grid>
        </Grid>
        {editUser && (
          <Grid container justifyContent="flex-end" sx={{ mb: 4 }}>
            <Grid item xs="auto">
              <Button fullWidth size="large" type="submit" variant="contained" disabled={!formik.dirty || isFormSend}>
                {t("Forms.Save")}
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
      <Alert variant="outlined" severity="info" sx={{ mt: 2 }}>
        {editUser ? t("Settings.CompanyDataEditInfoExtra") : t("Settings.CompanyDataEditInfo")}{" "}
        <Link href="mailto:order@gdata.de">order@gdata.de</Link>
      </Alert>
    </ContentBox>
  ) : (
    <></>
  );
};

export default CompanyDataForm;
