import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import {
  BasketApi,
  ContactsApi,
  ContractsApi,
  DashboardApi,
  DownloadsApi,
  FaqsApi,
  InvoiceApi,
  LicenseApi,
  PaymentApi,
  ShopApi,
  TrialsApi,
  Typo3PageApi,
} from "../api";
import { Contract } from "../types/contracts";
import { EndpointProtectionTrialsFormSettings } from "../types/trials";
import { Faq } from "../types/faqs";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../locales";
import { DownloadFilter } from "../types/downloads";
import { Typo3Page } from "../types/typo3Page";
import { LicenseCenterRow, ProductCode, Reseller } from "../types/license";
import { DashboardTile } from "../types/dashboard";
import { Contact } from "../types/contacts";
import { InvoiceRow } from "../types/invoice";
import { Basket } from "../types/basket";
import { AvailableProduct, Payment, SpecialEdition } from "../types/shop";
import { ISepaDataResponse } from "../api/payment";

export const KEY_CONTRACTS = "contracts";
export const KEY_DASHBOARD_TILES = "dashboard_tiles";
export const KEY_EP_TRIAL_SETTINGS = "ep_trial_settings";
export const KEY_FAQS = "faq";
export const KEY_DOWNLOADS_FILTERS = "downloads_filters";
export const KEY_DOWNLOADS_LABELS = "downloads_labels";
export const KEY_TYPO3PAGE = "typo3page";
export const KEY_LICENSES = "licenses";
export const KEY_CONTACTS = "contacts";
export const KEY_RESELLERS = "resellers";
export const KEY_INVOICES = "invoices";
export const KEY_BASKET_GET = "basket_get";
export const KEY_SHOP_PRODUCTS = "products_get";
export const KEY_PAYMENTS = "payments_get";
export const KEY_COUNTRIES = "countries_get";
export const KEY_SEPA_DATA = "sepa_data";
export const useGetContracts = (): UseQueryResult<Contract[]> => {
  return useQuery([KEY_CONTRACTS], async () => ContractsApi.getAll());
};

export const useGetTypo3Page = (route: string, languageCode: string): UseQueryResult<Typo3Page> => {
  return useQuery([KEY_TYPO3PAGE, languageCode, route], async () => Typo3PageApi.getT3Page(route, languageCode));
};

export const useGetEndpointProtectionTrialSettings = (): UseQueryResult<EndpointProtectionTrialsFormSettings> => {
  return useQuery([KEY_EP_TRIAL_SETTINGS], async () => TrialsApi.getFormSettings());
};

export const useGetFaqs = (): UseQueryResult<Faq[]> => {
  const { i18n } = useTranslation();
  const languageCode = getLanguage(i18n);
  return useQuery([KEY_FAQS, languageCode], async () => FaqsApi.getAll(languageCode));
};

export const useGetDashboardTiles = (): UseQueryResult<DashboardTile[]> => {
  const { i18n } = useTranslation();
  const languageCode = getLanguage(i18n);
  return useQuery([KEY_DASHBOARD_TILES, languageCode], async () => DashboardApi.getTiles(languageCode));
};

export const useGetDownloadFilters = (): UseQueryResult<DownloadFilter[]> => {
  const { i18n } = useTranslation();
  const languageCode = getLanguage(i18n);
  return useQuery([KEY_DOWNLOADS_FILTERS, languageCode], async () => DownloadsApi.getFilters("filters", languageCode));
};

export const useGetDownloadLabels = (): UseQueryResult<DownloadFilter[]> => {
  const { i18n } = useTranslation();
  const languageCode = getLanguage(i18n);
  return useQuery([KEY_DOWNLOADS_LABELS, languageCode], async () => DownloadsApi.getFilters("labels", languageCode));
};

export const useGetLicenseCenterResellerData = (resellerNo?: string): UseQueryResult<LicenseCenterRow[]> => {
  return useQuery(resellerNo ? [KEY_LICENSES, resellerNo] : [KEY_LICENSES], async () =>
    LicenseApi.search({ page: 1, pageSize: 20000, withResellerInfo: false, resellerNo: resellerNo })
  );
};

export const useSearchContacts = (): UseQueryResult<Contact[]> => {
  return useQuery([KEY_CONTACTS], async () => ContactsApi.getAll());
};

export const useGetResellerForDistributor = (customerNo?: string): UseQueryResult<Reseller[]> => {
  return useQuery(customerNo ? [KEY_RESELLERS, customerNo] : [KEY_RESELLERS], async () =>
    LicenseApi.getResellers(customerNo)
  );
};

export const useGetInvoices = (): UseQueryResult<InvoiceRow[]> => {
  return useQuery([KEY_INVOICES], async () => InvoiceApi.getAll());
};

export const useGetBasket = (): UseQueryResult<Basket> => {
  return useQuery([KEY_BASKET_GET], async () => BasketApi.get());
};

export const useGetPayments = (): UseQueryResult<Payment[]> => {
  return useQuery([KEY_PAYMENTS], async () => ShopApi.getPayments());
};

export const useGetCountries = (): UseQueryResult<string[]> => {
  return useQuery([KEY_COUNTRIES], async () => ShopApi.getCountries());
};

export const useGetProducts = (
  customerNo?: string
): UseQueryResult<{
  availableProducts: Record<ProductCode, AvailableProduct>;
  specialEditions: SpecialEdition[];
} | null> => {
  return useQuery(customerNo ? [KEY_SHOP_PRODUCTS, customerNo] : [KEY_SHOP_PRODUCTS], async () =>
    ShopApi.getProducts(customerNo)
  );
};

export const useGetSepaData = (): UseQueryResult<ISepaDataResponse> => {
  return useQuery([KEY_SEPA_DATA], async () => PaymentApi.getSepaData());
};

export const useInvalidateQueryClientData = () => {
  const queryClient = useQueryClient();

  function invalidateAll(): void {
    queryClient.clear();
  }

  function invalidateContracts(): Promise<void> {
    return queryClient.invalidateQueries([KEY_CONTRACTS]);
  }

  function invalidateResellers(): Promise<void> {
    return queryClient.invalidateQueries([KEY_RESELLERS]);
  }

  function invalidatePayments(): Promise<void> {
    return queryClient.invalidateQueries([KEY_PAYMENTS]);
  }

  function invalidateAllBasketData(): void {
    queryClient.invalidateQueries([KEY_PAYMENTS]).then();
    queryClient.invalidateQueries([KEY_BASKET_GET]).then();
  }

  function invalidateSepaData(): Promise<void> {
    return queryClient.invalidateQueries([KEY_SEPA_DATA]);
  }

  return {
    invalidateAll,
    invalidateContracts,
    invalidateResellers,
    invalidatePayments,
    invalidateAllBasketData,
    invalidateSepaData,
  };
};
