import { TFunction } from "i18next";
import { ObjectSchema } from "yup";
import * as Yup from "yup";

export type AddLicenseFormValues = {
  registrationNumberOrLogin: string;
  email: string;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<AddLicenseFormValues> => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return Yup.object().shape({
    registrationNumberOrLogin: Yup.string().required(
      t("Forms.FieldIsRequired", {
        defaultValue: "is required.",
        field: t("LicenseCenter.AddLicense.Form.RegistrationNumberOrLogin"),
      }) as string
    ),
    email: Yup.string()
      .matches(emailRegex, t("Forms.ValidEmail"))
      .required(
        t("Forms.FieldIsRequired", {
          defaultValue: "is required.",
          field: t("LicenseCenter.AddLicense.Form.Email"),
        }) as string
      ),
  });
};
