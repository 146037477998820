import * as Yup from "yup";
import { TFunction } from "i18next";
import { ObjectSchema } from "yup";

export type ProjectsFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  position: number;
  street: string;
  postCode: number;
  city: string;
  email: string;
  phone: string;
  product: string;
  licenseType: string;
  licenseVolume: number;
  additionalModules?: string[];
  currentProvider: number;
  message: string;
  licenseExpirationDate: Date;
  projectStartDate: Date;
  acceptTerms: boolean;
};

export const getValidationSchema = (t: TFunction): ObjectSchema<ProjectsFormValues> => {
  return Yup.object().shape({
    firstName: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.FirstName"),
      }) ?? ""
    ),
    lastName: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.LastName"),
      }) ?? ""
    ),
    companyName: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.CompanyName"),
      }) ?? ""
    ),
    position: Yup.number().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Position"),
      }) ?? ""
    ),
    street: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Address"),
      }) ?? ""
    ),
    postCode: Yup.number()
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.Zip"),
        }) ?? ""
      )
      .typeError(t("Forms.Required")),
    city: Yup.string()
      .min(
        2,
        t("Forms.TooShort", {
          minLength: 2,
          field: t("Forms.City"),
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.City"),
        }) ?? ""
      ),
    email: Yup.string()
      .email(t("Forms.ValidEmail") ?? "")
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.Email"),
        }) ?? ""
      ),
    phone: Yup.string()
      .min(
        6,
        t("Forms.TooShort", {
          minLength: 6,
          field: t("Forms.Phone"),
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.Phone"),
        }) ?? ""
      ),
    product: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.Product"),
      }) ?? ""
    ),
    licenseType: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Forms.LicenseType"),
      }) ?? ""
    ),
    licenseVolume: Yup.number()
      .moreThan(
        0,
        t("Forms.MoreThan", {
          field: t("Forms.LicenseVolume"),
          value: 0,
        }) ?? ""
      )
      .required(
        t("Forms.FieldIsRequired", {
          field: t("Forms.LicenseVolume"),
        }) ?? ""
      ),
    additionalModules: Yup.array().optional(),
    currentProvider: Yup.number().required(
      t("Forms.FieldIsRequired", {
        field: t("Projects.Form.CurrentProvider"),
      }) ?? ""
    ),
    message: Yup.string().required(
      t("Forms.FieldIsRequired", {
        field: t("Projects.Form.Message"),
      }) ?? ""
    ),
    licenseExpirationDate: Yup.date().required(
      t("Forms.FieldIsRequired", {
        field: t("Projects.Form.LicenseExpirationDate"),
      }) ?? ""
    ),
    projectStartDate: Yup.date().required(
      t("Forms.FieldIsRequired", {
        field: t("Projects.Form.ProjectStartDate"),
      }) ?? ""
    ),
    acceptTerms: Yup.boolean()
      .oneOf([true], t("Forms.Required") ?? "")
      .required(t("Forms.Required") ?? ""),
  });
};
