import React, { FC } from "react";
import MaterialUiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RawHeadline } from "../base";
import { ContentElement } from "../../../../types/typo3Page";

function formatCellContent(content: string) {
  return content.replace(`/app-pages/`, `/app/p/`);
}

export const Table: FC<{ data: ContentElement }> = ({ data }) => {
  const renderTableHead = data.content.tableHeaderPosition === 1;
  const bodytext = data.content.bodytext as Array<[]>;
  return (
    <>
      <RawHeadline
        header={data.content.header}
        headerLayout={data.content.headerLayout}
        subheader={data.content.subheader}
      />
      <TableContainer component={Paper}>
        <MaterialUiTable aria-label="customized table">
          {renderTableHead && bodytext[0] && (
            <TableHead>
              <TableRow>
                {bodytext[0].map((cell: string, index: number) => (
                  <TableCell key={`cell-1-${index}`} dangerouslySetInnerHTML={{ __html: formatCellContent(cell) }} />
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {bodytext.map((row, rIndex) =>
              renderTableHead && rIndex === 0 ? (
                <></>
              ) : (
                <TableRow key={`row-${rIndex}`}>
                  {row.map((cell, cIndex) => (
                    <TableCell
                      key={`cell-${rIndex}-${cIndex}`}
                      dangerouslySetInnerHTML={{
                        __html: formatCellContent(cell),
                      }}
                    />
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        </MaterialUiTable>
      </TableContainer>
    </>
  );
};

export default Table;
