import React, { BaseSyntheticEvent, FC, useEffect, useMemo, useState } from "react";
import FormatUtils from "../../../utils/FormatUtils";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { ArticleVariantType, BasketItem as BasketItemType } from "../../../types/basket";
import useAuth from "../../../contextProviders/Authentication";
import { useTranslation } from "react-i18next";
import { useShop } from "../../../contextProviders/Shop";
import ClearIcon from "@mui/icons-material/Clear";
import { BasketItemApi } from "../../../api";
import { KEY_BASKET_GET, KEY_PAYMENTS, useInvalidateQueryClientData } from "../../../lib/queryClient";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { debounce } from "lodash";
import CollapsableContent from "../../../components/CollapsableContent";
import { BasketItemHeader } from "../../../theme/styledComponents/Basket";
import { RebatePopover } from "./RebatePopover";

interface IBasketItemProps {
  basketItem: BasketItemType;
  isCheckoutMode: boolean;
}

export const BasketItem: FC<IBasketItemProps> = ({ basketItem, isCheckoutMode }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { basketItemSidebar } = useShop();
  const queryClient = useQueryClient();
  const { invalidatePayments } = useInvalidateQueryClientData();
  const [quantity, setQuantity] = useState<number>(basketItem.quantity);

  const address = basketItem.registrationAddress;

  const deleteBasketItem = async (basketItemUid: number) => {
    try {
      const updatedBasket = await BasketItemApi.delete(basketItemUid);
      queryClient.setQueryData([KEY_BASKET_GET], updatedBasket);
      enqueueSnackbar(t("Basket.Messages.ItemDeleted"), { variant: "success" });
      await invalidatePayments();
    } catch (e) {
      enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
    }
  };

  const debouncedChangeQuantity = useMemo(
    () =>
      debounce(async (basketItemUid: number, changedQuantity: number) => {
        const updatedBasket = await BasketItemApi.change(basketItemUid, { quantity: changedQuantity });
        queryClient.setQueryData([KEY_BASKET_GET], updatedBasket);
        await queryClient.invalidateQueries([KEY_PAYMENTS]);
      }, 500),
    [queryClient]
  );

  useEffect(() => {
    return () => {
      debouncedChangeQuantity.cancel();
    };
  }, [debouncedChangeQuantity]);

  const collapsableHeadline = () => {
    if (address) {
      if (address.company) {
        return address.company;
      }
      if (address.firstName || address.lastName) {
        return `${address.firstName} ${address.lastName}`;
      }
      return null;
    }
    return null;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <BasketItemHeader container>
            <Grid item xs={12} md={9}>
              <Typography variant="h5">{basketItem.description}</Typography>
            </Grid>
            <Grid item xs={8} md={1} display="flex" justifyContent="flex-end">
              {isCheckoutMode ? (
                <Typography>
                  <strong>{quantity}</strong> <small>✕</small>
                </Typography>
              ) : (
                <>
                  <TextField
                    sx={{ width: "4em" }}
                    type="number"
                    name="quantity"
                    size="small"
                    value={quantity}
                    disabled={!basketItem.isQuantityChangeAllowed}
                    onChange={(event: BaseSyntheticEvent) => {
                      const changedQuantity = event.target.value;
                      debouncedChangeQuantity(basketItem.uid, changedQuantity);
                      setQuantity(changedQuantity);
                    }}
                    inputProps={{ min: "1", sx: { height: "1em" } }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={2} md={1} display="flex" justifyContent="flex-start">
              {!basketItem.isDependingItem && !isCheckoutMode && (
                <IconButton sx={{ cursor: "pointer" }} onClick={() => deleteBasketItem(basketItem.uid)}>
                  <ClearIcon color={"error"} />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2} md={1} display="flex" justifyContent="flex-end">
              <strong>{FormatUtils.getFormattedPrice(basketItem.priceNet, i18n, user)}</strong>
              {basketItem.priceNet > 0 && (
                <RebatePopover
                  prices={[
                    {
                      finalPrice: basketItem.priceNet,
                      basePrice: basketItem.basePriceNet,
                      retailPrice: basketItem.retailPriceNet,
                      discounts: basketItem.discounts,
                    },
                  ]}
                  id={basketItem.uid}
                />
              )}
            </Grid>
          </BasketItemHeader>
        </Grid>
        <Grid item xs={12} sx={!isCheckoutMode && !basketItem.isDependingItem ? { mb: 1 } : { mb: 2 }}>
          <Grid item xs={12} pl={2}>
            <Typography>
              {basketItem.abbreviation != "VPN" && (
                <>
                  {t("Common.Devices")}: {basketItem.licenseCount},{" "}
                </>
              )}
              {!basketItem.validUntil
                ? t("Basket.Duration", { duration: basketItem.duration })
                : `${t("Basket.ExpirationDate")}: ${i18n.format(new Date(basketItem.validUntil), "P", i18n.language)}`}
              {basketItem.rebate != 0 && (
                <>
                  {", "}
                  {t("Basket.Rebate")}: {FormatUtils.getFormattedPercent(basketItem.rebate, i18n)}
                </>
              )}
            </Typography>
            {basketItem.registrationNumbers && (
              <Typography>
                {t("Basket.RegNo")}: {basketItem.registrationNumbers}
              </Typography>
            )}
            {basketItem.isSubscription && (
              <Typography>
                {t("Basket.SubscriptionActivated", {
                  subscriptionDuration: basketItem.subscriptionDuration,
                  subscriptionPrice: FormatUtils.getFormattedPrice(basketItem.subscriptionPriceNet, i18n, user),
                })}
              </Typography>
            )}
            <Box sx={{ ml: -1 }}>
              {address && collapsableHeadline() != null && (
                <CollapsableContent
                  headline={collapsableHeadline() ?? ""}
                  indentContent={true}
                  headlineSx={{ mt: 0, mb: 0, fontWeight: 400 }}
                  contentSx={{ mb: 0, pb: 0, mt: 0, pt: 0 }}
                  initialCollapsed={true}
                >
                  {address.company && address.firstName && (
                    <Typography>
                      {address.firstName} {address.lastName}
                    </Typography>
                  )}
                  {address.street && <Typography>{address.street}</Typography>}
                  {address.addition && <Typography>{address.addition}</Typography>}
                  <Typography>
                    {address.postcode} {address.city} / {address.country}
                  </Typography>
                  <Typography>{address.email}</Typography>
                </CollapsableContent>
              )}
              {basketItem.coupon && basketItem.coupon.value !== null && (
                <Grid container>
                  <Grid item xs={10}>
                    <Typography>
                      {basketItem.coupon.title} {FormatUtils.getFormattedPercent(basketItem.coupon.value, i18n)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography component="div" align={"right"}>
                      - <strong>{FormatUtils.getFormattedPrice(basketItem.rebateNet, i18n, user)}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {!isCheckoutMode && (
                <>
                  {!basketItem.isDependingItem && (
                    <Button variant="text" onClick={() => basketItemSidebar?.current?.open(basketItem, false)}>
                      <strong>
                        {[
                          ArticleVariantType.ADDON_NEW,
                          ArticleVariantType.B2C_NEW,
                          ArticleVariantType.B2B_NEW,
                        ].includes(basketItem.articleVariantType)
                          ? t("Basket.EditBasketItem")
                          : t("Basket.EditBasketItemAlt")}
                      </strong>
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
