import React, { FC, MouseEventHandler, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertColor,
  Box as MuiBox,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TextField } from "../../../components/formFields";
import { LicenseApi } from "../../../api";
import { useSnackbar } from "notistack";
import { LicenseCenterCheckLicenseResult } from "../../../types/license";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import _ from "lodash";
import { CheckLicenseFormValues, getValidationSchema } from "../formValidations/checkLicense";
import { styled } from "@mui/material/styles";
import Dialog from "../../../components/Dialog";
import { LoadingButton } from "@mui/lab";

const Box = styled(MuiBox)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up("xs")]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    marginTop: 0,
  },
}));

const ResellerLicense: FC<{ license: LicenseCenterCheckLicenseResult["license"] }> = ({ license }) => {
  const { t, i18n } = useTranslation();

  const getStatusSeverity = (status: string): AlertColor => {
    switch (status) {
      case "OK":
        return "success";
      case "NOT_ACTIVE":
        return "info";
      case "NOT_ACTIVATABLE":
      case "NOT_VALID":
        return "error";
      default:
        return "error";
    }
  };

  return (
    <>
      <Typography>
        <strong>{t("LicenseCenter.CheckLicense.Product")}</strong> {license.product}
      </Typography>
      <Alert severity={getStatusSeverity(license.status)} variant="outlined" sx={{ my: 2 }}>
        {t(`LicenseCenter.CheckLicense.Alert.${license.status}`)}
      </Alert>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("LicenseCenter.CheckLicense.Status")}</TableCell>
              <TableCell>{t("LicenseCenter.CheckLicense.LicenseCount")}</TableCell>
              {license.licenseDuration != 0 && <TableCell>{t("LicenseCenter.CheckLicense.LicenseDuration")}</TableCell>}
              {_.isNumber(license.expirationDate) && (
                <TableCell>{t("LicenseCenter.CheckLicense.ExpirationDate")}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t(`LicenseCenter.CheckLicense.StatusType.${license.status}`)}</TableCell>
              <TableCell>{license.licenseCount}</TableCell>
              {license.licenseDuration != 0 && (
                <TableCell>
                  {license.licenseDuration} {t("LicenseCenter.CheckLicense.Months")}
                </TableCell>
              )}
              {_.isNumber(license.expirationDate) && (
                <TableCell>{DateTimeUtils.getLocalDate(i18n, license.expirationDate as number, "P")}</TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const NonResellerLicense: FC<{ status: string }> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <>
      {status === "OK" || status === "NOT_ACTIVE" ? (
        <Alert severity="success" variant="outlined">
          {t("LicenseCenter.CheckLicense.Alert.ACTIVE")}
        </Alert>
      ) : (
        <Alert severity="error" variant="outlined">
          {t("LicenseCenter.CheckLicense.Alert.INACTIVE")}
        </Alert>
      )}
    </>
  );
};

const CheckLicense: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [licenseData, setLicenseData] = useState<LicenseCenterCheckLicenseResult | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialFormValues: CheckLicenseFormValues = {
    registrationNumberOrLogin: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidationSchema(t),
    onSubmit: async (values) => {
      setIsLoading(true);

      LicenseApi.checkLicense(values.registrationNumberOrLogin)
        .then((data) => {
          setLicenseData(data);
        })
        .catch(() => {
          enqueueSnackbar(t("Common.UnspecifiedError"), { variant: "error" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <Typography sx={{ mb: 2 }}>{t("LicenseCenter.CheckLicense.Description")}</Typography>
      <form onSubmit={formik.handleSubmit} noValidate>
        <TextField
          formik={formik}
          name="registrationNumberOrLogin"
          label={t("LicenseCenter.CheckLicense.Form.RegistrationNumberOrLogin")}
          required
        />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          size="large"
          loading={isLoading}
          disabled={!formik.dirty || isLoading}
        >
          {t("LicenseCenter.CheckLicense.Form.SubmitBtn")}
        </LoadingButton>
      </form>
      {!isLoading && licenseData !== null && (
        <Box>
          <Divider sx={{ mb: 2 }} />
          {licenseData.success ? (
            <>
              {licenseData.license.isReseller ? (
                <ResellerLicense license={licenseData.license} />
              ) : (
                <NonResellerLicense status={licenseData.license.status} />
              )}
            </>
          ) : (
            <Alert severity="error" variant="outlined">
              {t("LicenseCenter.CheckLicense.Alert.NOT_FOUND")}
            </Alert>
          )}
        </Box>
      )}
    </>
  );
};

interface LicenseCenterCheckLicenseDialogProps {
  show: boolean;
  handleClose: MouseEventHandler;
}

const LicenseCenterCheckLicenseDialog: FC<LicenseCenterCheckLicenseDialogProps> = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      show={show}
      handleClose={handleClose}
      maxWidth="sm"
      title={t("LicenseCenter.CheckLicense.Title")}
      content={<CheckLicense />}
    />
  );
};

export default LicenseCenterCheckLicenseDialog;
