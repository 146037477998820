import { FC, MouseEventHandler } from "react";
import { Box, Button, Typography, Zoom } from "@mui/material";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";

interface ISuccessBox {
  title: string;
  text: string;
  buttonEvt?: MouseEventHandler;
  buttonTitle?: string;
}
const SuccessBox: FC<ISuccessBox> = ({ title, text, buttonEvt, buttonTitle }) => {
  return (
    <Zoom in timeout={500}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <GppGoodOutlinedIcon sx={{ fontSize: "5em" }} color="success" />
        <Typography>{text}</Typography>
        {buttonEvt && buttonTitle && (
          <Button variant="contained" onClick={buttonEvt} sx={{ mt: 2 }}>
            {buttonTitle}
          </Button>
        )}
      </Box>
    </Zoom>
  );
};

export default SuccessBox;
