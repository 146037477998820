import React, { ReactNode, createContext, useEffect, useContext, useReducer, Dispatch, useState } from "react";

type DataLayerData = { [key: string]: unknown };
type MTMHookProviderProps = { state: MTMProviderState; children: ReactNode };
type MTMProviderState = { id: string; injectScript?: boolean; userId?: string };

declare global {
  interface Window {
    _mtm: { push: (data: DataLayerData) => void };
  }
}

const initialState: MTMProviderState = {
  id: "",
  injectScript: false,
  userId: "",
};

const sendToMTM = (data: DataLayerData): void => {
  window._mtm = window._mtm || [];
  window._mtm.push(data);
};

const setUserId = (userId: string): void => {
  sendToMTM({
    event: "initPP",
    pp_user: userId,
  });
};

const dataReducer = (state: MTMProviderState, data: DataLayerData) => {
  sendToMTM({ data });
  return state;
};

const MTMContext = createContext<MTMProviderState>(initialState);
const MTMContextDispatch = createContext<Dispatch<DataLayerData> | undefined>(undefined);

/**
 * The Matomo Tag Manager Provider
 */
export const MTMProvider = ({ state, children }: MTMHookProviderProps): JSX.Element => {
  const [store, dispatch] = useReducer(dataReducer, { ...initialState, ...state });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (state.userId !== undefined) {
      setUserId(state.userId);
    }
  }, [state.userId]);

  useEffect(() => {
    if (state.injectScript === false || !state.id) return;
    if (!isInitialized) {
      const script = document.createElement("script");
      script.innerHTML = `window._mtm = window._mtm || [];
        window._mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.defer=true; g.src='https://tracking.gdatasoftware.com/js/container_${state.id}.js'; s.parentNode.insertBefore(g,s);
      `;
      document.head.insertBefore(script, document.head.childNodes[1]);
      setIsInitialized(true);
    }
  }, [state.id, state.injectScript, isInitialized]);

  return (
    <MTMContext.Provider value={store}>
      <MTMContextDispatch.Provider value={dispatch}>{children}</MTMContextDispatch.Provider>
    </MTMContext.Provider>
  );
};

/**
 * Sending data to GTM
 *
 * use dispatcher:
 *    const sendDataToMTM = useGTMDispatch()
 *    const handleClick = () => sendDataToMTM({ event: 'awesomeButtonClicked', myValueName: 'myValue' })
 */

export const useMTMDispatch = () => {
  const context = useContext(MTMContextDispatch);
  if (context === undefined) {
    throw new Error("dispatchMTMEvent must be used within a MTMProvider");
  }
  return context;
};
