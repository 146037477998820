import React, { FC, ReactNode } from "react";
import { Checkbox as MuiCheckbox, FormHelperText, Typography, CheckboxProps } from "@mui/material";
import { FormikProps, getIn } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";

type CheckBoxProps<FormValues> = CheckboxProps & {
  formik: FormikProps<FormValues>;
  defaultChecked?: boolean;
  name: string;
  title: string;
  description?: ReactNode;
  value: string;
  required?: boolean;
  hideHelperText?: boolean;
};

const CheckBox = <FormValues,>(props: CheckBoxProps<FormValues>): ReturnType<FC> => {
  const { formik, defaultChecked, description, name, title, value, required, hideHelperText, ...rest } = props;
  return (
    <>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            defaultChecked={defaultChecked}
            value={value}
            onChange={formik.handleChange}
            required={required}
            {...rest}
          />
        }
        label={
          <>
            {title}
            {description ? (
              typeof description === "string" ? (
                <Typography variant="subtitle2">{description}</Typography>
              ) : (
                { description }
              )
            ) : null}
          </>
        }
      />
      {!hideHelperText && getIn(formik.touched, name) && getIn(formik.errors, name) && (
        <FormHelperText error>{getIn(formik.touched, name) && getIn(formik.errors, name)}</FormHelperText>
      )}
    </>
  );
};

export default CheckBox;
