import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { User, UserSettings } from "../types/user";
import { AuthApi, UserApi } from "../api";
import axios from "axios";
import { useInvalidateQueryClientData } from "../lib/queryClient";
import TokenUtils from "../utils/TokenUtils";
import { useEffectOnce } from "../hooks/useEffectOnce";

interface IAuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isMfaEnabled: boolean;
  isMfaVerified: boolean;
  user?: User;
}

interface AuthContextType extends IAuthState {
  login: (username: string, password: string) => Promise<number>;
  logout: () => void;
  setMfaVerified: () => Promise<boolean>;
  updateUserSettings: (settings: Partial<UserSettings>) => Promise<boolean>;
  updateUser: (updatedUser: Partial<User>) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const [state, setState] = useState<IAuthState>({
    isAuthenticated: false,
    isInitialized: false,
    isMfaEnabled: false,
    isMfaVerified: false,
    user: undefined,
  });

  const { invalidateAll } = useInvalidateQueryClientData();

  const initialize = async () => {
    let accessToken = TokenUtils.getAccessToken();
    if (!accessToken && TokenUtils.getRefreshToken()) {
      accessToken = await AuthApi.refresh();
    }
    if (AuthApi.isValid()) {
      UserApi.getCurrentUser()
        .then((currentUser) => {
          const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) : null;
          if (payload) {
            currentUser.roles = payload.roles;
            currentUser.uid = payload.uid;
          }
          setState((s) => ({
            ...s,
            isInitialized: true,
            isAuthenticated: true,
            isMfaEnabled: currentUser.isMfaEnabled,
            isMfaVerified: payload ? payload.isMfaVerified : false,
            user: currentUser,
          }));
        })
        .catch((error) => {
          if (error.response.status === 423) {
            console.error("BC Error. Please contact G DATA Support.");
          }
          setState((s) => ({
            ...s,
            isInitialized: true,
            isAuthenticated: false,
            isMfaEnabled: false,
            isMfaVerified: false,
            user: undefined,
          }));
        });
    } else {
      setState((s) => ({
        ...s,
        isInitialized: true,
        isAuthenticated: false,
        isMfaEnabled: false,
        isMfaVerified: false,
        user: undefined,
      }));
    }
  };

  useEffectOnce(() => {
    initialize();
  });

  const login = async (username: string, password: string): Promise<number> => {
    // Query Cache leeren, damit keine fremden Daten aus dem Cache geholt werden.
    await invalidateAll();
    try {
      await AuthApi.login(username, password);
      await initialize();
      return 200;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response ? error.response.status : 999;
      }
      return 999;
    }
  };

  const logout = async () => {
    AuthApi.logout().then(() =>
      setState((s) => ({
        ...s,
        isAuthenticated: false,
        isMfaEnabled: false,
        isMfaVerified: false,
        user: undefined,
      }))
    );
  };

  const setMfaVerified = async (): Promise<boolean> => {
    setState((s) => ({
      ...s,
      isMfaVerified: true,
    }));

    return true;
  };

  const updateUser = (updatedUserData: Partial<User>) => {
    setState((s) => {
      if (s.user === undefined) {
        return s;
      }
      return {
        ...s,
        user: {
          ...s.user,
          ...updatedUserData,
        },
      };
    });
  };

  const updateUserSettings = async (settings: Partial<UserSettings>): Promise<boolean> => {
    try {
      const updatedSettings = await UserApi.updateSettings(settings);
      updateUser({ settings: updatedSettings });
      return true;
    } catch (e) {
      return false;
    }
  };

  const memoedValue = useMemo(
    () => ({
      ...state,
      login,
      logout,
      setMfaVerified,
      updateUserSettings,
      updateUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  return useContext(AuthContext);
}
