import type { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface AppTitleProps {
  typography?: TypographyProps;
}

const renderAppTitle = ({ typography }: { typography?: TypographyProps }): JSX.Element => {
  return (
    <Typography className="appTitle" fontWeight={300} {...typography}>
      | Partnerportal
      {process.env.REACT_APP_APP_CONTEXT !== "production" && (
        <i style={{ fontSize: "50%", marginLeft: ".5em" }}>{process.env.REACT_APP_APP_CONTEXT}</i>
      )}
    </Typography>
  );
};

export const AppTitle: FC<AppTitleProps> = ({ typography }) => {
  return renderAppTitle({ typography });
};
